import { BaseFilterField, FilterFieldType } from '@models/filters/base-filter-field';
import { Chat } from '@models/chats';

export class ChatFilterFieldActiveMember extends BaseFilterField<Chat, boolean, true> {
    id: string = 'member';
    name: string = 'Вы Участник';
    desc: string = 'Вы участник чата';
    isFlag: boolean = true;
    type: FilterFieldType.Flag = FilterFieldType.Flag;
    single = true as const;

    value(o: Chat, userId?: string): boolean {
        return !!userId && !!o._membersMap[userId];
    }

    match(o: Chat, v?: string, data?: { userId?: string }): boolean {
        return o && ((v && data?.userId && !!o._membersMap[data.userId]) || !data?.userId || !v);
    }

}
