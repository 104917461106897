import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { BaseComponent } from '@base/base.component';
import {
    Tag, ContactTypeName, ContactSubTypeName,
    CONTACT_TYPE_LIST, CONTACT_SUBTYPE_MAP, ContactType, Person,
} from '@models';
import { AuthService, HelpersService, StoreService } from '@services';
import { ErrorMessageModule } from '../../_utils';

@Component({
    selector: 'contact-edit',
    templateUrl: './contact-edit.component.html',
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        NzFormModule, NzSelectModule, NzInputModule, NzButtonModule,
        ErrorMessageModule
    ]
})
@Tag('ContactEditComponent')
export class ContactEditComponent extends BaseComponent implements OnInit {

    @Input() person?: Person;
    @Input() contactId?: string | null;
    @Output() actionDone: EventEmitter<void> = new EventEmitter();

    form!: FormGroup;
    selectedType: ContactType | undefined;
    working: boolean = false;

    CTN = ContactTypeName;
    CSTN = ContactSubTypeName;
    CTL = CONTACT_TYPE_LIST;
    CSM = CONTACT_SUBTYPE_MAP;

    constructor(
        protected _store: StoreService,
        private _auth: AuthService,
        private _fb: FormBuilder,
        private _h: HelpersService,
    ) {
        super(_store);
        this.form = this._fb.group({
            type: [null, [Validators.required]],
            subType: [null, [Validators.required]],
            value: [null, [Validators.required]],
        });
    }

    ngOnInit(): void {
        if (this.person) {
            const contact = this.person.contacts.find(c => c.id == this.contactId);
            if (contact) {
                this.form.setValue({ type: contact.type, subType: contact.subType, value: contact.value });
            }
        }
    }

    submit(): void {
        Object.keys(this.form.controls).forEach(field => {
            this.form.get(field)?.markAsDirty();
            this.form.get(field)?.updateValueAndValidity();
        });
        if (this.form.valid && this.person?.id && this.contactId) {
            this.working = true;
            if (this.contactId == 'new') {
                this._auth.createPersonContact({
                    personId: this.person.id,
                    type: this.form.get('type')?.value,
                    subType: this.form.get('subType')?.value,
                    value: this.form.get('value')?.value,
                }).subscribe({
                    next: () => this.actionDone.emit(),
                    error: err => this.__error(err)
                });
            }
            else {
                this._auth.updatePersonContact(this.contactId, {
                    type: this.form.get('type')?.value,
                    subType: this.form.get('subType')?.value,
                    value: this.form.get('value')?.value,
                }).subscribe({
                    next: () => this.actionDone.emit(),
                    error: err => this.__error(err)
                });
            }
        }
    }

    deleteContact() {
        this._h.confirm(() => this._auth.deletePersonContact(this.contactId!).subscribe({
            next: () => this.actionDone.emit(),
            error: err => this.__error(err)
        }));
    }

}
