import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { TaskFavoriteModule } from '@shared/_tasks/task-favorite.module';
import { TaskIdBadgeModule } from '@shared/_tasks/task-id-badge.module';
import { TaskTextClassesModule } from '@shared/_tasks/task-text-classes.module';
import { AvatarModule } from '@shared/_views/avatar';
import { VzDtModule } from '@shared/_views/dt.module';
import { TagModule } from '@shared/_views/tag';
import { DuedateTextClassesModule } from '@shared/_tasks/duedate-text-classes.module';

import { BaseComponent } from '@base/base.component';
import {
    Tag, Task, LayoutElement, LayoutElementType, TaskFieldType, TaskPriorityName,
    TaskStatusIcon, TaskStatusColor, TaskStatusName
} from '@models';
import { StoreService } from '@services';

@Component({
    selector: 'vz-layout-tpl, [vz-layout-tpl]',
    templateUrl: './layout-tpl.component.html',
    styleUrls: ['./layout-tpl.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        NzTypographyModule,
        TaskIdBadgeModule, AvatarModule, VzDtModule, TagModule, DuedateTextClassesModule, TaskTextClassesModule,
        TaskFavoriteModule
    ],
})
@Tag('LayoutTplComponent')
export class LayoutTplComponent extends BaseComponent implements OnChanges {

    @Input() rootElement?: LayoutElement;
    @Input() templateData?: { task?: Task };

    LET = LayoutElementType;
    TFT = TaskFieldType;
    TPN = TaskPriorityName;
    TSI = TaskStatusIcon;
    TSC = TaskStatusColor;
    TSN = TaskStatusName;


    constructor(protected _store: StoreService, private _cdr: ChangeDetectorRef) {
        super(_store);
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this._cdr.markForCheck();
    }

}
