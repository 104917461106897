import { BaseModel, RecursivePartial } from '@models/base';

export class VzTipPartAction extends BaseModel {
    text?: string;
    icon?: string;
    style?: 'default' | 'primary' | 'dashed' | 'text';
    tooltip?: string;
    danger: boolean = false;
    url: string = '';
    urlExternal?: boolean;

    constructor(json?: RecursivePartial<VzTipPartAction>) {
        super(json);
        this.parseVzTipPartAction(json || {});
    }

    parse(json: RecursivePartial<VzTipPartAction>): VzTipPartAction {
        super.parse(json);
        this.parseVzTipPartAction(json);
        return this;
    }

    private parseVzTipPartAction(json: RecursivePartial<VzTipPartAction>): void {
        VzTipPartAction.assignFields<VzTipPartAction>(this, json, ['text', 'icon', 'style', 'tooltip', 'danger', 'url', 'urlExternal']);
    }
}
