<div class="person-drawer-root layout flex vertical">
    @if (personId == 'new') {
        <form [formGroup]="form" nzLayout="vertical" (ngSubmit)="submit()">
            <nz-form-item>
                <nz-form-control nzErrorTip="Пожалуйста укажите имя">
                    <nz-input-group>
                        <input type="text" nz-input formControlName="firstName" placeholder="Имя" aFocus />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control nzErrorTip="Пожалуйста укажите фамилию">
                    <nz-input-group>
                        <input type="text" nz-input formControlName="lastName" placeholder="Фамилия" />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <nz-input-group>
                        <input type="text" nz-input formControlName="avatar" placeholder="URL аватара" />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <button nz-button nzType="primary">Создать</button>
        </form>
    }
    @else {
        <vz-person [person]="person" [readOnly]="!canEdit" />
    }

    @if (person && person.id != userId) {
        <nz-button-group class="vz-chm-buttons mt05">
            <button class="ant-btn-icon-only"
                nz-button
                title="Написать сообщение"
                (click)="startDirectChat()">
                <i class="vzi-chat1"></i>
            </button>
            <button class="ant-btn-icon-only"
                nz-button
                title="Начать (видео)звонок"
                (click)="startDirectCall()">
                <i class="vzi-call"></i>
            </button>
        </nz-button-group>
    }

    @if (person) {
        <div class="fw-b fs-xxl mt2 mb05 txt-p">Способы связи</div>
        @if (person && person.contacts) {
            <table class="vz-table vz-table-rows-hover"
                [class.vz-table-clickable]="canEdit"
                width="100%">
                @if (!mobile) {
                    <thead>
                        <tr>
                            <td>Способ</td>
                            <td>Вариант</td>
                            <td>Данные</td>
                        </tr>
                    </thead>
                }
                <tbody>
                    @for (contact of person.contacts; track $index) {
                        <tr (click)="canEdit ? openContact(contact.id!) : undefined">
                            <td>
                                <div class="layout center">
                                    @if (person.mainContactId == contact.id) {
                                        <i class="vzi-star txt-p mr025"></i>
                                    }
                                    {{ CTN[contact.type] }}
                                    @if (mobile) {
                                        &nbsp;({{ CSTN[contact.subType] }})<br>{{ contact.value }}
                                    }
                                </div>
                            </td>
                            @if (!mobile) {
                                <td>{{ CSTN[contact.subType] }}</td>
                                <td>{{ contact.value }}</td>
                            }
                        </tr>
                    }
                </tbody>
            </table>
        }
        @if (canEdit) {
            <button class="vz-ok-btn mv2" style="width: 300px"
                nz-button
                nzType="dashed"
                (click)="openContact('new')">
                Добавить способ связи
            </button>
        }
    }

    @if (personId != 'new' && person && !person.isUser && !person.deleted && granted['org.client.delete']) {
        <button nz-button nzType="dashed" nzGhost nzDanger class="mt2 self-end" (click)="deletePerson()">
            Удалить этого клиента
        </button>
    }
</div>
@if (error) {
    <vz-error-message [error]="error" (dismiss)="error = undefined" />
}
