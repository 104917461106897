import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { CodeSnippet } from '@models/shared';

export class TaskActionNote extends BaseTaskAction {
    type: TaskActionType.Note = TaskActionType.Note;
    text?: { [key: string]: any };
    snippets?: CodeSnippet[];

    constructor(json?: RecursivePartial<TaskActionNote>) {
        super(json);
        this.parseTaskActionNote(json || {});
    }

    parse(json: RecursivePartial<TaskActionNote>): TaskActionNote {
        super.parse(json);
        this.parseTaskActionNote(json);
        return this;
    }

    private parseTaskActionNote(json: RecursivePartial<TaskActionNote>): void {
        TaskActionNote.assignFields<TaskActionNote>(this, json, ['text']);
        TaskActionNote.assignClassArrays<TaskActionNote>(this, json, { snippets: CodeSnippet });
    }
}
