export enum FilterFieldType {
    Flag            = 1,
    Enum            = 2,
    String          = 3,
    Strings         = 4,
    Date            = 5,
    DateOrUnset     = 6,
    Users           = 7,
    Groups          = 8,
    UsersOrGroups   = 9,
    Orgs            = 10,
    Projects        = 11,
    Tags            = 12,
}

export class BaseFilterField<T, K, S extends true | undefined = undefined> {
    static Type = FilterFieldType;
    id!: string;
    name!: string;
    desc?: string;
    isFlag?: boolean;
    hidden?: boolean;
    single!: S;
    type!: FilterFieldType;
    typeData: any;

    constructor(hidden?: boolean) {
        this.hidden = hidden;
    }

    value(_o: T, _data?: any): K | undefined {
        return undefined;
    }

    match(_o: T, _values?: S extends true ? string : string[], _data?: any): boolean {
        return false;
    }

    default?: (o: T) => boolean;

}
