import { Point } from '@models/shared/geometry';
import { Delta } from '@models/gridlayout/delta';

export function getScrollBarSize(): [number, number] {
    const inner = document.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '100%';

    const outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.height = '100px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    document.body.appendChild(outer);

    const w1 = inner.offsetWidth;
    const h1 = inner.offsetHeight;
    outer.style.overflow = 'scroll';
    let w2 = inner.offsetWidth;
    let h2 = inner.offsetHeight;
    if (w1 == w2) {
        w2 = outer.clientWidth;
    }
    if (h1 == h2) {
        h2 = outer.clientHeight;
    }

    document.body.removeChild(outer);

    return [(w1 - w2), (h1 - h2)];
}

export function isMobile(): boolean {
    const multiTouch = 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0;
    const mQ = matchMedia?.('(pointer:coarse)');
    const pc = mQ?.media === '(pointer:coarse)' && mQ.matches;
    const orientation = 'orientation' in window;
    const ua = navigator.userAgent || navigator.vendor || (window as any).opera;
    const uaMob =  /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(ua) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(ua);
    const wh = 'screen' in window && (
        (window.screen.availWidth < 1300 && window.screen.availHeight < 700)
        || (window.screen.availWidth < 700 && window.screen.availHeight < 1300)
    );
    return uaMob || (wh && (multiTouch || pc || orientation));
}

export function getDpi(): number {
    const div = document.createElement('div');
    div.style.width = '1in';
    div.style.height = '1in';
    div.style.position = 'absolute';
    div.style.top = '-100%';
    div.style.left = '-100%';
    document.body.appendChild(div);
    const dpr = window.devicePixelRatio || 1;
    const dpi = Math.max(div.offsetWidth * dpr, div.offsetHeight * dpr);
    document.body.removeChild(div);
    return dpi;
}

export function stopEvent(e: any) {
    if (e) {
        if (e.stopImmediatePropagation) {
            e.stopImmediatePropagation();
        }
        if (e.preventDefault) {
            e.preventDefault();
        }
    }
}

export function scrollIntoViewIfNeeded(target?: Element | null) {
    if (!target) {
        return;
    }
    const bottom = target.parentElement ? target.parentElement.getBoundingClientRect().bottom : window.innerHeight;
    const top = target.parentElement ? target.parentElement.getBoundingClientRect().top : 0;
    if (target.getBoundingClientRect().bottom > bottom) {
        target.scrollIntoView(false);
    }
    if (target.getBoundingClientRect().top < top) {
        target.scrollIntoView();
    }
}

export function onElementRemove(element: any, onDetachCallback: () => void): void {
    const observer = new MutationObserver(function () {
        function isDetached(el: any): boolean {
            if (el.parentNode === document) {
                return false;
            }
            else if (el.parentNode === null) {
                return true;
            }
            else {
                return isDetached(el.parentNode);
            }
        }
        if (isDetached(element)) {
            observer.disconnect();
            onDetachCallback();
        }
    })
    observer.observe(document, { childList: true, subtree: true });
}

export function isSelectionOnlyIn(element?: HTMLElement | null): boolean {
    const sel = window.getSelection();
    if (!element || !sel || sel.rangeCount < 1) {
        return false;
    }
    const range = sel.getRangeAt(0);
    if (range.collapsed) {
        return false;
    }
    const cont = range.commonAncestorContainer;
    return element.contains(cont);
}

export function getEventDelta(e: MouseEvent, p: Point = { x: 0, y: 0 }): Delta {
    // tslint:disable-next-line: deprecation
    e = e || window.event as MouseEvent;
    let x = 0;
    if (e.pageX) {
        x = e.pageX;
    }
    else if (e.clientX) {
        x = e.clientX;
    }
    let y = 0;
    if (e.pageY) {
        y = e.pageY;
    }
    else if (e.clientY) {
        y = e.clientY;
    }
    return { dx: x - p.x, dy: y - p.y };
}

export function isSupportsPDFs(): boolean {
    const nav = window.navigator;
    const ua = window.navigator.userAgent;
    const isModernBrowser = (window.Promise !== undefined);
    const supportsPdfMimeType = (nav.mimeTypes && (nav.mimeTypes as any)['application/pdf'] !== undefined);
    const isSafariIOSDesktopMode = (nav.platform !== undefined && nav.platform === 'MacIntel' && nav.maxTouchPoints !== undefined && nav.maxTouchPoints > 1 );
    const isMobileDevice = (isSafariIOSDesktopMode || isMobile());
    const isFirefoxWithPDFJS = (!isMobileDevice && /irefox/.test(ua) && ua.split('rv:').length > 1) ? (parseInt(ua.split('rv:')[1].split('.')[0], 10) > 18) : false;
    return !isMobileDevice && (isModernBrowser || isFirefoxWithPDFJS || supportsPdfMimeType || nav.pdfViewerEnabled);
}
