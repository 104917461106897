<nz-drawer
    [nzClosable]="true"
    [nzVisible]="open"
    nzPlacement="right"
    [nzWidth]="mobile ? '100%' : '550px'"
    [nzBodyStyle]="{ position: 'relative' }"
    nzTitle="Выберите значок"
    (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div class="select-icon-drawer-root layout flex wrap scroll-y">
            <i *ngFor="let icon of icons" class="sidr-icon" [style.color]="color" [ngClass]="icon" (click)="close(icon)"></i>
        </div>
    </ng-container>
</nz-drawer>
