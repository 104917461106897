import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionPriority extends BaseTaskAction {
    type: TaskActionType.Priority = TaskActionType.Priority;
    priority?: number;
    priorityOld?: number;

    constructor(json?: RecursivePartial<TaskActionPriority>) {
        super(json);
        this.parseTaskActionPriority(json || {});
    }

    parse(json: RecursivePartial<TaskActionPriority>): TaskActionPriority {
        super.parse(json);
        this.parseTaskActionPriority(json);
        return this;
    }

    private parseTaskActionPriority(json: RecursivePartial<TaskActionPriority>): void {
        TaskActionPriority.assignFields<TaskActionPriority>(this, json, ['priority', 'priorityOld']);
    }
}
