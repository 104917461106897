import { BaseModel, RecursivePartial } from '@models/base';

export class OrgsEventsStats extends BaseModel {
    orgId?: string;
    lastActivity: number = 0;
    numEvents: number = 0;

    constructor(json?: RecursivePartial<OrgsEventsStats>) {
        super(json);
        this.parseOrgsEventsStats(json || {});
    }

    parse(json: RecursivePartial<OrgsEventsStats>): OrgsEventsStats {
        super.parse(json);
        this.parseOrgsEventsStats(json);
        return this;
    }

    private parseOrgsEventsStats(json: RecursivePartial<OrgsEventsStats>): void {
        OrgsEventsStats.assignFields<OrgsEventsStats>(this, json, [
            'orgId',
            'lastActivity',
            'numEvents',
        ]);
    }

}
