import { NgModule, HostListener } from '@angular/core';
import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
    selector: '[aFocus]',
})
export class AutoFocusDirective implements AfterViewInit {

    private init: boolean = false;
    private tm: any;
    private _counter: number = 1000;
    @Input('aFocus')
    set counter(v: number | string) {
        this._counter = +v;
        if (this._counter > 0) {
            this._counter = 2000;
        }
        if (this.init && this._counter > -1) {
            this.setFocus();
            this.setFocusTimer();
        }
    }
    hasFocus: boolean = false;

    constructor(private _el: ElementRef) {}

    ngAfterViewInit(): void {
        if (this._counter > -1) {
            this.setFocus();
            this.setFocusTimer();
        }
        this.init = true;
    }

    setFocusTimer(): void {
        if (this.tm) {
            clearInterval(this.tm);
            this.tm = undefined;
        }
        this.tm = setTimeout(
            () => {
                if (!this.hasFocus) {
                    this.setFocus();
                    this._counter = this._counter - 200;
                    if (this._counter > 0) {
                        this.setFocusTimer();
                    }
                }
                else {
                    this.setFocus(false);
                }
            },
        200);
    }

    setFocus(focus = true): void {
        if (this._el && this._el.nativeElement) {
            if (focus && this._el.nativeElement.focus) {
                this._el.nativeElement.focus();
            }
            if (this._el.nativeElement.select) {
                this._el.nativeElement.select();
            }
        }
    }

    @HostListener('focus', ['$event'])
    gotFocus(e: FocusEvent): void {
        this.hasFocus = true;
    }

}

@NgModule({
    declarations: [AutoFocusDirective],
    exports: [AutoFocusDirective]
})
export class AutoFocusModule { }
