import { Injectable } from '@angular/core';

import { debounceTime, finalize, Subject } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import { FeedbackState, PrefsState, Tag } from '@models';
import { StoreService } from './store.service';
import { AuthService } from './auth.service';

const KEY_PREFS = 'prefs';
const KEY_FEEDBACK = 'feedback';

@Injectable({ providedIn: 'root' })
@Tag('PrefsService')
export class PrefsService extends BaseLogging {

    private _loggedIn: boolean = false;
    private _prefsDebouncer: Subject<PrefsState> = new Subject();
    private _prefs?: PrefsState;
    private _fbDebouncer: Subject<FeedbackState> = new Subject();
    private _fb?: FeedbackState;

    constructor(private _store: StoreService, private _auth: AuthService) {
        super();
        this._L('constructor');
        const us = this._store.getState('user');
        this._loggedIn = us.isLoggedIn() && !us.isGuest;
        this.loadStoredPrefs();
        this._store.state('user').subscribe(st => {
            const loggedIn = st.isLoggedIn() && !st.isGuest;
            if (this._loggedIn != loggedIn) {
                this._loggedIn = loggedIn;
                // if (loggedIn) {
                //     this.loadStoredPrefs();
                // }
            }
        });
        this._store.state('prefs').subscribe(st => this._prefsDebouncer.next(st));
        this._prefsDebouncer.pipe(debounceTime(500)).subscribe(st => this.storePrefs(st));
        this._store.state('feedback').subscribe(st => this._fbDebouncer.next(st));
        this._fbDebouncer.pipe(debounceTime(500)).subscribe(st => this.storeFeedback(st));
    }

    storePrefs(st: PrefsState): void {
        this._L('storePrefs', '_loggedIn:', this._loggedIn, '_prefs:', !!this._prefs);
        if (this._loggedIn && this._prefs) {
            st._dt = new Date();
            this._prefs = st;
            this._auth.setPref(KEY_PREFS, st).subscribe({ error: err => this._W('storePrefs', `Error saving pref "${KEY_PREFS}":`, err) });
        }
    }

    loadStoredPrefs(cb?: () => void): void {
        if (this._loggedIn) {
            this._W('loadStoredPrefs');
            this._auth.getPref<PrefsState>(KEY_PREFS).pipe(finalize(() => cb?.())).subscribe({
                next: st => {
                    const stOld = this._store.getStateDirect('prefs');
                    if (!st) {
                        this._L('loadStoredPrefs', 'no saved prefs found on server, create default');
                        this._prefs = new PrefsState({ _dt: new Date() });
                        this._store.setState('prefs', this._prefs);
                    }
                    else { // if (!st._dt) {
                        this._L('loadStoredPrefs', 'saved prefs has no date, set current and migrate');
                        this._L('loadStoredPrefs', 'got prefs from server:', st);
                        // st._dt = new Date();
                        this._prefs = st;
                        this._store.setState('prefs', this._store.migrateState('prefs', st));
                    }
                    // else if (!stOld._dt || stOld._dt < st._dt) {
                    //     this._L('loadStoredPrefs', 'saved prefs has no date or date is older, override current prefs with loaded from backend');
                    //     this._prefs = st;
                    //     this._store.setState('prefs', this._store.migrateState('prefs', st));
                    // }
                    // else {
                    //     this._L('loadStoredPrefs', 'saved prefs has newer date, skip', stOld._dt, '>', st._dt);
                    //     this._prefs = stOld;
                    // }
                    this._L('loadStoredPrefs', '_prefs:', this._prefs);
                },
                error: err => this._W('loadStoredPrefs', `Error restoring pref "${KEY_PREFS}":`, err)
            });
            this._auth.getPref<FeedbackState>(KEY_FEEDBACK).subscribe({
                next: st => {
                    const stOld = this._store.getStateDirect('feedback');
                    if (!st) {
                        this._L('loadStoredPrefs', 'no saved feedback found on server, create default');
                        this._fb = new FeedbackState({ _dt: new Date(0) });
                        this._store.setState('feedback', this._fb);
                    }
                    else if (!st._dt) {
                        this._L('loadStoredPrefs', 'saved feedback has no date, set current and migrate');
                        st._dt = new Date();
                        this._fb = st;
                        this._store.setState('feedback', this._store.migrateState('feedback', st));
                    }
                    else if (!stOld._dt || stOld._dt < st._dt) {
                        this._L('loadStoredPrefs', 'saved feedback has no date or date is older, override current feedback with loaded from backend');
                        this._fb = st;
                        this._store.setState('feedback', this._store.migrateState('feedback', st));
                    }
                    else {
                        this._L('loadStoredPrefs', 'saved feedback has newer date, skip', stOld._dt, '>', st._dt);
                        this._fb = stOld;
                    }
                    this._L('loadStoredPrefs', '_fb:', this._fb);
                },
                error: err => this._W('loadStoredPrefs', `Error restoring pref "${KEY_FEEDBACK}":`, err)
            });
        }
    }

    storeFeedback(st: FeedbackState): void {
        this._L('storeFeedback', '_loggedIn:', this._loggedIn, '_fb:', !!this._fb);
        if (this._loggedIn && this._fb) {
            st._dt = new Date();
            this._fb = st;
            this._auth.setPref(KEY_FEEDBACK, st).subscribe({ error: err => this._W('storeFeedback', `Error saving pref "${KEY_FEEDBACK}":`, err) });
        }
    }

}
