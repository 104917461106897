import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

import { SelectColorDrawerComponent } from './select-color-drawer.component';

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        NzDrawerModule, NzButtonModule,
    ],
    declarations: [SelectColorDrawerComponent],
    exports: [SelectColorDrawerComponent]
})
export class SelectColorDrawerModule { }
