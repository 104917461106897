<div [style.height.px]="size" class="layout center">
    <!-- [style.padding-top.px]="asTag && withName ? ((smallImg ? +sSize : +size) / 5) : null"
    [style.padding-bottom.px]="asTag && withName ? ((smallImg ? +sSize : +size) / 5) : null"> -->
    <div class="vz-avatar layout center relative"
        [class.vz-avatar-rounded]="realShape == 'rounded'"
        [class.vz-avatar-square]="realShape == 'square'"
        [class.vz-avatar-unknown]="unknown"
        [class.vz-avatar-astag]="asTag && withName"
        [class.vz-avatar-img]="url && !noImg"
        [class.vz-avatar-system]="uid == SUID || item?.id == SUID || isSystem"
        [class.vz-avatar-disabled]="disabled"
        [style.cursor]="cursor"
        nz-tooltip
        [nzTooltipColor]="type == 'org' && suspended ? 'red' : undefined"
        [nzTooltipTrigger]="(withName || noTooltip) && (type != 'org' || !suspended) ? null : 'hover'"
        [nzTooltipTitle]="type == 'org' && suspended
            ? 'Организация заблокирована до уплаты задолженности. Доступен только просмотр данных.'
            : typeName + (!unknown && name ? name : 'Неизвестно') + (showStatus && type == 'user' && lastSeenDt ? '\n, последняя активность: ' + (lastSeenDt | dt:false) : '')"
        nzTooltipPlacement="topLeft"
        [nzTooltipMouseEnterDelay]="0.5"
        [style.height.px]="asTag && withName ? (smallImg ? +sSize : +size) - 2 : null"
        [style.border-radius.px]="type == 'user' && asTag && withName ? ((smallImg ? +sSize : +size) - 2) / 2 : null">
        @if ((type == 'project' || prefixTpl) && asTag) {
            <div class="vz-avatar-name"
                [style.padding-left.px]="((smallImg ? +sSize : +size) - 2) / 5 + 2"
                [style.padding-right.px]="((smallImg ? +sSize : +size) - 2) / 5">
                @if (prefixTpl) {
                    <ng-container [ngTemplateOutlet]="prefixTpl" />
                }
                @else {
                    {{ initials }}
                }
            </div>
        }
        @if (!noImg) {
            <div class="vz-avatar-wrapper layout center-center relative"
                [style.width.px]="smallImg ? +sSize : +size"
                [style.height.px]="smallImg ? +sSize : +size"
                [style.min-width.px]="smallImg ? +sSize : +size"
                [style.min-height.px]="smallImg ? +sSize : +size"
                [style.background-color]="url ? (noBorder ? 'transparent' : undefined) : bgColor"
                [style.border-width.px]="noBorder ? 0 : undefined"
                [style.box-shadow]="noBorder ? 'none' : undefined"
                [style.border-radius.px]="type == 'user' && asTag && withName ? ((smallImg ? +sSize : +size) - 2) / 2 : null">
                @if (type != 'project' && initials) {
                    @if (initials.length < 3) {
                        <span class="vz-avatar-initials"
                            [style.color]="url ? bgColor : null"
                            [style.font-size.px]="initials.length == 2 ? (smallImg ? +sSize : +size) / 2.2 : (smallImg ? +sSize : +size) / 1.2"
                            [style.font-weight]="initials.length == 2 ? 'normal' : 'bold'"
                            [style.line-height.px]="(smallImg ? +sSize : +size) - 2"
                            [style.border-radius.px]="type == 'user' && asTag && withName ? (smallImg ? +sSize : +size) / 2 : null">
                            {{ initials }}
                        </span>
                    }
                    @else {
                        <span class="vz-avatar-initials"
                            [ngClass]="initials"
                            [style.color]="textColor ? textColor : null"
                            [style.font-size.px]="initials.length == 2 ? (smallImg ? +sSize : +size) / 2.5 : (smallImg ? +sSize : +size) / 1.5"
                            [style.line-height.px]="(smallImg ? +sSize : +size) - 2"
                            [style.border-radius.px]="type == 'user' && asTag && withName ? (smallImg ? +sSize : +size) / 2 : null">
                        </span>
                    }
                }
                @if (url) {
                    <img [src]="url"
                        [style.width.px]="smallImg ? +sSize : +size"
                        [style.height.px]="smallImg ? +sSize : +size"
                        [style.border-radius.px]="type == 'user' && asTag && withName ? (smallImg ? +sSize : +size) / 2 : null">
                }
                @if (uid == SUID || item?.id == SUID) {
                    <div class="vz-avatar-system-anim" [style.width.px]="(smallImg ? +sSize : +size) + 2" [style.height.px]="(smallImg ? +sSize : +size) + 2"></div>
                }
                @if (!url && type == 'project') {
                    <i class="vzi-project txt-inv" [style.font-size.px]="(smallImg ? +sSize : +size) / 1.5" [style.line-height.px]="(smallImg ? +sSize : +size) - 2"></i>
                }
            </div>
        }
        @if (withName) {
            <div class="vz-avatar-name layout center" [style.margin-left.px]="((smallImg ? +sSize : +size) - 2) / 5" [style.font-size.px]="note ? fs / 1.2 : fs">
                <div class="layout vertical" style="line-height: 1.2;">
                    <span class="elli" [style.color]="withColor ? nameColor : undefined">{{ unknown ? '<Не указано>' : name }}</span>
                    @if (note) {
                        <span class="elli txt-ll">{{ note }}</span>
                    }
                </div>
            </div>
        }
        @if (showStatus && (online == UOS.Online || online == UOS.Away) && !noImg) {
            <div style="position: absolute; bottom: -1px; left: -1px; border-radius: 50%; height: 9px; width: 9px;"
                [style.background]="online == UOS.Online ? 'radial-gradient(circle at 35% 35%, #8f8 1px, #0a0 60%, #050 100%)' : 'radial-gradient(circle at 35% 35%, #ff7 1px, #990 60%, #550 100%)'">
            </div>
        }
        @if (type == 'org' && suspended && !noImg) {
            <i class="vzi-warning txt-err-d"
                style="position: absolute; bottom: -1px; left: -1px; opacity: .9; border-radius: 50%; text-shadow: 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff;"
                [style.font-size.px]="(smallImg ? +sSize : +size) / 1.5 - 3">
            </i>
        }
    </div>
</div>