import { BaseModel, RecursivePartial } from '@models/base';

export class VzTariff extends BaseModel {
    slotsLimit?: number;
    videoroomLimit?: number;
    storageLimit?: number;
    maxActiveTasks?: number;
    maxChatChannels?: number;
    chatHistoryLimit?: number;
    directChatLimit?: number;

    constructor(json?: RecursivePartial<VzTariff>) {
        super(json);
        this.parseVzTariff(json || {});
    }

    parse(json: RecursivePartial<VzTariff>): VzTariff {
        super.parse(json);
        this.parseVzTariff(json);
        return this;
    }

    private parseVzTariff(json: RecursivePartial<VzTariff>): void {
        VzTariff.assignFields<VzTariff>(this, json, [
            'slotsLimit',
            'videoroomLimit',
            'storageLimit',
            'maxActiveTasks',
            'maxChatChannels',
            'chatHistoryLimit',
            'directChatLimit',
        ]);
    }
}
