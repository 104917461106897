import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { LocalNotifications } from '@capacitor/local-notifications';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Badge } from '@capawesome/capacitor-badge';
import { BehaviorSubject, debounceTime, fromEvent, map, Subject } from 'rxjs';

import { Vizorro } from '@opensys/vizorro';
import { BaseLogging } from '@base/base-logging';
import { CefEventName, CefReturnCode, Chat, ChatsCountersType, CountersState, Tag, getChatAvatar, getChatTitle } from '@models';
import { ICON_COUNTER, SOUND_CALL, SOUND_CHAT, SOUND_TASK } from '@assets';
import { StoreService } from './store.service';
import { cefSend, cefApp } from './externals';

export const VIZORRO_NOTIFICATIONS_ALARM_CHANNEL_ID = 'vizorro.new_client_chat';
export const VIZORRO_NOTIFICATIONS_NEWMESSAGE_CHANNEL_ID = 'vizorro.new_chat_message';
export type ChatIncomingCallNotification = {
    title: string;
    avatar: string | null;
    orgId: string;
    chatId: string;
    avatarUri?: SafeUrl;
}

@Injectable()
@Tag('NotificationsService')
export class NotificationsService extends BaseLogging {

    private _nid: number = 1;
    channelId?: string = VIZORRO_NOTIFICATIONS_ALARM_CHANNEL_ID;
    counters?: CountersState;
    badgeEnabled: boolean = false;

    icAudio?: HTMLAudioElement;
    icTimeoutId: any;
    webIncomingCall: BehaviorSubject<ChatIncomingCallNotification | undefined> = new BehaviorSubject<ChatIncomingCallNotification | undefined>(undefined);
    incomingCallResult: BehaviorSubject<{ rc: CefReturnCode, id?: string } | undefined> = new BehaviorSubject<{ rc: CefReturnCode, id?: string } | undefined>(undefined);
    inCall?: ChatIncomingCallNotification;

    chatSoundDebouncer: Subject<void> = new Subject();
    taskSoundDebouncer: Subject<void> = new Subject();

    constructor(
        protected _store: StoreService,
    ) {
        super();
        this.chatSoundDebouncer.pipe(debounceTime(1000)).subscribe(() => this.playSound(SOUND_CHAT));
        this.taskSoundDebouncer.pipe(debounceTime(1000)).subscribe(() => this.playSound(SOUND_TASK));
        if (this.__IOS || this.__ANDROID) {
            Badge.isSupported().then(supported => {
                if (supported) {
                    Badge.checkPermissions().then(st => {
                        if (st?.display == 'granted') {
                            this.badgeEnabled = true;
                        }
                        else if (st?.display != 'denied') {
                            Badge.requestPermissions().then(st => {
                                if (st.display == 'granted') {
                                    this.badgeEnabled = true;
                                    this.updateAppIcon(this._store.getState('counters'));
                                }
                            }).catch(() => this.badgeEnabled = false);
                        }
                    }).catch(() => this.badgeEnabled = false);
                }
            }).catch(() => this.badgeEnabled = false);
        }
        this._S.counters = this._store.state('counters').subscribe(st => this.updateAppIcon(st));
        this._S.chats = this._store.state('chats').subscribe(st => {
            if (!this.inCall?.chatId) {
                return;
            }
            const chat = st.items[this.inCall.chatId];
            if (!chat || !chat.call || (chat.call.activeMembers && chat.call.activeMembers.includes(this._store.userId!))) {
                this.handleIncomingCallResult(CefReturnCode.Ignore)
            }
        });
    }

    getNewNotificationId(): number {
        return this._nid++;
    }

    displayTaskAlertDialog(msg: any): number {
        const nid = this.getNewNotificationId();
        LocalNotifications.schedule({
            notifications: [{
                id: nid,
                title: 'Клиент жаждет общения!!!',
                body: msg.title,
                channelId: this.channelId,
                extra: { link: `/ui/${msg.orgId}/tasks/${msg.objectId}` }
            }]
        }).then(res => {
            this._L('displayTaskAlertDialog', 'LocalNotifications.schedule.Result:', res);
        }).catch(e => this._W('displayTaskAlertDialog', 'LocalNotifications.schedule.ERROR:', e));
        return nid;
    }

    updateAppIcon(st: CountersState): void {
        // this._L('updateAppIcon', st);
        const blue = st.chats.unreadChats[ChatsCountersType.Group] > 0
            || st.chats.unreadThreads[ChatsCountersType.Group] > 0
            || st.chats.unreadChats[ChatsCountersType.Plugin] > 0
            || st.chats.unreadThreads[ChatsCountersType.Plugin] > 0;
        const redD = st.chats.unreadMessages[ChatsCountersType.Direct] > 0 || st.chats.unreadThreads[ChatsCountersType.Direct] > 0;
        const redM = st.mentions.unread > 0;
        const green = st.tasks.inboxUnread > 0 || st.tasks.supportUnread > 0;
        if (
            (blue && (!this.counters || this.counters.chats.unreadMessages < st.chats.unreadMessages))
            || (redD && (!this.counters || this.counters.chats.unreadMessages[ChatsCountersType.Direct] < st.chats.unreadMessages[ChatsCountersType.Direct]))
            || (redM && (!this.counters || this.counters.mentions.unread < st.mentions.unread))
        ) {
            this.chatSoundDebouncer.next();
        }
        if (green && (!this.counters || this.counters.tasks.inboxUnread < st.tasks.inboxUnread)) {
            this.taskSoundDebouncer.next();
        }
        this.counters = new CountersState(st);
        if (blue || redD || redM || green) {
            this.setIconUnread(redD || redM, green, blue);
        }
        else {
            this.setDefaultIcon();
        }
        if (this.badgeEnabled) {
            Badge.set({
                count: st.chats.unreadMessages[ChatsCountersType.Direct] + st.mentions.unread
            }).catch(e => this._W('updateAppIcon', 'Badge.set.ERROR:', e));
        }
    }

    setIconUnread(red: boolean, green: boolean, blue: boolean): void {
        if (!red && !green && !blue) {
            return;
        }
        const colors: string[] = [];
        if (red) {
            colors.push('#e50000');
        }
        if (green) {
            colors.push('#1fb714');
        }
        if (blue) {
            colors.push('#3369f2');
        }
        // this._L('setIconUnread', red, green, blue, colors);
        if (this.__WEB) {
            const img: HTMLImageElement = new Image();
            img.src = ICON_COUNTER;
            fromEvent(img, 'load').pipe(
                map((event: any) => event.target),
                map((image: HTMLImageElement) => {
                    const canvas = document.createElement('canvas');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    const ctx = canvas.getContext('2d')!;
                    const tr = Math.PI * 2 / colors.length;
                    let trStart = Math.PI / 2;
                    const shift = colors.length > 1 ? Math.PI / 12 : 0;
                    for (const c of colors) {
                        ctx.beginPath();
                        ctx.moveTo(canvas.width / 2, canvas.height / 2);
                        ctx.arc(
                            canvas.width / 2,
                            canvas.height / 2,
                            Math.min(canvas.width / 2, canvas.height / 2),
                            trStart + shift, trStart + tr - shift);
                        ctx.fillStyle = c;
                        ctx.fill();
                        trStart += tr;
                    }
                    ctx.beginPath();
                    ctx.arc(canvas.width / 2, canvas.height / 2, 15, 0, Math.PI * 2);
                    ctx.fillStyle = '#fff';
                    ctx.fill();
                    ctx.drawImage(image, 0, 0);
                    if (this.__CEF) {
                        cefSend(CefEventName.TrayIconSet, { icon: ctx.canvas.toDataURL() }).subscribe({ error: err => this._W('setIconUnread', err) });
                    }
                    else {
                        const icon = document.getElementById('vzFavicon');
                        const newIcon: any = icon!.cloneNode(true);
                        newIcon.setAttribute('href', ctx.canvas.toDataURL());
                        icon!.parentNode!.replaceChild(newIcon, icon!);
                    }
                })
            ).subscribe();
        }
    }

    setIconNumber(count: number, color: string, inverse = false): void {
        // this._W('setIconNumber', count, color);
        if (this.__WEB) {
            const img: HTMLImageElement = new Image();
            img.src = ICON_COUNTER;
            fromEvent(img, 'load').pipe(
                map((event: any) => event.target),
                map((image: HTMLImageElement) => {
                    const canvas = document.createElement('canvas');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    const ctx = canvas.getContext('2d')!;
                    ctx.drawImage(image, 0, 0);
                    ctx.fillStyle = color;
                    if (inverse) {
                        ctx.beginPath();
                        ctx.arc(23, 18, 20, 0, 2 * Math.PI);
                        ctx.fill();
                        ctx.strokeStyle = '#ffffff';
                        ctx.beginPath();
                        ctx.arc(23, 18, 20, 0, 2 * Math.PI);
                        ctx.stroke();
                        ctx.fillStyle = '#ffffff';
                    }
                    ctx.font = 'bold 40px serif';
                    ctx.fillText(count > 9 ? '*' : '' + count, 12, 32)
                    if (this.__CEF) {
                        cefSend(CefEventName.TrayIconSet, { icon: ctx.canvas.toDataURL() }).subscribe({ error: err => this._W('setIconNumber', err) });
                    }
                    else {
                        const icon = document.getElementById('vzFavicon');
                        const newIcon: any = icon!.cloneNode(true);
                        newIcon.setAttribute('href', ctx.canvas.toDataURL());
                        icon!.parentNode!.replaceChild(newIcon, icon!);
                    }
                })
            ).subscribe();
        }
    }

    setDefaultIcon(): void {
        if (!this.__WEB) {
            return;
        }
        if (this.__CEF) {
            cefSend(CefEventName.TrayIconSet, { icon: '' }).subscribe({ error: err => this._W('setDefaultIcon', err) });
        }
        else {
            const icon = document.getElementById('vzFavicon');
            const newIcon: any = icon!.cloneNode(true);
            newIcon.setAttribute('href', 'favicon.ico');
            icon!.parentNode!.replaceChild(newIcon, icon!);
        }
    }

    playSound(sound: string, loop = false): HTMLAudioElement | undefined {
        Haptics.impact({ style: ImpactStyle.Light }).catch(e => this._W('playSound', 'Haptics.impact.ERROR:', e));
        const context = new AudioContext();
        if (context.state == 'running') {
            const audio = new Audio(sound);
            audio.volume = 1;
            audio.load();
            audio.loop = loop;
            audio.play();
            return audio;
        }
        return;
    }

    showIncomingCall(chat?: Chat): void {
        this._L('showIncomingCall', 'chat:', chat,  'inCall.chatId:', this.inCall?.chatId, !chat || this.inCall?.chatId == chat.id);
        if (!chat || this.inCall?.chatId == chat.id) {
            return;
        }
        this.clearIncomingCall();
        const title = getChatTitle(chat, this._store.userId!, this._store.getState('persons').items);
        this._L('showIncomingCall', 'call getChatAvatar, title:', title);
        getChatAvatar(chat, this._store.userId!, this._store.getState('persons').items).subscribe(avatar => {
            this.inCall = { title, avatar, chatId: chat.id!, orgId: chat.orgId! };
            this._L('showIncomingCall', 'inCall:', this.inCall);
            Haptics.vibrate({ duration: 2000 }).catch(e => this._L('showIncomingCall', 'Haptics.vibrate.ERROR:', e));
            if (this.__CEF) {
                cefApp.clearEventListener(CefEventName.IncomingCall);
                cefSend(CefEventName.IncomingCall, { title, avatar: 'data:image/png;base64,' + avatar, id: chat.id }).subscribe({
                    next: () => {
                        cefApp.setEventListener(CefEventName.IncomingCall, (cid, rc) => {
                            this._L('showIncomingCall', 'cefSend(CefEventName.IncomingCall).OK, callid:', cid, 'rc:', rc);
                            if (this.inCall && this.inCall.chatId == cid) {
                                this.handleIncomingCallResult(rc);
                            }
                        })
                    },
                    error: err => this._W('showIncomingCall', 'cefSend(CefEventName.IncomingCall).Error:', err)
                });
            }
            else if (this.__ANDROID) {
                this._L('showIncomingCall', 'Vizorro.displayIncomingCall', { title, avatar, uri: `vizorro://joincall/${chat.orgId}/${chat.id}` });
                Vizorro.displayIncomingCall(
                    { title, avatar, uri: `vizorro://joincall/${chat.orgId}/${chat.id}` },
                    (msg, err) => this._W('showIncomingCall', 'Vizorro.displayIncomingCall msg:', msg, 'err:', err)
                ).catch(e => this._W('showIncomingCall', 'Vizorro.displayIncomingCall.ERROR:', e));
            }
            else {
                this.webIncomingCall.next(this.inCall);
            }
            this.icAudio = this.playSound(SOUND_CALL, true);
            this.icTimeoutId = setTimeout(() => this.handleIncomingCallResult(CefReturnCode.Ignore), 30 * 1000)
        });
    }

    clearIncomingCall(): void {
        if (this.icAudio) {
            this.icAudio.pause();
            this.icAudio.removeAttribute('src');
            this.icAudio.load();
        }
        if (this.icTimeoutId) {
            clearTimeout(this.icTimeoutId);
        }
        this.inCall = undefined;
    }

    hideAlerts(): void {
        if (this.__ANDROID) {
            Vizorro.hideAlerts();
        }
    }

    handleIncomingCallResult(rc: CefReturnCode): void {
        this._L('handleIncomingCallResult','rc:', rc, 'chatId:', this.inCall?.chatId);
        this.incomingCallResult.next({ rc, id: this.inCall?.chatId });
        if (this.__CEF) {
            const id = this.inCall?.chatId;
            if (id) {
                cefSend(CefEventName.CancelCall, { id }).subscribe({
                    error: err => this._W('hideIncomingCall', 'cefSend(CefEventName.CancelCall).Error:', err)
                });
                cefApp.clearEventListener(CefEventName.IncomingCall);
            }
        }
        else if (this.__ANDROID) {
            Vizorro.hideAlerts();
        }
        else {
            this.webIncomingCall.next(undefined);
        }
        this.clearIncomingCall();
    }

}
