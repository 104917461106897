import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { DmStomp, StompFrame } from '@dimanoid/stomp';
import { delay, Observable, of, Subject, throwError } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import { StoreService } from './store.service';
import {
    ObjectType, StompLogState, IStompLogItem, ObjectOperation, Tag, OLD_BaseSystemModel,
    BaseSystemModel, Site, Org, VzTag, Chat, VzFile, Role, Task, Group, OrgInvite, Person, Contact,
    Project, Department, ChatMessage, KanbanBoard, UserOrgStatus, CalendarEvent, UserOrgStatusType, SM,
    Guid
} from '@models';
import pkg from '@root/package.json';

export interface IParsedMessage {
    body: any;
    routingKey: string;
    rk: string[];
    exchange: string;
    msgId: string;
}

export interface IObjectEvent {
    msgId: string;
    ids: string[];
    type: ObjectType;
    operation: ObjectOperation;
    refreshCounters?: boolean;
    projectId?: string;
    taskId?: string;
    requestId?: string;
    rk?: string;
    personal?: boolean;
}

export interface ICountersEvent {
    msgId: string;
    type: ObjectType;
}

export interface ITempSubscription {
    type: 'chat';
    sid?: string;
}

export enum StompConnectionType {
    Info = 'info',
    UI = 'ui',
    Chats = 'chats'
}
export type SctMap<T> = { [key in StompConnectionType]?: T };
export const ConnectionTypesList = [StompConnectionType.Info, StompConnectionType.UI, StompConnectionType.Chats];

export const ObjectTypeClass: { [T in ObjectType]?: (v: any) => BaseSystemModel | OLD_BaseSystemModel } = {
    [ObjectType.App]: v => new Site(v),
    [ObjectType.Org]: v => new Org(v),
    [ObjectType.Tag]: v => new VzTag(v),
    [ObjectType.Chat]: v => new Chat(v),
    [ObjectType.File]: v => new VzFile(v),
    [ObjectType.Role]: v => new Role(v),
    [ObjectType.Task]: v => new Task(v),
    [ObjectType.Group]: v => new Group(v),
    [ObjectType.Invite]: v => new OrgInvite(v),
    [ObjectType.Person]: v => new Person(v),
    [ObjectType.Contact]: v => new Contact(v),
    [ObjectType.Project]: v => new Project(v),
    [ObjectType.Department]: v => new Department(v),
    [ObjectType.ChatMessage]: v => new ChatMessage(v),
    [ObjectType.KanbanBoard]: v => new KanbanBoard(v),
    [ObjectType.UserOrgStatus]: v => new UserOrgStatus(v),
    [ObjectType.CalendarEvent]: v => new CalendarEvent(v),
    [ObjectType.UserOrgStatusType]: v => new UserOrgStatusType(v),
}

@Injectable()
@Tag('StompService')
export class StompService extends BaseLogging {

    private _baseUrl: string | undefined = this.__ENV.urls.rmq;
    private _stomp: SctMap<DmStomp> = {};

    connected: Subject<boolean> = new Subject();
    onUserMessage: Subject<IParsedMessage> = new Subject();
    onOrgMessage: Subject<IParsedMessage> = new Subject();
    onChatMessage: Subject<{ chatId: Guid, pm: IParsedMessage }> = new Subject();
    onObject: Subject<BaseSystemModel | OLD_BaseSystemModel> = new Subject();
    onObjectEvent: Subject<IObjectEvent> = new Subject();
    onRefreshCountersEvent: Subject<ICountersEvent> = new Subject();
    onIncomingCall: Subject<IObjectEvent> = new Subject();

    log!: StompLogState;
    storeLog: Subject<StompLogState> = new Subject();
    tempS: SM<ITempSubscription> = {};
    subscribedOrgs: SM<string> = {};

    private cAttempt: SctMap<number> = {};
    private rcAttempt: SctMap<number> = {};

    constructor(private _store: StoreService, private _swu: SwUpdate) {
        super();
        if (this.__ENV.env != 'prod') {
            this._store.state('url').subscribe(state => {
                this._baseUrl = state.rmqBaseUrl;
                this._L('constructor', 'baseUrl:', this._baseUrl);
            });
        }
        this.log = this._store.getState('stomp');
        this._store.authTokenState
    }

    connect(): void {
        const list = this._store.getState('user').isGuest ? [StompConnectionType.Chats] : ConnectionTypesList;
        for (const type of list) {
            this._stomp[type] = new DmStomp();
            this._S[`onConnect/${type}`] = this._stomp[type]!.onConnect.subscribe(f => this.onConnect(type, f));
            this._connect(type);
            this._S[`onDisconnect/${type}`] = this._stomp[type]!.onDisconnect.subscribe(f => this.onDisconnect(type, f));
        }
    }

    private _connect(type: StompConnectionType): Observable<StompFrame | null> {
        this._L(`connect/${type}`, 'isConnected:', this._stomp[type]?.isConnected());
        if (!this._stomp[type] || this._stomp[type]!.isConnected() || !this._baseUrl) {
            return of(null);
        }
        const user = this._store.getState('user');
        if (user.isLoggedIn()) {
            this._stomp[type]!.configure({
                host: '/users',
                login: user.userId,
                passcode: user.refreshToken,
                url: this._baseUrl,
                heartbeatIn: 60000
            });
        }
        else {
            this._W(`connect/${type}`, 'Not logged in');
            return throwError(() => new Error('Not logged in'));
        }
        // this._stomp[type]!.debug.subscribe((msg) => console.log(`[STOMP/${type}]`, msg));
        this.cAttempt[type] = Date.now();
        return this._stomp[type]!.connect();
    }

    disconnect(): void {
        this.__unsubscribe();
        this.tempS = {};
        for (const type of ConnectionTypesList) {
            this._disconnect(type);
            this._stomp[type] = undefined;
        }
    }

    private _disconnect(type: StompConnectionType): void {
        if (this._stomp[type]?.isConnected()) {
            this._stomp[type]!.disconnect();
        }
    }

    isConnected(type: StompConnectionType): boolean {
        return !!this._stomp[type]?.isConnected();
    }

    onConnect(type: StompConnectionType, f: StompFrame): void {
        this._L(`onConnect/${type}`, f);
        if (type == StompConnectionType.UI) {
            this.connected.next(true);
        }
        if (this._store.getState('user').isGuest && type != StompConnectionType.Chats) {
            return;
        }
        const userId = this._store.getState('user', state => state.userId);
        const orgIds = this._store.getState('user', state => state.user?.orgs?.map(org => org.id));
        if (userId) {
            if (type == StompConnectionType.Info && this._stomp.info) {
                this._L('onConnect/info', 'subscribing to [/exchange/global_info]',
                    this._stomp.info.subscribe('/exchange/global_info', (msg) => this.handleGlobalMessage(msg))
                );
            }
            else if (type == StompConnectionType.UI && this._stomp.ui) {
                this._L('onConnect/ui', `subscribing to [/exchange/ui_notifications/person.${userId}]`,
                    this._stomp.ui.subscribe(`/exchange/ui_notifications/person.${userId}`, (msg) => this.handleUserMessage(msg))
                );
                this.subscribedOrgs = {};
                if (orgIds) {
                    orgIds.forEach(id => {
                        if (this._stomp.ui) {
                            this.subscribedOrgs[id] = this._stomp.ui.subscribe(`/exchange/ui_notifications/org.${id}`, (msg) => this.handleOrgMessage(msg));
                            this._L('onConnect/ui', `subscribing to [/exchange/ui_notifications/org.${id}]`, this.subscribedOrgs[id]);
                        }
                    });
                }
            }
            else if (type == StompConnectionType.Chats) {
                Object.keys(this.tempS).forEach(id => {
                    const sub = this.tempS[id];
                    if (sub?.type == 'chat') {
                        const sid = this._stomp[type]!.subscribe(`/exchange/chats/chat.${id}`, frame => this.handleChatMessage(id, frame));
                        sub.sid = sid;
                        this._L(`onConnect/${type}`, `re-subscribe to chat#${id}`, sid);
                    }
                });

            }
        }
    }

    onDisconnect(type: StompConnectionType, f: StompFrame): void {
        this._W(`onDisconnect/${type}`, f);
        if (type == StompConnectionType.UI) {
            this.connected.next(false);
        }
        if (this.cAttempt[type] && Date.now() - this.cAttempt[type]! > 3000) {
            this.rcAttempt[type] = 0;
        }
        if (type == StompConnectionType.UI) {
            this.subscribedOrgs = {};
        }
        this._reconnect(type);
    }

    reconnect(): void {
        this._L('reconnect');
        ConnectionTypesList.forEach(t => {
            this._L('reconnect', `[${t}] readyState:`, this._stomp[t]?.client?.ws.readyState);
            this.rcAttempt[t] = 0;
            try {
                this._stomp[t]?.client?.ws.send('\x0A');
            }
            catch (_) {}
        });
        // this.disconnect();
        // this.connect();
        // ConnectionTypesList.forEach(t => {
        //     if (this._stomp && this._stomp[t]) {
        //         this._stomp[t]!.disconnect();
        //     }
        //     this.rcAttempt[t] = 0;
        //     this._reconnect(t);
        // });
    }

    private _reconnect(type: StompConnectionType): void {
        this.__U([`reconnect/${type}`]);
        if (this._store.getState('user').isLoggedIn()) {
            const d = this.rcAttempt[type] ? (this.rcAttempt[type]! <= 10 ? this.rcAttempt[type]! : 10) : 0;
            this.rcAttempt[type] = (this.rcAttempt[type] || 0) + 1;
            this._S[`reconnect/${type}`] = of(1).pipe(delay(d * 1000)).subscribe(
                () => {
                    this._L(`reconnect/${type}`, 'reconnecting...');
                    this._connect(type).subscribe({
                        error: err => {
                            this._W(`reconnect/${type}`, 'Failed to reconnect:', err);
                            this._reconnect(type);
                        }
                    });
                }
            );
        }
    }

    subscribe2chat(id: string): string | undefined {
        if (this.tempS[id]) {
            return this.tempS[id].sid;
        }
        if (this._stomp[StompConnectionType.Chats]) {
            const sid = this._stomp[StompConnectionType.Chats]?.subscribe(`/exchange/chats/chat.${id}`, frame => this.handleChatMessage(id, frame));
            this.tempS[id] = { type: 'chat', sid };
            this._L('subscribe2chat', this.tempS[id]);
            return sid;
        }
        return undefined;
    }

    unsubscribe(id: string): void {
        if (!this.tempS[id]) {
            return;
        }
        this._L('unsubscribe', this.tempS[id]);
        this._stomp[StompConnectionType.Chats]?.unsubscribe(this.tempS[id].sid!);
        delete this.tempS[id];
    }

    handleGlobalMessage(msg: StompFrame): void {
        const pm = this._parseMessage(msg, true);
        // this._L('handleGlobalMessage:', pm);
        if (pm?.exchange == 'global_info' && pm.routingKey == 'version.web' && pm.body) {
            const ver = pm.body.replace(/^\s+|\s+$/g, '');
            if (this._isVersionBigger(ver, pkg.version)) {
                this._L('handleGlobalMessage', `new version available ${pkg.version} -> ${ver}`);
                if (this._swu.isEnabled) {
                    this._L('handleGlobalMessage', 'set timeout for swu.checkForUpdate in 30 seconds');
                    setTimeout(() => {
                        if (this._swu.isEnabled) {
                            this._L('handleGlobalMessage', '_swu.isEnabled = true, call swu.checkForUpdate');
                            this._swu.checkForUpdate()
                                .then(() => this._L('handleGlobalMessage, swu.checkForUpdate.OK'))
                                .catch(err => this._L('handleGlobalMessage, swu.checkForUpdate.ERROR:', err));
                        }
                        else {
                            this._L('handleGlobalMessage', '_swu.isEnabled = false, skip swu.checkForUpdate');
                        }
                    }, 30000);
                }
            }
        }
    }

    handleUserMessage(msg: StompFrame): void {
        const pm = this._parseMessage(msg);
        // this._L('handleUserMessage:', pm);
        if (pm) {
            this.onUserMessage.next(pm);
            if (pm.body && ((pm.body.type && pm.body.operation) || (pm.body.id && pm.body._type)) && pm.exchange == 'ui_notifications') {
                if (pm.body.operation == ObjectOperation.Counters) {
                    this.handleRefreshCountersMessage(pm);
                }
                else {
                    this.handleObjectMessage(pm);
                }
            }
        }
    }

    handleOrgMessage(msg: StompFrame): void {
        const pm = this._parseMessage(msg);
        // this._L('handleOrgMessage:', pm);
        if (pm) {
            this.onOrgMessage.next(pm);
            if (pm.body && ((pm.body.type && pm.body.operation) || (pm.body.id && pm.body._type)) && pm.exchange == 'ui_notifications') {
                this.handleObjectMessage(pm);
            }
        }
    }

    handleChatMessage(chatId: Guid, msg: StompFrame): void {
        const pm = this._parseMessage(msg);
        // this._L('handleChatMessage:', pm);
        if (pm) {
            this.onChatMessage.next({ chatId, pm });
        }
    }

    private _parseMessage(msg: StompFrame, raw = false): IParsedMessage | undefined {
        let pm: IParsedMessage | undefined;
        let success = false;
        try {
            const parts = msg.headers.destination.split('/');
            if (parts.length != 4 || parts[0] != '' || parts[1] != 'exchange') {
                throw new Error('Wrong stomp message format.');
            }
            pm = {
                exchange: parts[2],
                routingKey: parts[3],
                rk: parts[3].split('.'),
                msgId: msg.headers['message-id'],
                body: raw ? msg.body : JSON.parse(msg.body)
            };
            if (pm.body != null && pm.routingKey && pm.exchange) {
                success = true;
            }
            else {
                success = false;
                this._W('_parseMessage', 'error parse message, body, routingKey or exchange are missed:', pm);
            }
        }
        catch (e) {
            success = false;
            this._W('_parseMessage', 'error parse message:', e);
        }
        return success && pm ? pm : undefined;
    }

    private _isVersionBigger(nv: string, ov: string): boolean {
        if (!nv) {
            return false;
        }
        if (!ov) {
            return true;
        }
        const nvs = nv.split('.');
        const ovs = ov.split('.');
        if (nvs.length != ovs.length) {
            return true;
        }
        for (let i = 0; i < 3; i++) {
            if (+nvs[i] > +ovs[i]) {
                return true;
            }
            else if (+nvs[i] < +ovs[i]) {
                return false;
            }
        }
        return false;
    }

    handleObjectMessage(pm: IParsedMessage): void {
        try {
            const obj = pm.body;
            const nobj = obj.id && obj._type && ObjectTypeClass[obj._type as ObjectType] ? ObjectTypeClass[obj._type as ObjectType]!(obj) : undefined;
            const ev: IObjectEvent = { ...obj, msgId: pm.msgId, rk: pm.routingKey, personal: pm.routingKey?.startsWith('person') };
            if (nobj) {
                this.onObject.next(nobj);
                this._addLog({
                    dt: new Date(),
                    e: pm.exchange,
                    rk: pm.routingKey,
                    ids: [obj.id],
                    t: obj._type,
                    o: ObjectOperation.Update,
                    data: obj
                });
                return;
            }
            else if (ev.type == ObjectType.IncomingCall) {
                this.onIncomingCall.next(ev);
            }
            else {
                this.onObjectEvent.next(ev);
                if (ev.refreshCounters) {
                    this.onRefreshCountersEvent.next({
                        msgId: pm.msgId,
                        type: obj.type,
                    });
                }
            }
            this._addLog({
                dt: new Date(),
                e: pm.exchange,
                rk: pm.routingKey,
                rc: ev.refreshCounters,
                ids: obj.ids,
                t: obj.type,
                o: obj.operation,
                data: (({ ids, type, operation, ...rest }) => rest)(obj)
            });
        }
        catch (e) {
            this._W('handleObjectMessage', 'wrong format of the message body:', e, 'pm:', pm);
        }
    }

    handleRefreshCountersMessage(pm: IParsedMessage): void {
        try {
            const obj = pm.body;
            this.onRefreshCountersEvent.next({
                msgId: pm.msgId,
                type: obj.type,
            });
            this._addLog({
                dt: new Date(),
                e: pm.exchange,
                rk: pm.routingKey,
                t: obj.type,
                o: obj.operation,
                data: (({ ids, type, operation, ...rest }) => rest)(obj)
            });
        }
        catch (e) {
            this._W('handleUnreadMessage', 'wrong format of the message body:', e, 'pm:', pm);
        }
    }

    subscribeToOrg(orgId: string): void {
        if (this._stomp.ui && !this.subscribedOrgs[orgId]) {
            this.subscribedOrgs[orgId] = this._stomp.ui.subscribe(`/exchange/ui_notifications/org.${orgId}`, (msg) => this.handleOrgMessage(msg));
            this._L('subscribeToOrg', `subscribing to [/exchange/ui_notifications/org.${orgId}]`, this.subscribedOrgs[orgId]);
        }
    }

    unsubscribeFromOrg(orgId: string): void {
        if (this._stomp.ui && this.subscribedOrgs[orgId]) {
            this._L('subscribeToOrg', `unsubscribing from [/exchange/ui_notifications/org.${orgId}], subscription id:`, this.subscribedOrgs[orgId]);
            this._stomp.ui.unsubscribe(this.subscribedOrgs[orgId]);
            delete this.subscribedOrgs[orgId];
        }
    }

    private _addLog(item: IStompLogItem): void {
        this.log.addItem(item);
        this.storeLog.next(this.log);
    }

}
