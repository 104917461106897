import { RecursivePartial } from '../base';
import { BaseState } from './base-state';

export class UrlState extends BaseState {

    _VER = 6;

    authBaseUrl?: string;
    apiBaseUrl?: string;
    rmqBaseUrl?: string;

    constructor(json?: RecursivePartial<UrlState>) {
        super(json);
        this.parseUrlState(json || {});
    }

    parse(json: RecursivePartial<UrlState>): UrlState {
        super.parse(json);
        this.parseUrlState(json);
        return this;
    }

    private parseUrlState(json: RecursivePartial<UrlState>): void {
        UrlState.assignFields<UrlState>(this, json, [
            'authBaseUrl',
            'apiBaseUrl',
            'rmqBaseUrl',
        ]);
    }

}
