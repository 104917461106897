import { RecursivePartial } from '../base';
import { BaseLayoutElement } from './_base-element';
import { LayoutElementType } from './layout-element-type';

export class IconElement extends BaseLayoutElement {
    type: LayoutElementType.Icon  = LayoutElementType.Icon;

    name: string = 'question';

    constructor(json?: RecursivePartial<IconElement>) {
        super(json);
        this.parseIcon(json || {});
    }

    parse(json: RecursivePartial<IconElement>): IconElement {
        super.parse(json);
        this.parseIcon(json);
        return this;
    }

    private parseIcon(json: RecursivePartial<IconElement>): void {
        IconElement.assignFields<IconElement>(this, json, ['name', 'name']);
        this.setCssClasses();
    }

    setCssClasses(): void {
        super.setCssClasses();
        this._classes![`vzi-${this.name}`] = true;
    }

}
