import { BaseModel, RecursivePartial } from "@models/base";
import { VzParamTriggerAction } from "./param-trigger-action";

export enum VzParamTriggerType {
    Manual           = 0,
    ValueChange      = 1,
    EnterKeyPressed  = 2,
}

export enum VzParamTriggerCondition {
    None       = 0,
    Equal      = 1,
    NotEqual   = 2,
}

export class VzParamTrigger extends BaseModel {
    static Type = VzParamTriggerType;
    static Condition = VzParamTriggerCondition;

    type!: VzParamTriggerType;
    condition?: VzParamTriggerCondition = VzParamTriggerCondition.None;
    value?: any;
    actions: VzParamTriggerAction[] = [];

    constructor(json?: RecursivePartial<VzParamTrigger>) {
        super(json);
        this.parseUnreadCounters(json || {});
    }

    parse(json: RecursivePartial<VzParamTrigger>): VzParamTrigger {
        super.parse(json);
        this.parseUnreadCounters(json);
        return this;
    }

    private parseUnreadCounters(json: RecursivePartial<VzParamTrigger>): void {
        VzParamTrigger.assignFields<VzParamTrigger>(this, json, [
            'type',
            'condition',
            'value',
        ]);
        VzParamTrigger.assignClassArrays<Omit<VzParamTrigger, 'value'>>(this, json, {
            actions: VzParamTriggerAction
        });
    }
}
