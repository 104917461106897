import { BaseState } from './base-state';
import { ObjectOperation, ObjectType, RecursivePartial } from '@models/base';

export interface IStompLogItem {
    dt: Date;
    e: string;
    rk: string;
    rc?: boolean;
    t?: ObjectType;
    o?: ObjectOperation;
    ids?: string[];
    data: any;
}

export class StompLogState extends BaseState {
    static SIZE = 500;

    cursor: number = 0;
    items: IStompLogItem[] = new Array(StompLogState.SIZE);

    constructor(json?: RecursivePartial<StompLogState>) {
        super(json);
        this.parseStompLog(json || {});
    }

    parse(json: RecursivePartial<StompLogState>): StompLogState {
        super.parse(json);
        this.parseStompLog(json);
        return this;
    }

    private parseStompLog(json: RecursivePartial<StompLogState>): void {
        StompLogState.assignFields<StompLogState, IStompLogItem[]>(this, json, ['cursor', 'items']);
    }

    addItem(item: IStompLogItem): void {
        this.items[this.cursor] = item;
        this.cursor = this.cursor == StompLogState.SIZE - 1 ? 0 : this.cursor + 1;
    }

    getItems(): IStompLogItem[] {
        const cur = this.cursor;
        let c = this.cursor + 1;
        if (c == StompLogState.SIZE) {
            c = 0;
        }
        let items: IStompLogItem[] = [];
        while (items.length < StompLogState.SIZE && c != cur) {
            if (this.items[c]) {
                items.push(this.items[c]);
            }
            c = c == StompLogState.SIZE - 1 ? 0 : c + 1;
        }
        return items;
    }

}
