import { BaseSystemModel, Guid, RecursivePartial } from '@models/base';

export class VideoServerRoom extends BaseSystemModel {
    orgId?: Guid;
    type?: number;
    status?: number;
    numUsers?: number;
    maxUsers?: number;
    finished?: Date;
    roomId?: number;
    // roomPin?: string;
    // token?: string;
    host?: string;

    constructor(json?: RecursivePartial<VideoServerRoom>) {
        super(json);
        this.parseVideoServerRoom(json || {});
    }

    parse(json: RecursivePartial<VideoServerRoom>): VideoServerRoom {
        super.parse(json);
        this.parseVideoServerRoom(json);
        return this;
    }

    private parseVideoServerRoom(json: RecursivePartial<VideoServerRoom>): void {
        VideoServerRoom.assignFields(this, json, [
            'orgId',
            'type',
            'status',
            'numUsers',
            'maxUsers',
            'roomId',
            'host',
        ]);
        VideoServerRoom.assignDates(this, json, ['finished']);
    }

}
