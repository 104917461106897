import { BaseModel, RecursivePartial } from '@models/base';

export class ApiError extends BaseModel {
    id?: string;
    code?: number;
    text?: string;
    debug?: string;

    constructor(json?: RecursivePartial<ApiError>) {
        super(json);
        this.parseApiError(json || {});
    }

    parse(json: RecursivePartial<ApiError>): ApiError {
        super.parse(json);
        this.parseApiError(json);
        return this;
    }

    private parseApiError(json: RecursivePartial<ApiError>): void {
        ApiError.assignFields<ApiError>(this, json, ['id', 'code', 'text', 'debug']);
    }
}
