import { BaseModel, SM, RecursivePartial } from '@models/base';

export enum ServiceStatus {
    Unknown = -1,
    Operational = 0,
	Maintenance,
	Failure
}

export const ServiceStatusName = {
    [ServiceStatus.Unknown]: 'Неизвестно',
    [ServiceStatus.Operational]: 'Работает',
    [ServiceStatus.Maintenance]: 'Тех.обслуживание',
    [ServiceStatus.Failure]: 'Внутренняя ошибка',
}

export const ServiceStatusColorClass = {
    [ServiceStatus.Unknown]: 'txt-l',
    [ServiceStatus.Operational]: 'txt-ok',
    [ServiceStatus.Maintenance]: 'txt-warn',
    [ServiceStatus.Failure]: 'txt-err',
}

export class HealthResponse extends BaseModel {
    status: ServiceStatus = ServiceStatus.Unknown;
    version: string = 'N/A';
    branch: string = '???';
    buildDate: string = '???';
    uptime: string = '???';
    systemUptime: string = '???';
    db?: {
        inUseConnections: number;
        totalConnections: number;
    };
    libraries: SM<string> = {};

    _name?: string;
    _css?: string;

    constructor(json?: RecursivePartial<HealthResponse>) {
        super(json);
        this.parseHealthResponse(json || {});
    }

    parse(json: RecursivePartial<HealthResponse>): HealthResponse {
        super.parse(json);
        this.parseHealthResponse(json);
        return this;
    }

    private parseHealthResponse(json: RecursivePartial<HealthResponse>): void {
        HealthResponse.assignFields<HealthResponse>(this, json, [
            'status',
            'version',
            'branch',
            'buildDate',
            'uptime',
            'systemUptime',
            'db',
            'libraries'
        ]);
        this._name = ServiceStatusName[this.status] || ServiceStatusName[ServiceStatus.Unknown];
        this._css = ServiceStatusColorClass[this.status] || ServiceStatusColorClass[ServiceStatus.Unknown];
    }
}
