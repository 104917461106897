import { BaseModel, RecursivePartial, SM } from '@models/base';

export enum VzFileReferenceType {
    Unknown = 'Unknown',
    Task = 'task.files',
    Chat = 'chat.file',
}

export const VzFileReferenceName: SM<string> = {
    [VzFileReferenceType.Unknown]: 'Неизвестно',
    [VzFileReferenceType.Chat]: 'Чаты',
    [VzFileReferenceType.Task]: 'Задачи',
}

export class VzFileReference extends BaseModel {
    static Type = VzFileReferenceType;
    type?: VzFileReferenceType;
    refId1?: string;
    refId2?: string;

    constructor(json?: RecursivePartial<VzFileReference>) {
        super(json);
        this.parseVzFileReference(json || {});
    }

    parse(json: RecursivePartial<VzFileReference>): VzFileReference {
        super.parse(json);
        this.parseVzFileReference(json);
        return this;
    }

    private parseVzFileReference(json: RecursivePartial<VzFileReference>): void {
        VzFileReference.assignFields<VzFileReference>(this, json, [
            'type',
            'refId1',
            'refId2',
        ]);
    }
}
