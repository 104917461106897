export enum ChatStatus {
    Unknown     = 0,
    Created     = 1,
    Started     = 2,
    Finished    = 3,
    Archived    = 4,
}

export const ChatStatusName = {
    [ChatStatus.Unknown]: '???',
    [ChatStatus.Created]: 'Создан',
    [ChatStatus.Started]: 'Идет',
    [ChatStatus.Finished]: 'Завершен',
    [ChatStatus.Archived]: 'В архиве',
}

export const ChatStatusIcon = {
    [ChatStatus.Unknown]: 'vzi-question',
    [ChatStatus.Created]: 'vzi-in',
    [ChatStatus.Started]: 'vzi-repeat',
    [ChatStatus.Finished]: 'vzi-done',
    [ChatStatus.Archived]: 'vzi-floppy',
}
