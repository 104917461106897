import { BaseModel, RecursivePartial } from '@models/base';

export class CodeSnippet extends BaseModel {

    text!: string;
    title?: string;
    syntax?: string;
    mimeType?: string;

    constructor(json?: RecursivePartial<CodeSnippet>) {
        super(json);
        this.parseCodeSnippet(json || {});
    }

    parse(json: RecursivePartial<CodeSnippet>): CodeSnippet {
        super.parse(json);
        this.parseCodeSnippet(json);
        return this;
    }

    private parseCodeSnippet(json: RecursivePartial<CodeSnippet>): void {
        CodeSnippet.assignFields<CodeSnippet>(this, json, ['text', 'title', 'syntax', 'mimeType']);
    }
}
