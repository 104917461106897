import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { format, formatRelative } from 'date-fns';
import { ru } from 'date-fns/locale';

@Pipe({
    name: 'dt'
})
export class DtPipe implements PipeTransform {

    transform(value: number | Date | undefined, abs = true, retStr = '-', fmt = 'dd.MM.y HH:mm:ss'): string {
        if (!value) {
            return retStr;
        }
        if (typeof value != 'object') {
            value = new Date(value * 1000);
        }
        return abs
            ? (value && !isNaN(value.getTime()) ? format(value, fmt, { locale: ru }) : '???')
            : (value && !isNaN(value.getTime()) ? formatRelative(value, new Date(), { locale: ru }) : '???');
    }

}

@NgModule({
    imports: [CommonModule],
    declarations: [DtPipe],
    exports: [DtPipe]
})
export class DtPipeModule { }
