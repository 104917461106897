import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { AvatarComponent } from './avatar.component';
import { DtPipeModule } from '@pipes';

@NgModule({
    imports: [
        CommonModule, NzToolTipModule, DtPipeModule
    ],
    declarations: [AvatarComponent],
    exports: [AvatarComponent]
})
export class AvatarModule { }
