import { BaseModel, RecursivePartial } from '@models/base';

export class VzFilePdfView extends BaseModel {
    numPages: number = 0;

    constructor(json?: RecursivePartial<VzFilePdfView>) {
        super(json);
        this.parseVzFilePdfView(json || {});
    }

    parse(json: RecursivePartial<VzFilePdfView>): VzFilePdfView {
        super.parse(json);
        this.parseVzFilePdfView(json);
        return this;
    }

    private parseVzFilePdfView(json: RecursivePartial<VzFilePdfView>): void {
        VzFilePdfView.assignFields<VzFilePdfView>(this, json, [
            'numPages',
        ]);
    }
}
