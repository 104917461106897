import { BaseFilterField, FilterFieldType } from '@models/filters/base-filter-field';
import { Chat } from '@models/chats';
import { matchFilter } from '@models/utils/strings';

export class ChatFilterFieldDefault extends BaseFilterField<Chat, string, true> {
    id: string = 'default';
    name: string = 'Заголовок';
    desc: string = 'Фильтрует по заголовку';
    type: FilterFieldType.String = FilterFieldType.String;
    single = true as const;

    value(o: Chat): string {
        return o.title;
    }

    match(o: Chat, v?: string): boolean {
        if (!v || matchFilter(o.title, v)) {
            return true;
        }
        return false;
    }

}
