/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import {
    IBillingOrgRequestFilter, IInvoiceRequestFilter, IOrgLimits, IUserRequestFilter,
    Org, OrgInfo, OrgInvoice, SM, Tag, OrgsCountStats, Person, OrgsTasksStats, OrgsChatsStats,
    OrgsEventsStats, VideoServer, Guid, VideoServerRoom,
    Integration, VzTip,
} from '@models';
import { ApiMethod, VzServices, emitArray, Delete, Get, Post, Put, sendRequest, TVzResponse } from '@models/utils/http-helpers';
import { AuthService } from './auth.service';
import { StoreService } from './store.service';

@Injectable()
@Tag('AdminApiService')
export class AdminApiService extends BaseLogging {

    private _authBaseUrl: string | undefined = this.__ENV.urls.auth;
    private _apiBaseUrl: string | undefined = this.__ENV.urls.api;
    private _conf: SM<() => VzServices> = {};
    RD: SM<ApiMethod<any, any>>;
    private __req?: ApiMethod<any, any> | null;
    lastRequest: number = new Date().getTime();

    constructor(
        private _auth: AuthService,
        private _http: HttpClient,
        private _store: StoreService
    ) {
        super();
        this.RD = (this as any).__RD;
        if (this.__ENV.env != 'prod') {
            this._store.state('url').subscribe(state => {
                this._authBaseUrl = state.authBaseUrl;
                this._apiBaseUrl = state.apiBaseUrl;
                this._L('constructor, authBaseUrl:', this._authBaseUrl, 'apiBaseUrl:', this._apiBaseUrl);
            });
        }
        const conf: () => VzServices = () => ({
            baseUrl: '',
            http: this._http,
            L: this._L,
            W: this._W,
            updateLastRequest: () => this.lastRequest = new Date().getTime(),
            authTokenState: this._store.authTokenState,
            getLastActivity: () => new Date(this._store.getLastActivity()),
            getRefreshToken: () => this._store.getState('user', st => st.refreshToken),
            getRefreshingAuthToken: () => this._auth.refreshingAuthToken,
            setRefreshingAuthToken: rat => this._auth.refreshingAuthToken = rat,
            getUserId: () => this._store.getState('user', st => st.userId),
            gotNewToken: token => this._store.patchState('user', { authToken: token }),
            renewAuthToken: (userId, refreshToken, lastActivity) => this._auth.renewAuthToken(userId, refreshToken, lastActivity)
        });
        this._conf.api = () => ({ ...conf(), baseUrl: this._apiBaseUrl! });
        this._conf.auth = () => ({ ...conf(), baseUrl: this._authBaseUrl! });
    }

    sendRequest<T extends TVzResponse>(id: string, ...args: any): Observable<T> {
        return sendRequest(this.RD[id], this._conf[this.RD[id].cfg!] || this._conf.api, ...args);
    }

    getBaseUrl(reqId: string): string {
        return (this._conf[this.RD[reqId].cfg!] || this._conf.api)().baseUrl;
    }


    // * ######################################################################################################### Orgs

    @Post('getOrgs', '/admin/orgs', {
        desc: 'Получить список организаций (auth)',
        body: (ids: Guid[]) => ids,
        cfg: 'auth',
        res: {
            errorTitle: 'Не удалось получить список организаций (auth)',
            parser: emitArray(Org)
        }
    }) // @ts-ignore
    getOrgs(ids: Guid[]): Observable<Org[] | undefined> {}

    @Post('setOrgLimits', (orgId: Guid, limits: IOrgLimits) => `/billing/admin/orgs/${orgId}/limits`, {
        desc: 'Измененить лимиты организации (billing)',
        body: (orgId: Guid, limits: IOrgLimits) => limits,
        res: {
            errorTitle: 'Не удалось измененить лимиты организации (billing)',
            clazz: OrgInfo
        }
    }) // @ts-ignore
    setOrgLimits(orgId: Guid, limits: IOrgLimits): Observable<OrgInfo> {}

    @Post('setOrgLimitsAuth', (orgId: Guid, slots: number) => `/admin/orgs/${orgId}/limits`, {
        desc: 'Измененить лимиты организации (auth)',
        body: (orgId: Guid, slots: number) => ({ slots }),
        cfg: 'auth',
        res: {
            errorTitle: 'Не удалось измененить лимиты организации (auth)',
            clazz: OrgInfo
        }
    }) // @ts-ignore
    setOrgLimitsAuth(orgId: Guid, slots: number): Observable<void> {}

    @Get('getBillingOrgs', '/billing/admin/orgs', {
        desc: 'Получить список организаций (billing)',
        params: (filter: IBillingOrgRequestFilter) => filter as any || {},
        res: {
            errorTitle: 'Не удалось получить список организаций (billing)',
            parser: emitArray(OrgInfo)
        }
    }) // @ts-ignore
    getBillingOrgs(filter: IBillingOrgRequestFilter): Observable<OrgInfo[] | undefined> {}

    @Put('changeBillingOrgVerified', (orgId: Guid, verified: boolean) => `/billing/admin/orgs/${orgId}`, {
        desc: 'Измененить статус verified организации',
        body: (id: Guid, verified: boolean) => ({ verified }),
        res: {
            errorTitle: 'Не удалось измененить статус verified организации',
        }
    }) // @ts-ignore
    changeBillingOrgVerified(orgId: Guid, verified: boolean): Observable<void> {}

    @Post('addToVideoBalance', (orgId: Guid, sum: number) => `/billing/admin/orgs/${orgId}/balance_changes`, {
        desc: 'Добавить средства на баланс для видео',
        body: (orgId: Guid, sum: number) => ({ balanceVideo: sum }),
        res: {
            errorTitle: 'Не удалось добавить средства на баланс для видео',
        }
    }) // @ts-ignore
    addToVideoBalance(orgId: Guid, sum: number): Observable<void> {}


    @Post('createMonthlyCharge', (orgId: Guid, dt: Date) => `/billing/admin/orgs/${orgId}/monthly_charge`, {
        desc: 'Создать задачу на ежемесячное списание',
        body: (orgId: Guid, dt: Date) => ({ nextProcessAt: dt.toISOString() }),
        res: {
            errorTitle: 'Не удалось создать задачу на ежемесячное списание',
        }
    }) // @ts-ignore
    createMonthlyCharge(orgId: Guid, dt: Date): Observable<void> {}

    @Delete('deleteMonthlyCharge', (orgId: Guid) => `/billing/admin/orgs/${orgId}/monthly_charge`, {
        desc: 'Удалить задачу на ежемесячное списание',
        res: {
            errorTitle: 'Не удалось удалить задачу на ежемесячное списание',
        }
    }) // @ts-ignore
    deleteMonthlyCharge(orgId: Guid): Observable<void> {}




    // * ######################################################################################################### Stats

    @Get('getOrgsCountStats', '/billing/admin/stats/orgs', {
        desc: 'Получить статистику по количеству организаций',
        params: (start?: Date, end?: Date) => {
            if (start || end) {
                const res: any = {};
                if (start) {
                    res.start = start.toISOString();
                }
                if (end) {
                    res.end = end.toISOString();
                }
                return res;
            }
            else {
                return undefined;
            }
        },
        res: {
            errorTitle: 'Не удалось получить статистику по количеству организаций',
            parser: emitArray(OrgsCountStats)
        }
    }) // @ts-ignore
    getOrgsCountStats(start?: Date, end?: Date): Observable<OrgsCountStats[]> {}

    @Post('getOrgsTasks', '/tasks/admin/stats', {
        desc: 'Получить количество задач в организациях',
        body: (ids: Guid[]) => ids,
        res: {
            errorTitle: 'Не удалось получить количество задач в организациях',
            parser: emitArray(OrgsTasksStats)
        }
    }) // @ts-ignore
    getOrgsTasks(ids: Guid[]): Observable<OrgsTasksStats[] | undefined> {}

    @Post('getOrgsChats', '/chats/admin/stats', {
        desc: 'Получить количество чатов и сообщений в организациях',
        body: (ids: Guid[]) => ids,
        res: {
            errorTitle: 'Не удалось получить количество чатов и сообщений в организациях',
            parser: emitArray(OrgsChatsStats)
        }
    }) // @ts-ignore
    getOrgsChats(ids: Guid[]): Observable<OrgsChatsStats[] | undefined> {}

    @Post('getOrgsEvents', '/events/admin/stats', {
        desc: 'Получить количество событий и дату последней активности в организациях',
        body: (ids: Guid[]) => ids,
        res: {
            errorTitle: 'Не удалось получить количество событий и дату последней активности в организациях',
            parser: emitArray(OrgsEventsStats)
        }
    }) // @ts-ignore
    getOrgsEvents(ids: Guid[]): Observable<OrgsEventsStats[] | undefined> {}




    // * ######################################################################################################### Users

    @Post('searchUsers', '/admin/users/search', {
        desc: 'Получить список пользователей',
        body: (filter: IUserRequestFilter) => filter,
        cfg: 'auth',
        res: {
            errorTitle: 'Не удалось получить список пользователей',
            parser: emitArray(Person)
        }
    }) // @ts-ignore
    searchUsers(filter: IUserRequestFilter): Observable<Person[] | undefined> {}



    // * ######################################################################################################### Invoices

    @Get('getInvoices', '/billing/admin/invoices', {
        desc: 'Получить список счетов',
        params: (filter: IInvoiceRequestFilter) => filter as any || {},
        res: {
            errorTitle: 'Не удалось получить список счетов',
            parser: emitArray(OrgInvoice)
        }
    }) // @ts-ignore
    getInvoices(filter: IInvoiceRequestFilter): Observable<OrgInvoice[] | undefined> {}

    @Post('createInvoice', '/billing/admin/invoices', {
        desc: 'Создать и провести технический счет',
        body: (orgId: Guid, orderNum: number, sum: number, note?: string) => ({ orgId, orderNum, sum, note }),
        res: {
            errorTitle: 'Не удалось создать и провести технический счет',
        }
    }) // @ts-ignore
    createInvoice(orgId: Guid, orderNum: number, sum: number, note?: string): Observable<void> {}

    @Put('changeInvoicePaid', (invoiceId: Guid, paid: boolean, orderNum: number) => `/billing/admin/invoices/${invoiceId}`, {
        desc: 'Измененить статус оплаты счета',
        body: (invoiceId: Guid, paid: boolean, orderNum: number) => ({ paid, orderNum }),
        res: {
            errorTitle: 'Не удалось измененить статус оплаты счета',
        }
    }) // @ts-ignore
    changeInvoicePaid(invoiceId: Guid, paid: boolean, orderNum: number): Observable<void> {}

    @Delete('deleteInvoice', (invoiceId: Guid) => `/billing/admin/invoices/${invoiceId}`, {
        desc: 'Удалить счет',
        res: {
            errorTitle: 'Не удалось удалить счет',
        }
    }) // @ts-ignore
    deleteInvoice(invoiceId: Guid): Observable<void> {}



    // * ######################################################################################################### Video

    @Get('getVideoServers', '/video/admin/servers', {
        desc: 'Получить список видеосерверов',
        res: {
            errorTitle: 'Не удалось получить список видеосерверов',
            parser: emitArray(VideoServer)
        }
    }) // @ts-ignore
    getVideoServers(): Observable<VideoServer[] | undefined> {}

    @Get('getVideoServer', (id: Guid) => `/video/admin/servers/${id}`, {
        desc: 'Получить видеосервер по ИД',
        res: {
            errorTitle: 'Не удалось получить видеосервер',
            clazz: VideoServer
        }
    }) // @ts-ignore
    getVideoServer(id: Guid): Observable<VideoServer> {}

    @Post('createVideoServer', '/video/admin/servers', {
        desc: 'Создать видеосервер',
        body: (server: VideoServer) => server,
        res: {
            errorTitle: 'Не удалось создать видеосервер',
        }
    }) // @ts-ignore
    createVideoServer(server: VideoServer): Observable<void> {}

    @Put('updateVideoServer', (id: Guid, server: VideoServer) => `/video/admin/servers/${id}`, {
        desc: 'Измененить видеосервер',
        body: (id: Guid, server: VideoServer) => server,
        res: {
            errorTitle: 'Не удалось измененить видеосервер',
        }
    }) // @ts-ignore
    updateVideoServer(id: Guid, server: VideoServer): Observable<void> {}

    @Delete('deleteVideoServer', (id: Guid) => `/video/admin/servers/${id}`, {
        desc: 'Удалить видеосервер по ИД',
        res: {
            errorTitle: 'Не удалось удалить видеосервер',
        }
    }) // @ts-ignore
    deleteVideoServer(id: Guid): Observable<void> {}

    @Get('getVideoServerRooms', (id: Guid) => `/video/admin/servers/${id}/rooms`, {
        desc: 'Получить список комнат на видеосервере',
        res: {
            errorTitle: 'Не удалось получить список комнат на видеосервере',
            parser: emitArray(VideoServerRoom)
        }
    }) // @ts-ignore
    getVideoServerRooms(id: Guid): Observable<VideoServerRoom[] | undefined> {}



    // * ######################################################################################################### Integrations

    @Get('getIntegrations', '/integrations/admin/integrations', {
        desc: 'Получить список интеграций',
        res: {
            errorTitle: 'Не удалось получить список интеграций',
            parser: emitArray(Integration)
        }
    }) // @ts-ignore
    getIntegrations(): Observable<Integration[] | undefined> {}

    @Get('getIntegration', (id: Guid) => `/integrations/admin/integrations/${id}`, {
        desc: 'Получить интеграцию по ИД',
        res: {
            errorTitle: 'Не удалось получить интеграцию',
            clazz: Integration
        }
    }) // @ts-ignore
    getIntegration(id: Guid): Observable<Integration> {}

    @Post('createIntegration', '/integrations/admin/integrations', {
        desc: 'Создать интеграцию',
        body: (integration: Integration) => integration,
        res: {
            errorTitle: 'Не удалось создать интеграцию',
        }
    }) // @ts-ignore
    createIntegration(integration: Integration): Observable<void> {}

    @Put('updateIntegration', (id: Guid, integration: Integration) => `/integrations/admin/integrations/${id}`, {
        desc: 'Измененить интеграцию',
        body: (id: Guid, integration: Integration) => integration,
        res: {
            errorTitle: 'Не удалось измененить интеграцию',
        }
    }) // @ts-ignore
    updateIntegration(id: Guid, integration: Integration): Observable<void> {}

    @Delete('deleteIntegration', (id: Guid) => `/integrations/admin/integrations/${id}`, {
        desc: 'Удалить интеграцию по ИД',
        res: {
            errorTitle: 'Не удалось удалить интеграцию',
        }
    }) // @ts-ignore
    deleteIntegration(id: Guid): Observable<void> {}


    // * ######################################################################################################### Tips

    @Get('getTips', '/tips/admin/tips', {
        desc: 'Получить список советов',
        res: {
            errorTitle: 'Не удалось получить список советов',
            parser: emitArray(VzTip)
        }
    }) // @ts-ignore
    getTips(): Observable<VzTip[] | undefined> {}

    @Get('getTip', (id: number) => `/tips/admin/tips/${id}`, {
        desc: 'Получить совет по ИД',
        res: {
            errorTitle: 'Не удалось получить совет',
            clazz: VzTip
        }
    }) // @ts-ignore
    getTip(id: number): Observable<VzTip> {}

    @Post('createTip', '/tips/admin/tips', {
        desc: 'Создать совет',
        body: (tip: VzTip) => tip,
        res: {
            errorTitle: 'Не удалось создать совет',
        }
    }) // @ts-ignore
    createTip(tip: VzTip): Observable<void> {}

    @Put('updateTip', (id: number, tip: VzTip) => `/tips/admin/tips/${id}`, {
        desc: 'Измененить совет',
        body: (id: number, tip: VzTip) => tip,
        res: {
            errorTitle: 'Не удалось измененить совет',
        }
    }) // @ts-ignore
    updateTip(id: number, tip: VzTip): Observable<void> {}

    @Delete('deleteTip', (id: number) => `/tips/admin/tips/${id}`, {
        desc: 'Удалить совет по ИД',
        res: {
            errorTitle: 'Не удалось удалить совет',
        }
    }) // @ts-ignore
    deleteTip(id: number): Observable<void> {}

}
