import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { VzFile } from '@models/files';

export class TaskActionFileAttach extends BaseTaskAction {
    type: TaskActionType.FileAttach = TaskActionType.FileAttach;
    fileIds!: string[];
    files: VzFile[] = [];

    constructor(json?: RecursivePartial<TaskActionFileAttach>) {
        super(json);
        this.parseTaskActionFile(json || {});
    }

    parse(json: RecursivePartial<TaskActionFileAttach>): TaskActionFileAttach {
        super.parse(json);
        this.parseTaskActionFile(json);
        return this;
    }

    private parseTaskActionFile(json: RecursivePartial<TaskActionFileAttach>): void {
        TaskActionFileAttach.assignFields<TaskActionFileAttach>(this, json, ['fileIds']);
        TaskActionFileAttach.assignClassArrays<TaskActionFileAttach>(this, json, { files: VzFile });
        if (!json.fileIds) {
            this.fileIds = (json as any).fileId ? [(json as any).fileId] : [];
        }
    }
}
