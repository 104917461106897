import { getUuid } from '@models/utils/uuid';
import { BaseModel, RecursivePartial } from '@models/base';
import { TaskChecklist } from './task-checklist';

export class TaskTemplate extends BaseModel {
    orgId!: string;
    subj?: string;
    text?: string;
    assignee?: string;
    assigneeGroup?: string;
    parentId?: string;
    projectId?: string;
    watchers?: string[];
    approvers?: string[];
    dueDateDays?: number;
    priority?: number;
    tagIds?: string[];
    list?: string;
    checklist?: TaskChecklist;

    id!: string;
    name?: string;
    created!: Date;
    lastUsed?: Date;
    usedCount: number = 0;

    constructor(json?: RecursivePartial<TaskTemplate>) {
        super(json);
        this.parseTaskTemplate(json || {});
    }

    parse(json: RecursivePartial<TaskTemplate>): TaskTemplate {
        super.parse(json);
        this.parseTaskTemplate(json);
        return this;
    }

    private parseTaskTemplate(json: RecursivePartial<TaskTemplate>): void {
        TaskTemplate.assignFields<TaskTemplate>(this, json, [
            'orgId',
            'text',
            'subj',
            'assignee',
            'assigneeGroup',
            'parentId',
            'projectId',
            'watchers',
            'approvers',
            'dueDateDays',
            'priority',
            'tagIds',
            'list',
            'id',
            'name',
            'usedCount'
        ]);
        TaskTemplate.assignDates<TaskTemplate>(this, json, ['created', 'lastUsed']);
        TaskTemplate.assignClassFields<TaskTemplate>(this, json, { checklist: TaskChecklist });
        if (this.priority && (this.priority > 3 || this.priority < 0)) {
            this.priority = 0;
        }
        if (!this.id) {
            this.id = getUuid();
        }
        if (!this.created) {
            this.created = new Date();
        }
    }

}
