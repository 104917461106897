import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { UserOrgStatus } from '@models/users';

export class OrgStatusState extends BaseItemsState<UserOrgStatus> {

    constructor(json?: RecursivePartial<OrgStatusState>) {
        super(json);
        this.parseUserOrgStatusState(json || {});
    }

    parse(json: RecursivePartial<OrgStatusState>): OrgStatusState {
        super.parse(json);
        this.parseUserOrgStatusState(json);
        return this;
    }

    private parseUserOrgStatusState(json: RecursivePartial<OrgStatusState>): void {
        OrgStatusState.assignClassMaps<OrgStatusState>(this, json, { items: UserOrgStatus });
    }

}
