import { BaseModel, RecursivePartial } from '@models/base';

export class IdActive extends BaseModel {
    id?: string;
    active?: boolean;

    constructor(json?: RecursivePartial<IdActive>) {
        super(json);
        this.parseIdActive(json || {});
    }

    parse(json: RecursivePartial<IdActive>): IdActive {
        super.parse(json);
        this.parseIdActive(json);
        return this;
    }

    private parseIdActive(json: RecursivePartial<IdActive>): void {
        IdActive.assignFields<IdActive>(this, json, ['id', 'active']);
    }
}
