import { HttpApiError } from "../api/http-api-error";
import { PrimitivesType, KeysByType, RecursivePartial } from "./helper-types";

type BaseModelKeys<T extends object> = KeysByType<T, BaseModel | undefined>;
type BaseModelArrayKeys<T extends object> = KeysByType<T, BaseModel[] | undefined>;

export class BaseModel {

    constructor(_json?: RecursivePartial<BaseModel>) {}

    static assignFields<T extends BaseModel, K = void>(
        obj: T,
        json: RecursivePartial<T>,
        keys: KeysByType<T, K | PrimitivesType | HttpApiError>[]
    ): void {
        if (json) {
            for (const key of keys) {
                if ((obj as any)[key] === undefined) {
                    if (json[key] !== undefined && json[key] !== null) {
                        if (Array.isArray(json[key])) {
                            (obj as any)[key] = [];
                            for (const item of (json[key] as any)) {
                                (obj as any)[key].push(item);
                            }
                        }
                        else {
                            (obj as any)[key] = json[key];
                        }
                    }
                    else {
                        (obj as any)[key] = null;
                    }
                }
                else if (json[key] !== undefined) {
                    if (json[key] !== null && Array.isArray(json[key])) {
                        (obj as any)[key] = [];
                        for (const item of (json[key] as any)) {
                            (obj as any)[key].push(item);
                        }
                    }
                    else if (json[key] !== null && typeof json[key] === 'object') {
                        (obj as any)[key] = {};
                        for (const k of Object.keys(json[key]!)) {
                            (obj as any)[key][k] = (json[key] as any)[k];
                        }
                    }
                    else {
                        (obj as any)[key] = json[key];
                    }
                }
            }
        }
        BaseModel.clearNulls(obj);
    }

    static assignClassFields<T extends BaseModel>(
        obj: T,
        json: RecursivePartial<T>,
        keys: { [P in BaseModelKeys<T>]: typeof BaseModel }
    ): void {
        if (json) {
            Object.keys(keys).forEach(n => {
                const key = n as BaseModelKeys<T>;
                const clazz = keys[key];
                if ((obj as any)[key] === undefined) {
                    (obj as any)[key] = (json[key] !== undefined && json[key] !== null) ? new clazz!(json[key] as any) : null;
                }
                else if (json[key] !== undefined && json[key] !== null) {
                    (obj as any)[key] = (obj as any)[key] == null
                        ? new clazz!(json[key] as any)
                        : ((obj as any)[key] instanceof clazz ? (obj as any)[key] : new clazz!((obj as any)[key])).parse(json[key] as any);
                }
            });
        }
        BaseModel.clearNulls(obj);
    }

    static assignClassArrays<T extends BaseModel>(
        obj: T,
        json: RecursivePartial<T>,
        keys: { [P in BaseModelArrayKeys<T>]: typeof BaseModel },
        data?: { [P in BaseModelArrayKeys<T>]?: any }
    ): void {
        if (json) {
            Object.keys(keys).forEach(n => {
                const key = n as BaseModelArrayKeys<T>;
                const clazz = keys[key];
                if ((obj as any)[key] === undefined || (json[key] !== null && Array.isArray(json[key]))) {
                    (obj as any)[key] = BaseModel.getClassArray<T>(json[key] as any, clazz!, data?.[key]);
                }
            });
        }
        BaseModel.clearNulls(obj);
    }

    static assignClassMaps<T extends BaseModel>(
        obj: T,
        json: RecursivePartial<T>,
        keys: { [P in BaseModelKeys<T>]: typeof BaseModel }
    ): void {
        if (json) {
            Object.keys(keys).forEach(key => {
                const clazz = keys[key as BaseModelKeys<T>];
                if ((obj as any)[key] === undefined || (json[key as keyof T] !== null && typeof json[key as BaseModelKeys<T>] === 'object')) {
                    (obj as any)[key] = BaseModel.getClassMap(json, key as BaseModelKeys<T>, clazz!);
                }
            });
        }
        BaseModel.clearNulls(obj);
    }

    static assignDates<T extends BaseModel>(
        obj: T,
        json: RecursivePartial<T>,
        keys: KeysByType<T, Date | undefined>[]
    ): void {
        if (json) {
            for (const key of keys) {
                if (json[key] !== undefined) {
                    const dt = new Date(json[key] as any);
                    if (dt && !isNaN(dt.getTime())) {
                        (obj as any)[key] = dt;
                    }
                }
            }
        }
    }

    static getClassArray<T extends BaseModel>(
        json: T[BaseModelArrayKeys<T>] | undefined,
        clazz: typeof BaseModel,
        data?: RecursivePartial<T[BaseModelArrayKeys<T>]>
    ): T[] | null {
        if (json !== undefined && json !== null && Array.isArray(json)) {
            const res: T[] = [];
            for (const obj of (json as any)) {
                res.push(new clazz(data ? {...obj, ...data} : obj) as T);
            }
            return res;
        }
        else {
            return null;
        }
    }

    static getClassMap<T extends BaseModel>(
        json: RecursivePartial<T>,
        key: BaseModelKeys<T>,
        clazz: typeof BaseModel
    ): { [id: string]: T } | null {
        if (json[key] !== undefined && json[key] !== null && typeof json[key] === 'object') {
            const res: { [id: string]: T } = {};
            for (const id of Object.keys(json[key]!)) {
                res[id] = new clazz((json[key] as any)[id]) as T;
            }
            return res;
        }
        else {
            return null;
        }
    }

    static clearNulls(obj: any): void {
        Object.keys(obj).forEach(f => {
            if ((obj as any)[f] === null) {
                delete (obj as any)[f];
            }
        });
    }

    parse<T extends BaseModel>(_json: RecursivePartial<T>): any {
        return this;
    }

}
