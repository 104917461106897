import { BaseModel, RecursivePartial } from '@models/base';

export enum TaskApproverState {
    Unknown = 0,
    Accepted,
    Rejected,
}

export class TaskApprover extends BaseModel {
    id!: string;
    state: TaskApproverState = 0;

    constructor(json?: RecursivePartial<TaskApprover>) {
        super(json);
        this.parseTaskApprover(json || {});
    }

    parse(json: RecursivePartial<TaskApprover>): TaskApprover {
        super.parse(json);
        this.parseTaskApprover(json);
        return this;
    }

    private parseTaskApprover(json: RecursivePartial<TaskApprover>): void {
        TaskApprover.assignFields<TaskApprover>(this, json, ['id', 'state']);
    }

}
