export const G = (tag: string, collapsed = false) => {
    return (target: any, _name: string, pd: PropertyDescriptor) => {
        const g = collapsed ? target._GC || console.groupCollapsed : target._G || console.group;
        const ge = target._GE || console.groupEnd;
        const om = pd.value;
        pd.value = function(...args: any[]) {
            g(tag);
            const result = om.apply(this, args);
            ge();
            return result;
        }
        return pd;
    };
};
