import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionAssign extends BaseTaskAction {
    type: TaskActionType.Assign = TaskActionType.Assign;
    assignee?: string;
    assigneeOld?: string;
    assigneeGroup?: string;
    assigneeGroupOld?: string;

    constructor(json?: RecursivePartial<TaskActionAssign>) {
        super(json);
        this.parseTaskActionAssign(json || {});
    }

    parse(json: RecursivePartial<TaskActionAssign>): TaskActionAssign {
        super.parse(json);
        this.parseTaskActionAssign(json);
        return this;
    }

    private parseTaskActionAssign(json: RecursivePartial<TaskActionAssign>): void {
        TaskActionAssign.assignFields<TaskActionAssign>(this, json, [
            'assignee',
            'assigneeOld',
            'assigneeGroup',
            'assigneeGroupOld'
        ]);
    }
}
