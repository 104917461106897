<div class="cmp-root layout fit vertical" [class.cmp-root-mobile]="mobile">
    <div class="layout" style="padding-bottom: 16px">
        <div class="layout flex mr1">
            <nz-input-group [nzPrefix]="prefixIconSearch">
                <input
                    nz-input
                    type="text"
                    [(ngModel)]="f"
                    (ngModelChange)="inputChange($event)"
                    placeholder="Ввведите текст..."
                    (keyup.enter)="search(f)"
                    aFocus
                />
            </nz-input-group>
            @if (multiple) {
                <button class="ml05" nz-button nzType="primary" (click)="close(controller.getSelectedItems())">Применить</button>
            }
        </div>
    </div>
    <div class="layout flex relative">
        <dm-table class="fit ngx-dmt-clickable vz-list-table"
            [class.ngx-dmt-noheader]="!multiple"
            [controller]="controller"
            moveableColumns="false"
            (rowClick)="multiple ? undefined : close([$event.row])"
            [showSelectColumn]="!!multiple"
            selectColumnWidth="40"
            itemSize="82">
            <ng-template #noItemsVisible>
                <div class="layout vertical fit center-center bg tac p1">
                    <span class="no-text fs-xl">Под данный фильтр не подходит ни одна задача</span>
                </div>
            </ng-template>
            <ng-template #selectColumnHeader let-all="all" let-none="none">
                <div class="tac">
                    <label nz-checkbox [ngModel]="all" [nzIndeterminate]="!all && !none"></label>
                </div>
            </ng-template>
            <ng-template #selectColumn let-checked let-row="row">
                <div class="tac">
                    <label nz-checkbox [ngModel]="checked"></label>
                </div>
            </ng-template>
            <dm-column title="" colId="row" resizable="false" sortable="false">
                <ng-template #header>
                    @if (cState && cState.itemsSelected > 0) {
                        <span class="ml1 fw-n txt-p">выбрано {{ cState.itemsSelected }} из {{ cState.itemsTotal }}</span>
                    }
                    @else {
                        задачи
                    }
                </ng-template>
                <ng-template #cell let-row="row">
                    <vz-task-card class="layout relative pe-none" [task]="row" [taskCardName]="'forSelect'" />
                </ng-template>
            </dm-column>
        </dm-table>
        <vz-loading [show]="loading" />
    </div>
</div>

@if (error) {
    <vz-error-message [error]="error" (dismiss)="error = undefined" />
}
<ng-template #prefixIconSearch>
    <i class="vzi-search fs-xl ml-025"></i>
</ng-template>
