import { BaseModel } from './base-model';
import { RecursivePartial } from './helper-types';
import { ObjectType } from './object-type';

export class OLD_BaseSystemModel extends BaseModel {
    static Type = ObjectType;
    _type?: ObjectType;

    id?: string;
    created?: number;
    modified?: number;
    createdBy?: string;
    modifiedBy?: string;

    constructor(json?: RecursivePartial<OLD_BaseSystemModel>) {
        super(json);
        this.parseBaseSystemModel(json || {});
    }

    parse(json: RecursivePartial<OLD_BaseSystemModel>): OLD_BaseSystemModel {
        super.parse(json);
        this.parseBaseSystemModel(json);
        return this;
    }

    private parseBaseSystemModel(json: RecursivePartial<OLD_BaseSystemModel>): void {
        OLD_BaseSystemModel.assignFields<OLD_BaseSystemModel>(this, json, ['id', 'created', 'modified', 'createdBy', 'modifiedBy']);
    }
}
