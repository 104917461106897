import { BaseFilterField, FilterFieldType } from '@models/filters/base-filter-field';
import { Chat } from '@models/chats';
import { isDateFilterMatch } from '@models/filters/utils';
import { DateRanges } from '@models/shared';

export class ChatFilterFieldCreated extends BaseFilterField<Chat, Date, true> {
    id: string = 'created';
    name: string = 'Дата создания';
    desc: string = 'Фильтрует по дате создания. Можно использовать префикс ">", "<" или "=" и дату в формате ISO 8601 (ГОД-МЕСЯЦ-ДЕНЬ), при этом время не учитывается';
    type: FilterFieldType.Date = FilterFieldType.Date;
    single = true as const;

    value(o: Chat): Date {
        return o.created!;
    }

    match(o: Chat, v: string, data?: { ranges: DateRanges }): boolean {
        return isDateFilterMatch(o.created, v, data?.ranges);
    }

}
