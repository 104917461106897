import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import { AutoFocusModule } from '@shared/_utils/autofocus.module';
import { AvatarModule } from '@shared/_views/avatar';
import { TagsSelectComponent } from '@shared/_controls/tags-select.component';
import { ProjectSelectModule } from '@shared/_controls/project-select.module';
import { UserOrGroupSelectModule } from '@shared/_controls/person-or-group-select.module';

import { FilterFormComponent } from './filter-form.component';

@NgModule({
    imports: [
        CommonModule, FormsModule,
        NzInputModule, NzToolTipModule, NzSelectModule, NzDatePickerModule, NzCheckboxModule,
        TagsSelectComponent, AutoFocusModule, AvatarModule, ProjectSelectModule, UserOrGroupSelectModule
    ],
    declarations: [FilterFormComponent],
    exports: [FilterFormComponent]
})
export class FilterFormModule { }
