import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { StoreService } from '@services';

import { finalize, Observable, skip } from 'rxjs';

import { AccessTokenState } from '@models/api';

import { BaseLogging } from './base-logging';
import { HttpApiError } from '@models/api/http-api-error';
import { Person } from '@models/users/person';
import { SM } from '@models/base/helper-types';
import { PrefsState } from '@models/states/prefs-state';
import { Org } from '@models/users/org';

@Component({ template: '' })
export class BaseComponent extends BaseLogging implements OnInit {
    @HostBinding('class.fit') _hostFit: boolean = false;
    @HostBinding('class.flex') _hostFlex: boolean = false;
    @HostBinding('class.wrap') _hostWrap: boolean = false;
    @HostBinding('class.relative') _hostRelative: boolean = false;
    @HostBinding('class.layout') _hostLayout: boolean = true;
    @HostBinding('class.vertical') _hostVertical: boolean = false;
    @HostBinding('class.scroll-x') _hostScrollX: boolean = false;
    @HostBinding('class.scroll-y') _hostScrollY: boolean = false;

    public error?: HttpApiError | HttpApiError[];
    public lError?: HttpApiError;
    public loading: boolean = true;
    public sending: boolean = false;
    public mobile?: boolean;
    public isGuest?: boolean;
    public userId?: string;
    public user?: Person;
    public activeOrgId?: string;
    public activeOrg?: Org;
    public pref: SM<any> = {};
    public granted: SM<boolean> = {};
    public pGranted: SM<SM<boolean>> = {};
    public isAuthUp?: boolean;
    private __prefs?: SM<{ id: string, v: any }>;
    protected initDone: boolean = false;

    constructor(protected _store?: StoreService) {
        super();
        if (_store) {
            this._S['__base.user'] = _store.state('user').subscribe(state => {
                this.mobile = state.mobile || !this.__WEB;
                this.userId = state.userId;
                this.user = state.user;
                this.isGuest = state.isGuest;
            });
            this.activeOrgId = _store.activeOrgId.getValue();
            this.activeOrg = this.activeOrgId ? _store.getStateDirect('orgs').items[this.activeOrgId] : undefined;
            this._S['__base.granted'] = _store.granted.subscribe(granted => {
                this.granted = granted.global;
                this.pGranted = granted;
            });
            this._S['__activeOrgId'] = _store.activeOrgId.pipe(skip(1)).subscribe(id => {
                this.activeOrgId = id;
                this.activeOrg = this.activeOrgId ? _store.getStateDirect('orgs').items[this.activeOrgId] : undefined;
                this.vzOnActiveOrgChange();
            });
            this._S['__activeOrg'] = _store.state('orgs').pipe(skip(1)).subscribe(st => {
                const org = this.activeOrgId ? st.items[this.activeOrgId] : undefined;
                if (org) {
                    this.activeOrg = org;
                }
            });
            this._S['__authState'] = _store.authTokenState.subscribe(ats => {
                if (ats == AccessTokenState.Good) {
                    if (this.isAuthUp === false) {
                        this.isAuthUp = true;
                        this.vzOnAuthUp();
                    }
                    this.isAuthUp = true;
                }
                else if (ats == AccessTokenState.ExpiredErrorTemp || ats == AccessTokenState.ExpiredErrorTempNetwork) {
                    if (this.isAuthUp === true) {
                        this.isAuthUp = false;
                        this.vzOnAuthDown();
                    }
                    this.isAuthUp = false;
                }
            });
        }
    }

    ngOnInit(): void {
        this.initDone = true;
    }

    vzOnAuthUp(): void {}
    vzOnAuthDown(): void {}
    vzOnActiveOrgChange(): void {}

    __initPrefs(prefs: SM<keyof PrefsState>, opts?: { cdr?: ChangeDetectorRef, ucb?: (id: string, v: string) => void }): void {
        this.__U(['__cmp_prefs']);
        if (!this._store) {
            return;
        }
        this.pref = {};
        this.__prefs = {};
        const p: any = this._store.getState('prefs');
        Object.keys(prefs).forEach((k: string) => {
            this.__prefs![k] = { id: (prefs as any)[k], v: p[(prefs as any)[k]] };
            Object.defineProperty(this.pref, k, {
                set: x => {
                    if (this.__prefs![k].v != x) {
                        this.__prefs![k].v = x;
                        this._store?.patchState('prefs', { [this.__prefs![k].id]: x });
                        // console.log('__initPrefs.pref.set', `[${this.__prefs![k].id}]:`, x);
                        opts?.cdr?.markForCheck();
                    }
                },
                get: () => this.__prefs![k].v
            });
        });
        this._S['__cmp_prefs'] = this._store.state('prefs').subscribe((st: any) => {
            Object.keys(this.__prefs!).forEach((k: string) => {
                if (st[this.__prefs![k].id] != this.__prefs![k].v) {
                    this.__prefs![k].v = st[this.__prefs![k].id];
                    if (opts?.ucb) {
                        opts.ucb(k, st[this.__prefs![k].id]);
                    }
                    opts?.cdr?.markForCheck();
                }
            });
        });
    }

    __error(err: HttpApiError | HttpApiError[], show = true, cdr?: ChangeDetectorRef): void {
        this.error = undefined;
        this.loading = false;
        this.__detectChanges(cdr);
        if (this._store && this._store.authTokenState.getValue() >= AccessTokenState.ExpiredErrorTemp) {
            return;
        }
        setTimeout(() => {
            if (show) {
                this.error = err;
            }
            this.lError = Array.isArray(err) ? err[err.length - 1] : err;
            this.__detectChanges(cdr);
        });
    }

    __loading<T>(obs: Observable<T>, opts?: { cdr?: ChangeDetectorRef, cb?: () => void }): Observable<T> {
        this.loading = true;
        this.__detectChanges(opts?.cdr);
        return obs.pipe(finalize(() => {
            this.loading = false;
            this.__detectChanges(opts?.cdr);
            if (opts?.cb) {
                opts?.cb();
            }
        }));
    }

    __sending<T>(obs: Observable<T>, opts?: { cdr?: ChangeDetectorRef, cb?: () => void }): Observable<T> {
        this.sending = true;
        this.__detectChanges(opts?.cdr);
        return obs.pipe(finalize(() => {
            this.sending = false;
            this.__detectChanges(opts?.cdr);
            if (opts?.cb) {
                opts?.cb();
            }
        }));
    }

    __finalize<T>(obs: Observable<T>, cb: () => void, opts?: { cdr?: ChangeDetectorRef }): Observable<T> {
        this.__detectChanges(opts?.cdr);
        return obs.pipe(finalize(() => {
            this.__detectChanges(opts?.cdr);
            cb();
        }));
    }

    __detectChanges(cdr?: ChangeDetectorRef): void {
        if (cdr) {
            if (this.initDone) {
                cdr.detectChanges();
            }
            else {
                cdr.markForCheck();
            }
        }
    }

}
