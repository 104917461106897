import { OLD_BaseSystemModel, RecursivePartial } from '@models/base';

export interface IUserOrgStatusTypeTpl {
    orgId: string;
    name: string;
    icon?: string;
    color?: string;
}

export class UserOrgStatusType extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.UserOrgStatusType;

    orgId!: string;
    name!: string;
    icon?: string;
    color?: string;
    deleted?: boolean;

    constructor(json?: RecursivePartial<UserOrgStatusType>) {
        super(json);
        this.parseUserOrgStatusType(json || {});
    }

    parse(json: RecursivePartial<UserOrgStatusType>): UserOrgStatusType {
        super.parse(json);
        this.parseUserOrgStatusType(json);
        return this;
    }

    private parseUserOrgStatusType(json: RecursivePartial<UserOrgStatusType>): void {
        UserOrgStatusType.assignFields<UserOrgStatusType>(this, json, ['orgId', 'name', 'icon', 'color', 'deleted']);
    }
}
