import { Injectable, OnDestroy } from '@angular/core';

import { Capacitor } from "@capacitor/core";
import { Subscription } from 'rxjs';

import { environment } from '@env/environment';
import { platform } from '@env/platform';
import { SM } from '@models/base/helper-types';

@Injectable()
export class BaseLogging implements OnDestroy {
    static _cb?: (severity: 'log' | 'warn' | 'error', tag: string, ...x: string[]) => void;
    protected _S: SM<Subscription> = {};
    private TAG?: string;

    __PLATFORM = Capacitor.getPlatform();
    __WEB = this.__PLATFORM == 'web';
    __IOS = this.__PLATFORM == 'ios';
    __ANDROID = this.__PLATFORM == 'android';
    __CEF = platform == 'cef';
    __ENV = environment;
    __WEB_REAL = this.__WEB && !this.__CEF;
    __PROD = environment.env == 'prod';

    constructor() {
        this.TAG = (this as any).__TAG;
        if (this.TAG) {
            this.__assignLogFunctions(true);
        }
    }

    protected _L = (_fn: string, ..._x: any[]) => {};
    protected _W = (_fn: string, ..._x: any[]) => {};
    protected _E = (_fn: string, ..._x: any[]) => {};
    protected _tS = (_fn: string) => {};
    protected _tL = (_fn: string, ..._x: any[]) => {};
    protected _tE = () => {};
    protected _G = (_fn: string, ..._x: any[]) => {};
    protected _GC = (_fn: string, ..._x: any[]) => {};
    protected _GE = () => {};

    ngOnDestroy(): void {
        this.__unsubscribe();
    }

    protected __unsubscribe(except?: string[]): void {
        this.__U(except ? Object.keys(this._S).filter(id => !except.includes(id)) : Object.keys(this._S));
    }

    protected __U(keys: string[]): void {
        for (const id of keys) {
            if (this._S[id] && !this._S[id].closed) {
                // this._L('unsubscribe:', id);
                this._S[id].unsubscribe();
            }
        }
    }

    private __assignLogFunction(func: any): any {
        return this.__WEB
            ? Function.prototype.bind.apply(func, [console, '[' + this.TAG + ']'])
            : (_fn: string, ..._x: any[]) => {
                let l = '';
                if (_x) {
                    for (const x of _x) {
                        try {
                            l += typeof x == 'string' || typeof x == 'number' ? x : JSON.stringify(x);
                        } catch (_) {}
                    }
                }
                func('[' + this.TAG + ']* ' + _fn + ' ' + l);
            };
    }

    __setLogFunctionsCb(cb: (severity: 'log' | 'warn' | 'error', tag: string, ...x: string[]) => void): void {
        BaseLogging._cb = cb;
        this.__assignLogFunctions(true);
    }

    __setTag(tag: string): void {
        this.TAG = tag;
        this.__assignLogFunctions(!!this.TAG);
    }

    private __assignLogFunctions(enable: boolean): void {
        if (enable) {
            if (BaseLogging._cb) {
                this._L = (fn: string, ...x: any[]) => BaseLogging._cb!('log', this.TAG!, fn, ...x);
                this._W = (fn: string, ...x: any[]) => BaseLogging._cb!('warn', this.TAG!, fn, ...x);
                this._E = (fn: string, ...x: any[]) => BaseLogging._cb!('error', this.TAG!, fn, ...x);
            }
            else {
                this._L = this.__assignLogFunction(console.log);
                this._W = this.__assignLogFunction(console.warn);
                this._E = this.__assignLogFunction(console.error);
            }
            // tslint:disable-next-line: no-console
            this._tS = console.time;
            if (console.timeLog) {
                this._tL = (label: string, ...x) => console.timeLog(label, ...x);
            }
            else {
                this._tL = () => {};
            }
            // tslint:disable-next-line: no-console
            this._tE = console.timeEnd;
            this._G = this.__assignLogFunction(console.group);
            this._GC = this.__assignLogFunction(console.groupCollapsed);
            this._GE = console.groupEnd;
        }
        else {
            this._L = () => {};
            this._W = () => {};
            this._E = () => {};
            this._tS = () => {};
            this._tL = () => {};
            this._tE = () => {};
            this._G = () => {};
            this._GC = () => {};
            this._GE = () => {};
        }
    }

}
