import { BaseModel, RecursivePartial } from '@models/base';

export class IdName extends BaseModel {
    id?: string;
    name: string = '';

    constructor(json?: RecursivePartial<IdName>) {
        super(json);
        this.parseIdName(json || {});
    }

    parse(json: RecursivePartial<IdName>): IdName {
        super.parse(json);
        this.parseIdName(json);
        return this;
    }

    private parseIdName(json: RecursivePartial<IdName>): void {
        IdName.assignFields<IdName>(this, json, ['id', 'name']);
        if (!this.name && ((json as any).firstName || (json as any).lastName)) {
            this.name = (json as any).firstName
                ? (json as any).firstName + ((json as any).lastName ? ' ' + (json as any).lastName : '')
                : ((json as any).lastName ? (json as any).lastName : '???????');
        }
    }
}
