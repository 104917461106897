import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionWatchers extends BaseTaskAction {
    type: TaskActionType.Watchers = TaskActionType.Watchers;
    watchers?: string[];
    watchersOld?: string[];

    constructor(json?: RecursivePartial<TaskActionWatchers>) {
        super(json);
        this.parseTaskActionWatchers(json || {});
    }

    parse(json: RecursivePartial<TaskActionWatchers>): TaskActionWatchers {
        super.parse(json);
        this.parseTaskActionWatchers(json);
        return this;
    }

    private parseTaskActionWatchers(json: RecursivePartial<TaskActionWatchers>): void {
        TaskActionWatchers.assignFields<TaskActionWatchers>(this, json, ['watchers', 'watchersOld']);
    }
}
