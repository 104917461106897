import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionNoteDelete extends BaseTaskAction {
    type: TaskActionType.NoteDelete = TaskActionType.NoteDelete;
    noteId?: string;
    noteCreated?: number;
    noteCreatedBy?: string;
    textOld?: string;

    constructor(json?: RecursivePartial<TaskActionNoteDelete>) {
        super(json);
        this.parseTaskActionNoteDelete(json || {});
    }

    parse(json: RecursivePartial<TaskActionNoteDelete>): TaskActionNoteDelete {
        super.parse(json);
        this.parseTaskActionNoteDelete(json);
        return this;
    }

    private parseTaskActionNoteDelete(json: RecursivePartial<TaskActionNoteDelete>): void {
        TaskActionNoteDelete.assignFields<TaskActionNoteDelete>(this, json, ['noteId', 'textOld', 'noteCreated', 'noteCreatedBy']);
    }
}
