import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionNoteEdit extends BaseTaskAction {
    type: TaskActionType.NoteEdit = TaskActionType.NoteEdit;
    noteId!: string;
    text?: string;
    textOld?: string;

    constructor(json?: RecursivePartial<TaskActionNoteEdit>) {
        super(json);
        this.parseTaskActionNoteEdit(json || {});
    }

    parse(json: RecursivePartial<TaskActionNoteEdit>): TaskActionNoteEdit {
        super.parse(json);
        this.parseTaskActionNoteEdit(json);
        return this;
    }

    private parseTaskActionNoteEdit(json: RecursivePartial<TaskActionNoteEdit>): void {
        TaskActionNoteEdit.assignFields<TaskActionNoteEdit>(this, json, ['noteId', 'text', 'textOld']);
    }
}
