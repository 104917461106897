import { TimeWindow } from '@models';
import { OLD_BaseSystemModel, RecursivePartial } from '@models/base';

export interface IDepartmentTpl {
    orgId: string;
    name: string;
    desc?: string;
    tz: string;
    workingHours: [
        TimeWindow[], TimeWindow[], TimeWindow[], TimeWindow[], TimeWindow[], TimeWindow[], TimeWindow[]
    ];
}

export class Department extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.Department;

    name!: string;
    orgId!: string;
    tz!: string;
    desc?: string;
    workingHours: [
        TimeWindow[], TimeWindow[], TimeWindow[], TimeWindow[], TimeWindow[], TimeWindow[], TimeWindow[]
    ] = [[], [], [], [], [], [], []];

    constructor(json?: RecursivePartial<Department>) {
        super(json);
        this.parseDepartment(json || {});
    }

    parse(json: RecursivePartial<Department>): Department {
        super.parse(json);
        this.parseDepartment(json);
        return this;
    }

    private parseDepartment(json: RecursivePartial<Department>): void {
        Department.assignFields<Department>(this, json, ['name', 'orgId', 'tz', 'desc']);
        if (Array.isArray(json['workingHours'])) {
            let dw = 0;
            for (const tws of json['workingHours']) {
                const d: TimeWindow[] = [];
                if (Array.isArray(tws)) {
                    for (const tw of tws) {
                        d.push(new TimeWindow(tw));
                    }
                }
                this.workingHours[dw] = d;
                dw++;
                if (dw > 6) {
                    break;
                }
            }
        }
    }
}
