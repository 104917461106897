<div class="fit layout">
    <div class="layout vertical flex center-center">
        <div class="delete-account layout vertical">
            <i class="vzi-warning self-center txt-err" style="font-size: 120px;"></i>
            <div class="delete-account-warning layout justified relative">
                ОСТОРОЖНО!<br />
                {{ text }}<br />
                Для подтверждения повторите вход в систему.
            </div>
            <div class="layout vertical">
                <input type="text" nz-input [(ngModel)]="login" placeholder="Email" required />
                <input type="password" nz-input [(ngModel)]="password" placeholder="Пароль" required />
                @if (user?.is2faEnabled) {
                    <input type="text" nz-input [(ngModel)]="pinCode" placeholder="Пинкод или код восстановления" required />
                }
                <button
                    nz-button
                    nzType="primary"
                    nzDanger
                    [disabled]="!password || !login || (user?.is2faEnabled && !pinCode)"
                    (click)="submit()"
                >
                    Подтверждаю
                </button>
            </div>
            <!-- <button
                style="margin-top: 0"
                nz-button
                nzDanger
                (click)="getGoogleToken()"
            >
                <img src="./assets/google.svg" style="width: 20px; margin-right: 12px;">
                Подтвердить через Google
            </button> -->
        </div>
    </div>
</div>

@if (error) {
    <vz-error-message [error]="error" (dismiss)="error = undefined" />
}
