import { BaseFilter, FILTER_DATA_KEY } from './base-filter';
import { Org } from '@models/users';
import { Chat } from '@models/chats';
import { SM } from '@models/base';
import {
    ChatFilterFieldDefault, ChatFilterFieldCreated, ChatFilterFieldNotFinished,
    ChatFilterFieldActiveMember
} from './chat';
import { getDateRanges } from '@models/utils/datetime';

export class ChatFilter extends BaseFilter<Chat> {

    DEFAULT_FILTER = 'member -finished';
    visibleTemplates = 8;

    constructor(
        private getOrgs: () => SM<Org> | undefined,
        private getUserId: () => string | undefined
    ) {
        super({
            id: 'Chats',
            name: 'Фильтр чатов',
            fields: [
                new ChatFilterFieldDefault() as any,
                new ChatFilterFieldCreated(),
                new ChatFilterFieldNotFinished(),
                new ChatFilterFieldActiveMember(),
            ]
        });
    }

    match(o: Chat, values?: SM<string | string[]>): boolean {
        if (!this.fields || this.fields.length == 0 || !values) {
            return true;
        }
        const ev: SM<boolean> = {};
        for (const k of Object.keys(values)) {
            if (k == FILTER_DATA_KEY) {
                continue;
            }
            const v = values[k];
            if (!k || !this.fieldsMap[k]) {
                return false;
            }
            ev[k] = true;
            if (!this.fieldsMap[k].match(o, v, { orgs: this.getOrgs(), userId: this.getUserId(), ranges: getDateRanges() })) {
                return false;
            }
        }
        for (const f of this.fields) {
            if (f?.id && !ev[f.id]) {
                if (!this.fieldsMap[f.id].match(o, undefined, { orgs: this.getOrgs(), userId: this.getUserId(), ranges: getDateRanges() })) {
                    return false;
                }
            }
        }
        return true;
    }

}
