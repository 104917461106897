import { BaseModel, RecursivePartial } from '@models/base';

export class Enable2faResponse extends BaseModel {
    algorithm!: string;
    secret!: string;
    url!: string;
    codes!: string[];
    digits!: number;
    period!: number;
    issuer!: string;

    constructor(json?: RecursivePartial<Enable2faResponse>) {
        super(json);
        this.parseEnable2faResponse(json || {});
    }

    parse(json: RecursivePartial<Enable2faResponse>): Enable2faResponse {
        super.parse(json);
        this.parseEnable2faResponse(json);
        return this;
    }

    private parseEnable2faResponse(json: RecursivePartial<Enable2faResponse>): void {
        Enable2faResponse.assignFields<Enable2faResponse>(this, json, [
            'algorithm',
            'secret',
            'url',
            'codes',
            'digits',
            'period',
            'issuer'
        ]);
    }
}
