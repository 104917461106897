import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { Org } from '@models/users';

export class OrgsState extends BaseItemsState<Org> {

    constructor(json?: RecursivePartial<OrgsState>) {
        super(json);
        this.parseOrgsState(json || {});
    }

    parse(json: RecursivePartial<OrgsState>): OrgsState {
        super.parse(json);
        this.parseOrgsState(json);
        return this;
    }

    private parseOrgsState(json: RecursivePartial<OrgsState>): void {
        OrgsState.assignClassMaps<OrgsState>(this, json, { items: Org });
    }

}
