import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { removeH, removeW } from '@base/base-animations';
import { BaseComponent } from '@base/base.component';
import {
    BaseFilter, FilterFieldType, Group, Org, Person, Project,
    SM, StateCacheMapsType, Tag, sortStringsBy, stateOrgFilterFn
} from '@models';
import { StoreService } from '@services';
import { FilterController } from './filter-controller';

@Component({
    selector: 'vz-filter-form, [vz-filter-form]',
    templateUrl: './filter-form.component.html',
    styleUrls: ['./filter-form.component.less'],
    host: { 'class': 'layout flex center' },
    animations: [removeH, removeW]
})
@Tag('FilterFormComponent')
export class FilterFormComponent extends BaseComponent implements OnInit {
    @Input() controller?: FilterController;
    @Output() enterPress: EventEmitter<void> = new EventEmitter();

    filter?: BaseFilter<any>;
    values?: SM<any>;
    projectId?: string;

    maps: StateCacheMapsType = {};
    persons: Person[] = [];
    groups: Group[] = [];
    projects: Project[] = [];
    orgs: Org[] = [];
    isGroup: SM<boolean> = {};
    ddOpen: SM<boolean> = { users: false };

    FFT = FilterFieldType;
    compareById = (a: any, b: any) => a?.id == b?.id;

    constructor(
        protected _store: StoreService,
    ) {
        super(_store);
        this._store.subscribeToStates(this._S, {
            persons: st => this.persons = Object.values(st.items).filter(p => p.isUser),
            groups: st => this.groups = Object.values(st.items),
            projects: st => this.projects = Object.values(st.items),
            orgs: st => this.orgs = Object.values(st.items),
        }, this.maps, stateOrgFilterFn(() => this.activeOrgId!));
    }

    ngOnInit(): void {
        if (!this.controller) {
            return;
        }
        this._S.values = this.controller.values.subscribe(v => {
            this.filter = this.controller?.getFilter();
            this.values = v;
        });
        let first = true;
        this._S.projectId = this.controller.projectId.subscribe(pid => {
            this.projectId = pid;
            const project = pid ? this.maps.projects?.[pid] : undefined;
            this.persons = Object.values(this._store.getState('persons').items).filter(p => p.isUser
                && p._hasOrg(this.activeOrgId)
                && p._isActive(this.activeOrgId)
                && (!project || project.personIds.some(id => p.id == id) || project.groupIds.some(id => p._hasGroup(this.activeOrgId, id)))
            );
            this.persons.sort(sortStringsBy('name'));
            this.groups = Object.values(this._store.getState('groups').items).filter(g => g.orgId == this.activeOrgId && (!project || project.groupIds.some(gid => gid == g.id)));
            this.groups.sort(sortStringsBy('name')).forEach(g => this.isGroup[g.id!] = true);
            if (!first) {
                this.controller?.setText('');
                first = false;
            }
        });
    }

    updateValues(): void {
        // this._L('updateValues', this.values);
        this.controller?.setValues(this.values!);
        // this._L('updateValues',
        //     '\n\t\tdef:', this.controller.defText.getValue(),
        //     '\n\t\ttext:', this.controller.text.getValue(),
        //     '\n\t\tvalues:', this.controller.values.getValue(),
        // );
    }

}
