import { BaseSystemModel, ObjectType, RecursivePartial } from '@models/base';
import { Reaction } from '@models/shared/reaction';
import { ChatCall } from './chat-call';
import { ChatEvent } from './chat-event';
import { ChatMessageThread } from './chat-message-thread';
import { CodeSnippet } from '@models/shared/code-snippet';

export enum ChatMessageOrigin {
    System = 1,
    Guest = 2,
    User = 3,
    Plugin = 4,
}

export class ChatMessage extends BaseSystemModel {
    _type: ObjectType.ChatMessage = ObjectType.ChatMessage;
    origin!: ChatMessageOrigin;
    chatId?: string;
    parentId?: string;
    replyId?: string;
    forwardId?: string;
    thread?: ChatMessageThread;
    deleted?: boolean;
    threadDepth: number = 0;
    text?: string;
    richText?: { [key: string]: any };
    call?: ChatCall;
    event?: ChatEvent;
    fileIds?: string[];
    snippets?: CodeSnippet[];
    reactions?: Reaction[];
    numId: number = 0;

    _isHuman!: boolean;

    constructor(json?: RecursivePartial<ChatMessage>) {
        super(json);
        this.parseChatMessage(json || {});
    }

    parse(json: RecursivePartial<ChatMessage>): ChatMessage {
        super.parse(json);
        this.parseChatMessage(json);
        return this;
    }

    private parseChatMessage(json: RecursivePartial<ChatMessage>): void {
        ChatMessage.assignFields<ChatMessage>(this, json, [
            'origin',
            'chatId',
            'parentId',
            'replyId',
            'forwardId',
            'deleted',
            'threadDepth',
            'text',
            'richText',
            'fileIds',
            'numId',
            '_isHuman'
        ]);
        ChatMessage.assignClassFields<ChatMessage>(this, json, { thread: ChatMessageThread, call: ChatCall, event: ChatEvent });
        ChatMessage.assignClassArrays<ChatMessage>(this, json, { snippets: CodeSnippet, reactions: Reaction });
        this._isHuman = this.origin == ChatMessageOrigin.User || this.origin == ChatMessageOrigin.Guest;
        if (this.call) {
            this.call.chatId = this.chatId;
        }
    }

}
