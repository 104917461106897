<div class="layout flex vertical p1">
    <div *ngIf="file" class="layout vertical center-center mb1">
        <img style="max-width: 420px; max-height: 420px" [src]="filePreviewUrl">
        <div *ngIf="!error" class="layout center mv05">
            <span class="txt-p mr05 pe-none">{{ file.name }}</span>
            <span class="txt-l tar pe-none">| {{ file.size | ksize }}</span>
        </div>
        <button *ngIf="!error && !uploaded && !loading"
            class="mt05"
            nz-button
            class="vz-ok-btn ml1"
            nzType="primary"
            nzSize="large"
            (click)="uploadFile($event)">
            <i class="vzi-out"></i>
            Загрузить
        </button>
        <nz-progress *ngIf="loading || uploaded"
            style="width: 98%"
            [nzPercent]="progress"
            [nzStatus]="error ? 'exception' : uploaded ? 'success' : 'active'">
        </nz-progress>
    </div>
    <div class="file-upload-drop-box layout vertical center-center mv1"
        [style.height.px]="file ? 150 : 400"
        (click)="upload.value = ''; upload.click()"
        [class.fileDrop-empty]="!file"
        (fileDrop)="onFileSelected($event)">
        <span class="layout vertical center-center pe-none">
            <span>Перетащите картинку сюда</span>
            <span>или кликните чтобы выбрать.</span>
        </span>
    </div>
    <vz-error-panel *ngIf="error" style="max-width: 420px;" [error]="$any(error)"></vz-error-panel>
</div>
<vz-loading [show]="loading"></vz-loading>
<input type="file"
    fileSelect
    (fileSelected)="onFileSelected($event)"
    [hidden]="true"
    accept="image/*"
    #upload>
