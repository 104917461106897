import { BaseModel, RecursivePartial } from '@models/base';
import { VzParamConstraints } from '@models/params';

export class SiteConfigFormField extends BaseModel {
    id?: string;
    show?: boolean;
    title?: string;
    placeholder?: string;
    constraints?: VzParamConstraints;

    constructor(json?: RecursivePartial<SiteConfigFormField>) {
        super(json);
        this.parseSiteConfigFormField(json || {});
    }

    parse(json: RecursivePartial<SiteConfigFormField>): SiteConfigFormField {
        super.parse(json);
        this.parseSiteConfigFormField(json);
        return this;
    }

    private parseSiteConfigFormField(json: RecursivePartial<SiteConfigFormField>): void {
        SiteConfigFormField.assignFields<SiteConfigFormField>(this, json, ['id', 'show', 'title', 'placeholder']);
        SiteConfigFormField.assignClassFields<SiteConfigFormField>(this, json, { constraints: VzParamConstraints });
    }
}
