import { RecursivePartial } from '@models/base';
import { LayoutElementUnknown } from './_unknown';
import { BoxLayoutElement } from './box';
import { IconElement } from './icon';
import { LayoutElementType } from './layout-element-type';
import { TaskFieldLayoutElement } from './task-field';

export type LayoutElementClassType = {
    [LayoutElementType.Box]: BoxLayoutElement,
    [LayoutElementType.Icon]: IconElement,
    [LayoutElementType.TaskField]: TaskFieldLayoutElement,
}

export const LayoutElementClass = {
    [LayoutElementType.Box]: BoxLayoutElement,
    [LayoutElementType.Icon]: IconElement,
    [LayoutElementType.TaskField]: TaskFieldLayoutElement,
}

export type LayoutElement = BoxLayoutElement | TaskFieldLayoutElement | IconElement;

export function createLayoutElement<T extends LayoutElementType>(le: RecursivePartial<LayoutElement>): LayoutElementClassType[T] {
    if (le?.type && LayoutElementClass[le.type]) {
        return new LayoutElementClass[le.type](le as any) as LayoutElementClassType[T];
    }
    else {
        console.log('[createLayoutElement] unknown element type:', le);
        return new LayoutElementUnknown(le as any) as any;
    }
}
