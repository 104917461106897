import { Observable, fromEvent, map } from "rxjs";

import { Chat, ChatType } from "@models/chats";
import { getInitials } from "./strings";
import { string2Color, strings2Color } from "./colors";
import { Person } from "@models/users";
import { SM } from "@models/base";

export function getChatTextAvatar(chat: Chat, currentUserId: string, personsMap: SM<Person>): string | null {
    const canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 300;
    const ctx = canvas.getContext('2d')!;
    if (chat.type == ChatType.Public) {
        ctx.fillStyle = '#0060AA';
        ctx.fillRect(0, 0, 300, 300);
        ctx.fillStyle = '#ffffff';
        ctx.font = 'bold 230px arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('#', 150, 150);
    }
    else if (chat.type == ChatType.Private) {
        ctx.fillStyle = '#0060AA';
        ctx.fillRect(0, 0, 300, 300);
        ctx.fillStyle = '#ffffff';
        ctx.font = 'bold 200px vizorro';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(String.fromCharCode(0xe92a), 150, 150);
    }
    else if (chat.type == ChatType.Direct) {
        let initials = '?';
        let bg = '#cccccc';
        let user: Person | undefined;
        let font = 'bold 130px arial';
        if(chat.thread.commentators.length == 1 && chat.thread.commentators[0]?.id == currentUserId) {
            initials = String.fromCharCode(0xe938);
            bg = '#007ddd';
            font = 'bold 230px vizorro';
        }
        else if (chat.thread.commentators.length) {
            const ids = chat.thread.commentators.filter(cm => cm.id != currentUserId).map(cm => cm.id!);
            if (ids.length == 1) {
                user = personsMap[ids[0]];
                initials = getInitials(user.name) || '?';
                bg = string2Color(user.id);
            }
            else if (ids.length == 2) {
                initials = personsMap[ids[0]].name.substring(0, 1) + personsMap[ids[1]].name.substring(0, 1);
                bg = strings2Color(ids);
            }
            else {
                initials = ids.length > 99 ? '**' : '' + ids.length;
                bg = strings2Color(ids);
            }
        }
        ctx.fillStyle = bg;
        ctx.font = font;
        ctx.fillRect(0, 0, 300, 300);
        ctx.fillStyle = '#ffffff';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(initials, 150, 150);
    }
    const b64 = ctx.canvas.toDataURL();
    return b64 && b64.length > 25 ? b64.substring(22) : null;
}

export function getChatAvatar(chat: Chat, currentUserId: string, personsMap: SM<Person>): Observable<string | null> {
    return new Observable(obs => {
        if (chat.type == ChatType.Direct) {
            let selfOnly = false;
            let initials = '?';
            let bg = '#cccccc';
            let user: Person | undefined;
            if(chat.thread.commentators.length == 1 && chat.thread.commentators[0]?.id == currentUserId) {
                selfOnly = true;
            }
            else if (chat.thread.commentators.length) {
                const ids = chat.thread.commentators.filter(cm => cm.id != currentUserId).map(cm => cm.id!);
                if (ids.length == 1) {
                    user = personsMap[ids[0]];
                    initials = getInitials(user.name) || '?';
                    bg = string2Color(user.id);
                }
                else if (ids.length == 2) {
                    initials = personsMap[ids[0]].name.substring(0, 1) + personsMap[ids[1]].name.substring(0, 1);
                    bg = strings2Color(ids);
                }
                else {
                    initials = ids.length > 99 ? '**' : '' + ids.length;
                    bg = strings2Color(ids);
                }
            }
            if (user?.avatar) {
                const canvas = document.createElement('canvas');
                canvas.width = 300;
                canvas.height = 300;
                const ctx = canvas.getContext('2d')!;
                    ctx.fillStyle = bg;
                ctx.fillRect(0, 0, 300, 300);
                ctx.fillStyle = '#ffffff';
                ctx.font = 'bold 130px vizorro';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(initials, 150, 150);
                const img: HTMLImageElement = new Image();
                img.src = user.avatar;
                fromEvent(img, 'load').pipe(
                    map((event: any) => event.target),
                    map((image: HTMLImageElement) => {
                        ctx.drawImage(image, 0, 0, 300, image.height * (300 / image.width));
                        const b64 = ctx.canvas.toDataURL();
                        obs.next(b64 && b64.length > 25 ? b64.substring(22) : null);
                        obs.complete();
                    })
                ).subscribe({ error: () => {
                    obs.next(getChatTextAvatar(chat, currentUserId, personsMap));
                    obs.complete();
                }});
                return;
            }
            else {
                obs.next(getChatTextAvatar(chat, currentUserId, personsMap));
                obs.complete();
            }
        }
        else {
            obs.next(getChatTextAvatar(chat, currentUserId, personsMap));
            obs.complete();
        }
    });
}

export function getChatTitle(chat:  Chat, currentUserId: string, personsMap: SM<Person>): string {
    let title = chat.title;
    if (chat.type == ChatType.Direct) {
        const ids = chat.thread.commentators.filter(cm => cm.id != currentUserId).map(cm => cm.id!);
        title = ids.map(id => personsMap[id].name || '#' + id).join(', ');
    }
    return title;
}