import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionArchive extends BaseTaskAction {
    type: TaskActionType.Archive = TaskActionType.Archive;

    constructor(json?: RecursivePartial<TaskActionArchive>) {
        super(json);
        this.parseTaskActionArchive(json || {});
    }

    parse(json: RecursivePartial<TaskActionArchive>): TaskActionArchive {
        super.parse(json);
        this.parseTaskActionArchive(json);
        return this;
    }

    private parseTaskActionArchive(json: RecursivePartial<TaskActionArchive>): void {
    }
}
