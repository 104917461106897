import { Component, Input, NgModule, OnChanges, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseComponent } from '@base/base.component';
import { Tag } from '@models';
import { InputBoolean } from '@models/utils/coercions';
import { DtPipeModule } from '@pipes';

@Component({
    selector: 'vz-dt, [vz-dt]',
    template: `
        <div *ngIf="dt" [title]="dt | dt">{{ display }}</div>
    `
})
@Tag('VzDtComponent')
export class VzDtComponent extends BaseComponent implements OnChanges, OnDestroy {
    @Input() dt?: number | Date;
    // @Input() @InputBoolean() relative: boolean | string = true;
    @Input() @InputBoolean() live: boolean | string = true;

    display?: string;
    timerSubscription?: any;

    ngOnChanges(): void {
        if (!this.dt) {
            return;
        }
        let timer = 0;
        const delta = new Date().getTime() / 1000 - (typeof this.dt == 'object' ? this.dt.getTime() / 1000 : this.dt);
        if (delta < 60) {
            this.display = 'только что';
            timer = 61 - delta;
        }
        else if (delta < 3600) {
            const v = Math.trunc(delta / 60);
            this.display = (v > 1 ? v + ' ' : '') + 'минут' + this.rus(v, ['', 'у', 'ы', 'ы', 'ы', '']) + ' назад';
            timer = 60;
        }
        else if (delta < 86400) {
            const v = Math.trunc(delta / 3600);
            this.display = (v > 1 ? v + ' ' : '') + 'час' + this.rus(v, ['ов', '', 'а', 'а', 'а', 'ов']) + ' назад';
            timer = 3600;
        }
        else if (delta < 86400 * 30) {
            const v = Math.trunc(delta / 86400);
            this.display = (v > 1 ? v + ' ' : '') + '' + this.rus(v, ['дней', 'день', 'дня', 'дня', 'дня', 'дней']) + ' назад';
        }
        else if (delta < 86400 * 365) {
            const v = Math.trunc(delta / 2592000);
            this.display = (v > 1 ? v + ' ' : '') + 'месяц' + this.rus(v, ['ев', '', 'а', 'а', 'а', 'ев']) + ' назад';
        }
        else {
            this.display = 'более года назад';
        }

        if (this.timerSubscription !== undefined) {
            clearTimeout(this.timerSubscription);
            this.timerSubscription = undefined;
        }
        if (this.live && timer > 0) {
            this.timerSubscription = setTimeout(() => this.ngOnChanges(), timer * 1000);
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.timerSubscription !== undefined) {
            clearTimeout(this.timerSubscription);
            this.timerSubscription = undefined;
        }
    }

    rus(value: any, suffixes: [string, string, string, string, string, string]): string {
        value = value ? +value : 0;
        let od = value < 11 || value > 19 ? value % 10 : 5;
        if (od > 5) {
            od = 5
        }
        return suffixes[od];
    }
}

@NgModule({
    imports: [
        CommonModule, DtPipeModule,
    ],
    declarations: [VzDtComponent],
    exports: [VzDtComponent]
})
export class VzDtModule { }
