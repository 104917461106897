import { BaseModel, RecursivePartial } from '@models/base';

export enum EventAttendeeState {
    Unknown = 0,
    Accepted,
    Rejected,
    Maybe
}

export class EventAttendee extends BaseModel {
    id!: string;
    state: EventAttendeeState = 0;

    constructor(json?: RecursivePartial<EventAttendee>) {
        super(json);
        this.parseEventAttendee(json || {});
    }

    parse(json: RecursivePartial<EventAttendee>): EventAttendee {
        super.parse(json);
        this.parseEventAttendee(json);
        return this;
    }

    private parseEventAttendee(json: RecursivePartial<EventAttendee>): void {
        EventAttendee.assignFields<EventAttendee>(this, json, ['id', 'state']);
    }

}
