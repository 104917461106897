import { getUuid } from '@models/utils/uuid';
import { BaseModel, RecursivePartial } from '@models/base';

export class FilterTemplate extends BaseModel {

    id!: string;
    name?: string;
    filterId!: string;
    filter: string = '';
    created!: Date;
    lastUsed?: Date;
    usedCount: number = 0;

    constructor(json?: RecursivePartial<FilterTemplate>) {
        super(json);
        this.parseFilterTemplate(json || {});
    }

    parse(json: RecursivePartial<FilterTemplate>): FilterTemplate {
        super.parse(json);
        this.parseFilterTemplate(json);
        return this;
    }

    private parseFilterTemplate(json: RecursivePartial<FilterTemplate>): void {
        FilterTemplate.assignFields<FilterTemplate>(this, json, [
            'id',
            'name',
            'filterId',
            'filter',
            'usedCount'
        ]);
        FilterTemplate.assignDates<FilterTemplate>(this, json, ['created', 'lastUsed']);
        if (!this.id) {
            this.id = getUuid();
        }
        if (!this.created) {
            this.created = new Date();
        }
    }

}
