import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionSetSprint extends BaseTaskAction {
    type: TaskActionType.SetSprint = TaskActionType.SetSprint;
    sprintId?: string | null;

    constructor(json?: RecursivePartial<TaskActionSetSprint>) {
        super(json);
        this.parseTaskActionSetSprint(json || {});
    }

    parse(json: RecursivePartial<TaskActionSetSprint>): TaskActionSetSprint {
        super.parse(json);
        this.parseTaskActionSetSprint(json);
        return this;
    }

    private parseTaskActionSetSprint(json: RecursivePartial<TaskActionSetSprint>): void {
        TaskActionSetSprint.assignFields<TaskActionSetSprint>(this, json, ['sprintId']);
    }
}
