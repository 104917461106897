import { BaseModel, RecursivePartial } from '@models/base';

export enum UserNotificationTransport {
    Email = 'email',
    Internal = 'internal',
    Sms = 'sms',
    Push = 'push',
    Alarm = 'alarm',
    Telegram = 'telegram'
}

export const UserNotificationTransportList = [
    UserNotificationTransport.Email,
    UserNotificationTransport.Internal,
    // UserNotificationTransport.Sms,
    UserNotificationTransport.Push,
    // UserNotificationTransport.Alarm,
    // UserNotificationTransport.Telegram,
];

export const UserNotificationTransportName = {
    [UserNotificationTransport.Email]: 'Почта',
    [UserNotificationTransport.Internal]: 'Встроенное',
    [UserNotificationTransport.Sms]: 'СМС',
    [UserNotificationTransport.Push]: 'Push',
    [UserNotificationTransport.Alarm]: 'Звонок',
    [UserNotificationTransport.Telegram]: 'Telegram',
};

export const UserNotificationTransportIcon = {
    [UserNotificationTransport.Email]: 'vzi-mail',
    [UserNotificationTransport.Internal]: 'vzi-bell',
    [UserNotificationTransport.Sms]: 'vzi-note',
    [UserNotificationTransport.Push]: 'vzi-google',
    [UserNotificationTransport.Alarm]: 'vzi-phone_in_talk',
    [UserNotificationTransport.Telegram]: 'vzi-telegram',
};

export class UserNotificationSettingsPart extends BaseModel {
    category!: string;
    priority = 'info';
    transports?: UserNotificationTransport[];

    constructor(json?: RecursivePartial<UserNotificationSettingsPart>) {
        super(json);
        this.parseUserNotificationSettingsPart(json || {});
    }

    parse(json: RecursivePartial<UserNotificationSettingsPart>): UserNotificationSettingsPart {
        super.parse(json);
        this.parseUserNotificationSettingsPart(json);
        return this;
    }

    private parseUserNotificationSettingsPart(json: RecursivePartial<UserNotificationSettingsPart>): void {
        UserNotificationSettingsPart.assignFields<UserNotificationSettingsPart>(this, json, ['category', 'priority', 'transports']);
    }

}
