import { TaskLinkType } from '@models/tasks/task-link';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { RecursivePartial } from '@models/base';

export class TaskActionLinkAdd extends BaseTaskAction {
    type: TaskActionType.LinkAdd = TaskActionType.LinkAdd;
    taskFromId!: string;
    taskToId!: string;
    linkType!: TaskLinkType;

    constructor(json?: RecursivePartial<TaskActionLinkAdd>) {
        super(json);
        this.parseTaskActionLinkAdd(json || {});
    }

    parse(json: RecursivePartial<TaskActionLinkAdd>): TaskActionLinkAdd {
        super.parse(json);
        this.parseTaskActionLinkAdd(json);
        return this;
    }

    private parseTaskActionLinkAdd(json: RecursivePartial<TaskActionLinkAdd>): void {
        TaskActionLinkAdd.assignFields<TaskActionLinkAdd>(this, json, ['taskFromId', 'taskToId', 'linkType']);
    }
}
