import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionDueDate extends BaseTaskAction {
    type: TaskActionType.DueDate = TaskActionType.DueDate;
    dueDate?: Date;
    dueDateOld?: Date;

    constructor(json?: RecursivePartial<TaskActionDueDate>) {
        super(json);
        this.parseTaskActionDueDate(json || {});
    }

    parse(json: RecursivePartial<TaskActionDueDate>): TaskActionDueDate {
        super.parse(json);
        this.parseTaskActionDueDate(json);
        return this;
    }

    private parseTaskActionDueDate(json: RecursivePartial<TaskActionDueDate>): void {
        TaskActionDueDate.assignDates<TaskActionDueDate>(this, json, ['dueDate', 'dueDateOld']);
    }
}
