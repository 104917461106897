import { registerPlugin } from '@capacitor/core';

import type { VizorroPlugin } from './definitions';

const Vizorro = registerPlugin<VizorroPlugin>('Vizorro', {
  web: () => import('./web').then(m => new m.VizorroWeb()),
});

export * from './definitions';
export { Vizorro };
