export const TAG_COLORS = [
    '#004377',
    '#EF5350', '#F44336', '#E53935', '#D32F2F', '#C62828', '#B71C1C', '#FF5252', '#FF1744', '#D50000',
    '#F06292', '#EC407A', '#E91E63', '#D81B60', '#C2185B', '#AD1457', '#880E4F', '#FF4081', '#F50057', '#C51162',
    '#BA68C8', '#AB47BC', '#9C27B0', '#8E24AA', '#7B1FA2', '#6A1B9A', '#4A148C', '#E040FB', '#D500F9', '#AA00FF',
    '#9575CD', '#7E57C2', '#673AB7', '#5E35B1', '#512DA8', '#4527A0', '#311B92', '#7C4DFF', '#651FFF', '#6200EA',
    '#7986CB', '#5C6BC0', '#3F51B5', '#3949AB', '#303F9F', '#283593', '#1A237E', '#536DFE', '#3D5AFE', '#304FFE',
    '#2196F3', '#1E88E5', '#1976D2', '#1565C0', '#0D47A1', '#448AFF', '#2979FF', '#2962FF',
    '#039BE5', '#0288D1', '#0277BD', '#01579B', '#0091EA',
    '#0097A7', '#00838F', '#006064',
    '#009688', '#00897B', '#00796B', '#00695C', '#004D40',
    '#43A047', '#388E3C', '#2E7D32', '#1B5E20',
    '#689F38', '#558B2F', '#33691E',
    '#827717', '#c29500', '#e0a800',
    '#EF6C00', '#E65100',
    '#FF5722', '#F4511E', '#E64A19', '#D84315', '#BF360C', '#FF3D00', '#DD2C00',
    '#A1887F', '#8D6E63', '#795548', '#6D4C41', '#5D4037', '#4E342E', '#3E2723',
    '#757575', '#616161', '#424242', '#212121',
    '#78909C', '#607D8B', '#546E7A', '#455A64', '#37474F', '#263238',
    '#FF0000', '#00aF00', '#0000FF', '#AFAF00', '#FF00FF', '#00AFAF', '#000000',
];

export const CHART_COLORS = [
    '#4a8ee0', '#e63232', '#e6932e', '#e6dc2c', '#8ecf40', '#55e08f', '#53d9e0', '#704ae0', '#c443e0', '#e04385',
    '#22599c', '#9c1717', '#9c5c17', '#999c19', '#359c19', '#1c9c6f', '#1f8d9c', '#36249c', '#89219c', '#9c1e6f',
    '#4080cf', '#d32b2b', '#d38528', '#d2cc27', '#79c236', '#46cf88', '#46c5cf', '#6140cf', '#b53acf', '#cf3980',
    '#2d66ad', '#ae1e1d', '#ae691d', '#abac1e', '#4ea923', '#29ad78', '#2c9fad', '#452dad', '#9729ad', '#ad2775',
    '#3773bd', '#c02524', '#c17722', '#bebc22', '#64b52c', '#37be80', '#39b2be', '#5337be', '#a632bd', '#be307b',
];

export function rgb2hsl(r: number, g: number, b: number): [number, number, number] {
    r /= 255, g /= 255, b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h: number = 0;
    let s: number;
    const l = (max + min) / 2;

    if (max == min) {
        h = s = 0; // achromatic
    }
    else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return [h, s, l];
}

function hue2rgb(p: number, q: number, t: number): number {
    if (t < 0) { t += 1; }
    if (t > 1) { t -= 1; }
    if (t < 1 / 6) { return p + (q - p) * 6 * t; }
    if (t < 1 / 2) { return q; }
    if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6; }
    return p;
}

export function hsl2rgb(h: number, s: number, l: number): [number, number, number] {
    let r: number;
    let g: number;
    let b: number;
    if (s == 0) {
        r = g = b = l; // achromatic
    }
    else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }
    return [Math.trunc(r * 255), Math.trunc(g * 255), Math.trunc(b * 255)];
}

export function toDark(color: string): string {
    const hsl = rgb2hsl(
        parseInt(color.substring(1, 3), 16),
        parseInt(color.substring(3, 5), 16),
        parseInt(color.substring(5, 7), 16)
    );
    const rgb = hsl2rgb(hsl[0], hsl[1], hsl[2] > 0.4 ? 0.4 : hsl[2]);
    return '#'
        + addZero(rgb[0].toString(16))
        + addZero(rgb[1].toString(16))
        + addZero(rgb[2].toString(16));
}

function addZero(s: string): string {
    if (!s) {
        return '00';
    }
    return s.length < 2 ? '0' + s : s;
}

export function colorOverBg(color: string, bgcolor = '#ffffff', opacity = .05): string {
    const bg: number[] = [
        parseInt(bgcolor.substring(1, 3), 16),
        parseInt(bgcolor.substring(3, 5), 16),
        parseInt(bgcolor.substring(5, 7), 16)
    ];
    const fg: number[] = [
        parseInt(color.substring(1, 3), 16),
        parseInt(color.substring(3, 5), 16),
        parseInt(color.substring(5, 7), 16)
    ];
    return '#'
        + addZero(Math.trunc(((1 - opacity) * bg[0] + opacity * fg[0])).toString(16))
        + addZero(Math.trunc(((1 - opacity) * bg[1] + opacity * fg[1])).toString(16))
        + addZero(Math.trunc(((1 - opacity) * bg[2] + opacity * fg[2])).toString(16));
}

export function tint(color: string, opacity = .1): string {
    return colorOverBg('#ffffff', color, opacity);
}

export function shade(color: string, opacity = .1): string {
    return colorOverBg('#000000', color, opacity);
}

export function color2rgba(color: string, opacity: number): string {
    return 'rgba('
        + parseInt(color.substring(1, 3), 16) + ','
        + parseInt(color.substring(3, 5), 16) + ','
        + parseInt(color.substring(5, 7), 16) + ','
        + opacity + ')';
}

export function color4Gl(color: string, opacity: number): number[] {
    return [
        parseInt(color.substring(1, 3), 16) / 255,
        parseInt(color.substring(3, 5), 16) / 255,
        parseInt(color.substring(5, 7), 16) / 255,
        opacity
    ];
}

export function string2Color(str?: string): string {
    if (str == null) {
        return '#000';
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        // tslint:disable-next-line: no-bitwise
        let value = (hash >> (i * 8)) & 0xFF;
        if (value > 196) {
            value -= 128;
        }
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

export function strings2Color(strings?: string[]): string {
    if (strings == null) {
        return '#000';
    }
    return string2Color(strings.sort().join('|'));
}
