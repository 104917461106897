import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { UserNotification } from '@models/events';

export class UserNotificationsState extends BaseItemsState<UserNotification> {

    constructor(json?: RecursivePartial<UserNotificationsState>) {
        super(json);
        this.parseUserNotificationsState(json || {});
    }

    parse(json: RecursivePartial<UserNotificationsState>): UserNotificationsState {
        super.parse(json);
        this.parseUserNotificationsState(json);
        return this;
    }

    private parseUserNotificationsState(json: RecursivePartial<UserNotificationsState>): void {
        UserNotificationsState.assignClassMaps<UserNotificationsState>(this, json, { items: UserNotification });
    }

}
