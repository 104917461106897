import { BaseModel, RecursivePartial } from '@models/base';

export class Reaction extends BaseModel {
    reaction?: string;
    userIds: string[] = [];

    constructor(json?: RecursivePartial<Reaction>) {
        super(json);
        this.parseReaction(json || {});
    }

    parse(json: RecursivePartial<Reaction>): Reaction {
        super.parse(json);
        this.parseReaction(json);
        return this;
    }

    private parseReaction(json: RecursivePartial<Reaction>): void {
        Reaction.assignFields<Reaction>(this, json, ['reaction', 'userIds']);
    }
}
