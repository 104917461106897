import { OLD_BaseSystemModel, RecursivePartial } from '@models/base';

export class OrgInvite extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.Invite;

    orgId?: string;
    email?: string;

    constructor(json?: RecursivePartial<OrgInvite>) {
        super(json);
        this.parseUserInvite(json || {});
    }

    parse(json: RecursivePartial<OrgInvite>): OrgInvite {
        super.parse(json);
        this.parseUserInvite(json);
        return this;
    }

    private parseUserInvite(json: RecursivePartial<OrgInvite>): void {
        OrgInvite.assignFields<OrgInvite>(this, json, ['orgId', 'email']);
    }

}
