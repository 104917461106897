import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClipboardService } from 'ngx-clipboard';

import { removeH } from '@base/base-animations';
import { HttpApiError } from '@models/api/http-api-error';
import { InputBoolean } from '@models/utils/coercions';
import { JsonSafePipe } from '@pipes';
import { StoreService } from '@services';

@Component({
    selector: 'vz-error-panel',
    templateUrl: 'error-panel.component.html',
    styleUrls: ['./error-panel.component.less'],
    animations: [removeH]
})

export class ErrorPanelComponent implements OnInit, OnChanges {
    @Input() error: HttpApiError | undefined;
    @Input() @InputBoolean() border: boolean | string = true;
    @Input() etype?: string;

    showMore: boolean = false;
    copied: boolean = false;
    isLoggedIn: boolean = false;

    constructor(
        private _store: StoreService,
        private _cb: ClipboardService,
        private _route: ActivatedRoute,
        private _router: Router,
    ) {
        this._store.state('user').subscribe(st => this.isLoggedIn = st.isLoggedIn());
    }

    ngOnInit(): void {
        this.update();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.update();
    }

    update(): void {
        if (!this.etype) {
            if (this.error?.type) {
                this.etype = this.error.type;
            }
            else if (
                this.error && (
                    this.error.status == 200
                    || this.error.status == 403
                    || this.error.status == 404
                    || this.error.status == 409
                )
            ) {
                this.etype = 'info';
            }
        }
    }

    copyError(): void {
        if (!this.error) {
            return;
        }
        this.copied = true;
        this._cb.copyFromContent(this.getErrorReportText());
        setTimeout(() => this.copied = false, 5000);
    }

    showDebug(): void {
        this._store.apiError = this.getErrorReportDoc();
        this._store.apiErrorTitle = 'ОШИБКА: ' + this.error?.title;
        this._router.navigate([{ outlets: { g: ['newticket'] } }], { relativeTo: this._route.root.firstChild?.firstChild });
    }

    getErrorReportText(): string {
        const js = new JsonSafePipe();
        return this.error
            ? '**ОШИБКА**: ' + this.error.title + ' '.repeat(30) + '\n'
                + this.error.text + '\n---\n'
                + '**' + this.error.method + '** ' + this.error.url + '\n\n'
                + '**Request**: ' + (this.error.body ? '\n```json\n' + js.transform(this.error.body, 4) + '\n```' : 'Empty') + '\n\n'
                + '**Response**: ' + (this.error.error ? '\n```json\n' + js.transform(this.error.error, 4) + '\n```' : 'Empty') + '\n\n'
            : '';
    }

    getErrorReportDoc(): any {
        const js = new JsonSafePipe();
        return this.error
            ? {
                type: 'doc',
                content: [
                    {
                        type: 'heading',
                        attrs: { level: 3 },
                        content: [{ type: 'text', text: 'ОШИБКА: ' + this.error.title }]
                    },
                    {
                        type: 'paragraph',
                        content: [
                            {
                                type: 'text',
                                marks: [{ type: 'bold' }],
                                text: this.error.method + ' '
                            },
                            {
                                type: 'text',
                                marks: [{ type: 'link', attrs: { href: this.error.url } }],
                                text: this.error.url
                            },
                        ]
                    },
                    {
                        type: 'paragraph',
                        content: [{ type: 'text', marks: [{ type: 'bold' }], text: 'Request:' }]
                    },
                    {
                        type: 'codeBlock',
                        attrs: { language: 'json' },
                        content: [{ type: 'text', text: this.error.body ? js.transform(this.error.body, 4) : '<Empty>' }]
                    },
                    {
                        type: 'paragraph',
                        content: [{ type: 'text', marks: [{ type: 'bold' }], text: 'Response:' }]
                    },
                    {
                        type: 'codeBlock',
                        attrs: { language: 'json' },
                        content: [{ type: 'text', text: this.error.error ? js.transform(this.error.error, 4) : '<Empty>' }]
                    }
                ]
            }
            : undefined;
    }

}
