import { BaseModel, RecursivePartial } from '@models/base';

export class UrlPreview extends BaseModel {
    title?: string;
    type?: string;
    image?: string;
    url?: string;

    constructor(json?: RecursivePartial<UrlPreview>) {
        super(json);
        this.parseUrlPreview(json || {});
    }

    parse(json: RecursivePartial<UrlPreview>): UrlPreview {
        super.parse(json);
        this.parseUrlPreview(json);
        return this;
    }

    private parseUrlPreview(json: RecursivePartial<UrlPreview>): void {
        UrlPreview.assignFields<UrlPreview>(this, json, ['title', 'type', 'image', 'url']);
    }
}
