import { HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import { IVzFileTpl, SM, Tag, Upload, UploadStatus, VzFile, getUuid } from '@models';
import { HttpApiError } from '@models/api/http-api-error';
import { ApiService } from './api.service';

@Injectable()
@Tag('UploadService')
export class UploadService extends BaseLogging {

    readonly uploads: BehaviorSubject<Upload[]> = new BehaviorSubject<Upload[]>([]);
    readonly created: Subject<Upload> = new Subject();
    readonly started: Subject<Upload> = new Subject();
    readonly progress: Subject<Upload> = new Subject();
    readonly finished: Subject<Upload> = new Subject();
    readonly removed: Subject<Upload> = new Subject();
    readonly previewReady: Subject<string> = new Subject();

    private _subs: SM<Subscription> = {};
    private _uploads: SM<Upload> = {};

    constructor(private _api: ApiService) {
        super();
    }

    addUpload(fileTpl: IVzFileTpl, file: File, sourceId: string, sourceTitle: string, sourceLink?: string): Upload {
        const id = getUuid();
        const up = new Upload({ id, file, info: new VzFile(fileTpl), bytesTotal: file.size, sourceId, sourceTitle, sourceLink });
        this._uploads[up.id!] = up;
        this.created.next(up);
        this.uploads.next(Object.values(this._uploads));
        this._api.createFile(fileTpl).subscribe({
            next: fileId => {
                up.info!.id = fileId;
                this._startUpload(up);
            },
            error: err => {
                up.status = UploadStatus.Error;
                up.error = err;
                up.finished = new Date().getTime();
                this.finished.next(up);
                this._updateUpload(up);
            }
        });
        return up;
    }

    removeUploadByFileId(fileId: string): void {
        const upload = Object.values(this._uploads).find(up => up.info?.id == fileId);
        if (upload?.id) {
            this.removeUpload(upload.id);
        }
    }


    removeUpload(id: string): void {
        const up = this._uploads[id];
        if (up) {
            if (this._subs[id]) {
                this._subs[id].unsubscribe();
            }
            delete this._subs[id];
            delete this._uploads[id];
            this.removed.next(up);
            this.uploads.next(Object.values(this._uploads));
        }
    }

    cancelUpload(id: string, reason: HttpApiError): void {
        const up = this._uploads[id];
        if (up) {
            if (this._subs[id]) {
                this._subs[id].unsubscribe();
            }
            delete this._subs[id];
            up.status = UploadStatus.Error;
            up.error = reason;
            up.finished = new Date().getTime();
            this.finished.next(up);
            this._updateUpload(up);
        }
    }

    private _startUpload(up: Upload): void {
        up.started = new Date().getTime();
        up.status = UploadStatus.UploadStarted;
        this.started.next(up);
        this._updateUpload(up);
        this._subs[up.id!] = this._api.uploadFile(up.info!.id!, up.file!, {
            requestId: rid => this._uploadStarted(rid, up),
            upload: e => this._uploadProgress(up, e)
        }).subscribe({
            next: () => this._uploadProgress(up, undefined, true),
            error: err => {
                up.status = UploadStatus.Error;
                up.error = err;
                up.finished = new Date().getTime();
                this.finished.next(up);
                this._updateUpload(up);
            },
        });
    }

    private _uploadStarted(reqId: string, up: Upload): void {
        up.started = new Date().getTime();
        up.requestId = reqId;
        up.status = UploadStatus.UploadStarted;
        this._updateUpload(up);
    }

    private _uploadProgress(up: Upload, e?: HttpProgressEvent, finished = false): void {
        const upBytes = finished ? up.bytesTotal : (e ? (e.loaded > up.bytesTotal ? up.bytesTotal : e.loaded) : 0);
        up.finished = finished ? new Date().getTime() : undefined;
        up.status = finished ? UploadStatus.Uploaded : UploadStatus.UploadStarted;
        up.bytesUploaded = upBytes;
        up.speed = this._getSpeed(up);
        if (finished) {
            this.finished.next(up);
        }
        this._updateUpload(up);
    }

    private _getSpeed(up: Upload): number {
        if (!up || !up.started || (up.status != UploadStatus.UploadStarted && up.status != UploadStatus.Uploaded)) {
            return 0;
        }
        const time = (up.status == UploadStatus.Uploaded && up.finished ? up.finished : new Date().getTime()) - up.started;
        return up.bytesUploaded / time * 1000;
    }

    private _updateUpload(upload: Upload): void {
        this.progress.next(upload);
        this.uploads.next(Object.values(this._uploads));
    }

}
