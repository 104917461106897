import { trigger, state, style, transition, animate, query, stagger, AnimationTriggerMetadata } from '@angular/animations';

export const listH = trigger('listH', [
    transition('* => *', [
        query(':leave', [
            style({ height: '*', opacity: 1 }),
            stagger(30, [
                style({ height: 0, opacity: 0, margin: 0, paddingTop: 0, paddingBottom: 0, borderWidth: 0 }),
                animate('150ms ease-in', style({ height: 0, opacity: 0 }))
            ])
        ], { optional: true }),
        query(':enter', [
            style({ height: 0, opacity: 0 }),
            stagger(20, [
                style({ height: '*', opacity: '*', margin: '*', paddingTop: '*', paddingBottom: '*', borderWidth: '*' }),
                animate('150ms ease-out', style({ height: '*', opacity: 1 }))
            ])
        ], { optional: true })
    ])
]);

export const removeOpacity = _removeOpacity('300ms');
function _removeOpacity(duration: string, name = 'removeOpacity'): AnimationTriggerMetadata {
    return trigger(name, [
        state('in', style({ opacity: 1 })),
        transition('void => *', [
            style({ opacity: 0 }),
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`)
        ]),
        transition('* => void', [
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`, style({ opacity: 0 }))
        ])
    ]);
}

export const removeH = _removeH('150ms');
function _removeH(duration: string, name = 'removeH'): AnimationTriggerMetadata {
    return trigger(name, [
        state('in', style({ height: '*', opacity: 1 })),
        transition('void => *', [
            style({ opacity: 0, height: 0 }),
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`)
        ]),
        transition('* => void', [
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`, style({ opacity: 0, height: 0 }))
        ])
    ]);
}

export const removeW = _removeW('150ms');
function _removeW(duration: string, name = 'removeW'): AnimationTriggerMetadata {
    return trigger(name, [
        state('in', style({ width: '*', opacity: 1 })),
        transition('void => *', [
            style({ opacity: 0, width: 0, 'min-width': 0 }),
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`)
        ]),
        transition('* => void', [
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`, style({ opacity: 0, width: 0, 'min-width': 0 }))
        ])
    ]);
}

export const removeRX = _removeRX('300ms');
export function _removeRX(duration: string, distance = '100%', name = 'removeRX'): AnimationTriggerMetadata {
    return trigger(name, [
        state('in', style({ transform: 'translateX(0)' })),
        transition('void => *', [
            style({ transform: `translateX(${distance})` }),
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`)
        ]),
        transition('* => void', [
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`, style({ transform: `translateX(${distance})` }))
        ])
    ]);
}

export const removeLX = _removeLX('300ms');
export function _removeLX(duration: string, distance = '100%', name = 'removeLX'): AnimationTriggerMetadata {
    return trigger(name, [
        state('in', style({ transform: 'translateX(0)' })),
        transition('void => *', [
            style({ transform: `translateX(-${distance})` }),
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`)
        ]),
        transition('* => void', [
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`, style({ transform: `translateX(-${distance})` }))
        ])
    ]);
}

export const removeTX = _removeTX('300ms');
export function _removeTX(duration: string, distance = '100%', name = 'removeTX'): AnimationTriggerMetadata {
    return trigger(name, [
        state('in', style({ transform: 'translateY(0)' })),
        transition('void => *', [
            style({ transform: `translateY(-${distance})` }),
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`)
        ]),
        transition('* => void', [
            animate(`${duration} cubic-bezier(0.19, 1, 0.22, 1)`, style({ transform: `translateY(-${distance})` }))
        ])
    ]);
}
