import { OLD_BaseSystemModel, RecursivePartial } from '@models/base';

export class VizorroPlugin extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.Plugin;

    name!: string;
    orgId?: string;
    categories: string[] = [];
    privileges: string[] = [];
    grantPrivileges: string[] = [];

    constructor(json?: RecursivePartial<VizorroPlugin>) {
        super(json);
        this.parseVizorroPlugin(json || {});
    }

    parse(json: RecursivePartial<VizorroPlugin>): VizorroPlugin {
        super.parse(json);
        this.parseVizorroPlugin(json);
        return this;
    }

    private parseVizorroPlugin(json: RecursivePartial<VizorroPlugin>): void {
        VizorroPlugin.assignFields<VizorroPlugin>(this, json, ['name', 'orgId', 'categories', 'privileges', 'grantPrivileges']);
    }
}
