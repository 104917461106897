import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { Project } from '@models/users/project';

export class ProjectsState extends BaseItemsState<Project> {

    constructor(json?: RecursivePartial<ProjectsState>) {
        super(json);
        this.parseProjectsState(json || {});
    }

    parse(json: RecursivePartial<ProjectsState>): ProjectsState {
        super.parse(json);
        this.parseProjectsState(json);
        return this;
    }

    private parseProjectsState(json: RecursivePartial<ProjectsState>): void {
        ProjectsState.assignClassMaps<ProjectsState>(this, json, { items: Project });
    }

}
