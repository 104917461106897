import { BaseModel, NM, RecursivePartial } from "@models/base";

export enum ChatsCountersType {
    Unknown     = 0,
    Direct      = 1,
    Group       = 2,
    Plugin      = 3
}

export class ChatsCounters extends BaseModel {

    chatsTotal: number = 0;
    unreadChatsTotal: number = 0;
    unreadThreadsTotal: number = 0;

    unreadChats: NM<ChatsCountersType> = {};
    unreadMessages: NM<ChatsCountersType> = {};
    unreadThreads: NM<ChatsCountersType> = {};

    constructor(json?: RecursivePartial<ChatsCounters>) {
        super(json);
        this.parseUnreadCounters(json || {});
    }

    parse(json: RecursivePartial<ChatsCounters>): ChatsCounters {
        super.parse(json);
        this.parseUnreadCounters(json);
        return this;
    }

    private parseUnreadCounters(json: RecursivePartial<ChatsCounters>): void {
        ChatsCounters.assignFields<ChatsCounters>(this, json, [
            'chatsTotal',
            'unreadChatsTotal',
            'unreadChats',
            'unreadMessages',
            'unreadThreads',
        ]);
    }
}
