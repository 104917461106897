import { BaseSystemModel, RecursivePartial } from '@models/base';

export enum OrgBalanceChangeType {
    Income = 0,
    MonthlyCharge = 1,
    SlotsIncreaseCharge = 2,
    ManualIncrease = 3
}

export const OrgBalanceChangeTypeName = {
    [OrgBalanceChangeType.Income]: 'Приход',
    [OrgBalanceChangeType.MonthlyCharge]: 'Ежемесячное списание',
    [OrgBalanceChangeType.SlotsIncreaseCharge]: 'Увелечение числа рабочих мест',
    [OrgBalanceChangeType.ManualIncrease]: 'Бонус',
};

export class OrgBalanceChange extends BaseSystemModel {
    orgId?: string;
    sum: number = 0;
    type?: OrgBalanceChangeType;
    invoiceId?: string;
    note?: string;
    adminNote?: string;

    constructor(json?: RecursivePartial<OrgBalanceChange>) {
        super(json);
        this.parseOrgBalanceChange(json || {});
    }

    parse(json: RecursivePartial<OrgBalanceChange>): OrgBalanceChange {
        super.parse(json);
        this.parseOrgBalanceChange(json);
        return this;
    }

    private parseOrgBalanceChange(json: RecursivePartial<OrgBalanceChange>): void {
        OrgBalanceChange.assignFields<OrgBalanceChange>(this, json, [
            'orgId',
            'sum',
            'type',
            'invoiceId',
            'note',
            'adminNote'
        ]);
    }
}
