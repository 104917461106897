import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionSubj extends BaseTaskAction {
    type: TaskActionType.Subj = TaskActionType.Subj;
    subj?: string;
    subjOld?: string;

    constructor(json?: RecursivePartial<TaskActionSubj>) {
        super(json);
        this.parseTaskActionSubj(json || {});
    }

    parse(json: RecursivePartial<TaskActionSubj>): TaskActionSubj {
        super.parse(json);
        this.parseTaskActionSubj(json);
        return this;
    }

    private parseTaskActionSubj(json: RecursivePartial<TaskActionSubj>): void {
        TaskActionSubj.assignFields<TaskActionSubj>(this, json, ['subj', 'subjOld']);
    }
}
