import { BaseFilterField, FilterFieldType } from '@models/filters/base-filter-field';
import { Person } from '@models/users';
import { isDateFilterMatch } from '@models/filters/utils';
import { DateRanges } from '@models/shared';

export class PersonFilterFieldCreated extends BaseFilterField<Person, number, true> {
    id: string = 'created';
    name: string = 'Дата добавления';
    desc: string = 'Фильтрует по дате добавления. Можно использовать префикс ">", "<" или "=" и дату в формате ISO 8601 (ГОД-МЕСЯЦ-ДЕНЬ), при этом время не учитывается';
    type: FilterFieldType.Date = FilterFieldType.Date;
    single = true as const;

    value(o: Person): number {
        return o.created!;
    }

    match(o: Person, v: string, data?: { ranges: DateRanges }): boolean {
        return isDateFilterMatch(o.created ? new Date(o.created * 1000) : undefined, v);
    }

}
