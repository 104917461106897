import { RecursivePartial } from '../base';
import { BaseState } from './base-state';

export class VideoState extends BaseState {

    _VER = 1;

    skipped?: boolean;
    checked?: boolean;
    audioDeviceId?: string;
    videoDeviceId?: string;
    outputDeviceId?: string;

    constructor(json?: RecursivePartial<VideoState>) {
        super(json);
        this.parseVideoState(json || {});
    }

    parse(json: RecursivePartial<VideoState>): VideoState {
        super.parse(json);
        this.parseVideoState(json);
        return this;
    }

    private parseVideoState(json: RecursivePartial<VideoState>): void {
        VideoState.assignFields<VideoState>(this, json, [
            'skipped',
            'checked',
            'audioDeviceId',
            'videoDeviceId',
            'outputDeviceId',
        ]);
    }

}
