import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { VzFile } from '@models/files';

export class TaskActionFileDetach extends BaseTaskAction {
    type: TaskActionType.FileDetach = TaskActionType.FileDetach;
    fileIds!: string[];
    files: VzFile[] = [];

    constructor(json?: RecursivePartial<TaskActionFileDetach>) {
        super(json);
        this.parseTaskActionDetachFile(json || {});
    }

    parse(json: RecursivePartial<TaskActionFileDetach>): TaskActionFileDetach {
        super.parse(json);
        this.parseTaskActionDetachFile(json);
        return this;
    }

    private parseTaskActionDetachFile(json: RecursivePartial<TaskActionFileDetach>): void {
        TaskActionFileDetach.assignFields<TaskActionFileDetach>(this, json, ['fileIds']);
        TaskActionFileDetach.assignClassArrays<TaskActionFileDetach>(this, json, { files: VzFile });
        if (!json.fileIds) {
            this.fileIds = (json as any).fileId ? [(json as any).fileId] : [];
        }
    }
}
