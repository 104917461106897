/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import {
    Contact, Department, Group, IAddRemove, IContactTpl, IDepartmentTpl, IGroupTpl, IOrgTpl, IPersonTpl,
    IProjectTpl, IRoleTpl, IdNameAvatar, Org, Person, Project, Role, SM, SigninRequest, SigninResponse, SignupRequest,
    Tag, TechSupportUsersResponse, OrgInvite, VizorroPlugin,
    Enable2faResponse,
    Guid,
} from '@models';
import { RequestCallbacks } from '@models/api/request-callbacks';
import { ApiMethod, Delete, Get, Post, Put, TVzResponse, VzServices, emitArray, emitField, refreshAuthToken, sendRequest } from '@models/utils/http-helpers';
import { StoreService } from './store.service';

@Injectable({ providedIn: 'root' })
@Tag('AuthService')
export class AuthService extends BaseLogging {

    private _baseUrl: string | undefined = this.__ENV.urls.auth;
    private _lastRequest: number = new Date().getTime();
    cfg: () => VzServices;
    RD: SM<ApiMethod<any, any>>;
    private __req?: ApiMethod<any, any> | null;

    // readonly refreshTokenTime: number[] = [];
    refreshingAuthToken?: Observable<string>;

    constructor(
        private _http: HttpClient,
        private _store: StoreService
    ) {
        super();
        this.RD = (this as any).__RD;
        if (this.__ENV.env != 'prod') {
            this._store.state('url').subscribe(state => {
                this._baseUrl = state.authBaseUrl;
                this._L('constructor', 'baseUrl:', this._baseUrl);
            });
        }
        this.cfg = () => ({
            http: this._http,
            baseUrl: this._baseUrl!,
            L: this._L,
            W: this._W,
            authTokenState: this._store.authTokenState,
            getLastActivity: () => new Date(this._store.getLastActivity()),
            getRefreshToken: () => this._store.getState('user', st => st.refreshToken),
            getRefreshingAuthToken: () => this.refreshingAuthToken,
            getUserId: () => this._store.getState('user', st => st.userId),
            gotNewToken: token => this._store.patchState('user', { authToken: token }),
            renewAuthToken: (userId, refreshToken, lastActivity) => this.renewAuthToken(userId, refreshToken, lastActivity),
            setRefreshingAuthToken: rat => this.refreshingAuthToken = rat,
            updateLastRequest: () => this.updateLastRequest(),
            logRequest: !this.__WEB && !this.__ENV.production
        });
    }

    sendRequest<T extends TVzResponse>(id: string, ...args: any): Observable<T> {
        return sendRequest(this.RD[id], this.cfg(), ...args);
    }

    getBaseUrl(_reqId: string): string {
        return this.cfg().baseUrl;
    }

    getLastRequestTime(): number {
        return this._lastRequest;
    }

    updateLastRequest(): void {
        this._lastRequest = new Date().getTime();
    }

    // Health Check
    @Get('healthCheck', () => `/health`, {
        desc: 'Gets the state of the AuthVizorro service',
        res: {
            errorTitle: 'Health check error',
        }
    }) // @ts-ignore
    healthCheck(): Observable<void> {}



    // * ######################################################################################################### Auth
    startAuthTokenRefresh(): Observable<string> {
        const st = this._store.getState('user');
        return refreshAuthToken(st.userId!, st.refreshToken!, this.cfg());
    }

    @Post('signin', '/signin', {
        desc: 'Войти в систему и получить авторизационные токены',
        body: (data: SigninRequest) => data,
        hideFields: ['password'],
        res: {
            errorTitle: 'Ошибка входа в систему',
            clazz: SigninResponse
        }
    }) // @ts-ignore
    signin(data: SigninRequest): Observable<SigninResponse> {}

    @Put('renewAuthToken', '/signin', {
        desc: 'Получить новый AccessToken',
        body: (userId: string, refreshToken: string, lastActivity: Date, debugReturnCode?: number) => ({ userId, refreshToken, lastActivity: lastActivity.toISOString(), returnCode: debugReturnCode }),
        hideFields: ['refreshToken'],
        res: {
            errorTitle: 'Ошибка обновления токена авторизации',
            parser: emitField('token')
        }
    }) // @ts-ignore
    renewAuthToken(userId: string, refreshToken: string, lastActivity: Date): Observable<string> {}

    @Delete('signout', () => `/signin`, {
        desc: 'Выйти из системы, сделать текущие Refresh/Access токены невалидными',
        res: {
            errorTitle: 'Ошибка выхода из системы',
        }
    }) // @ts-ignore
    singout(): Observable<void> {}

    @Post('signup', '/signup', {
        desc: 'Зарегистрироваться в системе и получить авторизационные токены',
        body: (data: SignupRequest) => data,
        hideFields: ['password'],
        res: {
            errorTitle: 'Ошибка входа в систему',
            parser: data => of(data && typeof data == 'object' ? new SigninResponse(data) : undefined)
        }
    }) // @ts-ignore
    signup(data: SignupRequest): Observable<SigninResponse | void> {}

    @Post('confirmSignup', '/signup/confirm', {
        desc: 'Подтвердить регистрацию в системе и получить авторизационные токены',
        body: (code: string) => ({ code }),
        res: {
            errorTitle: 'Не удалось Подтвердить регистрацию в системе',
            clazz: SigninResponse
        }
    }) // @ts-ignore
    confirmSignup(code: string): Observable<SigninResponse> {}

    @Post('deleteAccount', '/signup/delete', {
        desc: 'Удалить учетную запись в системе',
        body: (data: SigninRequest) => data,
        hideFields: ['password'],
        res: {
            errorTitle: 'Не удалось удалить учетную запись в системе',
        }
    }) // @ts-ignore
    deleteAccount(data: SigninRequest): Observable<void> {}

    @Post('resetPassword', '/password/reset', {
        desc: 'Послать запрос на сброс пароля',
        body: (email: string) => ({ email }),
        res: {
            errorTitle: 'Не удалось послать запрос на сброс пароля',
        }
    }) // @ts-ignore
    resetPassword(email: string): Observable<void> {}

    @Put('resetPasswordConfirm', '/password/reset', {
        desc: 'Подтвердить сброс пароля и установить новый',
        body: (code: string, password: string, pinCode?: string) => ({ code, password, pinCode }),
        hideFields: ['password'],
        res: {
            errorTitle: 'Не удалось подтвердить сброс пароля',
        }
    }) // @ts-ignore
    resetPasswordConfirm(code: string, password: string, pinCode?: string): Observable<void> {}

    @Put('changePassword', '/password/change', {
        desc: 'Поменять пароль',
        body: (old: string, newpass: string, pinCode?: string) => ({ old, new: newpass, pinCode }),
        hideFields: ['old', 'new'],
        res: {
            errorTitle: 'Не удалось изменить пароль',
        }
    }) // @ts-ignore
    changePassword(old: string, newpass: string, pinCode?: string): Observable<void> {}



    // * ######################################################################################################### Persons
    @Get('getPersons', '/persons', {
        desc: 'Получить список пользователей',
        res: {
            errorTitle: 'Не удалось получить список пользователей',
            parser: emitArray(Person)
        }
    }) // @ts-ignore
    getPersons(): Observable<Person[] | undefined> {}

    @Post('createPerson', '/persons', {
        desc: 'Создать нового пользователя',
        body: (person: IPersonTpl) => person,
        res: {
            errorTitle: 'Не удалось создать пользователя',
            clazz: Person
        }
    }) // @ts-ignore
    createPerson(person: IPersonTpl): Observable<Person> {}

    @Get('getPerson', (id: string) => `/persons/${id}`, {
        desc: 'Получить данные пользователя',
        res: {
            errorTitle: 'Не удалось получить данные пользователя',
            clazz: Person
        }
    }) // @ts-ignore
    getPerson(id: string): Observable<Person> {}

    @Put('updatePerson', (id: string, person: IPersonTpl) => `/persons/${id}`, {
        desc: 'Изменить данные пользователя',
        body: (id: string, person: IPersonTpl) => person,
        res: {
            errorTitle: 'Не удалось изменить данные пользователя',
            clazz: Person
        }
    }) // @ts-ignore
    updatePerson(id: string, person: IPersonTpl): Observable<Person> {}

    @Delete('deletePerson', (id: string) => `/persons/${id}`, {
        desc: 'Удалить пользователя',
        res: {
            errorTitle: 'Не удалось удалить пользователя',
            clazz: Person
        }
    }) // @ts-ignore
    deletePerson(id: string): Observable<void> {}

    @Post('uploadPersonAvatar', (id: string, file: File, callbacks?: RequestCallbacks) => `/persons/${id}/avatar`, {
        desc: 'Загрузить на сервер аватар пользователя',
        body: (id: string, file: File, callbacks?: RequestCallbacks) => {
            const body = new FormData();
            body.append('avatar', file, id);
            return body;
        },
        callbacks: (id: string, file: File, callbacks?: RequestCallbacks) => callbacks,
        res: {
            errorTitle: 'Не удалось загрузить на сервер аватар пользователя',
        },
        bypassSW: true
    }) // @ts-ignore
    uploadPersonAvatar(id: string, file: File, callbacks?: RequestCallbacks): Observable<void> {}

    @Post('setPersonPrivileges', (id: string, orgId: string, projectId: string, privileges: string[]) => `/persons/${id}/privileges`, {
        desc: 'Установить привилегии пользователя',
        body: (id: string, orgId: string, projectId: string, privileges: string[]) => ({ orgId, projectId, privileges }),
        res: {
            errorTitle: 'Не удалось установить привилегии пользователя',
        }
    }) // @ts-ignore
    setPersonPrivileges(id: string, orgId: string, projectId: string, privileges: string[]): Observable<void> {}



    // * ######################################################################################################### Contacts
    @Post('createPersonContact', '/contacts', {
        desc: 'Добавить способ связи',
        body: (contact: IContactTpl) => contact,
        res: {
            errorTitle: 'Не удалось добавить способ связи',
            clazz: Contact
        }
    }) // @ts-ignore
    createPersonContact(contact: IContactTpl): Observable<Contact> {}

    @Put('updatePersonContact', (id: string, contact: IContactTpl) => `/contacts/${id}`, {
        desc: 'Изменить способ связи',
        body: (id: string, contact: IContactTpl) => contact,
        res: {
            errorTitle: 'Не удалось изменить способ связи',
            clazz: Contact
        }
    }) // @ts-ignore
    updatePersonContact(id: string, contact: IContactTpl): Observable<Contact> {}

    @Delete('deletePersonContact', (id: string) => `/contacts/${id}`, {
        desc: 'Удалить способ связи',
        res: {
            errorTitle: 'Не удалось удалить способ связи',
        }
    }) // @ts-ignore
    deletePersonContact(id: string): Observable<void> {}



    // * ######################################################################################################### Groups
    @Get('getGroups', '/groups', {
        desc: 'Получить список групп',
        res: {
            errorTitle: 'Не удалось получить список групп',
            parser: emitArray(Group)
        }
    }) // @ts-ignore
    getGroups(): Observable<Group[] | undefined> {}

    @Post('createGroup', '/groups', {
        desc: 'Создать новую группу',
        body: (group: IGroupTpl) => group,
        res: {
            errorTitle: 'Не удалось создать группу',
            clazz: Group
        }
    }) // @ts-ignore
    createGroup(group: IGroupTpl): Observable<Group> {}

    @Get('getGroup', (id: string) => `/groups/${id}`, {
        desc: 'Получить данные группы',
        res: {
            errorTitle: 'Не удалось получить данные группы',
            clazz: Group
        }
    }) // @ts-ignore
    getGroup(id: string): Observable<Group> {}

    @Put('updateGroup', (id: string, group: IGroupTpl) => `/groups/${id}`, {
        desc: 'Изменить данные группы',
        body: (id: string, group: IGroupTpl) => group,
        res: {
            errorTitle: 'Не удалось изменить данные группы',
            clazz: Group
        }
    }) // @ts-ignore
    updateGroup(id: string, group: IGroupTpl): Observable<Group> {}

    @Delete('deleteGroup', (id: string) => `/groups/${id}`, {
        desc: 'Удалить группу',
        res: {
            errorTitle: 'Не удалось удалить группу',
        }
    }) // @ts-ignore
    deleteGroup(id: string): Observable<void> {}

    @Put('updateGroupMembers', (id: string, members: IAddRemove) => `/groups/${id}/persons`, {
        desc: 'Изменить список членов группы',
        body: (id: string, members: IAddRemove) => members,
        res: {
            errorTitle: 'Не удалось изменить список членов группы',
        }
    }) // @ts-ignore
    updateGroupMembers(id: string, members: IAddRemove): Observable<void> {}

    @Post('uploadGroupAvatar', (id: string, file: File, callbacks?: RequestCallbacks) => `/groups/${id}/avatar`, {
        desc: 'Загрузить на сервер аватар группы',
        body: (id: string, file: File, callbacks?: RequestCallbacks) => {
            const body = new FormData();
            body.append('avatar', file, id);
            return body;
        },
        callbacks: (id: string, file: File, callbacks?: RequestCallbacks) => callbacks,
        res: {
            errorTitle: 'Не удалось загрузить на сервер аватар группы',
        },
        bypassSW: true
    }) // @ts-ignore
    uploadGroupAvatar(id: string, avatar: File, options?: RequestCallbacks): Observable<void> {}



    // * ######################################################################################################### Roles
    @Get('getRoles', '/roles', {
        desc: 'Получить список ролей',
        res: {
            errorTitle: 'Не удалось получить список ролей',
            parser: emitArray(Role)
        }
    }) // @ts-ignore
    getRoles(): Observable<Role[] | undefined> {}

    @Post('createRole', '/roles', {
        desc: 'Создать новую роль',
        body: (role: IRoleTpl) => role,
        res: {
            errorTitle: 'Не удалось создать роль',
            clazz: Role
        }
    }) // @ts-ignore
    createRole(role: IRoleTpl): Observable<Role> {}

    @Get('getRole', (id: string) => `/roles/${id}`, {
        desc: 'Получить данные роли',
        res: {
            errorTitle: 'Не удалось получить данные роли',
            clazz: Role
        }
    }) // @ts-ignore
    getRole(id: string): Observable<Role> {}

    @Put('updateRole', (id: string, role: IRoleTpl) => `/roles/${id}`, {
        desc: 'Изменить данные группы',
        body: (id: string, role: IRoleTpl) => role,
        res: {
            errorTitle: 'Не удалось изменить данные группы',
            clazz: Role
        }
    }) // @ts-ignore
    updateRole(id: string, role: IRoleTpl): Observable<Role> {}

    @Delete('deleteRole', (id: string) => `/roles/${id}`, {
        desc: 'Удалить роль',
        res: {
            errorTitle: 'Не удалось удалить роль',
        }
    }) // @ts-ignore
    deleteRole(id: string): Observable<void> {}

    @Put('updateRoleMembers', (id: string, members: IAddRemove) => `/roles/${id}/persons`, {
        desc: 'Изменить список членов роли',
        body: (id: string, members: IAddRemove) => members,
        res: {
            errorTitle: 'Не удалось изменить список членов роли',
        }
    }) // @ts-ignore
    updateRoleMembers(id: string, members: IAddRemove): Observable<void> {}

    @Put('updateRoleGroups', (id: string, members: IAddRemove) => `/roles/${id}/groups`, {
        desc: 'Изменить список групп роли',
        body: (id: string, members: IAddRemove) => members,
        res: {
            errorTitle: 'Не удалось изменить список групп роли',
        }
    }) // @ts-ignore
    updateRoleGroups(id: string, members: IAddRemove): Observable<void> {}



    // * ######################################################################################################### Organizations
    @Get('getOrgs', '/orgs', {
        desc: 'Получить список организаций',
        res: {
            errorTitle: 'Не удалось получить список организаций',
            parser: emitArray(Org)
        }
    }) // @ts-ignore
    getOrgs(): Observable<Org[] | undefined> {}

    @Post('createOrg', '/orgs', {
        desc: 'Создать новую организацию',
        body: (org: IOrgTpl) => org,
        res: {
            errorTitle: 'Не удалось создать организацию',
            clazz: Org
        }
    }) // @ts-ignore
    createOrg(org: IOrgTpl): Observable<Org> {}

    @Get('getOrg', (id: string) => `/orgs/${id}`, {
        desc: 'Получить данные организации',
        res: {
            errorTitle: 'Не удалось получить данные организации',
            clazz: Org
        }
    }) // @ts-ignore
    getOrg(id: string): Observable<Org> {}

    @Put('updateOrg', (id: string, org: IOrgTpl) => `/orgs/${id}`, {
        desc: 'Изменить данные организации',
        body: (id: string, org: IOrgTpl) => org,
        res: {
            errorTitle: 'Не удалось изменить данные организации',
            clazz: Org
        }
    }) // @ts-ignore
    updateOrg(id: string, org: IOrgTpl): Observable<Org> {}

    @Delete('deleteOrg', (id: string, data: SigninRequest) => `/orgs/${id}`, {
        desc: 'Удалить организацию',
        body: (id: string, data: SigninRequest) => data,
        res: {
            errorTitle: 'Не удалось удалить организацию',
        }
    }) // @ts-ignore
    deleteOrg(id: string, data: SigninRequest): Observable<void> {}

    @Post('uploadOrgAvatar', (id: string, file: File, callbacks?: RequestCallbacks) => `/orgs/${id}/avatar`, {
        desc: 'Загрузить на сервер аватар организации',
        body: (id: string, file: File, callbacks?: RequestCallbacks) => {
            const body = new FormData();
            body.append('avatar', file, id);
            return body;
        },
        callbacks: (id: string, file: File, callbacks?: RequestCallbacks) => callbacks,
        res: {
            errorTitle: 'Не удалось загрузить на сервер аватар организации',
        },
        bypassSW: true
    }) // @ts-ignore
    uploadOrgAvatar(id: string, avatar: File, options?: RequestCallbacks): Observable<void> {}

    @Post('fireFromOrg', (id: string, uids: string[]) => `/orgs/${id}/leave`, {
        desc: 'Уволить сотрудников из организации, или покинуть ее самому если uids == []',
        body: (id: string, uids: string[]) => uids,
        res: {
            errorTitle: (id: string, uids: string[]) => uids.length == 0 ? 'Не удалось покинуть организацию' : 'Не удалось уволить сотрудников из организации',
        }
    }) // @ts-ignore
    fireFromOrg(id: string, uids: string[]): Observable<void> {}



    // * ######################################################################################################### Org Departments
    @Post('createDepartment', '/departments', {
        desc: 'Создать новое подразделение',
        body: (dep: IDepartmentTpl) => dep,
        res: {
            errorTitle: 'Не удалось создать подразделение',
            clazz: Department
        }
    }) // @ts-ignore
    createDepartment(dep: IDepartmentTpl): Observable<Department> {}

    @Put('updateDepartment', (id: string, dep: IDepartmentTpl) => `/departments/${id}`, {
        desc: 'Изменить данные подразделения',
        body: (id: string, dep: IDepartmentTpl) => dep,
        res: {
            errorTitle: 'Не удалось изменить данные подразделения',
            clazz: Department
        }
    }) // @ts-ignore
    updateDepartment(id: string, dep: IDepartmentTpl): Observable<Department> {}

    @Delete('deleteDepartment', (id: string) => `/departments/${id}`, {
        desc: 'Удалить группу',
        res: {
            errorTitle: 'Не удалось удалить подразделение',
        }
    }) // @ts-ignore
    deleteDepartment(id: string): Observable<void> {}

    @Put('updateDepartmentMembers', (id: string, members: IAddRemove) => `/departments/${id}/members`, {
        desc: 'Изменить список членов подразделения',
        body: (id: string, members: IAddRemove) => members,
        res: {
            errorTitle: 'Не удалось изменить список членов подразделения',
        }
    }) // @ts-ignore
    updateDepartmentMembers(id: string, members: IAddRemove): Observable<void> {}



    // * ######################################################################################################### Invites
    @Get('getOrgInvites', (orgId: string) => `/orgs/${orgId}/invites`, {
        desc: 'Получить список приглашений в организацию',
        res: {
            errorTitle: 'Не удалось получить список приглашений в организацию',
            parser: emitArray(OrgInvite)
        }
    }) // @ts-ignore
    getOrgInvites(orgId: string): Observable<OrgInvite[] | undefined> {}

    @Post('sendOrgInvite', (orgId: string, emails: string[]) => `/orgs/${orgId}/invites`, {
        desc: 'Создать новые приглашения в организацию',
        body: (orgId: string, emails: string[]) => emails,
        res: {
            errorTitle: 'Не удалось создать приглашения в организацию',
            parser: emitArray(OrgInvite)
        }
    }) // @ts-ignore
    sendOrgInvite(orgId: string, emails: string[]): Observable<OrgInvite[] | undefined> {}

    @Put('resendOrgInvite', (orgId: string, inviteIds: string[]) => `/orgs/${orgId}/invites`, {
        desc: 'Перепослать приглашения в организацию',
        body: (orgId: string, inviteIds: string[]) => inviteIds,
        res: {
            errorTitle: 'Не удалось перепослать приглашения в организацию',
            parser: emitArray(OrgInvite)
        }
    }) // @ts-ignore
    resendOrgInvite(orgId: string, inviteIds: string[]): Observable<OrgInvite[] | undefined> {}

    @Delete('cancelOrgInvite', (orgId: string, inviteIds: string[]) => `/orgs/${orgId}/invites`, {
        desc: 'Отменить приглашения в организацию',
        body: (orgId: string, inviteIds: string[]) => inviteIds,
        res: {
            errorTitle: 'Не удалось отменить приглашения в организацию',
        }
    }) // @ts-ignore
    cancelOrgInvite(orgId: string, inviteIds: string[]): Observable<void> {}

    @Post('joinOrg', '/orgs/join', {
        desc: 'Принять приглашение в организацию',
        body: (invitationId: string) => ({ invitationId }),
        res: {
            errorTitle: 'Не удалось принять приглашение в организацию',
            clazz: OrgInvite
        }
    }) // @ts-ignore
    joinOrg(invitationId: string): Observable<OrgInvite> {}

    @Delete('rejectOrgInvite', '/orgs/join', {
        desc: 'Отклонить приглашение в организацию',
        body: (invitationId: string) => ({ invitationId }),
        res: {
            errorTitle: 'Не удалось отклонить приглашение в организацию',
            clazz: OrgInvite
        }
    }) // @ts-ignore
    rejectOrgInvite(invitationId: string): Observable<OrgInvite> {}

    @Post('getInviteInfo', '/info/invite', {
        desc: 'Получить информацию о приглашение в организацию',
        body: (invitationId: string) => ({ invitationId }),
        res: {
            errorTitle: 'Не удалось получить информацию о приглашение в организацию',
            clazz: IdNameAvatar
        }
    }) // @ts-ignore
    getInviteInfo(invitationId: string): Observable<IdNameAvatar> {}


    // * ######################################################################################################### Projects
    @Get('getProjects', '/projects', {
        desc: 'Получить список проектов',
        res: {
            errorTitle: 'Не удалось получить список проектов',
            parser: emitArray(Project)
        }
    }) // @ts-ignore
    getProjects(): Observable<Project[] | undefined> {}

    @Post('createProject', '/projects', {
        desc: 'Создать новый проект',
        body: (project: IProjectTpl) => project,
        res: {
            errorTitle: 'Не удалось создать группу',
            clazz: Project
        }
    }) // @ts-ignore
    createProject(project: IProjectTpl): Observable<Project> {}

    @Get('getProject', (id: string) => `/projects/${id}`, {
        desc: 'Получить данные проекта',
        res: {
            errorTitle: 'Не удалось получить данные проекта',
            clazz: Project
        }
    }) // @ts-ignore
    getProject(id: string): Observable<Project> {}

    @Put('updateProject', (id: string, project: IProjectTpl) => `/projects/${id}`, {
        desc: 'Изменить параметры проекта',
        body: (id: string, project: IProjectTpl) => project,
        res: {
            errorTitle: 'Не удалось изменить проект',
            clazz: Project
        }
    }) // @ts-ignore
    updateProject(id: string, project: IProjectTpl): Observable<Project> {}

    @Delete('deleteProject', (id: string) => `/projects/${id}`, {
        desc: 'Удалить проект',
        res: {
            errorTitle: 'Не удалось удалить проект',
        }
    }) // @ts-ignore
    deleteProject(id: string): Observable<void> {}

    @Put('updateProjectMembers', (id: string, diff: { persons: IAddRemove, groups: IAddRemove }) => `/projects/${id}/members`, {
        desc: 'Изменить список членов группы',
        body: (id: string, diff: { persons: IAddRemove, groups: IAddRemove }) => diff,
        res: {
            errorTitle: 'Не удалось изменить список членов группы',
        }
    }) // @ts-ignore
    updateProjectMembers(id: string, diff: { persons: IAddRemove, groups: IAddRemove }): Observable<void> {}



    // * ######################################################################################################### Prefs
    @Get('getPrefKeys', '/prefs', {
        desc: 'Получить список ключей сохраненных настроек',
        res: {
            errorTitle: 'Не удалось получить список ключей сохраненных настроек',
            parser: emitArray(Group)
        }
    }) // @ts-ignore
    getPrefKeys(): Observable<string[]> {}

    @Post('setPref', (key: string, data: object) => `/prefs/${key}`, {
        desc: 'Сохранить данные с указанным ключем',
        body: (key: string, data: object) => data,
        res: {
            errorTitle: 'Не удалось сохранить настройки',
            errors: {
                413: 'Слишком большой объем настроек, сохранение на сервере невозможно'
            }
        },
        bodySizeLimit: 128 * 1024,
    }) // @ts-ignore
    setPref(key: string, data: object): Observable<void> {}

    @Get('getPref', (key: string) => `/prefs/${key}`, {
        desc: 'Получить сохраненные данные для указанного ключа',
        res: {
            errorTitle: 'Не удалось получить сохраненные настройки',
        }
    }) // @ts-ignore
    getPref<T = any>(key: string): Observable<T> {}

    @Delete('deletePref', (key: string) => `/prefs/${key}`, {
        desc: 'Удалить сохраненные данные для указанного ключа',
        res: {
            errorTitle: (key: string) => `Не удалось удалить сохраненные настройки "${key}"`,
        }
    }) // @ts-ignore
    deletePref(key: string): Observable<void> {}

    @Delete('deletePrefs', () => `/prefs`, {
        desc: 'Удалить сохраненные данные для всех ключей',
        res: {
            errorTitle: 'Не удалось удалить сохраненные данные для всех ключей',
        }
    }) // @ts-ignore
    deletePrefs(): Observable<void> {}



    // * ######################################################################################################### Plugins
    @Get('getPlugins', () => `/plugins`, {
        desc: 'Получить список плагинов',
        res: {
            errorTitle: 'Не удалось получить список плагинов',
            parser: emitArray(VizorroPlugin)
        }
    }) // @ts-ignore
    getPlugins(): Observable<VizorroPlugin[] | undefined> {}

    @Post('setupPlugin', (pluginId: Guid, data: object, orgId: Guid) => `/plugins/${pluginId}`, {
        desc: 'Подключить плагин к организации',
        body: (pluginId: Guid, data: object, orgId: Guid) => ({ orgId, data }),
        res: {
            errorTitle: 'Не удалось подключить плагин к организации',
        }
    }) // @ts-ignore
    setupPlugin(pluginId: Guid, data: object, orgId: Guid): Observable<void> {}



    // * ######################################################################################################### TechSupport
    @Get('getTechSupportUsers', () => `/tech_support_persons`, {
        desc: 'Получить список работников тех.поддержки',
        res: {
            errorTitle: 'Не удалось получить список работников тех.поддержки',
            clazz: TechSupportUsersResponse
        }
    }) // @ts-ignore
    getTechSupportUsers(): Observable<TechSupportUsersResponse> {}



    // * ######################################################################################################### 2FA
    @Post('enable2fa', (password: string) => `/2fa`, {
        desc: 'Включить двухфакторную авторизацию',
        body: (password: string) => ({ password }),
        res: {
            errorTitle: 'Не удалось включить двухфакторную авторизацию',
            clazz: Enable2faResponse
        }
    }) // @ts-ignore
    enable2fa(password: string): Observable<Enable2faResponse> {}

    @Put('enable2faConfirm', (password: string, pinCode: string) => `/2fa`, {
        desc: 'Подтвердить включение двухфакторной авторизации',
        body: (password: string, pinCode: string) => ({ password, pinCode: '' + pinCode }),
        res: {
            errorTitle: 'Не удалось подтвердить включение двухфакторной авторизации',
        }
    }) // @ts-ignore
    enable2faConfirm(password: string, pinCode: string): Observable<void> {}

    @Post('refresh2faCodes', (password: string, pinCode: string) => `/2fa/restore_codes`, {
        desc: 'Сгенерировать новый набор кодов для восстановления двухфакторной авторизации',
        body: (password: string, pinCode: string) => ({ password, pinCode: '' + pinCode }),
        res: {
            errorTitle: 'Не удалось сгенерировать новый набор кодов для восстановления двухфакторной авторизации',
            parser: (data, err) => {
                if (Array.isArray(data) && data[0] && typeof data[0] == 'string') {
                    return of(data)
                }
                else {
                    return throwError(() => err.setText('Неизвестный формат в ответе сервера.'))
                }
            }
        }
    }) // @ts-ignore
    refresh2faCodes(password: string, pinCode: string): Observable<string[]> {}

    @Delete('disable2fa', (password: string, pinCode: string) => `/2fa`, {
        desc: 'Отключить двухфакторную авторизацию',
        body: (password: string, pinCode: string) => ({ password, pinCode: '' + pinCode }),
        res: {
            errorTitle: 'Не удалось отключить двухфакторную авторизацию',
        }
    }) // @ts-ignore
    disable2fa(password: string, pinCode: string): Observable<void> {}

}
