import { BaseSystemModel, RecursivePartial } from '@models/base';

export interface IVzTagTpl {
    orgId: string;
    text: string;
    icon?: string;
    color?: string;
    parentId?: string;
}

export class VzTag extends BaseSystemModel {
    _type = BaseSystemModel.Type.Tag;

    orgId!: string;
    text: string = '?';
    icon!: string;
    color?: string;
    parentId?: string;
    projectId?: string;
    uniqueGroup?: boolean;
    description?: string;

    _label?: string;
    _path: string = '';

    constructor(json?: RecursivePartial<VzTag>) {
        super(json);
        this.parseVzTag(json || {});
    }

    parse(json: RecursivePartial<VzTag>): VzTag {
        super.parse(json);
        this.parseVzTag(json);
        return this;
    }

    private parseVzTag(json: RecursivePartial<VzTag>): void {
        VzTag.assignFields<VzTag>(this, json, ['orgId', 'text', 'icon', 'color', 'parentId', 'projectId', 'uniqueGroup', 'description', '_label', '_path']);
        if (!this.icon && !this.parentId) {
            this.icon = 'vzi-tag-lines';
        }
        if (!this._label) {
            this._label = this.text + (this.description ? ' (' + this.description + ')' : '');
        }
    }
}
