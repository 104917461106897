import { RecursivePartial } from '../base';
import { IdName } from './id-name';

export class IdNameAvatar extends IdName {
    avatar?: string;

    constructor(json?: RecursivePartial<IdNameAvatar>) {
        super(json);
        this.parseIdNameAvatar(json || {});
    }

    parse(json: RecursivePartial<IdNameAvatar>): IdNameAvatar {
        super.parse(json);
        this.parseIdNameAvatar(json);
        return this;
    }

    private parseIdNameAvatar(json: RecursivePartial<IdNameAvatar>): void {
        IdNameAvatar.assignFields<IdNameAvatar>(this, json, ['avatar']);
    }
}
