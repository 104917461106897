/* eslint-disable @typescript-eslint/ban-types */
import { BaseModel, RecursivePartial } from '@models/base';
import { VzTipPartAction } from './tip-part-action';

export class VzTipPart extends BaseModel {
    text: string = '';
    actions?: VzTipPartAction[];
    actionsLayout?: 'hor' | 'vert';

    constructor(json?: RecursivePartial<VzTipPart>) {
        super(json);
        this.parseVzTipPart(json || {});
    }

    parse(json: RecursivePartial<VzTipPart>): VzTipPart {
        super.parse(json);
        this.parseVzTipPart(json);
        return this;
    }

    private parseVzTipPart(json: RecursivePartial<VzTipPart>): void {
        VzTipPart.assignFields<VzTipPart>(this, json, ['text', 'actionsLayout']);
        VzTipPart.assignClassArrays<VzTipPart>(this, json, { actions: VzTipPartAction });
    }
}
