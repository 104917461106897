<nz-drawer
    [nzClosable]="true"
    [nzVisible]="open"
    nzPlacement="right"
    [nzWidth]="mobile ? '100%' : '750px'"
    [nzBodyStyle]="{ position: 'relative' }"
    [nzTitle]="titleTpl"
    (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div class="layout fit vertical p1 gv05 flines" [class.mobile]="mobile">
            <div class="layout center">
                <div class="mr05 flex-2 tar txt-l">Применение:</div>
                <nz-radio-group class="layout flex-10" nzButtonStyle="solid" [(ngModel)]="vis" (ngModelChange)="visChanged()" [disabled]="!granted['org.admin'] || !!tagId">
                    <label nz-radio-button [nzValue]="VORG">Вся организация</label>
                    <label nz-radio-button [nzValue]="VPRJ">Один проект</label>
                  </nz-radio-group>
            </div>
            @if (vis == VPRJ) {
                <div class="layout center" [@removeH]>
                    <div class="mr05 flex-2 tar txt-l">Проект:</div>
                    <vz-project-select
                        style="flex: 10"
                        [placeholder]="'Проект'"
                        forTaskCreate="true"
                        [allowedIds]="allowedProjectIds"
                        [(ngModel)]="pid"
                        (ngModelChange)="projectChanged()"
                        [disabled]="(!!tagId && type == TTAG) || !!projectId"
                        ngDefaultControl />
                </div>
            }
            <div class="layout center">
                <div class="mr05 flex-2 tar txt-l">Тип:</div>
                <nz-radio-group class="layout flex-10" nzButtonStyle="solid" [(ngModel)]="type" (ngModelChange)="typeChanged()" [disabled]="!!tagId">
                    <label nz-radio-button [nzValue]="TCAT">Категория тегов</label>
                    <label nz-radio-button [nzValue]="TTAG">Тег</label>
                </nz-radio-group>
            </div>
            @if (type == TTAG) {
                <div class="layout center" [@removeH]>
                    <div class="mr05 flex-2 tar txt-l">Категория:</div>
                    @if (tags.length) {
                        <nz-select class="flex-10"
                            [(ngModel)]="pTag"
                            (ngModelChange)="updatePreview()"
                            [nzCustomTemplate]="tplSelectTag"
                            [compareWith]="compareById"
                            [nzDisabled]="!!tagId"
                            nzPlaceHolder="Выберите категорию">
                            @for (item of tags; track item.id) {
                                <nz-option
                                    class="layout center"
                                    nzCustomContent
                                    [nzLabel]="item.text"
                                    [nzValue]="item">
                                    <vz-tag [tagId]="item.id"></vz-tag>
                                </nz-option>
                            }
                        </nz-select>
                    }
                    @else {
                        <div class="flex-10">
                            <button nz-button [nzType]="'link'" (click)="type = TCAT; typeChanged();">Нет категорий, создать</button>
                        </div>
                    }
                </div>
            }
            <div class="layout center">
                <div class="mr05 flex-2 tar txt-l">Название:</div>
                <input
                    class="flex-10"
                    type="text"
                    nz-input
                    placeholder="Название"
                    [aFocus]="!tagId ? 500 : -1"
                    [(ngModel)]="text"
                    (ngModelChange)="updatePreview()" />
            </div>
            <div class="layout">
                <div class="mr05 flex-2 tar txt-l">Описание:</div>
                <textarea class="flex-10" type="text" nz-input placeholder="Описание" [nzAutosize]="{ minRows: 3, maxRows: 10 }" [(ngModel)]="description" (ngModelChange)="updatePreview()"></textarea>
            </div>
            @if (type == TCAT) {
                <div class="layout" [@removeH]>
                    <div class="mr05 flex-2 tar txt-l">Уникальность:</div>
                    <label class="flex-10" nz-checkbox [(ngModel)]="uniqueGroup" [nzDisabled]="!!tagId">Одновременно можно назначить только один тег из этой группы</label>
                </div>
            }
            <div class="layout center">
                <div class="mr05 flex-2 tar txt-l">Цвет фона:</div>
                <div class="flex-10">
                    <span class="ced-color layout op08"
                        [style.background-color]="color"
                        [style.border-color]="color"
                        (click)="showSelectColor = true">
                        <span>&nbsp;&nbsp;</span>
                    </span>
                </div>
            </div>
            @if (type == TCAT) {
                <div class="layout center" [@removeH]>
                    <div class="mr05 flex-2 tar txt-l">Значок:</div>
                    <div class="flex-10">
                        <i class="ced-icon" [style.color]="color" [ngClass]="icon" (click)="showSelectIcon = true"></i>
                    </div>
                </div>
            }
            <div class="layout center">
                <div class="mr05 flex-2 tar txt-l">Предпросмотр:</div>
                <div class="flex-10">
                    <vz-tag [tag]="previewTag" />
                </div>
            </div>
            @if (lError) {
                <vz-error-panel class="layout center self-center" [error]="lError" />
            }
        </div>
    </ng-container>
</nz-drawer>

<ng-template #titleTpl>
    <div class="layout center">
        <span class="flex">{{ tagId ? 'Редактировать тег' : 'Создать новый тег'}}</span>
        @if (tagId) {
            <button class="layout mr05 ant-btn-icon-only"
                title="Удалить этот тег"
                nz-button nzType="dashed" nzGhost nzDanger
                nz-popconfirm
                [nzPopconfirmTitle]="'Уверены?' + (type == TCAT ? ' Будут также удалены все теги этой группы.' : '')"
                nzPopconfirmPlacement="topRight"
                (nzOnConfirm)="delete()">
                <i class="vzi-trash-o"></i>
            </button>
        }
        <button nz-button nzType="primary" [disabled]="!isCorrect" (click)="submit()">{{ tagId ? 'Сохранить' : 'Создать' }}</button>
    </div>
</ng-template>

@if (showSelectIcon) {
    <vz-select-icon [color]="color" (closePanel)="setIcon($event)" />
}
@if (showSelectColor) {
    <vz-select-color [op]="0.8" (closePanel)="setColor($event)" />
}

<ng-template #tplSelectTag let-item>
    <div class="layout center" style="height: 100%">
        <vz-tag [tagId]="item.nzValue.id" class="mr025"></vz-tag>
    </div>
</ng-template>
