import { TaskApprover } from '@models/tasks/task-approver';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { RecursivePartial } from '@models/base';

export class TaskActionApproversReset extends BaseTaskAction {
    type: TaskActionType.ApproversReset = TaskActionType.ApproversReset;
    statesOld?: TaskApprover[];

    constructor(json?: RecursivePartial<TaskActionApproversReset>) {
        super(json);
        this.parseTaskActionFile(json || {});
    }

    parse(json: RecursivePartial<TaskActionApproversReset>): TaskActionApproversReset {
        super.parse(json);
        this.parseTaskActionFile(json);
        return this;
    }

    private parseTaskActionFile(json: RecursivePartial<TaskActionApproversReset>): void {
    }
}
