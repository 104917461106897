import { platform } from './platform';

export const environment = {
    admin: true,
    production: true,
    env: 'prod',
    baseDomain: 'vizorro.ru',
    urls: {
        app: 'https://app.vizorro.ru',
        auth: 'https://auth.vizorro.ru',
        api: 'https://api.vizorro.ru',
        rmq: 'wss://rmq.vizorro.ru',
    },
    firebaseConfig: {
        apiKey: "AIzaSyCux7nsSzVH_4JCdGBCTx42Yq1rmbXOqAU",
        authDomain: "vizoro.firebaseapp.com",
        projectId: "vizoro",
        storageBucket: "vizoro.appspot.com",
        messagingSenderId: "578585105276",
        appId: "1:578585105276:web:d9d4fc7c6bc195089f4af9",
        measurementId: "G-NVC1704CZ8"
    },
    platform
};

export function sendBugReport(_error: any): void {}
