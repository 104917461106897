import { BaseSystemModel, RecursivePartial } from '@models/base';

export enum TaskActionType {
    Status              = 1,
    Note                = 2,
    Assign              = 3,
    Watchers            = 4,
    DueDate             = 5,
    Priority            = 6,
    SpentHours          = 7,
    Subj                = 8,
    Text                = 9,
    CreateChat          = 10,
    FileAttach          = 11,
    NoteEdit            = 12,
    NoteDelete          = 13,
    Video               = 14,
    LinkAdd             = 15,
    LinkRemove          = 16,
    FileDetach          = 17,
    TagAdd              = 18,
    TagRemove           = 19,
    Approvers           = 20,
    ApproverState       = 21,
    ApproversReset      = 22,
    SubtaskAdd          = 23,
    SubtaskRemove       = 24,
    Archive             = 25,
    SetSprint           = 26,
    ChecklistEdit       = 27,
    ChecklistState      = 28,
}

export enum TaskActionInternalType {
    Add     = 1,
    Delete  = 2,
    Change  = 3
}

export class BaseTaskAction extends BaseSystemModel {
    _type = BaseSystemModel.Type.Action;
    _unknown?: true;

    numId?: number;
    type!: TaskActionType;
    pluginId?: string;
    _date!: string;

    constructor(json?: RecursivePartial<BaseTaskAction>) {
        super(json);
        this.parseBaseTaskAction(json || {});
    }

    parse(json: RecursivePartial<BaseTaskAction>): BaseTaskAction {
        super.parse(json);
        this.parseBaseTaskAction(json);
        return this;
    }

    private parseBaseTaskAction(json: RecursivePartial<BaseTaskAction>): void {
        BaseTaskAction.assignFields<BaseTaskAction>(this, json, ['type', 'numId', 'pluginId', '_date']);
        if (!this._date) {
            this._date = this.created ? this.created.getFullYear() + '-' + (this.created.getMonth() + 1) + '-' + this.created.getDate() : '?';
        }
    }
}
