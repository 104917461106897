import { BaseSystemModel, RecursivePartial } from '@models/base';
import { VzTariff } from './tariff';

export enum OrgPayPlan {
    Free = 0,
    Paid = 1,
}

export const OrgPayPlanName = {
    [OrgPayPlan.Free]: 'Бесплатный',
    [OrgPayPlan.Paid]: 'Платный'
}

export interface IOrgLimits extends Omit<VzTariff, 'parse'> {
    creditLimit?: number;
    slotsCost?: number;
    videoCostPerMinute?: number;
}

export class OrgInfo extends BaseSystemModel {
    alias?: string;
    payPlan: OrgPayPlan = OrgPayPlan.Free;
    videoroomLimit: number = 0;
    storageLimit: number = 0;
    suspended?: boolean;
    maxActiveTasks: number = 0;
    maxChatChannels: number = 0;
    chatHistoryLimit: number = 0;
    directChatLimit: number = 0;

    verified?: boolean;
    shortName?: string;
    fullName?: string;
    legalAddr?: string;
    actualAddr?: string;
    inn?: string;
    kpp?: string;
    ogrn?: string;
    director?: string;
    phone?: string;
    email?: string;
    balance: number = 0;
    balanceVideo: number = 0;
    credit: number = 0;
    creditLimit: number = 0;
    slotsCost: number = 400;
    videoCostPerMinute: number = 0.1;

    bankName?: string;
    settlAcc?: string;
    corrAcc?: string;
    bik?: string;

    constructor(json?: RecursivePartial<OrgInfo>) {
        super(json);
        this.parseOrgInfo(json || {});
    }

    parse(json: RecursivePartial<OrgInfo>): OrgInfo {
        super.parse(json);
        this.parseOrgInfo(json);
        return this;
    }

    private parseOrgInfo(json: RecursivePartial<OrgInfo>): void {
        OrgInfo.assignFields<OrgInfo>(this, json, [
            'alias',
            'payPlan',
            'videoroomLimit',
            'storageLimit',
            'suspended',
            'maxActiveTasks',
            'maxChatChannels',
            'chatHistoryLimit',
            'directChatLimit',

            'verified',
            'shortName',
            'fullName',
            'legalAddr',
            'actualAddr',
            'inn',
            'kpp',
            'ogrn',
            'director',
            'phone',
            'email',
            'balance',
            'balanceVideo',
            'credit',
            'creditLimit',
            'slotsCost',
            'videoCostPerMinute',

            'bankName',
            'settlAcc',
            'corrAcc',
            'bik',
        ]);
        if (!this.id && (json as any).orgId) {
            this.id = (json as any).orgId;
        }
    }
}
