import { BaseModel, RecursivePartial } from '@models/base';
import { TaskChecklistItem } from './task-checklist-item';

export class TaskChecklist extends BaseModel {
    required?: boolean;
    revision: number = 0;
    items: TaskChecklistItem[] = [];

    _done?: boolean;

    constructor(json?: RecursivePartial<TaskChecklist>) {
        super(json);
        this.parseTaskChecklist(json || {});
    }

    parse(json: RecursivePartial<TaskChecklist>): TaskChecklist {
        super.parse(json);
        this.parseTaskChecklist(json);
        return this;
    }

    private parseTaskChecklist(json: RecursivePartial<TaskChecklist>): void {
        TaskChecklist.assignFields<TaskChecklist>(this, json, ['required', 'revision']);
        TaskChecklist.assignClassArrays<TaskChecklist>(this, json, { items: TaskChecklistItem });
        this._done = !this.items.some(v => !v.checked)
    }

}
