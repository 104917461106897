import { TaskLinkType } from '@models/tasks/task-link';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { RecursivePartial } from '@models/base';

export class TaskActionLinkRemove extends BaseTaskAction {
    type: TaskActionType.LinkRemove = TaskActionType.LinkRemove;
    taskFromId!: string;
    taskToId!: string;
    linkType!: TaskLinkType;

    constructor(json?: RecursivePartial<TaskActionLinkRemove>) {
        super(json);
        this.parseTaskActionLinkRemove(json || {});
    }

    parse(json: RecursivePartial<TaskActionLinkRemove>): TaskActionLinkRemove {
        super.parse(json);
        this.parseTaskActionLinkRemove(json);
        return this;
    }

    private parseTaskActionLinkRemove(json: RecursivePartial<TaskActionLinkRemove>): void {
        TaskActionLinkRemove.assignFields<TaskActionLinkRemove>(this, json, ['taskFromId', 'taskToId', 'linkType']);
    }
}
