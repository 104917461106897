import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JsonSafePipeModule, EllipsisPipeModule } from '@pipes';

import { ErrorPanelComponent } from './error-panel.component';

@NgModule({
    imports: [
        CommonModule, JsonSafePipeModule, EllipsisPipeModule
    ],
    declarations: [ErrorPanelComponent],
    exports: [ErrorPanelComponent]
})
export class ErrorPanelModule { }
