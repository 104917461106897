import { BaseModel, RecursivePartial } from '@models/base';
import { VzFile } from './file';
import { HttpApiError } from '@models/api/http-api-error';

export enum UploadStatus {
    Error           = -100,
    Created         = 1,
    UploadStarted   = 2,
    Uploaded        = 3,
}

export class Upload extends BaseModel {
    static Status = UploadStatus;
    id?: string;
    requestId?: string;
    file?: File;
    info?: VzFile;
    status: UploadStatus = UploadStatus.Created;
    error?: HttpApiError;
    started?: number;
    finished?: number;
    bytesUploaded: number = 0;
    bytesTotal: number = 0;
    speed: number = 0;
    sourceId?: string;
    sourceTitle?: string;
    sourceLink?: string;

    constructor(json?: RecursivePartial<Upload>) {
        super(json);
        this.parseUpload(json || {});
    }

    parse(json: RecursivePartial<Upload>): Upload {
        super.parse(json);
        this.parseUpload(json);
        return this;
    }

    private parseUpload(json: RecursivePartial<Upload>): void {
        Upload.assignFields<Upload>(this, json, [
            'id',
            'requestId',
            'file',
            'status',
            'error',
            'started',
            'finished',
            'bytesUploaded',
            'bytesTotal',
            'speed',
            'sourceId',
            'sourceTitle',
            'sourceLink'
        ]);
        Upload.assignClassFields(this, json, { info: VzFile });
    }

}
