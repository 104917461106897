import { NgModule, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { Task } from '@models';
import { isAfter, isPast, isSameDay, isToday } from 'date-fns';

@Directive({
    selector: '[vz-task-text-classes]',
})
export class TaskTextClassesDirective {

    @Input('vz-task-text-classes')
    set task(v: Task | undefined) {
        this.applyClasses(v);
    }

    dueToday: boolean = false;
    dueExpired: boolean = false;

    constructor(private _el: ElementRef, private _r2: Renderer2) { }

    applyClasses(t?: Task): void {
        if (!t) {
            return;
        }
        const el = this._el.nativeElement;
        this._r2.removeClass(el, 'fw-b');
        this._r2.removeClass(el, 'vz-task-archived');
        this._r2.removeClass(el, 'txt-warn');
        this._r2.removeClass(el, 'txt-error');

        this.dueExpired = false;
        this.dueToday = false;
        if (t && t.dueDate) {
            if (t.archived) {
                if (t.modified) {
                    this.dueExpired = t.dueDate && isAfter(t.modified, t.dueDate);
                    this.dueToday = t.dueDate && isSameDay(t.modified, t.dueDate);
                }
            }
            else {
                this.dueExpired = !!t.dueDate && isPast(t.dueDate);
                this.dueToday = !!t.dueDate && isToday(t.dueDate);
            }
        }

        if (this.dueExpired && !this.dueToday) {
            this._r2.addClass(el, 'txt-error');
        }
        if (this.dueToday) {
            this._r2.addClass(el, 'txt-warn');
        }
        if (!t.flags.seen) {
            this._r2.addClass(el, 'fw-b');
        }
        if (t.archived) {
            this._r2.addClass(el, 'vz-task-archived');
        }
    }

}

@NgModule({
    declarations: [TaskTextClassesDirective],
    exports: [TaskTextClassesDirective]
})
export class TaskTextClassesModule { }
