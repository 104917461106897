import { BaseSystemModel, Guid, RecursivePartial } from '@models/base';
import { CodeSnippet } from '@models/shared';
import { TaskFlags } from './task-flags';
import {
    TaskActionType,
    TaskActionUnknown,
    TaskActionNote,
    TaskActionAssign,
    TaskActionStatus,
    TaskActionWatchers,
    TaskActionSubj,
    TaskActionText,
    TaskActionDueDate,
    TaskActionPriority,
    TaskActionSpentHours,
    TaskActionCreateChat,
    TaskActionFileAttach,
    TaskActionVideo,
    TaskActionLinkAdd,
    TaskActionLinkRemove,
    TaskActionFileDetach,
    TaskActionTagAdd,
    TaskActionTagRemove,
    TaskActionNoteEdit,
    TaskActionNoteDelete,
    TaskActionApprovers,
    TaskActionApproverState,
    TaskActionApproversReset,
    TaskActionSubtaskAdd,
    TaskActionSubtaskRemove,
    TaskActionArchive,
    TaskActionSetSprint,
    TaskActionChecklistEdit,
    TaskActionChecklistState,
} from './actions';
import { TaskLink } from './task-link';
import { TaskApprover, TaskApproverState } from './task-approver';
import { string2Color } from '@models/utils/colors';
import { TaskChecklist } from './task-checklist';

export type TaskActionClassType = {
    [TaskActionType.Assign]: TaskActionAssign,
    [TaskActionType.Note]: TaskActionNote,
    [TaskActionType.Status]: TaskActionStatus,
    [TaskActionType.Watchers]: TaskActionWatchers,
    [TaskActionType.Subj]: TaskActionSubj,
    [TaskActionType.Text]: TaskActionText,
    [TaskActionType.DueDate]: TaskActionDueDate,
    [TaskActionType.Priority]: TaskActionPriority,
    [TaskActionType.SpentHours]: TaskActionSpentHours,
    [TaskActionType.CreateChat]: TaskActionCreateChat,
    [TaskActionType.FileAttach]: TaskActionFileAttach,
    [TaskActionType.NoteEdit]: TaskActionNoteEdit,
    [TaskActionType.NoteDelete]: TaskActionNoteDelete,
    [TaskActionType.Video]: TaskActionVideo,
    [TaskActionType.LinkAdd]: TaskActionLinkAdd,
    [TaskActionType.LinkRemove]: TaskActionLinkRemove,
    [TaskActionType.FileDetach]: TaskActionFileDetach,
    [TaskActionType.TagAdd]: TaskActionTagAdd,
    [TaskActionType.TagRemove]: TaskActionTagRemove,
    [TaskActionType.Approvers]: TaskActionApprovers,
    [TaskActionType.ApproverState]: TaskActionApproverState,
    [TaskActionType.ApproversReset]: TaskActionApproversReset,
    [TaskActionType.SubtaskAdd]: TaskActionSubtaskAdd,
    [TaskActionType.SubtaskRemove]: TaskActionSubtaskRemove,
    [TaskActionType.Archive]: TaskActionArchive,
    [TaskActionType.SetSprint]: TaskActionSetSprint,
    [TaskActionType.ChecklistEdit]: TaskActionChecklistEdit,
    [TaskActionType.ChecklistState]: TaskActionChecklistState,
}

export const TaskActionClass = {
    [TaskActionType.Assign]: TaskActionAssign,
    [TaskActionType.Note]: TaskActionNote,
    [TaskActionType.Status]: TaskActionStatus,
    [TaskActionType.Watchers]: TaskActionWatchers,
    [TaskActionType.Subj]: TaskActionSubj,
    [TaskActionType.Text]: TaskActionText,
    [TaskActionType.DueDate]: TaskActionDueDate,
    [TaskActionType.Priority]: TaskActionPriority,
    [TaskActionType.SpentHours]: TaskActionSpentHours,
    [TaskActionType.CreateChat]: TaskActionCreateChat,
    [TaskActionType.FileAttach]: TaskActionFileAttach,
    [TaskActionType.NoteEdit]: TaskActionNoteEdit,
    [TaskActionType.NoteDelete]: TaskActionNoteDelete,
    [TaskActionType.Video]: TaskActionVideo,
    [TaskActionType.LinkAdd]: TaskActionLinkAdd,
    [TaskActionType.LinkRemove]: TaskActionLinkRemove,
    [TaskActionType.FileDetach]: TaskActionFileDetach,
    [TaskActionType.TagAdd]: TaskActionTagAdd,
    [TaskActionType.TagRemove]: TaskActionTagRemove,
    [TaskActionType.Approvers]: TaskActionApprovers,
    [TaskActionType.ApproverState]: TaskActionApproverState,
    [TaskActionType.ApproversReset]: TaskActionApproversReset,
    [TaskActionType.SubtaskAdd]: TaskActionSubtaskAdd,
    [TaskActionType.SubtaskRemove]: TaskActionSubtaskRemove,
    [TaskActionType.Archive]: TaskActionArchive,
    [TaskActionType.SetSprint]: TaskActionSetSprint,
    [TaskActionType.ChecklistEdit]: TaskActionChecklistEdit,
    [TaskActionType.ChecklistState]: TaskActionChecklistState,
}

export const TaskActionErrorText = {
    [TaskActionType.Assign]: 'Не удалось изменить ответственного',
    [TaskActionType.Note]: 'Не удалось добавить комментарий',
    [TaskActionType.Status]: 'Не удалось изменить статус',
    [TaskActionType.Watchers]: 'Не удалось изменить список наблюдателей',
    [TaskActionType.Subj]: 'Не удалось изменить тему',
    [TaskActionType.Text]: 'Не удалось изменить текст задачи',
    [TaskActionType.DueDate]: 'Не удалось изменить срок выполнения',
    [TaskActionType.Priority]: 'Не удалось изменить приоритет',
    [TaskActionType.SpentHours]: 'Не удалось изменить затраченное время',
    [TaskActionType.CreateChat]: 'Не удалось создать чат для обчуждения задачи',
    [TaskActionType.FileAttach]: 'Не удалось прикрепить файл',
    [TaskActionType.NoteEdit]: 'Не удалось изменить текст комментария',
    [TaskActionType.NoteDelete]: 'Не удалось удалить комментарий',
    [TaskActionType.Video]: 'Не удалось начать видеотрансляцию',
    [TaskActionType.LinkAdd]: 'Не удалось добавить связь с задачей',
    [TaskActionType.LinkRemove]: 'Не удалось удалить связь с задачей',
    [TaskActionType.FileDetach]: 'Не удалось убрать прикрепленный файл',
    [TaskActionType.TagAdd]: 'Не удалось добавить тег',
    [TaskActionType.TagRemove]: 'Не удалось удалить тег',
    [TaskActionType.Approvers]: 'Не удалось изменить список согласующих',
    [TaskActionType.ApproverState]: 'Не удалось изменить статус согласования',
    [TaskActionType.ApproversReset]: 'Не удалось сбросить статусы согласований',
    [TaskActionType.SubtaskAdd]: 'Не удалось добавить подзадачу',
    [TaskActionType.SubtaskRemove]: 'Не удалось удалить подзадачу',
    [TaskActionType.Archive]: 'Не удалось перевести задачу в архив',
    [TaskActionType.SetSprint]: 'Не удалось изменить состояние "активна" задачи',
    [TaskActionType.ChecklistEdit]: 'Не удалось изменить чек-лист',
    [TaskActionType.ChecklistState]: 'Не удалось изменить состояние пункта чек-листа',
}

export type TaskAction = TaskActionNote | TaskActionAssign | TaskActionStatus | TaskActionWatchers | TaskActionSubj
    | TaskActionText | TaskActionDueDate | TaskActionPriority | TaskActionSpentHours | TaskActionCreateChat | TaskActionFileAttach
    | TaskActionNoteEdit | TaskActionNoteDelete | TaskActionFileDetach | TaskActionVideo | TaskActionLinkAdd | TaskActionLinkRemove
    | TaskActionTagAdd | TaskActionTagRemove | TaskActionApprovers | TaskActionApproverState | TaskActionApproversReset
    | TaskActionSubtaskAdd | TaskActionSubtaskRemove | TaskActionArchive | TaskActionSetSprint | TaskActionChecklistEdit | TaskActionChecklistState;

export enum TaskStatus {
    Created     = 1,
    Inprogress  = 2,
    Done        = 3,
}

export const TaskStatusList = [
    TaskStatus.Created,
    TaskStatus.Inprogress,
    TaskStatus.Done,
];

export const TaskStatusIcon = {
    [TaskStatus.Created]: 'vzi-idea',
    [TaskStatus.Inprogress]: 'vzi-construction',
    [TaskStatus.Done]: 'vzi-check',
}

export const TaskStatusColor = {
    [TaskStatus.Created]: 'txt-ok',
    [TaskStatus.Inprogress]: 'txt-pl',
    [TaskStatus.Done]: 'txt-pd',
}

export const TaskStatusName = {
    [TaskStatus.Created]: 'Создана',
    [TaskStatus.Inprogress]: 'В работе',
    [TaskStatus.Done]: 'Выполнена',
}

export const TaskStatusText = {
    [TaskStatus.Created]: 'Задача переведена в статус "Создана"',
    [TaskStatus.Inprogress]: 'Задача переведена в статус "В работе"',
    [TaskStatus.Done]: 'Задача переведена в статус "Выполнена"',
}

export const TaskPriorityName = ['Не задан', 'Высокий', 'Средний', 'Низкий'];
export const TaskPriorityTooltipColor = ['#ccc', 'red', 'orange', 'green'];

export interface ITaskTpl {
    subj?: string;
    text?: { [key: string]: any };
    orgId?: Guid;
    assignee?: Guid;
    assigneeGroup?: Guid;
    parentId?: Guid;
    projectId?: Guid;
    watchers?: Guid[];
    dueDate?: Date;
    priority?: number;
    files?: Guid[];
    type?: number;
    snippets?: CodeSnippet[];
    tags?: Guid[];
    actions?: TaskAction[];
}

export type TaskListType = 'inbox' | 'favorites' | 'inprogress' | 'outgoing' | 'closed' | 'sprint' | 'backlog' | 'support';
export type TaskWorkflowFlags = {
    active?: boolean;
    backlog?: boolean;
    inprogress?: boolean;
    done?: boolean;
    archive?: boolean;
};

export class Task extends BaseSystemModel {
    _type = BaseSystemModel.Type.Task;

    shortId!: string;
    orgId!: Guid;
    chatId?: Guid;
    subj!: string;
    text?: { [key: string]: any };
    status!: TaskStatus;
    assignee?: Guid;
    assigneeGroup?: Guid;
    parentId?: Guid;
    projectId?: Guid;
    watchers: Guid[] = [];
    files?: Guid[] = [];
    dueDate?: Date;
    priority: number = 0;
    spentHours: number = 0;
    chats?: Guid[];
    lastRead?: number;
    pluginId?: Guid;
    snippets?: CodeSnippet[];
    type: number = 0;
    flags!: TaskFlags;
    tags?: Guid[];
    links?: TaskLink[];
    approvers?: TaskApprover[];
    subTasks?: Task[];
    sprintId?: Guid;
    archived?: boolean;
    checklist?: TaskChecklist;

    _color: string = '#f00';
    _doneSubtasks?: number;
    _cg2: TaskWorkflowFlags = {};

    constructor(json?: RecursivePartial<Task>) {
        super(json);
        this.parseTask(json || {});
    }

    parse(json: RecursivePartial<Task>): Task {
        super.parse(json);
        this.parseTask(json);
        return this;
    }

    private parseTask(json: RecursivePartial<Task>): void {
        Task.assignFields<Task>(this, json, [
            'shortId',
            'orgId',
            'chatId',
            'text',
            'status',
            'subj',
            'assignee',
            'assigneeGroup',
            'parentId',
            'projectId',
            'watchers',
            'files',
            'priority',
            'spentHours',
            'lastRead',
            'pluginId',
            'chats',
            'type',
            'tags',
            'sprintId',
            'archived'
        ]);
        Task.assignClassArrays<Task>(this, json, { snippets: CodeSnippet, links: TaskLink, approvers: TaskApprover, subTasks: Task });
        Task.assignClassFields<Task>(this, json, { flags: TaskFlags, checklist: TaskChecklist });
        Task.assignDates<Task>(this, json, ['dueDate']);
        if (this.priority > 3) {
            this.priority = 3;
        }
        else if (this.priority < 0) {
            this.priority = 0;
        }
        if (this.status < 1) {
            this.status = 1;
        }
        else if (this.status > 3) {
            this.status = 3;
        }
        this._color = this.projectId ? string2Color(this.projectId) : '#f00';
        if (this.subTasks?.length) {
            this._doneSubtasks = this.subTasks.filter(t => t.status == TaskStatus.Done).length;
        }
        this._cg2 = getTaskWorkflowFlags(this);
    }

}

export function createTaskAction<T extends TaskActionType>(action: TaskAction): TaskActionClassType[T] {
    if (TaskActionClass[action.type]) {
        return new TaskActionClass[action.type](action as any) as TaskActionClassType[T];
    }
    else {
        console.log('[createTaskAction] unknown action type:', action);
        return new TaskActionUnknown(action) as TaskActionClassType[T];
    }
}

export function isTaskInList(task: Task, list: TaskListType, uid?: string): boolean {
    if (list == 'backlog') {
        return !task.sprintId;
    }
    if (list == 'closed') {
        return !!task.archived && !!uid && task.assignee == uid;
    }
    if (list == 'favorites') {
        return !!task.flags?.favorite;
    }
    if (list == 'inbox') {
        return !!task.flags?.inbox;
    }
    if (list == 'inprogress') {
        return task.status == TaskStatus.Inprogress && !!uid && task.assignee == uid;
    }
    if (list == 'outgoing') {
        return !task.archived && !!uid && task.createdBy == uid;
    }
    if (list == 'sprint') {
        return !!task.sprintId;
    }
    if (list == 'support') {
        return task.type > 0 && !!uid && task.createdBy == uid;
    }
    return false;
}

export function getTaskWorkflowFlags(task: Task): TaskWorkflowFlags {
    return {
        active: (!task.sprintId || (task.archived && !task.parentId)) && (!!task.assignee || !!task.assigneeGroup),
        backlog: (!!task.sprintId || (task.archived && !task.parentId)),
        inprogress: !!task.sprintId && !!task.assignee,
        done: task.status == TaskStatus.Inprogress,
        archive: task.status == TaskStatus.Done && (!task.approvers?.length || task.approvers.every(ta => ta.state == TaskApproverState.Accepted)) && !task.parentId,
    };
}