<div *ngIf="sTag" class="vz-tag layout center" [ngClass]="'vz-tag-' + tagSize" [title]="(pTag ? pTag._label + '\n' : '') + (sTag._label)">
    <ng-template [ngIf]="pTag">
        <div class="vzt-group" [style.background-color]="pTag.color">
            <i *ngIf="pTag.icon" class="vzt-icon" [ngClass]="pTag.icon"></i>
            <span class="vzt-text">{{ pTag.text }}</span>
        </div>
        <div class="vzt-separator" [style.border-left-color]="pTag.color"></div>
        <div class="vzt-separator2" [style.border-top-color]="sTag.color" [style.border-bottom-color]="sTag.color"></div>
    </ng-template>
    <div class="vzt-tag" [class.vzt-tag-nogroup]="!pTag" [style.background-color]="sTag.color">
        <i *ngIf="sTag.icon" class="vzt-icon" [ngClass]="sTag.icon"></i>
        <span class="vzt-text">{{ sTag.text }}</span>
    </div>
</div>
<div *ngIf="sTag === null" class="vz-tag layout center" [ngClass]="'vz-tag-' + tagSize" [title]="'Тег не найден, #' + this.tagId">
    <div class="vzt-tag vzt-tag-nogroup" style="background-color: #ccc">
        <span class="vzt-text">#{{ tagId ? tagId.substring(0, 7) + '…' : '?' }}</span>
    </div>
</div>
