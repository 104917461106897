import { AbstractControl, ValidationErrors } from '@angular/forms';

export class VzValidators {

    static uuidPattern = /^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

    static UUID(control: AbstractControl): ValidationErrors | null {
        const uuid = control.value;
        if (uuid) {
            if (!VzValidators.uuidPattern.test(uuid)) {
                return { formatError: { uuid } };
            }
        }
        return null;
    }

    static MeanfulText(min: number): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            const v = control.value;
            if (!v || v.trim().length < min) {
                return { lengthError: true };
            }
            return null;
        }
    }

    static ForbiddenChars(chars: string): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            const v: string = control.value;
            if (v && chars?.length) {
                for (let i = 0; i < chars.length; i++) {
                    if (v.includes(chars.charAt(i))) {
                        return { forbiddenError: true };
                    }
                }
            }
            return null;
        }
    }

    static StrongPassword(min: number = 8, max: number = 30): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            let res = 0;
            const v: string = control.value;
            if (v?.length && v.length >= min && v.length <= max) {
                for (let i = 0; i < v.length; i++) {
                    const c = v.charAt(i);
                    if ('`~!@#$%^&*()_+=-[]{};:\'"\\|/?.>,<'.includes(c)) {
                        res = res | 1;
                    }
                    else if ('0123456789'.includes(c)) {
                        res = res | 2;
                    }
                    else if (c == c.toLocaleLowerCase() && c != c.toLocaleUpperCase()) {
                        res = res | 4;
                    }
                    else if (c == c.toLocaleUpperCase() && c != c.toLocaleLowerCase()) {
                        res = res | 8;
                    }
                }
            }
            return res == 15 ? null : { passError: true };
        }
    }

    static MatchValue(controlName: string, matchControlName: string): (control: AbstractControl) => ValidationErrors | null {
        return (group: AbstractControl) => {
            if (group.get(controlName)?.value != group.get(matchControlName)?.value) {
                group.get(matchControlName)?.setErrors({ matchError: true });
                return { matchError: true };
            }
            return null;
        }
    }

}
