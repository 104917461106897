import { BaseModel, RecursivePartial } from "../base";

export class VzParamStyles extends BaseModel {
    prefix?: string;
    suffix?: string;
    subtype?: any;
    size?: any;
    checkedSymbol?: string;
    uncheckedSymbol?: string;
    hidden?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    maxWidth?: number | string;
    minWidth?: number | string;
    textAlign?: string;
    nzType?: string;
    loading?: boolean;
    vzType?: string;
    icon?: string;
    placeholder?: string;
    margin?: number | string;
    nomobile?: boolean;
    layout?: 'hor' | 'vert';
    flex?: boolean;

    constructor(json?: RecursivePartial<VzParamStyles>) {
        super(json);
        this.parseUnreadCounters(json || {});
    }

    parse(json: RecursivePartial<VzParamStyles>): VzParamStyles {
        super.parse(json);
        this.parseUnreadCounters(json);
        return this;
    }

    private parseUnreadCounters(json: RecursivePartial<VzParamStyles>): void {
        VzParamStyles.assignFields<VzParamStyles>(this, json, [
            'prefix',
            'suffix',
            'subtype',
            'size',
            'checkedSymbol',
            'uncheckedSymbol',
            'hidden',
            'disabled',
            'readonly',
            'maxWidth',
            'minWidth',
            'textAlign',
            'nzType',
            'loading',
            'vzType',
            'icon',
            'placeholder',
            'margin',
            'nomobile',
            'layout',
            'flex',
        ]);
    }
}
