import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { format } from 'date-fns';

import { removeH } from '@base/base-animations';
import { BaseComponent } from '@base/base.component';
import { Tag, UrlState } from '@models';
import { VzCrashData } from '@models/shared/crash-data';
import { getSnippetsForTicket } from '@models/utils/tasks';
import { ApiService, StoreService } from '@services';
import pkg from '@root/package.json';

@Component({
    selector: 'vz-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    animations: [removeH]
})
@Tag('AppComponent')
export class AppComponent extends BaseComponent {
    _hostFit = true;

    newVersionReady: boolean = false;
    newVersionHidden: boolean = false;
    version: string = pkg.version;
    accessToken?: string;
    crash?: VzCrashData;
    reportSent?: string;
    reportSending: boolean = false;
    suspended?: boolean;

    constructor(
        protected _store: StoreService,
        private _swu: SwUpdate,
        private _api: ApiService,
    ) {
        super(_store);
        if (!this._store.getState('user').isLoggedIn()) {
            this._store.clearAllStates();
        }
        const url = this._store.getState('url', state => state.authBaseUrl);
        if (!url) {
            let schema = 'https://';
            let baseDomain = this.__ENV.baseDomain;
            if (!this.__CEF && origin.indexOf('://localhost') == -1) {
                schema = origin.substring(0, origin.indexOf('://') + 4);
                baseDomain = origin.substring(origin.indexOf('://admin.') + 7);
            }
            const ws = schema.replace(/^http/, 'ws');
            this._store.setState('url', new UrlState({
                authBaseUrl: `${schema}auth.${baseDomain}`,
                apiBaseUrl: `${schema}api.${baseDomain}`,
                rmqBaseUrl: `${ws}rmq.${baseDomain}`,
            }));
        }
        if (this._swu.isEnabled) {
            this._L('constructor', 'swu.isEnabled = true, subscribe to swu.versionUpdates');
            this._swu.versionUpdates.subscribe(e => {
                this._L('constructor', 'swu.versionUpdates:', e);
                if (e.type == 'VERSION_READY') {
                    this.newVersionReady = true;
                }
            });
        }
        this._store.state('user').subscribe(() => this.checkSuspended());
        this._store.crashReport.subscribe(crash => {
            if (!this.crash) {
                this.crash = crash;
                this.reportSent = undefined;
            }
        });
    }

    vzOnActiveOrgChange(): void {
        this.checkSuspended();
    }

    checkSuspended(): void {
        if (this.activeOrgId) {
            const org = this._store.getState('orgs').items[this.activeOrgId];
            this.suspended = org?.suspended;
        }
        else {
            this.suspended = false;
        }
    }

    reload(): void {
        this._W('reload');
        window.location.reload();
    }

    createTicket(): void {
        this.reportSending = true;
        this.__finalize(this._api.createTask({
            orgId: this._store.activeOrgId.getValue(),
            subj: '[Crash Report] ' + this.crash?.title,
            text: {
                type: 'doc',
                content: [
                    {
                        type: 'paragraph',
                        content: [{ type: 'text', text: `Версия: ${this.version}` }]
                    },
                    {
                        type: 'paragraph',
                        content: [{ type: 'text', text: `Дата и время: ${format(this.crash?.dt || new Date(), 'dd.MM.y HH:mm:ss.SSS')}` }]
                    }
                ]
            },
            snippets: getSnippetsForTicket(this._store, this.crash),
            type: 1,
        }), () => this.reportSending = false).subscribe({
            next: task => {
                if (task && task.id) {
                    this.reportSent = task.shortId;
                }
            },
            error: err => this.__error(err)
        });
    }

}
