import { OLD_BaseSystemModel, RecursivePartial } from '@models/base';
import { VzFileImgView } from './file-img-view';
import { VzFileMetadata } from './file-metadata';
import { VzFilePdfView } from './file-pdf-view';
import { VzFileReference } from './file-reference';

export interface IVzFileTpl {
    name?: string;
    description?: string;
    orgId?: string;
    projectId?: string;
    readPersons?: string[];
    writePersons?: string[];
    readGroups?: string[];
    writeGroups?: string[];
    mimeType?: string;
    size?: number;
}

export class VzFile extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.File;

    name!: string;
    orgId!: string;
    projectId?: string;
    readPersons?: string[];
    writePersons?: string[];
    readGroups?: string[];
    writeGroups?: string[];
    description?: string;
    size!: number;
    mimeType!: string;
    imgView?: VzFileImgView;
    pdfView?: VzFilePdfView;
    metadata?: VzFileMetadata;
    attached: number = 0;
    uploaded?: boolean;
    references?: VzFileReference[];
    _icon!: string;

    static getFileIcon(mt?: string): string {
        if (!mt) {
            return 'vzi-file';
        }
        if (mt.indexOf('ms-word') != -1 || mt.indexOf('msword') != -1) {
            return 'vzi-file-word';
        }
        else if (mt.indexOf('ms-excel') != -1) {
            return 'vzi-file-excel';
        }
        else if (mt.indexOf('ms-powerpoint') != -1) {
            return 'vzi-file-pp';
        }
        else if (mt.indexOf('pdf') != -1) {
            return 'vzi-file-pdf';
        }
        else if (mt.indexOf('video') != -1) {
            return 'vzi-file-video';
        }
        else if (mt.indexOf('image') != -1) {
            return 'vzi-file-image';
        }
        else if (mt.indexOf('audio') != -1) {
            return 'vzi-file-audio';
        }
        else if (mt == 'text/plain') {
            return 'vzi-file-text';
        }
        else if (
            mt == 'application/x-tar'
            || mt == 'application/x-compressed'
            || mt == 'application/gzip'
            || mt == 'application/x-bzip2'
            || mt == 'application/x-7z-compressed'
            || mt == 'application/zip'
            || mt == 'application/x-rar-compressed'
        ) {
            return 'vzi-file-archive';
        }
        else if (
            mt == 'text/x-c++src'
            || mt == 'text/x-c'
            || mt == 'application/java'
            || mt == 'text/x-h'
            || mt == 'text/x-java-source'
            || mt == 'application/x-php'
            || mt == 'application/x-perl'
            || mt == 'text/x-perl'
            || mt == 'text/x-python'
            || mt == 'text/x-shellscript'
            || mt == 'application/javascript'
            || mt == 'text/javascript'
            || mt == 'text/x-shellscript'
        ) {
            return 'vzi-file-code';
        }
        return 'vzi-file';
    }

    constructor(json?: RecursivePartial<VzFile>) {
        super(json);
        this.parseVzFile(json || {});
    }

    parse(json: RecursivePartial<VzFile>): VzFile {
        super.parse(json);
        this.parseVzFile(json);
        return this;
    }

    private parseVzFile(json: RecursivePartial<VzFile>): void {
        VzFile.assignFields<VzFile>(this, json, [
            'name',
            'orgId',
            'projectId',
            'readPersons',
            'writePersons',
            'readGroups',
            'writeGroups',
            'description',
            'size',
            'mimeType',
            'attached',
            'uploaded',
            '_icon'
        ]);
        VzFile.assignClassFields<VzFile>(this, json, {
            imgView: VzFileImgView,
            pdfView: VzFilePdfView,
            metadata: VzFileMetadata
        });
        VzFile.assignClassArrays<VzFile>(this, json, { references: VzFileReference });
        if (!this._icon) {
            this._icon = VzFile.getFileIcon(this.mimeType);
        }
        if (!this.metadata) {
            this.metadata = new VzFileMetadata();
        }
        if (this._icon == 'vzi-file-audio' && !this.metadata.seconds) {
            this.metadata.seconds = 0;
            this.metadata.type = VzFileMetadata.Type.Audio;
            this.metadata.playable = true;
        }
        else if (this._icon == 'vzi-file-video' && !this.metadata.seconds) {
            this.metadata.seconds = 0;
            this.metadata.width = 0;
            this.metadata.height = 0;
            this.metadata.type = VzFileMetadata.Type.Video;
            this.metadata.playable = true;
        }
        else if (this._icon == 'vzi-file-pdf' && !this.metadata.playable) {
            this.metadata.pages = 0;
            this.metadata.type = VzFileMetadata.Type.Pdf;
        }
    }
}
