/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import { ISiteTpl, SM, Site, Tag } from '@models';
import { ApiMethod, Delete, Get, Post, Put, TVzResponse, VzServices, emitArray, sendRequest } from '@models/utils/http-helpers';
import { AuthService } from './auth.service';
import { StoreService } from './store.service';

const GE = (s: number): string => `/events/return_error/${s}?req=`;

@Injectable()
@Tag('PluginVizorroService')
export class PluginVizorroService extends BaseLogging {

    private _baseUrl: string | undefined = this.__ENV.urls.api;
    cfg: () => VzServices;
    lastRequest: number = new Date().getTime();

    RD: SM<ApiMethod<any, any>>;
    private __req?: ApiMethod<any, any> | null;

    constructor(
        private _http: HttpClient,
        private _auth: AuthService,
        private _store: StoreService,
    ) {
        super();
        this.RD = (this as any).__RD;
        if (this.__ENV.env != 'prod') {
            this._store.state('url').subscribe(state => {
                this._baseUrl = state.apiBaseUrl;
                this._L('constructor', 'baseUrl:', this._baseUrl);
            });
        }
        this.cfg = () => ({
            baseUrl: this._baseUrl! + '/vizorro',
            http: this._http,
            L: this._L,
            W: this._W,
            updateLastRequest: () => this.lastRequest = new Date().getTime(),
            authTokenState: this._store.authTokenState,
            getLastActivity: () => new Date(this._store.getLastActivity()),
            getRefreshToken: () => this._store.getState('user', st => st.refreshToken),
            getRefreshingAuthToken: () => this._auth.refreshingAuthToken,
            setRefreshingAuthToken: rat => this._auth.refreshingAuthToken = rat,
            getUserId: () => this._store.getState('user', st => st.userId),
            gotNewToken: token => this._store.patchState('user', { authToken: token }),
            renewAuthToken: (userId, refreshToken, lastActivity) => this._auth.renewAuthToken(userId, refreshToken, lastActivity)
        });
    }

    sendRequest<T extends TVzResponse>(id: string, ...args: any): Observable<T> {
        return sendRequest(this.RD[id], this.cfg(), ...args);
    }

    getBaseUrl(_reqId: string): string {
        return this.cfg().baseUrl;
    }

    // * ######################################################################################################### Sites

    @Get('getSites', '/sites', {
        desc: 'Получить список сайтов',
        res: {
            errorTitle: 'Не удалось получить список сайтов',
            parser: emitArray(Site)
        }
    }) // @ts-ignore
    getSites(): Observable<Site[] | undefined> {}

    @Post('createSite', '/sites', {
        desc: 'Создать новый сайт',
        body: (site: ISiteTpl) => site,
        res: {
            errorTitle: 'Не удалось создать сайт',
            clazz: Site
        }
    }) // @ts-ignore
    createSite(site: ISiteTpl): Observable<Site> {}

    @Get('getSite', (id: string) => `/sites/${id}`, {
        desc: 'Получить параметры сайта',
        res: {
            errorTitle: 'Не удалось получить параметры сайта',
            clazz: Site
        }
    }) // @ts-ignore
    getSite(id: string): Observable<Site> {}

    @Put('updateSite', (id: string, site: ISiteTpl) => `/sites/${id}`, {
        desc: 'Изменить параметры сайта',
        body: (id: string, site: ISiteTpl) => site,
        res: {
            errorTitle: 'Не удалось изменить параметры сайта',
            clazz: Site
        }
    }) // @ts-ignore
    updateSite(id: string, site: ISiteTpl): Observable<Site> {}

    @Delete('deleteSite', (id: string) => `/sites/${id}`, {
        desc: 'Удалить сайт',
        res: {
            errorTitle: 'Не удалось удалить сайт',
        }
    }) // @ts-ignore
    deleteSite(id: string): Observable<void> {}

}
