import { BaseModel, RecursivePartial } from '@models/base';

export class IdSize extends BaseModel {
    id?: string;
    size: number = 0;

    constructor(json?: RecursivePartial<IdSize>) {
        super(json);
        this.parseIdSize(json || {});
    }

    parse(json: RecursivePartial<IdSize>): IdSize {
        super.parse(json);
        this.parseIdSize(json);
        return this;
    }

    private parseIdSize(json: RecursivePartial<IdSize>): void {
        IdSize.assignFields<IdSize>(this, json, ['id', 'size']);
    }
}
