import { BaseState } from './base-state';
import { Person } from '@models/users';
import { AuthType } from '@models/api';
import { RecursivePartial } from '../base';

export class UserState extends BaseState {

    _VER = 5;

    authToken?: string;
    authType?: AuthType;
    refreshToken?: string;
    userId?: string;
    user?: Person;
    isGuest: boolean = false;
    chatId?: string;
    invitationId?: string;
    mobile?: boolean;

    constructor(json?: RecursivePartial<UserState>) {
        super(json);
        this.parseUserState(json || {});
    }

    parse(json: RecursivePartial<UserState>): UserState {
        super.parse(json);
        this.parseUserState(json);
        return this;
    }

    private parseUserState(json: RecursivePartial<UserState>): void {
        UserState.assignFields<UserState>(this, json, [
            'authToken',
            'authType',
            'refreshToken',
            'userId',
            'isGuest',
            'chatId',
            'invitationId',
            'mobile',
        ]);
        UserState.assignClassFields<UserState>(this, json, { user: Person });
    }

    isLoggedIn(): boolean {
        return !!this.authToken && !!this.refreshToken && !!this.userId;
    }

}
