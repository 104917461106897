import { BaseModel, RecursivePartial } from '@models/base';

export class Histogram extends BaseModel {
    start: number = 0;
    step: number = 0;
    data: number[] | number[][] = [];
    lastStepExceed?: boolean;

    constructor(json?: RecursivePartial<Histogram>) {
        super(json);
        this.parseHistogram(json || {});
    }

    parse(json: RecursivePartial<Histogram>): Histogram {
        super.parse(json);
        this.parseHistogram(json);
        return this;
    }

    private parseHistogram(json: RecursivePartial<Histogram>): void {
        Histogram.assignFields<Histogram>(this, json, ['start', 'step', 'data', 'lastStepExceed']);
    }
}
