import { BaseModel, RecursivePartial, SM } from "@models/base";

export class VzParamConstraints extends BaseModel {
    min?: number;
    max?: number;
    required?: boolean;
    regexp?: string;
    regexpClear?: string;
    requiredFields?: string[];
    errors?: { [id: string]: string };

    _regexp?: RegExp;
    _regexpClear?: RegExp;

    constructor(json?: RecursivePartial<VzParamConstraints>) {
        super(json);
        this.parseParamConstraints(json || {});
    }

    parse(json: RecursivePartial<VzParamConstraints>): VzParamConstraints {
        super.parse(json);
        this.parseParamConstraints(json);
        return this;
    }

    private parseParamConstraints(json: RecursivePartial<VzParamConstraints>): void {
        VzParamConstraints.assignFields<VzParamConstraints>(this, json, [
            'min',
            'max',
            'required',
            'errors',
            'regexp',
            'regexpClear',
            'requiredFields'
        ]);
        if (this.regexp) {
            try {
                this._regexp = new RegExp(this.regexp);
            }
            catch(_) {}
        }
        if (this.regexpClear) {
            try {
                this._regexpClear = new RegExp(this.regexpClear, 'g');
            }
            catch(_) {}
        }
    }
}

export const DEFAULT_CONSTRAINTS_ERRORS: SM<string> = {
    required: 'Обязательный параметр',
    min: 'Значение меньше допустимого',
    max: 'Значение больше допустимого',
    regexp: 'Некорректный формат значения',
}
