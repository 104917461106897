import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { Role } from '@models/users';

export class RolesState extends BaseItemsState<Role> {

    constructor(json?: RecursivePartial<RolesState>) {
        super(json);
        this.parseRolesState(json || {});
    }

    parse(json: RecursivePartial<RolesState>): RolesState {
        super.parse(json);
        this.parseRolesState(json);
        return this;
    }

    private parseRolesState(json: RecursivePartial<RolesState>): void {
        RolesState.assignClassMaps<RolesState>(this, json, { items: Role });
    }

}
