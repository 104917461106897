import { TaskStatus } from '@models/tasks/task';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { RecursivePartial } from '@models/base';

export class TaskActionStatus extends BaseTaskAction {
    type: TaskActionType.Status = TaskActionType.Status;
    status?: TaskStatus;
    statusOld?: TaskStatus;

    constructor(json?: RecursivePartial<TaskActionStatus>) {
        super(json);
        this.parseTaskActionStatus(json || {});
    }

    parse(json: RecursivePartial<TaskActionStatus>): TaskActionStatus {
        super.parse(json);
        this.parseTaskActionStatus(json);
        return this;
    }

    private parseTaskActionStatus(json: RecursivePartial<TaskActionStatus>): void {
        TaskActionStatus.assignFields<TaskActionStatus>(this, json, ['status', 'statusOld']);
    }
}
