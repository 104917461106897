import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

@Pipe({
    name: 'jsonSafe'
})
export class JsonSafePipe implements PipeTransform {

    transform(value: any, spaces?: string | number): any {
        let res: string | undefined;
        try {
            res = JSON.stringify(value, null, spaces);
        } catch(e) {
            res = 'Error stringifying object: ' + e;
        }
        return res !== undefined ? res : 'undefined';
        // return this.stringify(value, null, spaces);
    }

    // stringify(obj: any, replacer: any, spaces: any, cycleReplacer?: any): string {
    //     return JSON.stringify(obj, this.serializer(replacer, cycleReplacer), spaces);
    // }

    // serializer(replacer: any, cycleReplacer: any): any {
    //     const stack: any[] = [];
    //     const keys: string[] = [];

    //     if (cycleReplacer == null) {
    //         cycleReplacer = (key: string, value: any) => {
    //             if (stack[0] === value) {
    //                 return '[Circular ~]';
    //             }
    //             return '[Circular ~.' + keys.slice(0, stack.indexOf(value)).join('.') + ']';
    //         };
    //     }

    //     /* tslint:disable no-bitwise */
    //     return (key: string, value: any) => {
    //         if (stack.length > 0) {
    //             const thisPos = stack.indexOf(this);
    //             ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
    //             ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
    //             if (~stack.indexOf(value)) { value = cycleReplacer.call(this, key, value); }
    //         }
    //         else {
    //             stack.push(value);
    //         }

    //         return replacer == null ? value : replacer.call(this, key, value);
    //     };
    // }

}

@NgModule({
    imports: [CommonModule],
    declarations: [JsonSafePipe],
    exports: [JsonSafePipe]
})
export class JsonSafePipeModule { }
