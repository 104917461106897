import { BaseModel, RecursivePartial } from '@models/base';

export interface IBrowserInfo {
    name?: string;
    version?: string;
    navigator?: {
        userAgent?: string;
        appVersion?: string;
        platform?: string;
        vendor?: string;
    }
}

export class DeviceInfo extends BaseModel {
    os?: {
        name?: string;
        version?: string
    };
    browser?: IBrowserInfo;
    clientType?: string;

    constructor(json?: RecursivePartial<DeviceInfo>) {
        super(json);
        this.parseDeviceInfo(json || {});
    }

    parse(json: RecursivePartial<DeviceInfo>): DeviceInfo {
        super.parse(json);
        this.parseDeviceInfo(json);
        return this;
    }

    private parseDeviceInfo(json: RecursivePartial<DeviceInfo>): void {
        DeviceInfo.assignFields<DeviceInfo, IBrowserInfo>(this, json, ['os', 'browser', 'clientType']);
    }
}
