import { BaseFilterField, FilterFieldType } from '@models/filters/base-filter-field';
import { Chat } from '@models/chats';

export class ChatFilterFieldNotFinished extends BaseFilterField<Chat, boolean, true> {
    id: string = '-finished';
    name: string = 'Только активные';
    desc: string = 'Не завершенные чаты, в которых можно продолжить общение';
    isFlag: boolean = true;
    type: FilterFieldType.Flag = FilterFieldType.Flag;
    single = true as const;

    value(o: Chat): boolean {
        return o.status == Chat.Status.Started || o.status == Chat.Status.Created;
    }

    match(o: Chat, v: string): boolean {
        return o && (!v || o.status == Chat.Status.Started || o.status == Chat.Status.Created);
    }

}
