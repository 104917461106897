<div class="persons-select-root layout fit vertical">
    <div class="layout gap05" style="padding-bottom: 16px">
        <button class="ant-btn-icon-only"
            title="Одним списком / разделить по группам"
            nz-button
            [nzType]="pref.groupped ? 'primary' : 'default'"
            (click)="toggleGroupped()">
            <i class="vzi-list-nested"></i>
        </button>
        <div class="flex">
            <vz-ctrl-search [controller]="controller" [filterController]="filterController" placeholder="Фильтр..." />
        </div>
        @if (!single) {
            <button nz-button nzType="primary" (click)="close(controller.getSelectedItemIds())">{{ okText }}</button>
        }
    </div>
    <div class="layout flex relative">
            <dm-table
                class="fit ngx-dmt-clickable"
                [controller]="controller"
                [colsWidth]="{ name: 400, created: 150, modified: 150, tz: 200 }"
                [defaultColumnConfig]="{ minWidth: 100 }"
                (rowClick)="single ? (returnObject ? drawerRef.close($event.row) : drawerRef.close($event.row.id!)) : toggle($event.row.id)"
                [showSelectColumn]="!single"
                [rowClasses]="{ 'pe-none': disableRow }"
                moveableColumns="false"
                selectColumnWidth="40"
                itemSize="49">
                <ng-template #selectColumnHeader let-all="all" let-none="none">
                    <div style="text-align: center">
                        <label nz-checkbox [ngModel]="all" [nzIndeterminate]="!all && !none"></label>
                    </div>
                </ng-template>
                <ng-template #selectColumn let-checked let-row="row">
                    <div class="layout center-center">
                        <label nz-checkbox [ngModel]="checked" [nzDisabled]="disabledIds?.[row.id!]"></label>
                    </div>
                </ng-template>
                <ng-template #groupHeader let-gr="group">
                    <div class="layout center fw-b" (click)="controller.setGroupsCollapsed([gr.id], !gr.collapsed)">
                        <i class="vz-expand vzi-ch-right fs-xxl txt-l" [class.vz-expanded]="!gr.collapsed"></i>
                        @if (gr.data.group) {
                            <vz-avatar [item]="gr.data.group" type="group" size="24" withName="true" />
                        }
                        @else {
                            <b class="ml025">{{ gr.data.title }}</b>
                        }
                    </div>
                </ng-template>
                <dm-column title="Имя" colId="name">
                    <ng-template #cell let-row="row">
                        <div class="layout center">
                            <vz-avatar [item]="row" cursor="pointer" [type]="row._type == 'group' ? 'group' : 'user'" withName="true" />
                            @if (row.id == userId) {
                                <span class="txt-ll ml025">(Вы)</span>
                            }
                        </div>
                    </ng-template>
                </dm-column>
            </dm-table>
    </div>
</div>

@if (error) {
    <vz-error-message [error]="error" (dismiss)="error = undefined" />
}
