import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';

export const APP_TITLE = 'Vizorro';

export interface TitlePart {
    type?: string;
    icon?: string;
    title: string;
    path?: string;
    desc?: string;
}

@Injectable()
export class TitleService {
    private _titles: TitlePart[] = [];

    public onTitleChange: Subject<TitlePart[]> = new Subject();

    constructor(private _ts: Title) { }

    setTitles(titles: TitlePart[] | string): void {
        this._titles = typeof titles == 'string' ? [{ title: titles }] : titles;
        this.updateTitles();
    }

    addPart(part: TitlePart | string): void {
        this._titles.push(typeof part == 'string' ? { title: part } : part);
        this.updateTitles();
    }

    replaceLastpart(part: TitlePart | string): void {
        this._titles.pop();
        this.addPart(part);
        this.updateTitles();
    }

    removeLastpart(): void {
        this._titles.pop();
        this.updateTitles();
    }

    getParts(): TitlePart[] {
        return this._titles;
    }

    updateTitles(): void {
        let title = APP_TITLE;
        if (this._titles && this._titles.length > 0) {
            for (const ttl of this._titles) {
                title = ttl.title + ' | ' + title;
            }
        }
        this._ts.setTitle(title);
        this.onTitleChange.next(this._titles);
    }

}
