import { Component, Input } from '@angular/core';

import { BaseDrawerComponent } from '@base/base-drawer.component';
import { Tag } from '@models';
import { StoreService } from '@services';
import { ICONS } from '@models';

@Component({
    selector: 'vz-select-icon',
    templateUrl: './select-icon-drawer.component.html',
    styleUrls: ['./select-icon-drawer.component.less']
})
@Tag('SelectIconDrawerComponent')
export class SelectIconDrawerComponent extends BaseDrawerComponent {
    @Input() color?: string;
    icons = ICONS;

    constructor(
        protected _store: StoreService,
    ) {
        super(_store);
    }

}
