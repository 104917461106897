import { BaseModel, RecursivePartial } from '@models/base';

export class SiteConfigCaptcha extends BaseModel {
    show?: boolean;
    type = 'yandex' as const;
    data: any | undefined;

    constructor(json?: RecursivePartial<SiteConfigCaptcha>) {
        super(json);
        this.parseSiteConfigCaptcha(json || {});
    }

    parse(json: RecursivePartial<SiteConfigCaptcha>): SiteConfigCaptcha {
        super.parse(json);
        this.parseSiteConfigCaptcha(json);
        return this;
    }

    private parseSiteConfigCaptcha(json: RecursivePartial<SiteConfigCaptcha>): void {
        SiteConfigCaptcha.assignFields<SiteConfigCaptcha>(this, json, [
            'show',
            'type',
        ]);
        if (this.data == null) {
            if (json.data !== undefined) {
                this.data = json.data;
            }
        }
        else {
            if (json.data === null) {
                this.data = undefined;
            }
            else if (json.data != null) {
                if (typeof json.data == 'object') {
                    for(const k of Object.keys(json.data)) {
                        this.data[k] = (json.data as any)[k];
                    }
                }
                else {
                    this.data = json.data;
                }
            }
        }
    }
}
