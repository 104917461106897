import { BaseModel, RecursivePartial } from '@models/base';

export class BaseState extends BaseModel {

    _VER: number = 0;
    _dt?: Date;

    constructor(json?: RecursivePartial<BaseState>) {
        super(json);
        this.parseBaseState(json || {});
    }

    parse(json: RecursivePartial<BaseState>): BaseState {
        super.parse(json);
        this.parseBaseState(json);
        return this;
    }

    private parseBaseState(json: RecursivePartial<BaseState>): void {
        BaseState.assignFields<BaseState>(this, json, ['_VER']);
        BaseState.assignDates<BaseState>(this, json, ['_dt']);
    }

}
