import { IdName } from '@models/shared';
import { RecursivePartial } from '../base';

export class OrgPlugin extends IdName {
    privileges: string[] = [];
    projectIds: string[] = [];

    constructor(json?: RecursivePartial<OrgPlugin>) {
        super(json);
        this.parseOrgPlugin(json || {});
    }

    parse(json: RecursivePartial<OrgPlugin>): OrgPlugin {
        super.parse(json);
        this.parseOrgPlugin(json);
        return this;
    }

    private parseOrgPlugin(json: RecursivePartial<OrgPlugin>): void {
        OrgPlugin.assignFields<OrgPlugin>(this, json, ['privileges', 'projectIds']);
    }
}
