import { Observable, of } from "rxjs";

import { CefApp } from '@models/shared/cef';

export function cefSend(_channel: string, _data?: any): Observable<any> {
    return of('not implemented');
}

export const cefApp: CefApp = {
    setEventListener: () => {},
    clearEventListener: () => {},
    clearAllEventListeners: () => {}
};
