import { BaseModel, RecursivePartial } from '@models/base';

export class SiteConfigTheme extends BaseModel {
    name?: string;
    bgColor?: string;
    textColor?: string;
    primaryColor?: string;
    accentColor?: string;

    constructor(json?: RecursivePartial<SiteConfigTheme>) {
        super(json);
        this.parseSiteConfigTheme(json || {});
    }

    parse(json: RecursivePartial<SiteConfigTheme>): SiteConfigTheme {
        super.parse(json);
        this.parseSiteConfigTheme(json);
        return this;
    }

    private parseSiteConfigTheme(json: RecursivePartial<SiteConfigTheme>): void {
        SiteConfigTheme.assignFields<SiteConfigTheme>(this, json, [
            'name',
            'bgColor',
            'textColor',
            'primaryColor',
            'accentColor',
        ]);
    }
}
