import { BaseModel, RecursivePartial } from '@models/base';
import { Histogram, IdSize } from '@models/shared';

export class VzFilesOrgStats extends BaseModel {
    sizeLimit: number = 0;  // 0 - no limit, MBs
    sizeUsed: number = 0;
    sizeHistogram?: Histogram;
    services: IdSize[] = [];
    servicesHistogram?: Histogram;
    users: IdSize[] = [];

    constructor(json?: RecursivePartial<VzFilesOrgStats>) {
        super(json);
        this.parseVzFilesOrgStats(json || {});
    }

    parse(json: RecursivePartial<VzFilesOrgStats>): VzFilesOrgStats {
        super.parse(json);
        this.parseVzFilesOrgStats(json);
        return this;
    }

    private parseVzFilesOrgStats(json: RecursivePartial<VzFilesOrgStats>): void {
        VzFilesOrgStats.assignFields<VzFilesOrgStats>(this, json, ['sizeLimit', 'sizeUsed']);
        VzFilesOrgStats.assignClassArrays<VzFilesOrgStats>(this, json, { services: IdSize, users: IdSize });
        VzFilesOrgStats.assignClassFields<VzFilesOrgStats>(this, json, { sizeHistogram: Histogram, servicesHistogram: Histogram });
    }
}
