import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { Group } from '@models/users';

export class GroupsState extends BaseItemsState<Group> {

    constructor(json?: RecursivePartial<GroupsState>) {
        super(json);
        this.parseGroupsState(json || {});
    }

    parse(json: RecursivePartial<GroupsState>): GroupsState {
        super.parse(json);
        this.parseGroupsState(json);
        return this;
    }

    private parseGroupsState(json: RecursivePartial<GroupsState>): void {
        GroupsState.assignClassMaps<GroupsState>(this, json, { items: Group });
    }

}
