/* eslint-disable @typescript-eslint/ban-types */
import { BaseSystemModel, RecursivePartial } from '@models/base';
import { VzTipPart } from './tip-part';

export class VzTip extends BaseSystemModel {
    numId!: number;
    title?: string;
    text?: {};
    parts: VzTipPart[] = [];

    constructor(json?: RecursivePartial<VzTip>) {
        super(json);
        this.parseVzTip(json || {});
    }

    parse(json: RecursivePartial<VzTip>): VzTip {
        super.parse(json);
        this.parseVzTip(json);
        return this;
    }

    private parseVzTip(json: RecursivePartial<VzTip>): void {
        VzTip.assignFields<VzTip>(this, json, ['numId', 'title', 'text']);
        VzTip.assignClassArrays<VzTip>(this, json, { parts: VzTipPart });
    }
}
