import { BaseModel, RecursivePartial } from '@models/base';

export class OrgsCountStats extends BaseModel {
    createdAt?: string;
    freeTotalCount: number = 0;
    paidTotalCount: number = 0;
    paidVerifiedCount: number = 0;
    balanceTotal: number = 0;
    creditTotal: number = 0;
    activeTotalCount: number = 0;
    suspendedTotalCount: number = 0;

    _all: number = 0;

    constructor(json?: RecursivePartial<OrgsCountStats>) {
        super(json);
        this.parseOrgsCountStats(json || {});
    }

    parse(json: RecursivePartial<OrgsCountStats>): OrgsCountStats {
        super.parse(json);
        this.parseOrgsCountStats(json);
        return this;
    }

    private parseOrgsCountStats(json: RecursivePartial<OrgsCountStats>): void {
        OrgsCountStats.assignFields<OrgsCountStats>(this, json, [
            'createdAt',
            'freeTotalCount',
            'paidTotalCount',
            'paidVerifiedCount',
            'balanceTotal',
            'creditTotal',
            'activeTotalCount',
            'suspendedTotalCount',
        ]);
        this._all = this.freeTotalCount + this.paidTotalCount;
    }

}
