import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { KanbanBoard } from '@models/tasks';

export class BoardsState extends BaseItemsState<KanbanBoard> {

    constructor(json?: RecursivePartial<BoardsState>) {
        super(json);
        this.parseBoardsState(json || {});
    }

    parse(json: RecursivePartial<BoardsState>): BoardsState {
        super.parse(json);
        this.parseBoardsState(json);
        return this;
    }

    private parseBoardsState(json: RecursivePartial<BoardsState>): void {
        BoardsState.assignClassMaps<BoardsState>(this, json, { items: KanbanBoard });
    }

}
