import { ModelTemplate, RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { TaskChecklistItem } from '../task-checklist-item';

export class TaskActionChecklistState extends BaseTaskAction {
    type: TaskActionType.ChecklistState = TaskActionType.ChecklistState;
    revision: number = 0;
    items?: string[];
    state?: boolean[];
    stateOld?: boolean[];

    _changed?: ModelTemplate<TaskChecklistItem>[];

    constructor(json?: RecursivePartial<TaskActionChecklistState>) {
        super(json);
        this.parseTaskActionChecklistState(json || {});
    }

    parse(json: RecursivePartial<TaskActionChecklistState>): TaskActionChecklistState {
        super.parse(json);
        this.parseTaskActionChecklistState(json);
        return this;
    }

    private parseTaskActionChecklistState(json: RecursivePartial<TaskActionChecklistState>): void {
        TaskActionChecklistState.assignFields<TaskActionChecklistState>(this, json, ['revision', 'items', 'state', 'stateOld']);
        if (this.items?.length && this.state?.length && this.stateOld?.length && this.items.length == this.state.length) {
            this._changed = [];
            for (let i = 0; i < this.items.length; i++) {
                if (this.state[i] != this.stateOld[i]) {
                    this._changed.push({ title: this.items[i], checked: !!this.stateOld[i] });
                }
            }
        }
    }
}
