import { ModelTemplate, RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionInternalType, TaskActionType } from './_base-task-action';
import { TaskChecklistItem } from '../task-checklist-item';

export class TaskActionChecklistEdit extends BaseTaskAction {
    type: TaskActionType.ChecklistEdit = TaskActionType.ChecklistEdit;
    revision?: number;
    required?: boolean;
    requiredOld?: boolean;
    items?: string[];
    itemsOld?: string[];
    state?: boolean[];
    stateOld?: boolean[];

    _tait?: TaskActionInternalType;
    _taitR?: TaskActionInternalType;
    _items?: ModelTemplate<TaskChecklistItem>[];
    _itemsOld?: ModelTemplate<TaskChecklistItem>[];

    constructor(json?: RecursivePartial<TaskActionChecklistEdit>) {
        super(json);
        this.parseTaskActionChecklistEdit(json || {});
    }

    parse(json: RecursivePartial<TaskActionChecklistEdit>): TaskActionChecklistEdit {
        super.parse(json);
        this.parseTaskActionChecklistEdit(json);
        return this;
    }

    private parseTaskActionChecklistEdit(json: RecursivePartial<TaskActionChecklistEdit>): void {
        TaskActionChecklistEdit.assignFields<TaskActionChecklistEdit>(this, json, [
            'revision',
            'required',
            'requiredOld',
            'items',
            'itemsOld',
            'state',
            'stateOld'
        ]);
        this._tait = this.itemsOld ? (this.items ? TaskActionInternalType.Change : TaskActionInternalType.Delete) : TaskActionInternalType.Add;
        this._taitR = this.requiredOld !== undefined ? (this.required !== undefined ? TaskActionInternalType.Change : TaskActionInternalType.Delete) : TaskActionInternalType.Add;
        if (this.items?.length && this.state?.length && this.items.length == this.state.length) {
            this._items = this.items.map((v, i) => ({ title: v, checked: this.state?.[i] }));
        }
        if (this.itemsOld?.length && this.stateOld?.length && this.itemsOld.length == this.stateOld.length) {
            this._itemsOld = this.itemsOld.map((v, i) => ({ title: v, checked: this.state?.[i] }));
        }
    }

}
