import { BaseModel, RecursivePartial } from '@models/base';

export enum ChatEventAction {
    MemberJoin = 1,
    MemberLeave = 2,
    ChatFinished = 3,
    VideoInvite = 4,
    MemberKick = 5,
}

export class ChatEvent extends BaseModel {

    action?: ChatEventAction;
    data?: any;

    constructor(json?: RecursivePartial<ChatEvent>) {
        super(json);
        this.parseChatEvent(json || {});
    }

    parse(json: RecursivePartial<ChatEvent>): ChatEvent {
        super.parse(json);
        this.parseChatEvent(json);
        return this;
    }

    private parseChatEvent(json: RecursivePartial<ChatEvent>): void {
        ChatEvent.assignFields<ChatEvent>(this, json, ['action', 'data']);
    }
}
