import { BaseModel, RecursivePartial } from '@models/base';

export class OrgsTasksStats extends BaseModel {
    orgId?: string;
    numTasks: number = 0;

    constructor(json?: RecursivePartial<OrgsTasksStats>) {
        super(json);
        this.parseOrgsTasksStats(json || {});
    }

    parse(json: RecursivePartial<OrgsTasksStats>): OrgsTasksStats {
        super.parse(json);
        this.parseOrgsTasksStats(json);
        return this;
    }

    private parseOrgsTasksStats(json: RecursivePartial<OrgsTasksStats>): void {
        OrgsTasksStats.assignFields<OrgsTasksStats>(this, json, [
            'orgId',
            'numTasks',
        ]);
    }

}
