import { BaseModel, RecursivePartial } from '@models/base';
import { SYSTEM_USER_ID } from '@models/shared';
import { UserNotificationSettingsPart } from './user-notification-settings-part';

export class UserNotificationSettings extends BaseModel {
    pluginId: string = SYSTEM_USER_ID;
    parts: UserNotificationSettingsPart[] = [];

    constructor(json?: RecursivePartial<UserNotificationSettings>) {
        super(json);
        this.parseUserNotificationSettings(json || {});
    }

    parse(json: RecursivePartial<UserNotificationSettings>): UserNotificationSettings {
        super.parse(json);
        this.parseUserNotificationSettings(json);
        return this;
    }

    private parseUserNotificationSettings(json: RecursivePartial<UserNotificationSettings>): void {
        UserNotificationSettings.assignFields<UserNotificationSettings>(this, json, ['pluginId']);
        UserNotificationSettings.assignClassArrays<UserNotificationSettings>(this, json, { parts: UserNotificationSettingsPart });
    }

}
