import { BaseSystemModel, RecursivePartial } from '@models/base';

export enum OrgInvoiceType {
    UserIssued = 0,
    AdminIssued = 1,
}

export class OrgInvoice extends BaseSystemModel {
    orgId?: string;
    orgName?: string;
    inn?: string;
    paid?: boolean;
    sum: number = 0;
    invoiceType: OrgInvoiceType = OrgInvoiceType.UserIssued;
    note?: string;

    constructor(json?: RecursivePartial<OrgInvoice>) {
        super(json);
        this.parseOrgInvoice(json || {});
    }

    parse(json: RecursivePartial<OrgInvoice>): OrgInvoice {
        super.parse(json);
        this.parseOrgInvoice(json);
        return this;
    }

    private parseOrgInvoice(json: RecursivePartial<OrgInvoice>): void {
        OrgInvoice.assignFields<OrgInvoice>(this, json, [
            'orgId',
            'orgName',
            'inn',
            'paid',
            'sum',
            'invoiceType',
            'note'
        ]);
    }
}
