import { BaseModel, ModelTemplate, RecursivePartial } from '@models/base';
import { VzParamStyles } from './param-styles';
import { VzParamConstraints } from './param-constraints';
import { VzParamTrigger } from './param-trigger';

export enum VzParamType {
    Boolean      = 1,
    String       = 2,
    Integer      = 3,
    Text         = 4,
    ProjectId    = 5,
    GroupId      = 6,
    Group        = 7,  // fake param for groupping other params
    Enum         = 8,
    OrgId        = 9,
    User4AdminId = 10,
    IntRange     = 11,
    Date         = 12,
    Action       = 13, // fake param to provide some interaction, button for example
    Color        = 14,
    JsonArray    = 15, // deprecated, use JsonObject
    JsonObject   = 16,
    ChatId       = 17,
    RichText     = 18,
}

export const VzParamOption = {
    Input:      1,
    Output:     2,
    Array:      4,
}

export interface IVzParam extends ModelTemplate<VzParam> {}
export class VzParam extends BaseModel {
    static Type = VzParamType;
    id!: string;
    type!: VzParamType;
    name?: string;
    description?: string;
    defaultValue?: any;
    constraints?: VzParamConstraints;
    styles?: VzParamStyles;
    enumValues?: number[] | string[];
    enumNames?: string[];
    _enumMap?: { [id: number | string]: string };
    children?: VzParam[];
    triggers?: VzParamTrigger[];
    options?: number = VzParamOption.Input;                    // bitmask of VzParamOption members

    _isInput?: boolean;
    _isOutput?: boolean;
    _isArray?: boolean;

    constructor(json?: RecursivePartial<VzParam>) {
        super(json);
        this.parseTaskAction(json || {});
    }

    parse(json: RecursivePartial<VzParam>): VzParam {
        super.parse(json);
        this.parseTaskAction(json);
        return this;
    }

    private parseTaskAction(json: RecursivePartial<VzParam>): void {
        VzParam.assignFields<VzParam>(this, json, [
            'id',
            'type',
            'name',
            'description',
            'defaultValue',
            'enumValues',
            'enumNames',
            'options'
        ]);
        VzParam.assignClassFields<Omit<VzParam, 'defaultValue'>>(this, json, {
            constraints: VzParamConstraints,
            styles: VzParamStyles
        });
        VzParam.assignClassArrays<Omit<VzParam, 'defaultValue'>>(this, json, {
            children: VzParam,
            triggers: VzParamTrigger
        });
        if (!this._enumMap && this.enumValues) {
            this._enumMap = {};
            for (let i = 0; i < this.enumValues.length; i++) {
                this._enumMap[this.enumValues[i]] = this.enumNames ? this.enumNames[i] : '' + this.enumValues[i];
            }
        }
        if (this.options) {
            if (this.options & VzParamOption.Input) {
                this._isInput = true;
            }
            if (this.options & VzParamOption.Output) {
            }
            if (this.options & VzParamOption.Array) {
                this._isArray = true;
            }
        }
    }

}
