import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { VideoStatus } from '@models/chats';

export class TaskActionVideo extends BaseTaskAction {
    type: TaskActionType.Video = TaskActionType.Video;
    videoId?: string;
    status: VideoStatus = 1;
    statusOld: VideoStatus = 0;

    constructor(json?: RecursivePartial<TaskActionVideo>) {
        super(json);
        this.parseTaskActionVideo(json || {});
    }

    parse(json: RecursivePartial<TaskActionVideo>): TaskActionVideo {
        super.parse(json);
        this.parseTaskActionVideo(json);
        return this;
    }

    private parseTaskActionVideo(json: RecursivePartial<TaskActionVideo>): void {
        TaskActionVideo.assignFields<TaskActionVideo>(this, json, ['videoId', 'status', 'statusOld']);
    }
}
