import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import { Chat, ChatCall, ChatMessage, ChatsState, Guid, SM, Tag, getBestAudioType, getMediaDevices } from '@models';
import { HttpApiError } from '@models/api/http-api-error';
import { ApiService } from './api.service';
import { IObjectEvent, StompService } from './stomp.service';
import { StoreService } from './store.service';

export interface ChatMessageId {
    id: string;
    chatId: string;
    threadId?: string;
    noHighlight?: boolean;
}

export enum ChatVideoState {
    Hidden = 0,
    DockedLeft = 1,
    DockedRight = 2,
    Windowed = 3,
    Minimized = 4,
    Maximized = 5,
    Fullscreen = 6
}

export enum VzCallState {
    None = 0,
    Connecting = 1,
    Connected = 2,
    Closing = 3,
}

@Injectable()
@Tag('ChatService')
export class ChatService extends BaseLogging {

    readonly chat: BehaviorSubject<Chat | undefined> = new BehaviorSubject<Chat | undefined>(undefined);
    readonly threadStart: BehaviorSubject<ChatMessage | undefined> = new BehaviorSubject<ChatMessage | undefined>(undefined);
    readonly go2msg: BehaviorSubject<ChatMessageId | undefined> = new BehaviorSubject<ChatMessageId | undefined>(undefined);
    readonly extras: BehaviorSubject<{ files?: File[] } | undefined> = new BehaviorSubject<{ files?: File[] } | undefined>(undefined);
    readonly threadReadChange: Subject<Guid> = new Subject();

    readonly call: BehaviorSubject<ChatCall | undefined> = new BehaviorSubject<ChatCall | undefined>(undefined);
    readonly callState: BehaviorSubject<VzCallState> = new BehaviorSubject<VzCallState>(VzCallState.None);
    readonly incomingCall: BehaviorSubject<Chat | undefined> = new BehaviorSubject<Chat | undefined>(undefined);
    readonly videoState: BehaviorSubject<ChatVideoState> = new BehaviorSubject<ChatVideoState>(ChatVideoState.Hidden);
    readonly videoDockWidth: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    readonly storedScrollChat: SM<string | undefined> = {};
    readonly storedScrollThread: SM<string | undefined> = {};

    private amt?: string;
    private audioDeviceId?: string;

    constructor(
        private _store: StoreService,
        private _api: ApiService,
        private _stomp: StompService
    ) {
        super();
        this._S.chats = this._store.state('chats').subscribe(st => {
            const chat = this.chat.getValue();
            if (chat?.id && !chat?.taskId) {
                this.chat.next(st.items[chat.id]);
            }
            const ic = this.incomingCall.getValue();
            if (ic?.id) {
                const cc = st.items[ic.id];
                const userId = this._store.getState('user').userId;
                if (!cc || !cc.call || (cc.call.members && userId && cc.call.members.includes(userId))) {
                    this.incomingCall.next(undefined);
                }
            }
        });
        this._S.incomingCall = this._stomp.onIncomingCall.subscribe(e => this.handleIncomingCall(e));
    }

    private _joinVideo(call: ChatCall): void {
        this._L('_joinVideo', call);
        if (call) {
            const oc = this.call.getValue();
            if (!oc || oc.id != call.id) {
                this.call.next(call);
                if (this.videoState.getValue() == ChatVideoState.Hidden) {
                    this.videoState.next(ChatVideoState.DockedRight);
                }
            }
        }
    }

    startVideo(chatId: string, pluginId?: string): Observable<void> {
        this._L('startVideo', chatId, pluginId);
        return new Observable(obs => {
            if (chatId) {
                this._api.videoStart(chatId!).subscribe({
                    next: call => {
                        this._L('startVideo', 'got room:', call);
                        this._joinVideo(new ChatCall({ ...call, chatId, pluginId }));
                        obs.next();
                        obs.complete();
                    },
                    error: err => obs.error(err)
                });
            }
            else {
                obs.error(new HttpApiError('Не удалось подключиться в видеокомнате', 'Id чата пустое'));
            }
        });
    }

    handleIncomingCall(e: IObjectEvent): void {
        const c = this.call.getValue();
        const ic = this.incomingCall.getValue();
        this._L('handleIncomingCall', e, 'call:', c, 'incomingCall:', ic);
        if (!c && !ic && e?.ids?.length) {
            this._api.getChat(e.ids[0]).subscribe({
                next: chat => {
                    if (chat?.id) {
                        const items = this._store.getState('chats').items;
                        items[chat.id] = chat;
                        this._store.setState('chats', new ChatsState({ items }));
                    }
                    this._L('handleIncomingCall', 'chat.call:', chat?.call);
                    if (chat?.call?.id) {
                        this.incomingCall.next(chat);
                    }
                },
                error: err => this._W('handleIncomingCall', 'Cant get chat where incoming call has been started.', err)
            });
        }
    }

    getBestAudioType(): string {
        if (!this.amt) {
            this.amt = getBestAudioType();
        }
        return this.amt;
    }

    getAudioDevice(cb: (audioDeviceId?: string) => void): void {
        // this._L('getAudioDevice', 'audioDeviceId:', this.audioDeviceId);
        if (this.audioDeviceId) {
            cb(this.audioDeviceId);
            return;
        }
        const vs = this._store.getState('video');
        getMediaDevices({ audio: true }).subscribe(devices => {
            // this._L('getAudioDevice', 'getMediaDevices.devices:', devices);
            let aid: string | undefined;
            devices.ai.forEach(d => {
                if (!aid && (
                    (vs.audioDeviceId && vs.audioDeviceId == d.deviceId)
                    || (!vs.audioDeviceId && d.deviceId?.toLocaleLowerCase() == 'default')
                )) {
                    aid = d.deviceId;
                }
            });
            this.audioDeviceId = aid || (devices.ai.length ? devices.ai[0]?.deviceId : undefined);
            cb(this.audioDeviceId);
        });
    }

}
