import { BaseSystemModel, RecursivePartial } from '@models/base';
import { KanbanBoardQuickfilter } from './kanban-board-quickfilter';

export class KanbanBoard extends BaseSystemModel {
    _type = BaseSystemModel.Type.KanbanBoard;

    name: string = 'KB';
    filter?: string;
    sort?: string;
    projectId?: string;
    orgId?: string;
    quickFilters?: KanbanBoardQuickfilter[];
    personal?: boolean;
    showBacklog?: boolean;

    constructor(json?: RecursivePartial<KanbanBoard>) {
        super(json);
        this.parseKanbanBoard(json || {});
    }

    parse(json: RecursivePartial<KanbanBoard>): KanbanBoard {
        super.parse(json);
        this.parseKanbanBoard(json);
        return this;
    }

    private parseKanbanBoard(json: RecursivePartial<KanbanBoard>): void {
        KanbanBoard.assignFields<KanbanBoard>(this, json, ['name', 'filter', 'sort', 'projectId', 'orgId', 'personal', 'showBacklog']);
        KanbanBoard.assignClassArrays<KanbanBoard>(this, json, { quickFilters: KanbanBoardQuickfilter });
        if (this.quickFilters?.length) {
            let i = 1;
            this.quickFilters.forEach(qf => {
                if (!qf.id) {
                    qf.id = '' + (i++)
                }
            });
        }
    }

}
