import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionTagAdd extends BaseTaskAction {
    type: TaskActionType.TagAdd = TaskActionType.TagAdd;
    tagIds: string[] = [];

    constructor(json?: RecursivePartial<TaskActionTagAdd>) {
        super(json);
        this.parseTaskActionFile(json || {});
    }

    parse(json: RecursivePartial<TaskActionTagAdd>): TaskActionTagAdd {
        super.parse(json);
        this.parseTaskActionFile(json);
        return this;
    }

    private parseTaskActionFile(json: RecursivePartial<TaskActionTagAdd>): void {
        TaskActionTagAdd.assignFields<TaskActionTagAdd>(this, json, ['tagIds']);
    }
}
