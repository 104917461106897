import { BaseFilterField, FilterFieldType } from '@models/filters/base-filter-field';
import { Group, Person } from '@models/users';

export class PersonFilterFieldActive extends BaseFilterField<Person, boolean, true> {
    id: string = 'active';
    name: string = 'Активные уч. записи';
    desc: string = 'Скрыть не активные учетные записи';
    isFlag: boolean = true;
    type: FilterFieldType.Flag = FilterFieldType.Flag;
    single = true as const;

    value(o: Person): boolean {
        return !o.deleted && !o.disabled;
    }

    match(o: Person, v: string, data: { activeOrgId?: string }): boolean {
        return o && (!v || o instanceof Group || o._isActive(data.activeOrgId));
    }

}
