import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { Chat } from '@models/chats';

export class ChatsState extends BaseItemsState<Chat> {

    visible: boolean = false;
    activeId?: string;

    constructor(json?: RecursivePartial<ChatsState>) {
        super(json);
        this.parseChatsState(json || {});
    }

    parse(json: RecursivePartial<ChatsState>): ChatsState {
        super.parse(json);
        this.parseChatsState(json);
        return this;
    }

    private parseChatsState(json: RecursivePartial<ChatsState>): void {
        ChatsState.assignFields<ChatsState>(this, json, ['visible', 'activeId']);
        ChatsState.assignClassMaps<ChatsState>(this, json, { items: Chat });
    }

}
