import { TaskApproverState } from '@models/tasks/task-approver';
import { BaseTaskAction, TaskActionType } from './_base-task-action';
import { RecursivePartial } from '@models/base';

export class TaskActionApproverState extends BaseTaskAction {
    type: TaskActionType.ApproverState = TaskActionType.ApproverState;
    userId?: string;
    state: TaskApproverState = TaskApproverState.Unknown;
    stateOld?: TaskApproverState = TaskApproverState.Unknown;

    constructor(json?: RecursivePartial<TaskActionApproverState>) {
        super(json);
        this.parseTaskActionFile(json || {});
    }

    parse(json: RecursivePartial<TaskActionApproverState>): TaskActionApproverState {
        super.parse(json);
        this.parseTaskActionFile(json);
        return this;
    }

    private parseTaskActionFile(json: RecursivePartial<TaskActionApproverState>): void {
        TaskActionApproverState.assignFields<TaskActionApproverState>(this, json, ['userId', 'state', 'stateOld']);
    }
}
