import { Chat } from '@models/chats';
import { VzTag } from '@models/tags';
import { KanbanBoard } from '@models/tasks';
import { UserNotification } from '@models/events';
import { Group, Org, Person, Project, Role, UserOrgStatus } from '@models/users';

import { BoardsState } from './boards-state';
import { ChatsState } from './chats-state';
import { GroupsState } from './groups-state';
import { OrgsState } from './orgs-state';
import { PersonsState } from './persons-state';
import { ProjectsState } from './projects-state';
import { RolesState } from './roles-state';
import { TagsState } from './tags-state';
import { UserNotificationsState } from './user-notifications-state';
import { UrlState } from './url-state';
import { AppLogState } from './app-log-state';
import { UserState } from './user-state';
import { PrefsState } from './prefs-state';
import { StompLogState } from './stomp-log-state';
import { VideoState } from './video-state';
import { CountersState } from './counters-state';
import { OrgStatusState } from './org-status-state';
import { FeedbackState } from './feedback-state';

import { BehaviorSubject } from 'rxjs';

export const StateCacheKeys = ['orgs', 'tags', 'chats', 'roles', 'groups', 'persons', 'projects', 'notifications', 'boards', 'status'] as const; //, 'orgstatustypes'] as const;
export const StateKeys = [...StateCacheKeys, 'url', 'user', 'prefs', 'stomp', 'counters', 'video', 'feedback'] as const;

export type StateCacheKeyType = typeof StateCacheKeys[number];
export type StateCacheType = {
    orgs: OrgsState,
    tags: TagsState,
    chats: ChatsState,
    roles: RolesState,
    boards: BoardsState,
    groups: GroupsState,
    status: OrgStatusState,
    persons: PersonsState,
    projects: ProjectsState,
    notifications: UserNotificationsState,
    // orgstatustypes: OrgStatusTypesState,
};
export type StateCacheItemType = {
    orgs: Org,
    tags: VzTag,
    chats: Chat,
    roles: Role,
    boards: KanbanBoard,
    groups: Group,
    status: UserOrgStatus,
    persons: Person,
    projects: Project,
    notifications: UserNotification,
    // orgstatustypes: UserOrgStatusType
}
export type StateCacheMapsType = { [T in StateCacheKeyType]?: { [id: string]: StateCacheItemType[T] } };
export type StateCacheArrayType = { [T in StateCacheKeyType]?: StateCacheItemType[T][] };

export type StateKeyType = typeof StateKeys[number];
export type StatesType = StateCacheType & {
    url: UrlState,
    log: AppLogState,
    user: UserState,
    prefs: PrefsState,
    stomp: StompLogState,
    video: VideoState,
    counters: CountersState,
    feedback: FeedbackState,
};
export type StatesMapType = { [T in StateKeyType]: BehaviorSubject<StatesType[T]> };

export const newStateInstance: { [T in StateKeyType]: (v: any) => StatesType[T] } = {
    url: v => new UrlState(v),
    user: v => new UserState(v),
    orgs: v => new OrgsState(v),
    tags: v => new TagsState(v),
    chats: v => new ChatsState(v),
    roles: v => new RolesState(v),
    prefs: v => new PrefsState(v),
    stomp: v => new StompLogState(v),
    video: v => new VideoState(v),
    boards: v => new BoardsState(v),
    groups: v => new GroupsState(v),
    status: v => new OrgStatusState(v),
    persons: v => new PersonsState(v),
    projects: v => new ProjectsState(v),
    counters: v => new CountersState(v),
    feedback: v => new FeedbackState(v),
    notifications: v => new UserNotificationsState(v),
    // orgstatustypes: v => new OrgStatusTypesState(v),
};

export type StateFilterType = { [T in StateCacheKeyType]?: (item: StateCacheItemType[T]) => boolean };
export function stateOrgFilter(oid: string): StateFilterType {
    return {
        orgs: item => item.id == oid,
        tags: item => item.orgId == oid,
        chats: item => item.orgId == oid,
        roles: item => item.orgId == oid,
        boards: item => item.orgId == oid,
        groups: item => item.orgId == oid,
        status: item => item.orgId == oid,
        persons: item => !!item._orgMap[oid],
        projects: item => item.orgId == oid,
        notifications: () => true,
        // orgstatustypes:  item => item.orgId == oid,
    };
}
export function stateOrgFilterFn(oidFn: () => string): StateFilterType {
    return {
        orgs: item => item.id == oidFn(),
        tags: item => item.orgId == oidFn(),
        chats: item => item.orgId == oidFn(),
        roles: item => item.orgId == oidFn(),
        boards: item => item.orgId == oidFn(),
        groups: item => item.orgId == oidFn(),
        status: item => item.orgId == oidFn(),
        persons: item => !!item._orgMap[oidFn()],
        projects: item => item.orgId == oidFn(),
        notifications: () => true,
        // orgstatustypes:  item => item.orgId == oidFn(),
    };
}

export type FilterType<T extends StateKeyType, K> = (state: StatesType[T]) => K;
