import { BaseModel, RecursivePartial } from '@models/base';

export class TaskChecklistItem extends BaseModel {
    title: string = '';
    checked?: boolean;

    constructor(json?: RecursivePartial<TaskChecklistItem>) {
        super(json);
        this.parseTaskChecklistItem(json || {});
    }

    parse(json: RecursivePartial<TaskChecklistItem>): TaskChecklistItem {
        super.parse(json);
        this.parseTaskChecklistItem(json);
        return this;
    }

    private parseTaskChecklistItem(json: RecursivePartial<TaskChecklistItem>): void {
        TaskChecklistItem.assignFields<TaskChecklistItem>(this, json, ['title', 'checked']);
    }

}
