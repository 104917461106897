import { BaseModel, RecursivePartial } from "@models/base";

export enum VzParamTriggerActionType {
    HideParam      = 1,
    ShowParam      = 2,
    SetValue       = 3,
    ArrayNextOrAdd = 4,
}

export class VzParamTriggerAction extends BaseModel {
    static Type = VzParamTriggerActionType;

    type!: VzParamTriggerActionType;
    paramId?: string;
    value?: any;

    constructor(json?: RecursivePartial<VzParamTriggerAction>) {
        super(json);
        this.parseUnreadCounters(json || {});
    }

    parse(json: RecursivePartial<VzParamTriggerAction>): VzParamTriggerAction {
        super.parse(json);
        this.parseUnreadCounters(json);
        return this;
    }

    private parseUnreadCounters(json: RecursivePartial<VzParamTriggerAction>): void {
        VzParamTriggerAction.assignFields<VzParamTriggerAction>(this, json, [
            'type',
            'paramId',
            'value'
        ]);
    }
}
