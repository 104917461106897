import { BaseModel, Guid, RecursivePartial } from '@models/base';

export class ChatInvitation extends BaseModel {

    id?: Guid;
    firstName!: string;
    lastName?: string;
    description?: string;
    email?: string;
    personId?: Guid;

    constructor(json?: RecursivePartial<ChatInvitation>) {
        super(json);
        this.parseChatInvitation(json || {});
    }

    parse(json: RecursivePartial<ChatInvitation>): ChatInvitation {
        super.parse(json);
        this.parseChatInvitation(json);
        return this;
    }

    private parseChatInvitation(json: RecursivePartial<ChatInvitation>): void {
        ChatInvitation.assignFields<ChatInvitation>(this, json, ['id', 'firstName', 'lastName', 'description', 'email', 'personId']);
    }
}
