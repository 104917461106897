import { BaseModel, RecursivePartial } from '@models/base';
import { SiteConfigFormField } from './site-config-form-field';
import { SiteConfigFormButton } from './site-config-form-button';
import { SiteConfigCaptcha } from './site-config-captcha';

export class SiteConfigAuth extends BaseModel {
    nameField?: SiteConfigFormField;            // Имя
    emailField?: SiteConfigFormField;           // E-Mail
    phoneField?: SiteConfigFormField;           // Телефон
    messageField?: SiteConfigFormField;         // Сообщение

    additionalFields?: SiteConfigFormField[];
    captcha?: SiteConfigCaptcha;

    chatButton?: SiteConfigFormButton;          // Начать чат
    videoButton?: SiteConfigFormButton;         // Запросить видеовстречу

    verifyEmail?: boolean;                      // true
    verifyEmailTitle?: string;                  // Введите код из письма отправленного вам.

    verifyCodeButtonTitle?: string;                  // Проверить код

    constructor(json?: RecursivePartial<SiteConfigAuth>) {
        super(json);
        this.parseSiteConfigAuth(json || {});
    }

    parse(json: RecursivePartial<SiteConfigAuth>): SiteConfigAuth {
        super.parse(json);
        this.parseSiteConfigAuth(json);
        return this;
    }

    private parseSiteConfigAuth(json: RecursivePartial<SiteConfigAuth>): void {
        SiteConfigAuth.assignFields<SiteConfigAuth>(this, json, [
            'verifyEmail',
            'verifyEmailTitle',
            'verifyCodeButtonTitle',
        ]);
        SiteConfigAuth.assignClassFields<SiteConfigAuth>(this, json, {
            nameField: SiteConfigFormField,
            emailField: SiteConfigFormField,
            phoneField: SiteConfigFormField,
            messageField: SiteConfigFormField,
            chatButton: SiteConfigFormButton,
            videoButton: SiteConfigFormButton,
            captcha: SiteConfigCaptcha,
        });
        SiteConfigAuth.assignClassArrays<SiteConfigAuth>(this, json, {
            additionalFields: SiteConfigFormField
        });
    }
}
