import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionSpentHours extends BaseTaskAction {
    type: TaskActionType.SpentHours = TaskActionType.SpentHours;
    spentHours: number = 0;
    spentHoursOld: number = 0;

    constructor(json?: RecursivePartial<TaskActionSpentHours>) {
        super(json);
        this.parseTaskActionSpentHours(json || {});
    }

    parse(json: RecursivePartial<TaskActionSpentHours>): TaskActionSpentHours {
        super.parse(json);
        this.parseTaskActionSpentHours(json);
        return this;
    }

    private parseTaskActionSpentHours(json: RecursivePartial<TaskActionSpentHours>): void {
        TaskActionSpentHours.assignFields<TaskActionSpentHours>(this, json, ['spentHours', 'spentHoursOld']);
    }
}
