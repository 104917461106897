import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { VzTag } from '@models/tags';

export class TagsState extends BaseItemsState<VzTag> {

    constructor(json?: RecursivePartial<TagsState>) {
        super(json);
        this.parseTagsState(json || {});
    }

    parse(json: RecursivePartial<TagsState>): TagsState {
        super.parse(json);
        this.parseTagsState(json);
        return this;
    }

    private parseTagsState(json: RecursivePartial<TagsState>): void {
        TagsState.assignClassMaps<TagsState>(this, json, { items: VzTag });
    }

}
