import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { HttpApiError } from '@models/api/http-api-error';
import { StoreService } from '@services/store.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private _injector: Injector) { }

    sending: boolean = false;

    handleError(error: Error): void {
        if (
            this.sending
            || !error
            || error instanceof HttpApiError
        ) {
            return;
        }
        if (error.message && (error.message as string).startsWith('Uncaught (in promise): ChunkLoadError')) {
            console.warn('[AppErrorHandler]', 'Uncaught (in promise): ChunkLoadError, reload');
            window.location.reload();
            return;
        }
        console.warn('[AppErrorHandler]', error);

        const store = this._injector.get<StoreService>(StoreService);
        store.crashReport.next({ title: error?.name, text: error?.message, stack: error.stack, dt: new Date() });
        this.sending = true;
        // sendBugReport(error);
    }
}
