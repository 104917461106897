import { BaseModel, RecursivePartial } from "../base";

export class MentionsCounters extends BaseModel {
    unread: number = 0;

    constructor(json?: RecursivePartial<MentionsCounters>) {
        super(json);
        this.parseMentionsCounters(json || {});
    }

    parse(json: RecursivePartial<MentionsCounters>): MentionsCounters {
        super.parse(json);
        this.parseMentionsCounters(json);
        return this;
    }

    private parseMentionsCounters(json: RecursivePartial<MentionsCounters>): void {
        MentionsCounters.assignFields<MentionsCounters>(this, json, [
            'unread',
        ]);
    }
}
