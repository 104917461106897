<div class="contact-drawer-root layout flex vertical">
    <form nz-form [formGroup]="form" (ngSubmit)="submit()">
        <nz-form-item>
            <nz-form-control nzErrorTip="Пожалуйста выберите категорию">
                <nz-select nzShowSearch formControlName="type" nzPlaceHolder="Категория" (ngModelChange)="selectedType = $event; form.get('subType')?.reset()">
                    @for (ct of CTL; track ct) {
                        <nz-option [nzValue]="ct" [nzLabel]="CTN[ct]"></nz-option>
                    }
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        @if (selectedType) {
            <nz-form-item>
                <nz-form-control nzErrorTip="Пожалуйста выберите подкатегорию">
                    <nz-select nzShowSearch formControlName="subType" nzPlaceHolder="Подкатегория">
                        @for (st of CSM[selectedType]; track st) {
                            <nz-option [nzValue]="st" [nzLabel]="CSTN[st]"></nz-option>
                        }
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        }
        <nz-form-item>
            <nz-form-control nzErrorTip="Поле не может быть пустым">
                <nz-input-group>
                    <input type="text" nz-input formControlName="value" aFocus />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <button nz-button nzType="primary" [nzLoading]="working">{{ contactId == 'new' ? 'Добавить' : 'Сохранить' }}</button>
    </form>
    <button nz-button nzType="dashed" nzGhost nzDanger class="mt2 self-end" (click)="deleteContact()">
        Удалить этот способ связи
    </button>
</div>

@if (error) {
    <vz-error-message [error]="error" (dismiss)="error = undefined"></vz-error-message>
}
