import { BaseModel, RecursivePartial } from "../base";

export class TasksCounters extends BaseModel {
    sprint: number = 0;
    backlog: number = 0;
    outbox: number = 0;
    closed: number = 0;
    inboxAll: number = 0;
    inboxUnread: number = 0;
    favoriteAll: number = 0;
    favoriteUnread: number = 0;
    inprogressAll: number = 0;
    inprogressUnread: number = 0;
    supportAll: number = 0;
    supportUnread: number = 0;

    constructor(json?: RecursivePartial<TasksCounters>) {
        super(json);
        this.parseUnreadCounters(json || {});
    }

    parse(json: RecursivePartial<TasksCounters>): TasksCounters {
        super.parse(json);
        this.parseUnreadCounters(json);
        return this;
    }

    private parseUnreadCounters(json: RecursivePartial<TasksCounters>): void {
        TasksCounters.assignFields<TasksCounters>(this, json, [
            'sprint',
            'backlog',
            'outbox',
            'closed',
            'inboxAll',
            'inboxUnread',
            'favoriteAll',
            'favoriteUnread',
            'inprogressAll',
            'inprogressUnread',
            'supportAll',
            'supportUnread'
        ]);
    }
}
