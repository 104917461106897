import { CodeSnippet } from '@models/shared';
import { BaseTaskAction, TaskActionInternalType, TaskActionType } from './_base-task-action';
import { RecursivePartial } from '@models/base';

export class TaskActionText extends BaseTaskAction {
    type: TaskActionType.Text = TaskActionType.Text;
    text?: string;
    textOld?: string;
    snippets?: CodeSnippet[];
    snippetsOld?: CodeSnippet[];

    _tait?: TaskActionInternalType;

    constructor(json?: RecursivePartial<TaskActionText>) {
        super(json);
        this.parseTaskActionText(json || {});
    }

    parse(json: RecursivePartial<TaskActionText>): TaskActionText {
        super.parse(json);
        this.parseTaskActionText(json);
        return this;
    }

    private parseTaskActionText(json: RecursivePartial<TaskActionText>): void {
        TaskActionText.assignFields<TaskActionText>(this, json, ['text', 'textOld']);
        TaskActionText.assignClassArrays<TaskActionText>(this, json, { snippets: CodeSnippet, snippetsOld: CodeSnippet });
        this._tait = this.textOld || this.snippetsOld?.length ? (this.text || this.snippets?.length ? TaskActionInternalType.Change: TaskActionInternalType.Delete) : TaskActionInternalType.Add;
    }
}
