import { BaseFilter, FILTER_DATA_KEY } from './base-filter';
import { Group, Org, Person } from '@models/users';
import { SM } from '@models/base';
import {
    PersonFilterFieldDefault, PersonFilterFieldCreated, PersonFilterFieldActive,
} from './person';
import { getDateRanges } from '@models/utils/datetime';

export class PersonFilter extends BaseFilter<Person> {

    DEFAULT_FILTER = '';

    constructor(private opts: {
        getOrgs?: () => SM<Org> | undefined,
        getActiveOrgId?: () => string | undefined,
        hideClients?: boolean,
        defaultFilter?: string
    }) {
        super({
            id: 'Persons',
            name: 'Фильтр контактов',
            fields: [
                new PersonFilterFieldDefault() as any,
                new PersonFilterFieldCreated(),
                new PersonFilterFieldActive(),
                // !opts.hideClients ? new PersonFilterFieldDeleted() : undefined,
                // !opts.hideClients ? new PersonFilterFieldPluginOwned() : undefined,
                // !opts.hideClients ? new PersonFilterFieldNonClients() : undefined,
            ]
        });
        this.DEFAULT_FILTER = opts.defaultFilter == null ? (opts.hideClients ? 'active' : '-del -temp') : opts.defaultFilter;
        this.visibleTemplates = opts.hideClients ? 3 : 3;
    }

    match(o: Person, values: SM<string | string[]>): boolean {
        if (!this.fields || this.fields.length == 0) {
            return true;
        }
        if (this.opts.hideClients && (!o?.isUser && !(o instanceof Group))) {
            return false;
        }
        if (!values) {
            return true;
        }
        const ev: SM<boolean> = {};
        for (const k of Object.keys(values)) {
            if (k == FILTER_DATA_KEY) {
                continue;
            }
            const v = values[k];
            if (!k || !this.fieldsMap[k]) {
                return false;
            }
            ev[k] = true;
            if (!this.fieldsMap[k].match(o, v, { orgs: this.opts.getOrgs ? this.opts.getOrgs() : undefined, ranges: getDateRanges(), activeOrgId: this.opts.getActiveOrgId?.() })) {
                return false;
            }
        }
        for (const f of this.fields) {
            if (f?.id && !ev[f.id]) {
                if (!this.fieldsMap[f.id].match(o, undefined, { orgs: this.opts.getOrgs ? this.opts.getOrgs() : undefined, ranges: getDateRanges(), activeOrgId: this.opts.getActiveOrgId?.() })) {
                    return false;
                }
            }
        }
        return true;
    }

}
