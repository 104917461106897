import { BaseSystemModel, RecursivePartial } from '@models/base';
import { SiteConfig } from './site-config';

export interface ISiteTpl {
    name?: string;
    avatar?: string;
    orgId?: string;
    refs?: string[];
    projectId?: string;
    assignee?: string;
    assigneeGroup?: string;
    siteConfig?: SiteConfig;
}

export class Site extends BaseSystemModel {
    name?: string;
    avatar?: string;
    orgId?: string;
    refs?: string[];
    projectId?: string;
    assignee?: string;
    assigneeGroup?: string;
    siteConfig?: SiteConfig;

    constructor(json?: RecursivePartial<Site>) {
        super(json);
        this.parseSite(json || {});
    }

    parse(json: RecursivePartial<Site>): Site {
        super.parse(json);
        this.parseSite(json);
        return this;
    }

    private parseSite(json: RecursivePartial<Site>): void {
        Site.assignFields<Site>(this, json, ['name', 'avatar', 'orgId', 'refs', 'projectId', 'assignee', 'assigneeGroup']);
        Site.assignClassFields<Site>(this, json, { siteConfig: SiteConfig });
    }
}
