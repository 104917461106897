import { OLD_BaseSystemModel, RecursivePartial } from '@models/base';

export interface IGroupTpl {
    name?: string;
    avatar?: string;
    orgId?: string;
}

export class Group extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.Group;

    name?: string;
    avatar?: string;
    orgId?: string;
    roleIds: string[] = [];

    constructor(json?: RecursivePartial<Group>) {
        super(json);
        this.parseGroup(json || {});
    }

    parse(json: RecursivePartial<Group>): Group {
        super.parse(json);
        this.parseGroup(json);
        return this;
    }

    private parseGroup(json: RecursivePartial<Group>): void {
        Group.assignFields<Group>(this, json, ['name', 'avatar', 'orgId', 'roleIds']);
    }
}
