import { BaseModel, RecursivePartial } from '@models/base';
import { PersonOrgDetailsProject } from './person-org-details-project';
import { PersonType } from './person-type';

export class PersonOrgDetails extends BaseModel {
    id!: string;
    isOwner?: boolean;
    fired?: boolean;
    roleIds: string[] = [];
    groupIds: string[] = [];
    projectIds: string[] = [];
    projects: PersonOrgDetailsProject[] = [];
    personType!: PersonType;
    departmentId!: string;
    privs?: string[];

    constructor(json?: RecursivePartial<PersonOrgDetails>) {
        super(json);
        this.parsePersonOrgDetails(json || {});
    }

    parse(json: RecursivePartial<PersonOrgDetails>): PersonOrgDetails {
        super.parse(json);
        this.parsePersonOrgDetails(json);
        return this;
    }

    private parsePersonOrgDetails(json: RecursivePartial<PersonOrgDetails>): void {
        PersonOrgDetails.assignFields<PersonOrgDetails>(this, json, ['id', 'isOwner', 'fired', 'roleIds', 'groupIds', 'projectIds', 'departmentId', 'privs']);
        PersonOrgDetails.assignClassArrays<PersonOrgDetails>(this, json, { projects: PersonOrgDetailsProject });
        this.projectIds = this.projects.map(p => p.id);
    }
}
