import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

import { LayoutTplComponent } from '@shared/_views/layout-tpl';
import { LoadingModule } from '@shared/_utils/loading.module';
import { BaseComponent } from '@base/base.component';
import {
    Tag, Task, Point, SYSTEM_USER, VzTag, LayoutElement, createLayoutElement, TTaskCardName, TaskCardsMap
} from '@models';
import { ApiService, StoreService } from '@services';
import { isPast, isToday, isAfter, isSameDay } from 'date-fns';

@Component({
    selector: 'vz-task-card, [vz-task-card]',
    templateUrl: './task.component.html',
    styleUrls: ['./task.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        NzButtonModule, NzCheckboxModule, NzToolTipModule, NzDropDownModule,
        LoadingModule, LayoutTplComponent
    ],
})
@Tag('TaskCardComponent')
export class TaskCardComponent extends BaseComponent implements OnChanges {

    _hostVertical = true;

    @HostBinding('style.--vzt-project-color') projectColor?: string;
    @HostBinding('style.--vzt-priority-color') priorityColor?: string;
    // @HostBinding('class.bg-t-error') bgErr?: boolean;
    // @HostBinding('class.bg-t-warn') bgWarn?: boolean;
    // @HostBinding('class.bg') bgNorm?: boolean;

    @Input() task?: Task;
    @Input() taskId?: string;
    @Input() taskCardName?: TTaskCardName;

    dueToday: boolean = false;
    dueExpired: boolean = false;
    tStart?: Point;
    tDirection: number = 0;
    tPercent: number = 0;
    swiped: boolean = false;
    workingSetConditions: boolean = false;
    workingSetPriority: boolean = false;
    priorityMenuShow: boolean = false;
    tags?: VzTag[];
    cardTpl?: LayoutElement;

    constructor(
        protected _store: StoreService,
        private _cdr: ChangeDetectorRef,
        private _api: ApiService
    ) {
        super(_store);
    }

    ngOnChanges(_changes: SimpleChanges): void {
        if (this.taskCardName) {
            this.cardTpl = createLayoutElement(TaskCardsMap[this.taskCardName]);
        }
        else {
            const cn = this._store.getState('prefs').kanbanCard || 'clear';
            this.cardTpl =createLayoutElement(TaskCardsMap[cn]);
        }
        if (this.task) {
            this.updateTaskView();
        }
        else if (this.taskId) {
            this.error = undefined;
            this.__loading(
                this._store.getTempObject<Task>(this.taskId, { prefix: `${this.activeOrgId}|`, fn: id => this._api.getTask(id, this.activeOrgId), ttl: 300 }),
                { cdr: this._cdr }
            ).subscribe({
                next: task => {
                    this.task = task;
                    this.updateTaskView();
                },
                error: err => {
                    this.error = err;
                }
            });
        }
    }

    updateTaskView(): void {
        this.dueExpired = false;
        this.dueToday = false;
        this.swiped = false;
        this.workingSetConditions = false;
        this.tags = undefined;
        if (this.task?.tags) {
            const tm = this._store.getState('tags').items;
            this.tags = this.task.tags.map(id => tm[id]).filter(t => t?.createdBy == SYSTEM_USER.id);
        }
        if (this.task && this.task.dueDate) {
            if (this.task.archived) {
                if (this.task.modified) {
                    this.dueExpired = this.task.dueDate && isAfter(this.task.modified , this.task.dueDate );
                    this.dueToday = this.task.dueDate && isSameDay(this.task.modified , this.task.dueDate );
                }
            }
            else {
                this.dueExpired = !!this.task.dueDate && isPast(this.task.dueDate );
                this.dueToday = !!this.task.dueDate && isToday(this.task.dueDate );
            }
        }
        this.projectColor = this.task?._color;
        this.priorityColor = `var(--vz-priorityColor${this.task?.priority || 0})`;
        // this.bgErr = this.dueExpired && !this.dueToday;
        // this.bgWarn = this.dueToday;
        // this.bgNorm = !this.dueExpired && !this.dueToday;
        this._cdr.detectChanges();
    }

}
