import { RecursivePartial } from '../base';
import { BaseItemsState } from './base-items-state';
import { Person } from '@models/users';

export class PersonsState extends BaseItemsState<Person> {

    constructor(json?: RecursivePartial<PersonsState>) {
        super(json);
        this.parsePersonsState(json || {});
    }

    parse(json: RecursivePartial<PersonsState>): PersonsState {
        super.parse(json);
        this.parsePersonsState(json);
        return this;
    }

    private parsePersonsState(json: RecursivePartial<PersonsState>): void {
        PersonsState.assignClassMaps<PersonsState>(this, json, { items: Person });
    }

}
