import { NM, RecursivePartial, SM } from '@models/base';
import { FilterTemplate } from '@models/filters';
import { TTaskCardName, TaskActionType, TaskTemplate } from '@models/tasks';
import { BaseState } from './base-state';

export class PrefsState extends BaseState {

    _VER = 11;

    lsmCollapsed: boolean = false;
    lsmShow: SM<boolean> = {
        'favorites': true,
        'inprogress': true,
        'outgoing': true,
        'closed': true,
        'sprint': true,
        'backlog': true,
        'download': true,
        'kanban': true,
        'telegram': true,
    };
    tempInfoHidden: SM<boolean> = {};
    tasksLPSize: number = 320;
    defaultOrgId?: string;
    menuWidth: number = 250;
    menuChatsListOpened: boolean = true;
    menuDirectChatsListOpened: boolean = true;
    menuPluginChatsListOpened: boolean = true;
    menuTasksOpened: boolean = true;
    favoriteEmojis: SM<number> = {
        '1F44D': 1,
        '1F44E': 1,
        '1F64F': 1,
        '1F440': 1,
        '1F600': 1,
        '1F355': 1,
        '1F37A': 1,
        '1F3C5': 1,
        '2714': 1,
        '274C': 1
    };
    orgBg: SM<string> =  {};
    filterTemplates: FilterTemplate[] = [];
    orgHideFileStats: boolean = false;

    // Video
    videoDockWidth: number = 600;

    // Tasks
    taskShowAll: boolean = false;
    taskShowFilter: NM<boolean> = {
        [TaskActionType.Note]: true,
        [TaskActionType.CreateChat]: true,
        [TaskActionType.FileAttach]: true,
        [TaskActionType.Video]: true,
    };
    taskLastProjectId: SM<string> = {};
    taskTemplates: TaskTemplate[] = [];
    taskShowNewFullscreen: boolean = false;
    taskShowFullscreen: boolean = false;
    taskShowWidth: number = 1000;
    taskTextHeight: number = 500;
    kanbanCard?: TTaskCardName = 'compact';
    kanbanVis: SM<string[]> = {};
    menuKanbanOpened: boolean = true;

    // Chat
    chatShowUrlPreview: boolean = true;
    chatShowUrlPreviewImg: boolean = true;
    chatShowFilePreview: boolean = true;

    // Editor
    editorToolbarShow: SM<boolean> = { _: true };
    editorSendByEnter: SM<boolean> = { _: true, newTask: false, taskAddNote: false, taskTextEdit: false, chatAddComment: true };

    // Tables
    tableResizePolicy: string = 'msword';

    kanbanTasksSort: string[] = ['favorite', '-isRead', 'status', 'priority', '-modified'];

    inboxTasksSort: string[] = ['favorite', '-isRead', 'status', 'priority', '-modified'];
    inboxTasksColsOrder: string[] = ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'];
    inboxTasksColsWidth: SM<number> = { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 };
    inboxTasksColsVis: SM<boolean> = { priority: true, createdBy: true, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true };

    favoritesTasksSort: string[] = ['favorite', '-isRead', 'status', 'priority', '-modified'];
    favoritesTasksColsOrder: string[] = ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'];
    favoritesTasksColsWidth: SM<number> = { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 };
    favoritesTasksColsVis: SM<boolean> = { priority: true, createdBy: true, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true };

    inprogressTasksSort: string[] = ['favorite', '-isRead', 'priority', '-modified'];
    inprogressTasksColsOrder: string[] = ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'];
    inprogressTasksColsWidth: SM<number> = { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 };
    inprogressTasksColsVis: SM<boolean> = { priority: true, createdBy: true, created: true, status: false, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true };

    outgoingTasksSort: string[] = ['favorite', '-isRead', 'status', 'priority', '-modified'];
    outgoingTasksColsOrder: string[] = ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'];
    outgoingTasksColsWidth: SM<number> = { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 };
    outgoingTasksColsVis: SM<boolean> = { priority: true, createdBy: false, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true };

    closedTasksSort: string[] = ['favorite', '-isRead', 'priority', '-modified'];
    closedTasksColsOrder: string[] = ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'];
    closedTasksColsWidth: SM<number> = { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 };
    closedTasksColsVis: SM<boolean> = { priority: true, createdBy: true, created: true, status: false, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true };

    sprintTasksSort: string[] = ['favorite', '-isRead', 'status', 'priority', '-modified'];
    sprintTasksColsOrder: string[] = ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'];
    sprintTasksColsWidth: SM<number> = { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 };
    sprintTasksColsVis: SM<boolean> = { priority: true, createdBy: true, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: true, project: true, modified: true, tags: true };

    backlogTasksSort: string[] = ['favorite', '-isRead', 'status', 'priority', '-modified'];
    backlogTasksColsOrder: string[] = ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'];
    backlogTasksColsWidth: SM<number> = { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 };
    backlogTasksColsVis: SM<boolean> = { priority: true, createdBy: true, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: true, project: true, modified: true, tags: true };

    supportTasksSort: string[] = ['-isRead', 'status', '-created'];
    supportTasksColsOrder: string[] = ['created', 'status', 'shortId', 'subj'];
    supportTasksColsWidth: SM<number> = { created: 130, status: 120, subj: 400, shortId: 140 };
    supportTasksColsVis: SM<boolean> = { created: true, status: true, subj: true, shortId: true };

    chatsSort: string[] = ['status', '-modified'];
    taskSelectDrawerSort: string[] = ['favorite', '-isRead', 'status', 'priority', '-modified'];

    showFilterPanel: boolean = true;
    grouppedUsers: boolean = false;
    hideReleaseNotes: boolean = false;
    hideTips: boolean = false;

    constructor(json?: RecursivePartial<PrefsState>) {
        super(json);
        this.parsePrefsState(json || {});
    }

    parse(json: RecursivePartial<PrefsState>): PrefsState {
        super.parse(json);
        this.parsePrefsState(json);
        return this;
    }

    private parsePrefsState(json: RecursivePartial<PrefsState>): void {
        PrefsState.assignFields<PrefsState>(this, json, [
            'lsmCollapsed',
            'lsmShow',
            'tempInfoHidden',
            'tasksLPSize',
            'defaultOrgId',
            'inboxTasksSort',
            'inboxTasksColsOrder',
            'inboxTasksColsWidth',
            'inboxTasksColsVis',
            'favoritesTasksSort',
            'favoritesTasksColsOrder',
            'favoritesTasksColsWidth',
            'favoritesTasksColsVis',
            'inprogressTasksSort',
            'inprogressTasksColsOrder',
            'inprogressTasksColsWidth',
            'inprogressTasksColsVis',
            'outgoingTasksSort',
            'outgoingTasksColsOrder',
            'outgoingTasksColsWidth',
            'outgoingTasksColsVis',
            'closedTasksSort',
            'closedTasksColsOrder',
            'closedTasksColsWidth',
            'closedTasksColsVis',
            'sprintTasksSort',
            'sprintTasksColsOrder',
            'sprintTasksColsWidth',
            'sprintTasksColsVis',
            'backlogTasksSort',
            'backlogTasksColsOrder',
            'backlogTasksColsWidth',
            'backlogTasksColsVis',
            'supportTasksSort',
            'supportTasksColsOrder',
            'supportTasksColsWidth',
            'supportTasksColsVis',
            'chatsSort',
            'taskSelectDrawerSort',
            'menuWidth',
            'menuChatsListOpened',
            'menuDirectChatsListOpened',
            'menuPluginChatsListOpened',
            'menuTasksOpened',
            'videoDockWidth',
            'favoriteEmojis',
            'orgBg',
            'taskShowAll',
            'taskShowFilter',
            'taskLastProjectId',
            'taskShowNewFullscreen',
            'taskShowFullscreen',
            'taskShowWidth',
            'editorToolbarShow',
            'editorSendByEnter',
            'tableResizePolicy',
            'chatShowUrlPreview',
            'chatShowUrlPreviewImg',
            'chatShowFilePreview',
            'orgHideFileStats',
            'taskTextHeight',
            'showFilterPanel',
            'kanbanCard',
            'kanbanVis',
            'menuKanbanOpened',
            'grouppedUsers',
            'hideReleaseNotes',
            'hideTips'
        ]);
        PrefsState.assignClassArrays<PrefsState>(this, json, {
            filterTemplates: FilterTemplate,
            taskTemplates: TaskTemplate
        });
    }

}

export const PREFS_DEFAULTS: { [K in keyof Partial<PrefsState>]: Partial<PrefsState>[K] } = {
    inboxTasksSort: ['favorite', '-isRead', 'status', 'priority', '-modified'],
    inboxTasksColsOrder: ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'],
    inboxTasksColsWidth: { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 },
    inboxTasksColsVis: { priority: true, createdBy: true, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true },

    favoritesTasksSort: ['favorite', '-isRead', 'status', 'priority', '-modified'],
    favoritesTasksColsOrder: ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'],
    favoritesTasksColsWidth: { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 },
    favoritesTasksColsVis: { priority: true, createdBy: true, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true },

    inprogressTasksSort: ['favorite', '-isRead', 'priority', '-modified'],
    inprogressTasksColsOrder: ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'],
    inprogressTasksColsWidth: { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 },
    inprogressTasksColsVis: { priority: true, createdBy: true, created: true, status: false, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true },

    outgoingTasksSort: ['favorite', '-isRead', 'status', 'priority', '-modified'],
    outgoingTasksColsOrder: ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'],
    outgoingTasksColsWidth: { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 },
    outgoingTasksColsVis: { priority: true, createdBy: false, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true },

    closedTasksSort: ['favorite', '-isRead', 'priority', '-modified'],
    closedTasksColsOrder: ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags', 'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'],
    closedTasksColsWidth: { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 },
    closedTasksColsVis: { priority: true, createdBy: true, created: true, status: false, assignee: true, favorite: true, subj: true, shortId: true, attaches: false, project: false, modified: false, tags: true },

    sprintTasksSort: ['favorite', '-isRead', 'status', 'priority', '-modified'],
    sprintTasksColsOrder: ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags',  'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'],
    sprintTasksColsWidth: { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 },
    sprintTasksColsVis: { priority: true, createdBy: true, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: true, project: true, modified: true, tags: true },

    backlogTasksSort: ['favorite', '-isRead', 'status', 'priority', '-modified'],
    backlogTasksColsOrder: ['favorite', 'priority', 'status', 'shortId', 'subj', 'tags',  'attaches', 'createdBy', 'assignee', 'project', 'created', 'modified'],
    backlogTasksColsWidth: { priority: 32, createdBy: 160, created: 130, status: 45, assignee: 160, favorite: 30, subj: 400, shortId: 90, attaches: 45, project: 120, modified: 130, tags: 300 },
    backlogTasksColsVis: { priority: true, createdBy: true, created: true, status: true, assignee: true, favorite: true, subj: true, shortId: true, attaches: true, project: true, modified: true, tags: true },

    supportTasksSort: ['-isRead', 'status', '-created'],
    supportTasksColsOrder: ['created', 'status', 'shortId', 'subj'],
    supportTasksColsWidth: { created: 130, status: 120, subj: 400, shortId: 140 },
    supportTasksColsVis: { created: true, status: true, subj: true, shortId: true },

    chatsSort: ['status', '-modified'],
    taskSelectDrawerSort: ['favorite', '-isRead', 'status', 'priority', '-modified'],
};

export const KANBAN_DEFAULT_SORT = ['favorite', '-isRead', 'priority', '-modified'];
