import { BaseModel, RecursivePartial } from '@models/base';

export enum ChatCallStatus {
    Created = 1,
    Started = 2,
    Finished = 3,
}

export class ChatCall extends BaseModel {

    id!: string;
    chatId?: string;
    pluginId?: string;
    status?: ChatCallStatus;
    host?: string;
    roomId?: number;
    roomPin?: string;
    token?: string;
    duration?: number;
    members?: string[];
    activeMembers?: string[];

    constructor(json?: RecursivePartial<ChatCall>) {
        super(json);
        this.parseChatCall(json || {});
    }

    parse(json: RecursivePartial<ChatCall>): ChatCall {
        super.parse(json);
        this.parseChatCall(json);
        return this;
    }

    private parseChatCall(json: RecursivePartial<ChatCall>): void {
        ChatCall.assignFields<ChatCall>(this, json, [
            'id',
            'chatId',
            'pluginId',
            'status',
            'host',
            'roomId',
            'roomPin',
            'token',
            'duration',
            'members',
            'activeMembers'
        ]);
    }
}
