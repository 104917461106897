@if (cardTpl && task) {
    <div class="vz-task-card layout vertical bg"
        [class.vz-task-unread]="!task.flags.seen"
        [style.border-radius]="cardTpl.borderRadius">
        <vz-layout-tpl [rootElement]="cardTpl" [templateData]="{ task }" />
    </div>
}
@else {
    <div class="vz-task-card layout center-center bg txt-ll"
        style="border-radius: 4px; height: 100px; min-width: 200px;">
        @if (error) {
            <span class="txt-err p1">Ошибка загрузки данных задачи</span>
        }
        @else if (loading) {
            <vz-loading [show]="true" />
        }
        @else {
            <i class="vzi-3dots r90 fs-3xl"></i>
            <span>{{ loading | json }}</span>
            <small>{{ error | json }}</small>
        }
    </div>
}
