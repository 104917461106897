import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionApprovers extends BaseTaskAction {
    type: TaskActionType.Approvers = TaskActionType.Approvers;
    approvers: string[] = [];
    approversOld?: string[] = [];

    constructor(json?: RecursivePartial<TaskActionApprovers>) {
        super(json);
        this.parseTaskActionFile(json || {});
    }

    parse(json: RecursivePartial<TaskActionApprovers>): TaskActionApprovers {
        super.parse(json);
        this.parseTaskActionFile(json);
        return this;
    }

    private parseTaskActionFile(json: RecursivePartial<TaskActionApprovers>): void {
        TaskActionApprovers.assignFields<TaskActionApprovers>(this, json, ['approvers', 'approversOld']);
    }
}
