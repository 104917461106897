import { BaseModel, RecursivePartial } from '@models/base';

export enum CalendarEventRepeatType {
    Daily = 1,
    Weekly,
    Monthly,
    Yearly
}

export const CalendarEventRepeatTypeName: { [T in CalendarEventRepeatType]: string } = {
    [CalendarEventRepeatType.Daily]: 'день',
    [CalendarEventRepeatType.Weekly]: 'неделю',
    [CalendarEventRepeatType.Monthly]: 'месяц',
    [CalendarEventRepeatType.Yearly]: 'год',
}

export const CALENDAR_LAST_VALUE = 1000;

export class CalendarEventRepeat extends BaseModel {
    type!: CalendarEventRepeatType;
    every: number = 1;
    weekDays?: number[];
    weekDayRepeat?: number;
    monthDays?: number[];

    constructor(json?: RecursivePartial<CalendarEventRepeat>) {
        super(json);
        this.parseCalendarRepeat(json || {});
    }

    parse(json: RecursivePartial<CalendarEventRepeat>): CalendarEventRepeat {
        super.parse(json);
        this.parseCalendarRepeat(json);
        return this;
    }

    private parseCalendarRepeat(json: RecursivePartial<CalendarEventRepeat>): void {
        CalendarEventRepeat.assignFields<CalendarEventRepeat>(this, json, [
            'type', 'every', 'weekDays', 'weekDayRepeat', 'monthDays'
        ]);
    }

}
