import { BaseModel, RecursivePartial, SM } from '@models/base';
import { LayoutElementType } from './layout-element-type';

const styleNames = [
    'padding',
    'margin',
    'borderWidth',
    'borderStyle',
    'borderColor',
    'borderRadius',
    'overflow',
    'color',
    'backgroundColor',
    'opacity',
    'flex',
    'fontSize',
    'fontWeight',
    'boxShadow',
    'position',
    'top',
    'right',
    'bottom',
    'left',
    'minHeight',
    'minWidth',
    'maxHeight',
    'maxWidth',
    'height',
    'width'
] as const;

export class BaseLayoutElement extends BaseModel {
    type!: LayoutElementType;

    padding?: string;
    margin?: string;
    borderWidth?: string;
    borderStyle?: string;
    borderColor?: string;
    borderRadius?: string;
    overflow?: string;
    color?: string;
    backgroundColor?: string;
    opacity?: number;
    flex?: number;
    fontSize?: string;
    fontWeight?: string | number;
    boxShadow?: string;
    absolutePos?: boolean;
    position?: string;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    minHeight?: string;
    minWidth?: string;
    maxHeight?: string;
    maxWidth?: string;
    height?: string;
    width?: string;

    _classes?: SM<boolean>;
    _styles?: SM<string>;

    constructor(json?: RecursivePartial<BaseLayoutElement>) {
        super(json);
        this.parseBaseLayoutElement(json || {});
    }

    parse(json: RecursivePartial<BaseLayoutElement>): BaseLayoutElement {
        super.parse(json);
        this.parseBaseLayoutElement(json);
        return this;
    }

    private parseBaseLayoutElement(json: RecursivePartial<BaseLayoutElement>): void {
        BaseLayoutElement.assignFields<BaseLayoutElement>(this, json, [
            'type',
            ...styleNames as any
        ]);
        this.setCssClasses();
        this.setCssStyles();
    }

    setCssStyles(): void {
        this._styles = {};
        styleNames.forEach(n => {
            if ((this as any)[n]) {
                this._styles![n] = (this as any)[n];
            }
        });
        //     + (this.padding ? ` padding: ${this.padding}px;` : '')
        //     + (this.margin ? ` margin: ${this.margin}px;` : '')
        //     + (this.borderWidth && this.borderStyle && this.borderColor ? ` border: ${this.borderWidth}px ${this.borderStyle} ${this.borderColor};` : '')
        //     + (this.borderRadius ? ` border-radius: ${this.borderRadius}px;` : '')
        //     + (this.overflow ? ` overflow: ${this.overflow};` : '')
        //     + (this.color ? ` color: ${this.color};` : '')
        //     + (this.bgColor ? ` overflow: ${this.bgColor};` : '')
        //     + (this.opacity ? ` overflow: ${this.opacity};` : '')
        //     + (this.flex ? ` flex: ${this.flex};` : '')
        // ;
    }

    setCssClasses(): void {
        this._classes = { 'vz-le': true };
    }

}
