import { HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { ApiError } from './api-error';

export class HttpApiError {
    url: string | null = null;
    method: string | null = null;
    body: any = null;
    guid: string | null = null;
    status: number = 0;
    statusText: string = '';
    error?: ApiError;
    debug?: boolean = environment.env != 'prod';
    type?: string;

    constructor(public title = 'Error', public text = '') { }

    parse(r: HttpResponseBase, method: string | null = null, body: any = null, hideFields: string[] = ['password']): HttpApiError {
        if (r) {
            this.method = method ? method.toUpperCase() : method;
            this.body = { ...body };
            if (hideFields && this.body) {
                hideFields.forEach(f => {
                    if (this.body[f]) {
                        this.body[f] = '<*hidden*>';
                    }
                });
            }
            this.status = r.status;
            this.statusText = r.statusText;
            if ((r.status < 200 || r.status > 299) && r.statusText == 'OK') {
                const error = (r as any).error;
                this.statusText = error && typeof error == 'string' && error.startsWith(r.status + ' ')
                    ? error.substring((r.status + ' ').length)
                    : 'ERROR';
            }
            this.url = r.url;
            if (r.headers) {
                this.guid = r.headers.get('x-vizoro-requestid');
            }
            if (r instanceof HttpErrorResponse) {
                if (r.error && r.error.text != undefined) {
                    this.error = new ApiError(r.status == 200 && !r.ok && r.error.error?.message ? { debug: r.error.error.message } : r.error);
                }
                this.text = r.status > 0 && r.status != 200
                    ? ('' + (r.error?.id && r.error?.text
                        ? r.error.text
                        : (r.error?.error && r.error?.error.message
                            ? r.error.error.message
                            : (typeof r.error == 'string' ? r.error : ''))
                        )
                    )
                    : r.status == 200 ? '' : 'Сервер не отвечает, или соединение было разорвано.';
            }
            else if (this.text == '') {
                this.text = r.ok && r.statusText ? r.statusText : 'Ошибка подключения';
            }
        }
        else {
            this.text = this.text == '' ? 'Внутренняя ошибка приложения.' : this.text;
        }
        return this;
    }

    setTitle(title: string): HttpApiError {
        this.title = title;
        return this;
    }

    setText(text: string): HttpApiError {
        this.text = text;
        return this;
    }

    setType(type: string): HttpApiError {
        this.type = type;
        return this;
    }

}
