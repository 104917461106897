import { Observable, Subscriber } from "rxjs";

export type AvDevices = { ai: MediaDeviceInfo[], ao: MediaDeviceInfo[], vi: MediaDeviceInfo[] };
export function getMediaDevices(constraints?: MediaStreamConstraints): Observable<AvDevices> {
    // console.warn('[utils/media] getMediaDevices');
    return new Observable(obs => {
        if (constraints) {
            navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                stopAllTracks(stream);
                return _getDevices(obs);
            }).catch(_ => {
                obs.next({ ai: [], ao: [], vi: [] });
                obs.complete();
            });
        }
        else {
            navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
                stopAllTracks(stream);
                return _getDevices(obs);
            }).catch(_ => {
                navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                    stopAllTracks(stream);
                    return _getDevices(obs);
                }).catch(_ => {
                    obs.next({ ai: [], ao: [], vi: [] });
                    obs.complete();
                });
            });
        }
    });
}

function _getDevices(obs: Subscriber<AvDevices>): void {
    navigator.mediaDevices.enumerateDevices().then(devices => {
        const ai: MediaDeviceInfo[] = [];
        const ao: MediaDeviceInfo[] = [];
        const vi: MediaDeviceInfo[] = [];
        if (devices) {
            devices.forEach(d => {
                if (d.kind == 'audioinput') {
                    ai.push(d);
                }
                else if (d.kind == 'videoinput') {
                    vi.push(d);
                }
                else if (d.kind == 'audiooutput') {
                    ao.push(d);
                }
            });
        }
        obs.next({ ai, ao, vi });
        obs.complete();
    }).catch(_ => {
        obs.next({ ai: [], ao: [], vi: [] });
        obs.complete();
    });
}

export function stopAllTracks(stream?: MediaStream): void {
    try {
        stream?.getTracks().forEach(tr => tr.stop());
    } catch (_) { }
}

export function getSupportedMimeTypes(media: string, types: string[], codecs: string[]): string[] {
    if (!('MediaRecorder' in window) || !MediaRecorder.isTypeSupported) {
        return [];
    }
    const supported: string[] = [];
    types.forEach(type => {
        const mimeType = `${media}/${type}`;
        codecs.forEach((codec) => [
            `${mimeType};codecs=${codec}`,
            `${mimeType};codecs=${codec.toUpperCase()}`,
        ].forEach(variation => {
            if (MediaRecorder.isTypeSupported(variation))
                supported.push(variation);
        }));
        if (MediaRecorder.isTypeSupported(mimeType))
            supported.push(mimeType);
    });
    return supported;
}

export function getBestAudioType(): string {
    const types = ['mp3', 'ogg', 'webm', 'x-matroska'];
    const codecs = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus', 'pcm', 'aac', 'mpeg', 'mp4a'];
    const mt = getSupportedMimeTypes('audio', types, codecs);
    return mt.length ? mt[0] : 'audio/webm';
}

export function getBestVideoType(): string {
    const types = ['mp4', 'ogg', 'webm', 'x-matroska'];
    const codecs = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus', 'pcm', 'aac', 'mpeg', 'mp4a'];
    const mt = getSupportedMimeTypes('video', types, codecs);
    return mt.length ? mt[0] : 'video/webm';
}
