import { BaseModel, RecursivePartial } from '@models/base';

export class TaskFlags extends BaseModel {
    dueDate?: boolean;
    favorite?: boolean;
    file?: boolean;
    inbox?: boolean;
    note?: boolean;
    priority?: boolean;
    seen?: boolean;
    status?: boolean;
    subj?: boolean;
    text?: boolean;
    tag?: boolean;
    link?: boolean;
    approver?: boolean;
    subtask?: boolean;
    checklist?: boolean;

    constructor(json?: RecursivePartial<TaskFlags>) {
        super(json);
        this.parseTaskFlags(json || {});
    }

    parse(json: RecursivePartial<TaskFlags>): TaskFlags {
        super.parse(json);
        this.parseTaskFlags(json);
        return this;
    }

    private parseTaskFlags(json: RecursivePartial<TaskFlags>): void {
        TaskFlags.assignFields<TaskFlags>(this, json, [
            'dueDate',
            'favorite',
            'file',
            'inbox',
            'note',
            'priority',
            'seen',
            'status',
            'subj',
            'text',
            'tag',
            'link',
            'approver',
            'subtask',
            'checklist'
        ]);
    }

}
