import { BaseModel, RecursivePartial } from '@models/base';
import { SiteConfigFormButton } from './site-config-form-button';

export class SiteConfigCalendar extends BaseModel {
    title?: string;                         // Когда вы хотите посмотреть товар?
    // showNow?: boolean;                      // true
    windowSize?: number;                    // 10 (в минутах)
    startOffset?: number;                   // 120 (в минутах)
    confirmMessage?: string;                // Запросить видеовстречу (сейчас или дата + время)
    finishedMessage?: string;               // Видеовстреча была успешно зарезервирована. Пожалуйста следуйте инструкциям высланным на ваш Email.

    sendButton?: SiteConfigFormButton;      // Отправить запрос
    videoButton?: SiteConfigFormButton;     // Перейти на видеовстречу

    constructor(json?: RecursivePartial<SiteConfigCalendar>) {
        super(json);
        this.parseSiteConfigCalendar(json || {});
    }

    parse(json: RecursivePartial<SiteConfigCalendar>): SiteConfigCalendar {
        super.parse(json);
        this.parseSiteConfigCalendar(json);
        return this;
    }

    private parseSiteConfigCalendar(json: RecursivePartial<SiteConfigCalendar>): void {
        SiteConfigCalendar.assignFields<SiteConfigCalendar>(this, json, [
            'title',
            // 'showNow',
            'windowSize',
            'startOffset',
            'confirmMessage',
            'finishedMessage'
        ]);
        SiteConfigCalendar.assignClassFields<SiteConfigCalendar>(this, json, {
            sendButton: SiteConfigFormButton,
            videoButton: SiteConfigFormButton,
        });
    }
}
