import { BaseModel, RecursivePartial } from '@models/base';
import { DeviceInfo } from '@models/shared/device-info';

export type AuthType = 'local' | 'google' | 'app' | 'external';

export class SigninRequest extends BaseModel {
    type!: AuthType;
    login?: string;
    password?: string;
    token?: string;
    invitationId?: string;
    pinCode?: string;
    deviceInfo?: DeviceInfo;

    constructor(json?: RecursivePartial<SigninRequest>) {
        super(json);
        this.parseSigninRequest(json || {});
    }

    parse(json: RecursivePartial<SigninRequest>): SigninRequest {
        super.parse(json);
        this.parseSigninRequest(json);
        return this;
    }

    private parseSigninRequest(json: RecursivePartial<SigninRequest>): void {
        SigninRequest.assignFields<SigninRequest>(this, json, ['type', 'login', 'password', 'token', 'invitationId', 'pinCode']);
        SigninRequest.assignClassFields(this, json, { deviceInfo: DeviceInfo });
    }
}
