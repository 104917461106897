import { RecursivePartial } from '../base';
import { BaseState } from './base-state';

export class FeedbackState extends BaseState {

    _VER = 1;

    lastShownTip: number = 0;
    lastShownTipDt?: Date;

    constructor(json?: RecursivePartial<FeedbackState>) {
        super(json);
        this.parseFeedbackState(json || {});
    }

    parse(json: RecursivePartial<FeedbackState>): FeedbackState {
        super.parse(json);
        this.parseFeedbackState(json);
        return this;
    }

    private parseFeedbackState(json: RecursivePartial<FeedbackState>): void {
        FeedbackState.assignFields<FeedbackState>(this, json, ['lastShownTip']);
        FeedbackState.assignDates<FeedbackState>(this, json, ['lastShownTipDt']);
    }

}
