import { BaseModel, RecursivePartial } from '@models/base';

export class SigninResponse extends BaseModel {
    token!: string;
    refreshToken!: string;
    chatId?: string;
    customerId?: string;

    constructor(json?: RecursivePartial<SigninResponse>) {
        super(json);
        this.parseSigninResponse(json || {});
    }

    parse(json: RecursivePartial<SigninResponse>): SigninResponse {
        super.parse(json);
        this.parseSigninResponse(json);
        return this;
    }

    private parseSigninResponse(json: RecursivePartial<SigninResponse>): void {
        SigninResponse.assignFields<SigninResponse>(this, json, ['token', 'refreshToken', 'chatId', 'customerId']);
    }
}
