import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { BaseComponent } from '@base/base.component';
import { Tag } from '@models';
import { InputBoolean, InputNumber } from '@models/utils/coercions';

@Component({
    selector: 'vz-loading',
    template: `
        @if (showReal) {
            <div class="fit layout center-center" [class.fixed]="global" style="z-index: 999">
                <div class="vz-backdrop layout fit"></div>
                <div class="loader small"></div>
                @if (text) {
                    <div class="loader-text">{{ text }}</div>
                }
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Tag('LoadingComponent')
export class LoadingComponent extends BaseComponent {
    showReal: boolean = false;
    @Input() @InputBoolean()
    set show(v: boolean | string) {
        if (this.debounce) {
            this.debouncer.next(!!v);
        }
        else {
            this.showReal = !!v;
            this._cdr.detectChanges();
        }
    }
    @Input() @InputBoolean() global: boolean | string = false;
    @Input() text: string = '';
    @Input() @InputNumber() debounce: number | string = 100;

    debouncer: Subject<boolean> = new Subject();

    constructor(private _cdr: ChangeDetectorRef) {
        super();
        this.debouncer.pipe(debounceTime(+this.debounce)).subscribe(v => {
            this.showReal = v;
            this._cdr.detectChanges();
        });
    }
}

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [LoadingComponent],
    exports: [LoadingComponent]
})
export class LoadingModule { }
