import { BaseModel, RecursivePartial } from '@models/base';

export class KanbanBoardQuickfilter extends BaseModel {

    id: string = '';
    filterName!: string;
    filterText: string = '';

    constructor(json?: RecursivePartial<KanbanBoardQuickfilter>) {
        super(json);
        this.parseKanbanBoardQuickfilter(json || {});
    }

    parse(json: RecursivePartial<KanbanBoardQuickfilter>): KanbanBoardQuickfilter {
        super.parse(json);
        this.parseKanbanBoardQuickfilter(json);
        return this;
    }

    private parseKanbanBoardQuickfilter(json: RecursivePartial<KanbanBoardQuickfilter>): void {
        KanbanBoardQuickfilter.assignFields<KanbanBoardQuickfilter>(this, json, [
            'id',
            'filterName',
            'filterText',
        ]);
        if (this.filterName == null || this.filterName == '') {
            this.filterName = this.id;
            this.id = '';
        }
        if (this.filterText.startsWith('###')) {
            this.id = this.filterText;
            this.filterText = '';
        }
    }

}
