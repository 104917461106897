export enum CefEventName {
    TrayIconSet = 'icon',
    IncomingCall = 'incomingCall',
    CancelCall = 'cancelCall',
    GetScreenShareSources = 'desktop',
    NewMessageNotification = 'notify'
}

export interface CefApp {
    setEventListener: (eventName: CefEventName, callback: (callId: string, code: number) => void) => void;
    clearEventListener: (eventName: CefEventName) => void;
    clearAllEventListeners: () => void;
};

export enum CefReturnCode {
    Accept = 1,
    Reject = 2,
    Ignore = 3
};
