import { BaseModel, RecursivePartial } from "../base";
import { IdNameAvatar } from "../shared/id-name-avatar";

export class InvitationInfo extends BaseModel {
    id!: string;
    created?: number;
    org?: IdNameAvatar;
    initiator?: IdNameAvatar;

    constructor(json?: RecursivePartial<InvitationInfo>) {
        super(json);
        this.parseInvitationInfo(json || {});
    }

    parse(json: RecursivePartial<InvitationInfo>): InvitationInfo {
        super.parse(json);
        this.parseInvitationInfo(json);
        return this;
    }

    private parseInvitationInfo(json: RecursivePartial<InvitationInfo>): void {
        InvitationInfo.assignFields<InvitationInfo>(this, json, ['id', 'created']);
        InvitationInfo.assignClassFields(this, json, { org: IdNameAvatar, initiator: IdNameAvatar });
        if (!this.org && (json as any).orgId) {
            this.org = new IdNameAvatar({ id: (json as any).orgId });
        }
        if (!this.initiator && (json as any).createdBy) {
            this.initiator = new IdNameAvatar({ id: (json as any).createdBy });
        }
    }
}
