import { BaseModel, RecursivePartial } from '@models/base';
import { IdNameObjectType } from '@models/shared';

export class UserNotification extends BaseModel {
    id!: string;
    priority = 'info';
    created!: number;
    createdBy?: string;
    orgId!: string;
    seen: boolean = false;
    title!: string;
    text!: string;
    objects: IdNameObjectType[] = [];

    constructor(json?: RecursivePartial<UserNotification>) {
        super(json);
        this.parseUserNotification(json || {});
    }

    parse(json: RecursivePartial<UserNotification>): UserNotification {
        super.parse(json);
        this.parseUserNotification(json);
        return this;
    }

    private parseUserNotification(json: RecursivePartial<UserNotification>): void {
        UserNotification.assignFields<UserNotification>(this, json, [
            'id',
            'priority',
            'created',
            'createdBy',
            'orgId',
            'seen',
            'title',
            'text'
        ]);
        UserNotification.assignClassArrays(this, json, { objects: IdNameObjectType });
    }

}
