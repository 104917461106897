import { BaseModel, RecursivePartial } from '@models/base';

export class PersonOrgDetailsProject extends BaseModel {
    id: string = '?';

    constructor(json?: RecursivePartial<PersonOrgDetailsProject>) {
        super(json);
        this.parsePersonOrgDetailsProject(json || {});
    }

    parse(json: RecursivePartial<PersonOrgDetailsProject>): PersonOrgDetailsProject {
        super.parse(json);
        this.parsePersonOrgDetailsProject(json);
        return this;
    }

    private parsePersonOrgDetailsProject(json: RecursivePartial<PersonOrgDetailsProject>): void {
        PersonOrgDetailsProject.assignFields<PersonOrgDetailsProject>(this, json, ['id']);
    }
}
