import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from '@base/base.component';
import { ChatType, ContactSubTypeName, ContactTypeName, IPersonTpl, Person, Tag } from '@models';
import { HttpApiError } from '@models/api/http-api-error';
import { VzValidators } from '@models/utils/validators';
import { ApiService, AuthService, ChatService, HelpersService, StoreService } from '@services';

@Component({
    selector: 'person-edit',
    templateUrl: './person-edit.component.html',
})
@Tag('PersonEditComponent')
export class PersonEditComponent extends BaseComponent implements OnInit {

    @Input() personId?: string | null;
    @Output() gotPerson: EventEmitter<Person> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    person?: Person;
    form!: FormGroup;
    canEdit: boolean = false;

    CTN = ContactTypeName;
    CSTN = ContactSubTypeName;

    constructor(
        protected _store: StoreService,
        private _auth: AuthService,
        private _api: ApiService,
        private _fb: FormBuilder,
        private _h: HelpersService,
        private _router: Router,
        private _chat: ChatService
    ) {
        super(_store);
        this.form = this._fb.group({
            firstName: [null, [VzValidators.MeanfulText(3)]],
            lastName: [null, [VzValidators.MeanfulText(3)]],
            avatar: [null],
        });
    }

    ngOnInit(): void {
        if (this.personId != 'new') {
            this.getData();
        }
        else {
            this.canEdit = true;
        }
    }

    getData(): void {
        if (this.personId) {
            this._auth.getPerson(this.personId).subscribe({
                next: person => {
                    this.person = person;
                    this.loading = false;
                    this.gotPerson.emit(person);
                    this.canEdit = this.personId == this.userId
                        // || (!!person && person.isUser && this.granted['org.admin.team.edit'])
                        || (!!person && !person.isUser && this.granted['org.client.edit']);
                },
                error: err => this.__error(err)
            });
        }
        else {
            this.__error(new HttpApiError('Внутренняя ошибка', 'ID пользователя пустой'));
        }
    }

    submit(): void {
        Object.keys(this.form.controls).forEach(field => {
            this.form.get(field)?.markAsDirty();
            this.form.get(field)?.updateValueAndValidity();
        });
        if (this.form.valid && this.personId) {
            const person: IPersonTpl = {
                firstName: this.form.get('firstName')?.value,
                lastName: this.form.get('lastName')?.value,
                avatar: this.form.get('avatar')?.value,
                orgId: this.activeOrgId,
            };
            this._auth.createPerson(person).subscribe({
                next: () => this.close.emit(),
                error: err => this.__error(err)
            });
        }
    }

    deletePerson(): void {
        this._h.confirm(() => this._auth.deletePerson(this.personId!).subscribe({
            next: () => this.close.emit(),
            error: err => this.__error(err)
        }));
    }

    openContact(id: string): void {
        this._h.editContact(this.person!, id).afterClose.subscribe(() => this.getData());
    }

    startDirectChat(): void {
        if (!this.person?.id) {
            return;
        }
        this._api.createChat({
            orgId: this.activeOrgId!,
            memberIds: [this.person.id, this.userId!],
            type: ChatType.Direct,
            threadsDepthAllowed: 1
        }).subscribe({
            next: chat => this._router.navigate(['/ui', this.activeOrgId, 'chats', chat.id]),
            error: err => this.__error(err)
        })
    }

    startDirectCall(): void {
        if (!this.person?.id) {
            return;
        }
        this._api.createChat({
            orgId: this.activeOrgId!,
            memberIds: [this.person.id, this.userId!],
            type: ChatType.Direct,
            threadsDepthAllowed: 1
        }).subscribe({
            next: chat => this._chat.startVideo(chat.id!).subscribe({ error: err => this.__error(err) }),
            error: err => this.__error(err)
        })
    }

}
