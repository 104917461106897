import { BaseModel, SM, RecursivePartial } from '@models/base';
import { Person } from '@models/users';

export class TechSupportUsersResponse extends BaseModel {
    groupId!: string;
    persons: Person[] = [];
    _personMap: SM<Person> = {};

    constructor(json?: RecursivePartial<TechSupportUsersResponse>) {
        super(json);
        this.parseTechSupportUsersResponse(json || {});
    }

    parse(json: RecursivePartial<TechSupportUsersResponse>): TechSupportUsersResponse {
        super.parse(json);
        this.parseTechSupportUsersResponse(json);
        return this;
    }

    private parseTechSupportUsersResponse(json: RecursivePartial<TechSupportUsersResponse>): void {
        TechSupportUsersResponse.assignFields<TechSupportUsersResponse>(this, json, ['groupId']);
        TechSupportUsersResponse.assignClassArrays<TechSupportUsersResponse>(this, json, { persons: Person });
        this.persons.filter(p => p?.id).forEach(p => {
            p.isUser = true;
            // p._active = true;
            this._personMap[p.id!] = p;
        });
        if (this.groupId) {
            this.groupId = this.groupId.toUpperCase();
        }
    }
}
