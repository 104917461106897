import { BaseSystemModel, Guid, RecursivePartial, SYSTEM_MODULE_IDS, VzSystemModule } from '@models/base';
import { VzParam } from '../params';

export class Integration extends BaseSystemModel {
    orgCreatedBy!: Guid;
    type!: string;
    title!: string;
    description?: string;
    avatar?: string;
    params?: VzParam[];
    filters?: VzParam[];
    modules?: { [moduleId in VzSystemModule]?: VzParam[] | null };

    constructor(json?: RecursivePartial<Integration>) {
        super(json);
        this.parseIntegration(json || {});
    }

    parse(json: RecursivePartial<Integration>): Integration {
        super.parse(json);
        this.parseIntegration(json);
        return this;
    }

    private parseIntegration(json: RecursivePartial<Integration>): void {
        Integration.assignFields(this, json, [
            'orgCreatedBy',
            'type',
            'title',
            'description',
            'avatar',
        ]);
        Integration.assignClassArrays(this, json, { params: VzParam, filters: VzParam });
        if (json.modules && typeof json.modules == 'object') {
            this.modules = {};
            for (const id of SYSTEM_MODULE_IDS) {
                if (json?.modules[id]) {
                    this.modules[id] = Integration.getClassArray<VzParam>(json.modules[id]!, VzParam);
                }
            }
        }
    }

}
