import { BaseModel, NM, RecursivePartial } from '@models/base';

export enum TaskLinkType {
    Related              = 1,    // связана с  A->B & B->A
    Duplicate            = 2,    // дубликат   A->B
    Block                = 3,    // блокирует      A->B
    // MustBeStartedWith    = 5,    // должна выполняться одновременно с
    // MustBeFinishedWith   = 6,    // должна завершиться одновременно с
    // MustBeStartedAfter   = 7,    // должна выполняться после
    // MustBeFinishedBefore = 8,    // должна завершиться до
}

export const TaskLinkTypeName: NM<[string, string]> = {
    [TaskLinkType.Related]: ['связана с', 'связана с'],
    [TaskLinkType.Duplicate]: ['дубликат', 'дубликат'],
    [TaskLinkType.Block]: ['блокирует', 'заблокирована'],
    // [TaskLinkType.MustBeStartedWith]: 'должна выполняться одновременно с',
    // [TaskLinkType.MustBeFinishedWith]: 'должна завершиться одновременно с',
    // [TaskLinkType.MustBeStartedAfter]: 'должна выполняться после',
    // [TaskLinkType.MustBeFinishedBefore]: 'должна завершиться до',
}

export const TaskLinkTypeCss: NM<[string, string]> = {
    [TaskLinkType.Related]: ['vzi-link vz-tl-related', 'vzi-link vz-tl-related'],
    [TaskLinkType.Duplicate]: ['vzi-copy vz-tl-duplicate', 'vzi-copy vz-tl-duplicate'],
    [TaskLinkType.Block]: ['vzi-arrow-right vz-tl-blocks', 'vzi-arrow-left vz-tl-blocked'],
}


export class TaskLink extends BaseModel {
    linkType?: TaskLinkType;
    taskFromId?: string;
    taskToId?: string;

    constructor(json?: RecursivePartial<TaskLink>) {
        super(json);
        this.parseTaskLink(json || {});
    }

    parse(json: RecursivePartial<TaskLink>): TaskLink {
        super.parse(json);
        this.parseTaskLink(json);
        return this;
    }

    private parseTaskLink(json: RecursivePartial<TaskLink>): void {
        TaskLink.assignFields<TaskLink>(this, json, ['linkType', 'taskFromId', 'taskToId']);
    }

}
