import { BaseModel, RecursivePartial } from "../base";
import { DeviceInfo } from "../shared/device-info";

export class FcmSubscription extends BaseModel {
    token!: string;
    created?: number;
    info?: DeviceInfo;

    constructor(json?: RecursivePartial<FcmSubscription>) {
        super(json);
        this.parseFcmSubscription(json || {});
    }

    parse(json: RecursivePartial<FcmSubscription>): FcmSubscription {
        super.parse(json);
        this.parseFcmSubscription(json);
        return this;
    }

    private parseFcmSubscription(json: RecursivePartial<FcmSubscription>): void {
        FcmSubscription.assignFields<FcmSubscription>(this, json, ['token', 'created']);
        FcmSubscription.assignClassFields(this, json, { info: DeviceInfo });
    }
}
