import { string2Color } from '@models/utils/colors';
import { BaseSystemModel, RecursivePartial } from '@models/base';

export interface IProjectTpl {
    name?: string;
    abbr?: string;
    avatar?: string;
    orgId?: string;
    groupIds?: string[];
    personIds?: string[];
    managerId?: string;
}

export class Project extends BaseSystemModel {
    _type = BaseSystemModel.Type.Project;

    name!: string;
    abbr!: string;
    managerId!: string;
    avatar?: string;
    orgId!: string;
    closed?: boolean;
    deleted?: boolean;
    groupIds: string[] = [];
    personIds: string[] = [];

    _color!: string;

    constructor(json?: RecursivePartial<Project>) {
        super(json);
        this.parseProject(json || {});
    }

    parse(json: RecursivePartial<Project>): Project {
        super.parse(json);
        this.parseProject(json);
        return this;
    }

    private parseProject(json: RecursivePartial<Project>): void {
        Project.assignFields<Project>(this, json, ['name', 'abbr', 'managerId', 'avatar', 'orgId', 'closed', 'deleted', 'groupIds', 'personIds']);
        this._color = string2Color(this.id);
    }
}
