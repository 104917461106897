import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

import { AutoFocusModule } from '@shared/_utils/autofocus.module';
import { AvatarModule } from '@shared/_views/avatar';
import { TagsSelectComponent } from '@shared/_controls/tags-select.component';
import { FilterFormModule } from '@shared/_controls/filter-form';
import { RusPipe } from '@pipes';

import { CtrlSearchComponent } from './ctrl-search.component';

@NgModule({
    imports: [
        CommonModule, FormsModule,
        NzInputModule, NzPopoverModule, NzButtonModule, NzToolTipModule, NzSelectModule,
        NzDatePickerModule, NzCheckboxModule, NzSwitchModule, NzDropDownModule,
        TagsSelectComponent, AutoFocusModule, AvatarModule, RusPipe, FilterFormModule
    ],
    declarations: [CtrlSearchComponent],
    exports: [CtrlSearchComponent]
})
export class CtrlSearchModule { }
