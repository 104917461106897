import { BaseState } from './base-state';
import { TasksCounters } from '@models/tasks';
import { ChatsCounters, MentionsCounters } from '@models/chats';
import { RecursivePartial } from '../base';

export class CountersState extends BaseState {

    tasks: TasksCounters = new TasksCounters();
    chats: ChatsCounters = new ChatsCounters();
    mentions: MentionsCounters = new MentionsCounters();

    constructor(json?: RecursivePartial<CountersState>) {
        super(json);
        this.parseCountersState(json || {});
    }

    parse(json: RecursivePartial<CountersState>): CountersState {
        super.parse(json);
        this.parseCountersState(json);
        return this;
    }

    private parseCountersState(json: RecursivePartial<CountersState>): void {
        CountersState.assignClassFields<CountersState>(this, json, {
            tasks: TasksCounters,
            chats: ChatsCounters,
            mentions: MentionsCounters
        });
    }

}
