import { EventAttendeeState } from '.';
import { BaseSystemModel, Guid, RecursivePartial } from '@models/base';
import { IdNameObjectType } from '@models/shared';
import { CalendarEventRepeat } from './calendar-event-repeat';
import { EventAttendee } from './event-attendee';

export const DaysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export const DaysOfWeekFull = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

export class CalendarEvent extends BaseSystemModel {
    _type = BaseSystemModel.Type.CalendarEvent;
    parentId?: string;
    orgId?: Guid;
    projectId?: Guid;
    groupId?: Guid;
    pluginId?: Guid;
    start?: Date;
    end?: Date;                 // calculated on ui
    until?: Date;
    duration?: number;
    title?: string;
    description?: string;
    richText?: { [key: string]: any };
    type?: number;
    status?: number;
    iterationCount?: number;
    iterationLimit?: number;
    attendees: EventAttendee[] = [];
    linkedObjects?: IdNameObjectType[];
    icon?: string;
    color?: string;
    attState?: EventAttendeeState;
    remindMinutes: number[] = [];
    numGuests?: number;
    chatId?: Guid;
    invitationId?: Guid;

    repeat?: CalendarEventRepeat;

    constructor(json?: RecursivePartial<CalendarEvent>) {
        super(json);
        this.parseCalendarEvent(json || {});
    }

    parse(json: RecursivePartial<CalendarEvent>): CalendarEvent {
        super.parse(json);
        this.parseCalendarEvent(json);
        return this;
    }

    private parseCalendarEvent(json: RecursivePartial<CalendarEvent>): void {
        CalendarEvent.assignFields<CalendarEvent>(this, json, [
            'parentId',
            'orgId',
            'projectId',
            'groupId',
            'pluginId',
            'duration',
            'title',
            'description',
            'type',
            'status',
            'iterationCount',
            'iterationLimit',
            'icon',
            'color',
            'attState',
            'remindMinutes',
            'numGuests',
            'chatId',
            'invitationId',
            'richText'
        ]);
        CalendarEvent.assignDates<CalendarEvent>(this, json, [
            'start',
            'until',
        ]);
        if (this.start) {
            this.end = new Date(this.start.getTime() + (this.duration ? this.duration : 0) * 1000);
        }
        CalendarEvent.assignClassArrays(this, json, {
            attendees: EventAttendee,
            linkedObjects: IdNameObjectType,
        });
        CalendarEvent.assignClassFields(this, json, {
            repeat: CalendarEventRepeat
        });
        if (!this.color) {
            this.color = '#1b5e20';
        }
        if (!this.icon) {
            this.icon = 'vzi-mug';
        }
    }

    updateState(userId: string): void {
        this.attState = undefined;
        if (this.attendees) {
            for (const att of this.attendees) {
                if (att.id == userId) {
                    this.attState = att.state;
                    return;
                }
            }
        }
    }

}
