import { RecursivePartial } from '../base';
import { BaseState } from './base-state';

export interface IAppLogItem {
    dt: number;
    tag: string;
    severity: 'log' | 'warn' | 'error';
    items: string[];
}

export class AppLogState extends BaseState {
    static SIZE = 1000;

    cursor: number = 0;
    items: IAppLogItem[] = new Array(AppLogState.SIZE);

    constructor(json?: RecursivePartial<AppLogState>) {
        super(json);
        this.parseAppLog(json || {});
    }

    parse(json: RecursivePartial<AppLogState>): AppLogState {
        super.parse(json);
        this.parseAppLog(json);
        return this;
    }

    private parseAppLog(json: RecursivePartial<AppLogState>): void {
        AppLogState.assignFields<AppLogState, IAppLogItem[]>(this, json, ['cursor', 'items']);
    }

    addItem(item: IAppLogItem): void {
        this.items[this.cursor] = item;
        this.cursor = this.cursor == AppLogState.SIZE - 1 ? 0 : this.cursor + 1;
    }

    getItems(): IAppLogItem[] {
        const cur = this.cursor;
        let c = this.cursor + 1;
        if (c == AppLogState.SIZE) {
            c = 0;
        }
        let items: IAppLogItem[] = [];
        while (items.length < AppLogState.SIZE && c != cur) {
            if (this.items[c]) {
                items.push(this.items[c]);
            }
            c = c == AppLogState.SIZE - 1 ? 0 : c + 1;
        }
        return items;
    }

}
