<div class="vz-error" [class.vz-error-border]="border" [class.vz-error-info]="etype == 'info'">
    @if (error) {
        <div class="vz-error-title">{{ error.title }}</div>
        @if (error.text) {
            <div class="vz-error-text" [style.margin-bottom.px]="error.debug ? undefined : 0">{{ error.text }}</div>
        }
        @if (error.debug) {
            <div class="vz-error-url">
                @if (error.method) {
                    <b>{{ error.method }}</b>
                }
                {{ error.url }}
            </div>
        }
        @if (showMore) {
            @if (error.body) {
                <small>REQUEST</small>
                <pre class="vz-error-details" [@removeH]>{{ error.body | jsonSafe:4 }}</pre>
            }
            <small>{{ error.error ? '' : 'EMPTY ' }}RESPONSE</small>
            @if (error.error) {
                <pre class="vz-error-details scroll-y" syle="max-height: 500px" [@removeH]>{{ error.error | jsonSafe:4 | ellipsis:4096 }}</pre>
            }
            @else {
                <pre class="vz-error-details txt-error" [@removeH]>&nbsp;</pre>
            }
        }
        @else {
            <div class="vz-error-guid" [@removeH]>{{ error.guid }}</div>
        }
        @if (error.debug) {
            <div class="vz-error-links layout center">
                <span class="mr1 fs-l vz-btn-xs"
                    title="Скопировать ошибку"
                    (click)="$event.stopImmediatePropagation(); copyError();"
                    [ngClass]="copied ? 'vzi-check txt-ok disabled' : 'vzi-copy txt-pl'">
                </span>
                <a *ngIf="isLoggedIn" class="mr1"
                    title="Открыть форму для обращения в тех.поддержку"
                    (click)="$event.stopImmediatePropagation(); showDebug();">
                    СООБЩИТЬ ОБ ОШИБКЕ
                </a>
                <span class="flex"></span>
                <a (click)="$event.stopImmediatePropagation(); showMore = ! showMore">{{ showMore ? 'скрыть' : 'показать' }} подробности</a>
            </div>
        }
    }
</div>
