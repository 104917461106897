import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

@Pipe({
    name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

    transform(val: string, l: number): string {
        return val.length > l ? val.substring(0, l) + '…' : val;
    }

}

@NgModule({
    imports: [CommonModule],
    declarations: [EllipsisPipe],
    exports: [EllipsisPipe]
})
export class EllipsisPipeModule { }
