import { OLD_BaseSystemModel, RecursivePartial } from '@models/base';

export enum UserOnlineStatus {
    Undefined = 0,
    Online = 1,
    Offline = 2,
    Away = 3,
}

export class UserOrgStatus extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.UserOrgStatus;

    statusTypeId!: string;
    statusId!: string;
    userId!: string;
    orgId!: string;
    lastSeenDt?: number;
    online?: boolean;
    onlineStatus?: UserOnlineStatus;

    constructor(json?: RecursivePartial<UserOrgStatus>) {
        super(json);
        this.parseUserOrgStatus(json || {});
    }

    parse(json: RecursivePartial<UserOrgStatus>): UserOrgStatus {
        super.parse(json);
        this.parseUserOrgStatus(json);
        return this;
    }

    private parseUserOrgStatus(json: RecursivePartial<UserOrgStatus>): void {
        UserOrgStatus.assignFields<UserOrgStatus>(this, json, ['statusTypeId',  'userId', 'orgId', 'lastSeenDt', 'online', 'onlineStatus']);
        this.statusId = this.id!;
        this.id = this.orgId + '|' + this.userId;
        if (json.onlineStatus == null) {
            this.onlineStatus = this.online ? UserOnlineStatus.Online : UserOnlineStatus.Offline;
        }
    }
}
