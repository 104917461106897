import { RecursivePartial, SM } from '@models/base';
import { BaseFilterField } from './base-filter-field';

export const FILTER_DATA_KEY = '__data:';

export class BaseFilter<T> {
    id!: string;
    name!: string;
    fields?: (BaseFilterField<T, any, any> | undefined)[];
    fieldsMap: SM<BaseFilterField<T, any, any>> = {};
    visibleTemplates: number = 5;

    DEFAULT_FILTER = '';

    constructor(json?: RecursivePartial<BaseFilter<T>>) {
        Object.assign(this, json);
        if (this.fields && this.fields.length > 0) {
            this.fields = this.fields.filter(f => !!f);
            for (const field of this.fields) {
                if (!field) {
                    continue;
                }
                this.fieldsMap[field.id!] = field;
            }
        }
    }

    match(_o: T, _values?: SM<string | string[]>): boolean {
        return true;
    }

    parse(text: string): SM<string | string[]> {
        const res: SM<string | string[]> = {};
        if (text != null && this.fields) {
            text = text.trim().toLocaleLowerCase();
            while (text.length > 0) {
                let key: string = '';
                let flag = false;
                for (const f of this.fields) {
                    if (f && text.startsWith(f.id)) {
                        key = f.id;
                        flag = !!this.fieldsMap[key]?.isFlag;
                        break;
                    }
                }
                if (!key && text.startsWith(FILTER_DATA_KEY)) {
                    text = text.substring(FILTER_DATA_KEY.length);
                    const si = text.indexOf(' ');
                    const value = (si == -1 ? text : text.substring(0, si)).trim();
                    text = si == -1 ? '' : text.substring(si).trim();
                    res[FILTER_DATA_KEY] = value;
                    continue;
                }
                else if (key) {
                    text = text.substring(key.length);
                    const si = text.indexOf(' ');
                    if (si == 0 || text.length == 0) {
                        text = text.trim();
                        if (flag) {
                            res[key] = '1';
                            continue;
                        }
                    }
                    else if (!flag) {
                        if (this.fieldsMap[key]?.single) {
                            const value = (si == -1 ? text : text.substring(0, si)).trim();
                            text = si == -1 ? '' : text.substring(si).trim();
                            res[key] = value;
                            continue;
                        }
                        else {
                            const values = (si == -1 ? text : text.substring(0, si)).split(',');
                            text = si == -1 ? '' : text.substring(si).trim();
                            let i = values.length;
                            while (i--) {
                                if (values[i] == null || values[i] == '') {
                                    values.splice(i, 1);
                                }
                            }
                            if (values && values.length > 0) {
                                res[key] = values.map(v => v);
                                continue;
                            }
                        }
                    }
                }
                if (text && text.length > 0 && this.fieldsMap['default']) {
                    res['default'] = text;
                }
                break;
            }
        }
        // if (this.fields) {
        //     for (const f of this.fields) {
        //         if (f && f.isFlag && !pf[f.id]) {
        //             res.push([f.id, '']);
        //         }
        //     }
        // }
        return res;
    }

}
