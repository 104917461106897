import { ObjectType, RecursivePartial } from '@models/base';
import { IdName } from './id-name';

export class IdNameObjectType extends IdName {
    type?: ObjectType;

    constructor(json?: RecursivePartial<IdNameObjectType>) {
        super(json);
        this.parseIdNameObjectType(json || {});
    }

    parse(json: RecursivePartial<IdNameObjectType>): IdNameObjectType {
        super.parse(json);
        this.parseIdNameObjectType(json);
        return this;
    }

    private parseIdNameObjectType(json: RecursivePartial<IdNameObjectType>): void {
        IdNameObjectType.assignFields<IdNameObjectType>(this, json, ['type']);
    }
}
