import { BaseModel, RecursivePartial } from '@models/base';

export class VzFileImgView extends BaseModel {
    width: number = 0;
    height: number = 0;

    constructor(json?: RecursivePartial<VzFileImgView>) {
        super(json);
        this.parseVzFileImgView(json || {});
    }

    parse(json: RecursivePartial<VzFileImgView>): VzFileImgView {
        super.parse(json);
        this.parseVzFileImgView(json);
        return this;
    }

    private parseVzFileImgView(json: RecursivePartial<VzFileImgView>): void {
        VzFileImgView.assignFields<VzFileImgView>(this, json, [
            'width',
            'height',
        ]);
    }
}
