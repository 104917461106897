import { OLD_BaseSystemModel, RecursivePartial, SM } from '@models/base';
import { Contact } from './contact';
import { InvitationInfo } from './invitation-info';
import { PersonOrgDetails } from './person-org-details';

export interface IPersonTpl {
    firstName?: string;
    lastName?: string;
    avatar?: string;
    tz?: string;
    tzOffset?: number;
    orgId?: string;
    departmentId?: string;
}

export class Person extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.Person;

    firstName?: string;
    lastName?: string;
    avatar?: string;
    tz?: string;
    tzOffset?: number;
    isUser?: boolean;
    disabled?: boolean;
    deleted?: boolean;
    orgs?: PersonOrgDetails[];
    contacts: Contact[] = [];
    mainContactId?: string;
    invitations?: InvitationInfo[];
    telegramConnected?: boolean;
    pluginId?: string;
    is2faEnabled?: boolean;

    name!: string;
    _orgMap!: SM<PersonOrgDetails>;

    constructor(json?: RecursivePartial<Person>) {
        super(json);
        this.parsePerson(json || {});
    }

    parse(json: RecursivePartial<Person>): Person {
        super.parse(json);
        this.parsePerson(json);
        return this;
    }

    private parsePerson(json: RecursivePartial<Person>): void {
        Person.assignFields<Person>(this, json, [
            'firstName',
            'lastName',
            'avatar',
            'tz',
            'tzOffset',
            'isUser',
            'disabled',
            'deleted',
            'mainContactId',
            'telegramConnected',
            'pluginId',
            'is2faEnabled'
        ]);
        Person.assignClassArrays<Person>(this, json, {
            orgs: PersonOrgDetails,
            contacts: Contact,
            invitations: InvitationInfo,
        });
        this._orgMap = {};
        if (this.orgs) {
            this.orgs.forEach(org => this._orgMap[org.id] = org);
        }
        this.name = this.firstName
            ? this.firstName + (this.lastName ? ' ' + this.lastName : '')
            : (this.lastName ? this.lastName : '???????');
    }

    _hasOrg(oid?: string | null): boolean {
        return !!oid && !!this._orgMap[oid!];
    }

    _hasRole(oid?: string | null, rid?: string | null): boolean {
        return !!oid && !!rid && this._orgMap[oid]?.roleIds?.includes(rid);
    }

    _hasGroup(oid?: string | null, gid?: string | null): boolean {
        return !!oid && !!gid && this._orgMap[oid]?.groupIds?.includes(gid);
    }

    _isActive(orgId?: string): boolean {
        return this._isAdmin() || (!!orgId && !this.disabled && !this.deleted && ((orgId == '00000000-0000-0000-0000-000000000000') || (this._orgMap[orgId] && !this._orgMap[orgId].fired)));
    }

    _isAdmin(): boolean {
        if (this.orgs) {
            for (const org of this.orgs) {
                if (org?.privs) {
                    for (const p of org.privs) {
                        if (p && p.startsWith('admin')) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

}
