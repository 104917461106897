import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rus', standalone: true })
export class RusPipe implements PipeTransform {

    transform(value: any, suffixes: [string, string, string, string, string, string]): string {
        value = value ? +value : 0;
        let od = value < 11 || value > 19 ? value % 10 : 5;
        if (od > 5) {
            od = 5
        }
        return suffixes[od];
    }

}
