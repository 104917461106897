import { ApplicationRef, EventEmitter, Injectable, Injector, TemplateRef, Type, createComponent } from '@angular/core';

import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpProgressEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import { StoreService } from './store.service';
import { Chat, Group, IVzMenuItem, Person, SM, SigninRequest, SortOption, Task, TaskLinkType } from '@models';
import { PersonOrGroup, PersonsSelectComponent } from '@shared/_select/persons-select/persons-select.component';
import { ConfirmDangerComponent } from '@shared/_utils/confirm-danger/confirm-danger.component';
import { SortFormComponent } from '@shared/_views/sort-form/sort-form.component';
import { ChatsSelectComponent } from '@shared/_chats/chats-select/chats-select.component';
// import { ChatEditComponent } from '@shared/_chats/chat-edit/chat-edit.component';
import { PersonEditComponent } from '@shared/_drawers/person/person-edit.component';
import { ContactEditComponent } from '@shared/_drawers/contact/contact-edit.component';
import { AvatarUploadComponent } from '@shared/_drawers/avatar-upload/avatar-upload.component';
import { TasksSelectComponent } from '@shared/_tasks/tasks-select';
import { ContextMenuComponent } from '@shared/_views/context-menu.component';

@Injectable()
export class HelpersService extends BaseLogging {

    public mobile?: boolean;
    public userId?: string;
    public user?: Person;

    constructor(
        protected _store: StoreService,
        private _drawer: NzDrawerService,
        private _modal: NzModalService,
        private _injector: Injector,
    ) {
        super();
        this._store.state('user').subscribe(state => {
            this.mobile = state.mobile || !this.__WEB;
            this.userId = state.userId;
            this.user = state.user;
        });
    }

    selectPersons(opts: {
        persons?: Person[],
        pSelected?: string[],
        title?: string,
        groups?: Group[],
        gSelected?: string[],
        disabledIds?: SM<boolean>,
        okText?: string
    }): NzDrawerRef<PersonsSelectComponent, { ps: string[], gs: string[] } | undefined> {
        if (opts.title == null) {
            opts.title = 'Выберите людей из списка';
        }
        if (opts.okText == null) {
            opts.okText = 'Применить';
        }
        const width = this.mobile ? '100%' : 550;
        return this._drawer.create<
            PersonsSelectComponent,
            { persons: Person[] | undefined, selected: string[] | undefined },
            { ps: string[], gs: string[] } | undefined
        >({
            nzTitle: opts.title,
            nzContent: PersonsSelectComponent,
            nzWidth: width,
            nzContentParams: {
                persons: opts.persons,
                pSelected: opts.pSelected,
                groups: opts.groups,
                gSelected: opts.gSelected,
                okText: opts.okText,
                disabledIds: opts.disabledIds
            }
        });
    }

    selectPersonOrGroup(
        persons?: Person[],
        groups?: Group[],
        title: string = 'Выберите человека или группу из списка',
    ): NzDrawerRef<PersonsSelectComponent, PersonOrGroup | undefined> {
        const width = this.mobile ? '100%' : 600;
        return this._drawer.create<
            PersonsSelectComponent,
            { persons: Person[] | undefined, selected: string | undefined },
            PersonOrGroup | undefined
        >({
            nzTitle: title,
            nzContent: PersonsSelectComponent,
            nzWidth: width,
            nzContentParams: { persons, groups, single: true, returnObject: true }
        });
    }

    selectPerson(
        persons?: Person[],
        selected?: string,
        title: string = 'Выберите человека из списка',
    ): NzDrawerRef<PersonsSelectComponent, string | undefined> {
        const width = this.mobile ? '100%' : 600;
        return this._drawer.create<
            PersonsSelectComponent,
            { persons: Person[] | undefined, selected: string | undefined },
            string | undefined
        >({
            nzTitle: title,
            nzContent: PersonsSelectComponent,
            nzWidth: width,
            nzContentParams: { persons, selected, single: true }
        });
    }

    selectGroup(
        groups?: Group[],
        selected?: string,
        title: string = 'Выберите группу из списка',
    ): NzDrawerRef<PersonsSelectComponent, string | undefined> {
        const width = this.mobile ? '100%' : 600;
        return this._drawer.create<
            PersonsSelectComponent,
            { groups: Group[] | undefined, selected: string | undefined },
            string | undefined
        >({
            nzTitle: title,
            nzContent: PersonsSelectComponent,
            nzWidth: width,
            nzContentParams: { groups, selected, single: true }
        });
    }

    confirm(opts: {
        ok: () => void,
        cancel?: () => void,
        okText?: string,
        cancelText?: string,
        title?: string,
        content?: string | TemplateRef<any> | Type<any>
    } | (() => void)): NzModalRef {
        if (typeof opts == 'function') {
            opts = { ok: opts };
        }
        return this._modal.confirm({
            nzTitle: opts.title || 'Вы уверены?',
            nzContent: opts.content,
            nzOnOk: opts.ok,
            nzOnCancel: opts.cancel || (() => true),
            nzOkText: opts.okText || 'ОК',
            nzCancelText: opts.cancelText || 'Отмена'
        });
    }

    confirmDanger(
        title: string = 'Внимание!!!',
        text?: string
    ): NzDrawerRef<ConfirmDangerComponent, SigninRequest | undefined> {
        const width = this.mobile ? '100%' : 450;
        return this._drawer.create<
            ConfirmDangerComponent,
            any,
            SigninRequest | undefined
        >({
            nzTitle: title,
            nzContent: ConfirmDangerComponent,
            nzWidth: width,
            nzPlacement: 'left',
            nzContentParams: { text },
            nzWrapClassName: 'ant-drawer-danger',
        });
    }

    showSortForm(sort: string[], sortOptions: SortOption[], sortDefault?: string[]): NzDrawerRef<SortFormComponent, string[] | undefined> {
        const width = this.mobile ? '100%' : 500;
        return this._drawer.create<
            SortFormComponent,
            { sort: string[], sortOptions: SortOption[], sortDefault?: string[] },
            string[] | undefined
        >({
            nzContent: SortFormComponent,
            nzTitle: 'Порядок сортировки',
            nzWidth: width,
            nzPlacement: 'left',
            nzContentParams: { sort, sortOptions, sortDefault },
            nzWrapClassName: 'vz-drawer-allow-overflow'
        });
    }

    selectTasks(opts?: {
        multiple?: boolean,
        tasks?: Task[],
        selected?: string[],
        title?: string,
        projectId?: string,
        linkType?: TaskLinkType,
        excludeTaskId?: string,
    }): NzDrawerRef<TasksSelectComponent, Task[] | undefined> {
        const width = this.mobile ? '100%' : 900;
        return this._drawer.create<
            TasksSelectComponent,
            { projectId?: string, excludeTaskId?: string, linkType?: TaskLinkType, tasks?: Task[], selected?: string[], multiple?: boolean },
            Task[] | undefined
        >({
            nzTitle: opts?.multiple ? 'Выберите задачи' : 'Выберите задачу',
            nzContent: TasksSelectComponent,
            nzWidth: width,
            nzContentParams: {
                projectId: opts?.projectId,
                excludeTaskId: opts?.excludeTaskId,
                linkType: opts?.linkType,
                tasks: opts?.tasks,
                selected: opts?.selected,
                multiple: opts?.multiple
            }
        });
    }

    selectChats(opts: {
        chats?: Chat[],
        selected?: string[],
        single?: boolean,
        noFilters?: boolean,
        returnObjects?: boolean,
        title?: string,
        okText?: string,
        avatarSize?: number
    }): NzDrawerRef<ChatsSelectComponent, Chat[] | string[] | undefined> {
        const width = this.mobile ? '100%' : 550;
        return this._drawer.create<
            ChatsSelectComponent,
            { items: Chat[] | undefined, selected: string[] | undefined },
            Chat[] | string[] | undefined
        >({
            nzTitle: opts.title || 'Выберите чаты из списка',
            nzContent: ChatsSelectComponent,
            nzWidth: width,
            nzContentParams: {
                items: opts.chats,
                selected: opts.selected,
                single: opts.single,
                returnObjects: opts.returnObjects,
                okText: opts.okText,
                noFilters: opts.noFilters,
                avatarSize: opts.avatarSize
            }
        });
    }

    // editChat(chatId: string): NzDrawerRef<ChatEditComponent, void> {
    //     const width = this.mobile ? '100%' : 550;
    //     return this._drawer.create<ChatEditComponent, { chatId: string | undefined }, void>({
    //         nzTitle: chatId == 'new' ? 'Новый чат' : 'Настройки чата',
    //         nzContent: ChatEditComponent,
    //         nzWidth: width,
    //         nzContentParams: { chatId }
    //     });
    // }

    editPerson(personId: string): NzDrawerRef<PersonEditComponent, void> {
        const width = this.mobile ? '100%' : 750;
        return this._drawer.create<PersonEditComponent, { personId: string | undefined }, void>({
            nzTitle: 'Пользователь',
            nzContent: PersonEditComponent,
            nzWidth: width,
            nzContentParams: { personId }
        });
    }

    editContact(person: Person, contactId: string): NzDrawerRef<ContactEditComponent, void> {
        const width = this.mobile ? '100%' : 550;
        return this._drawer.create<ContactEditComponent, { person: Person | undefined, contactId: string | undefined }, void>({
            nzTitle: 'Способ связи',
            nzContent: ContactEditComponent,
            nzWidth: width,
            nzContentParams: { person, contactId }
        });
    }

    uploadAvatar(uploadFn: (file: File, eventHandler: (e: HttpProgressEvent) => void) => Observable<void>): NzDrawerRef<AvatarUploadComponent, void> {
        const width = this.mobile ? '100%' : 450;
        return this._drawer.create<AvatarUploadComponent, { uploadFn: (file: File, eventHandler: (e: HttpProgressEvent) => void) => Observable<void>, avatarId: string, avatarShape: string }, void>({
            nzTitle: 'Загрузить новый аватар',
            nzContent: AvatarUploadComponent,
            nzWidth: width,
            nzContentParams: { uploadFn }
        });
    }

    showContextMenu(opts: {
        event: MouseEvent | { x: number, y: number },
        items: IVzMenuItem[],
        title?: string | TemplateRef<any>,
        data?: any
    }): EventEmitter<{ mi: IVzMenuItem, data: any }> {
        const appRef = this._injector.get(ApplicationRef);
        const cmpRef = createComponent(ContextMenuComponent, { environmentInjector: appRef.injector });
        cmpRef.instance.menuItems = opts.items;
        cmpRef.instance.menuTitle = opts.title;
        cmpRef.instance.event = opts.event;
        cmpRef.instance.data = opts.data;
        cmpRef.instance.mobile = this.mobile;
        cmpRef.instance.onClose.subscribe(() => {
            appRef.detachView(cmpRef.hostView);
            cmpRef.destroy();
        });
        cmpRef.changeDetectorRef.detectChanges();
        appRef.attachView(cmpRef.hostView);
        return cmpRef.instance.onItemClick;
    }

}
