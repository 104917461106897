import { BaseModel, RecursivePartial } from "@models/base";
import { IdActive } from "@models/shared/id-active";

export class ChatMessageThread extends BaseModel {
    id?: string;
    count: number = 0;
    unreadCount: number = 0;
    hasMentions?: boolean;
    lastMsgDt?: Date;
    lastReadDt?: Date;
    commentators: IdActive[] = [];

    constructor(json?: RecursivePartial<ChatMessageThread>) {
        super(json);
        this.parseUnreadCounters(json || {});
    }

    parse(json: RecursivePartial<ChatMessageThread>): ChatMessageThread {
        super.parse(json);
        this.parseUnreadCounters(json);
        return this;
    }

    private parseUnreadCounters(json: RecursivePartial<ChatMessageThread>): void {
        ChatMessageThread.assignFields<ChatMessageThread>(this, json, ['id', 'count', 'unreadCount', 'hasMentions']);
        ChatMessageThread.assignClassArrays<ChatMessageThread>(this, json, { commentators: IdActive })
        ChatMessageThread.assignDates<ChatMessageThread>(this, json, ['lastMsgDt', 'lastReadDt']);
    }
}
