import { BaseModel, RecursivePartial } from '@models/base';

export class OrgsChatsStats extends BaseModel {
    orgId?: string;
    numChats: number = 0;
    numMessages: number = 0;

    constructor(json?: RecursivePartial<OrgsChatsStats>) {
        super(json);
        this.parseOrgsChatsStats(json || {});
    }

    parse(json: RecursivePartial<OrgsChatsStats>): OrgsChatsStats {
        super.parse(json);
        this.parseOrgsChatsStats(json);
        return this;
    }

    private parseOrgsChatsStats(json: RecursivePartial<OrgsChatsStats>): void {
        OrgsChatsStats.assignFields<OrgsChatsStats>(this, json, [
            'orgId',
            'numChats',
            'numMessages',
        ]);
    }

}
