import { RecursivePartial } from '@models/base';
import { ChatMessage } from './chat-message';

export enum ChatMentionType {
    Mention = 1,
    Reminder = 2,
}

export class ChatMentionMessage extends ChatMessage {
    mentionType?: ChatMentionType;
    mentionSeen?: boolean;
    reminderCreated?: Date;
    taskId?: string;

    constructor(json?: RecursivePartial<ChatMentionMessage>) {
        super(json);
        this.parseTaskAction(json || {});
    }

    parse(json: RecursivePartial<ChatMentionMessage>): ChatMentionMessage {
        super.parse(json);
        this.parseTaskAction(json);
        return this;
    }

    private parseTaskAction(json: RecursivePartial<ChatMentionMessage>): void {
        ChatMentionMessage.assignFields<ChatMentionMessage>(this, json, ['mentionType', 'mentionSeen', 'taskId']);
        ChatMentionMessage.assignDates<ChatMentionMessage>(this, json, ['reminderCreated']);
    }

}
