import { BaseSystemModel, SM, RecursivePartial } from '@models/base';
import { ChatStatus } from './chat-status';
import { ChatType } from './chat';
import { IdActive } from '@models/shared';

export class ChatStats extends BaseSystemModel {
    static Status = ChatStatus;
    title: string = '';
    type?: ChatType;
    description?: string;
    orgId?: string;
    projectId?: string;
    status: ChatStatus = ChatStatus.Unknown;
    pluginId?: string;
    ownerId!: string;
    commentators: IdActive[] = [];
    filesCount: number = 0;
    filesSize: number = 0;

    _percent: number = 0;
    _percentReal: number = 0;
    _membersMap: SM<boolean | undefined> = {};
    _activeMembers: string[] = [];

    constructor(json?: RecursivePartial<ChatStats>) {
        super(json);
        this.parseChat(json || {});
    }

    parse(json: RecursivePartial<ChatStats>): ChatStats {
        super.parse(json);
        this.parseChat(json);
        return this;
    }

    private parseChat(json: RecursivePartial<ChatStats>): void {
        ChatStats.assignFields<ChatStats>(this, json, [
            'title',
            'type',
            'projectId',
            'description',
            'orgId',
            'status',
            'pluginId',
            'ownerId',
            'filesCount',
            'filesSize',
        ]);
        ChatStats.assignClassArrays<ChatStats>(this, json, { commentators: IdActive });
        this.updateMembersMap();
        (this as any).thread = { commentators: this.commentators };
    }

    updateMembersMap(): void {
        this._membersMap = {};
        this._activeMembers = [];
        for (const c of this.commentators) {
            this._membersMap[c.id!] = c.active;
            if (c.active) {
                this._activeMembers.push(c.id!);
            }
        }
    }

}
