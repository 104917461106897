<button style="position: absolute; right: 24px; top: -44px;" nz-button nzSise="small" nzType="primary" (click)="applySort()">Применить сортировку</button>
<div *ngIf="sort && sortOptions" class="sort-form-container layout vertical fit p1 scroll-y">
    <div class="mb1">
        <label class="layout center fs-l" style="margin-bottom:-.5rem">
            <span>Текущая сортировка</span>
            <div class="flex"></div>
            <button *ngIf="sortDefault" [disabled]="isDefault" nz-button nzType="link" class="p05" (click)="resetSortOrder()">по умолчанию</button>
            <button nz-button nzType="link" [disabled]="currentSort.length == 0" class="p05" (click)="clearCurrentSort()">очистить</button>
        </label>
        <small class="txt-l lh1 mt025 block">Кликните на плашку, чтобы поменять направление сортировки, перетащите плашку, чтобы изменить порядок полей в сортировке.</small>
    </div>
    <div class="sort-form-lists">
        <div class="mb1">
            <div class="layout vertical sort-form-list"
                cdkDropList
                cdkDropListLockAxis="y"
                [cdkDropListConnectedTo]="[aList]"
                [cdkDropListData]="'c'"
                (cdkDropListDropped)="dropped($event)"
                #cList="cdkDropList">
                <div *ngIf="currentSort.length == 0" class="layout center-center" style="min-height:3.125rem">
                    <span class="loader-text small">Без сортировки.</span>
                </div>
                <div *ngFor="let sf of currentSort; let i = index" class="sort-form-list-item layout center mb025"
                    cdkDrag [cdkDragData]="sf.id" cdkDragBoundary=".sort-form-lists">
                    <span class="vz-badge mr05 pe-all" style="cursor: grab">{{i + 1}}</span>
                    <nz-button-group>
                        <button class="tal" nz-button title="Поменять порядок сортировки" (click)="sf.asc = !sf.asc; updateIsDefault();">
                            <div class="layout flex center">
                                <i class="vzi-sort-asc" [class.r180]="!sf.asc" style="transition: transform .3s;"></i>
                                <span class="layout flex ml1">
                                    <b>{{ sortOptionsMap[sf.id].ttl }}</b>,
                                    {{ sf.asc ? 'по возрастанию' : 'по убыванию' }}
                                </span>
                            </div>
                        </button>
                        <button class="ant-btn-icon-only" nz-button title="Убрать" (click)="removeSortField(i); $event.stopImmediatePropagation();">
                            <i class="vzi-close fs-xs txt-error"></i>
                        </button>
                    </nz-button-group>
                </div>
            </div>
        </div>

        <div class="mb1 mt1">
            <label class="layout center fs-l" style="margin-bottom:-.5rem">
                Доступные поля для сортировки
            </label>
            <small class="txt-l">Нажмите или перетащите плашку наверх, чтобы добавить критерий сортировки</small>

            <div class="layout vertical sort-form-list"
                cdkDropList
                cdkDropListLockAxis="y"
                [cdkDropListConnectedTo]="[cList]"
                [cdkDropListData]="'a'"
                (cdkDropListDropped)="dropped($event)"
                #aList="cdkDropList">
                <div *ngIf="sortOptions.length == 0" class="layout center-center" style="min-height:3.125rem">
                    <span class="loader-text small">Нет доступных вариантов сортировки.</span>
                </div>

                <div *ngIf="sortOptions.length > 0 && sortOptions.length == currentSort.length" class="layout center-center" style="min-height:3.125rem">
                    <span class="loader-text small">Больше нет доступных полей для сортировки.</span>
                </div>
                <div *ngFor="let so of sortOptions; let i = index" class="sort-form-list-item layout center mb025"
                    cdkDrag [cdkDragData]="so.id" cdkDragBoundary=".sort-form-lists">
                    <ng-template [ngIf]="!usedFields[so.id]">
                        <span class="vz-badge-l mr05 pe-all" style="cursor: grab">&nbsp;&nbsp;</span>
                        <nz-button-group>
                            <button nz-button title="Добавить с сортировкой по возрастанию" (click)="addSortField(i, true)">
                                <div class="layout flex center">
                                    <i class="vzi-sort-asc mr05"></i>
                                    <b class="layout flex ml1">{{ so.ttl }}</b>
                                </div>
                            </button>
                            <button class="ant-btn-icon-only" nz-button title="Добавить с сортировкой по убыванию" (click)="addSortField(i, false)">
                                <i class="top0 vzi-sort-asc r180" style="display: inline-block"></i>
                            </button>
                        </nz-button-group>
                    </ng-template>
                </div>
            </div>
        </div>

    </div>

</div>
