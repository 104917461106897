import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { getUuid } from '@models';
import { StoreService } from './store.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _store: StoreService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authToken = this._store.getState('user').authToken;
        const req2 = req.clone({ headers: req.headers.set('x-vizorro-requestid', getUuid()) });
        return next.handle(
            authToken
                ? req2.clone({ headers: req.headers.set('Authorization', `Bearer ${authToken}`) })
                : req2
        );
    }
}
