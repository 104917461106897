export function arraysDiff(origIds: string[] = [], newIds: string[] = []): { add: string[], remove: string[] } | undefined {
    const add: string[] = [];
    const remove: string[] = [];
    if (!origIds || !Array.isArray(origIds)) {
        origIds = [];
    }
    if (!newIds || !Array.isArray(newIds)) {
        newIds = [];
    }
    origIds.forEach(id => {
        if (!newIds.includes(id)) {
            remove.push(id);
        }
    });
    newIds.forEach(id => {
        if (!origIds.includes(id)) {
            add.push(id);
        }
    });
    return add.length > 0 || remove.length > 0 ? { add, remove } : undefined;
}

export function isArraysDiff(arr1?: any[], arr2?: any[]): boolean {
    if (arr1 === arr2 || (!arr1 && !arr2)) {
        return false;
    }
    if ((!arr1 && arr2) || (arr1 && !arr2) || arr1!.length != arr2!.length) {
        return true;
    }
    if (arr1!.length < 1000) {
        for (const a1 of arr1!) {
            if (arr2!.indexOf(a1) == -1) {
                return true;
            }
        }
    }
    else {
        const a2m: any = {};
        for (const a2 of arr2!) {
            a2m[a2] = true;
        }
        for (const a1 of arr1!) {
            if (!a2m[a1]) {
                return true;
            }
        }
    }
    return false;
}

export function combineArrays<T, K>(arr1?: T[], arr2?: K[]): (T | K)[] | undefined {
    let items: (T | K)[] | undefined;
    if (arr1) {
        items = [...arr1];
    }
    if (arr2) {
        if (!items) {
            items = [...arr2];
        }
        else {
            items.push(...arr2);
        }
    }
    return items;
}

export function removeFromArray<T>(arr: T[], cmpFn: (el: T) => boolean): T | undefined {
    if (!arr) {
        return;
    }
    let i = arr.length;
    while (i--) {
        if (cmpFn(arr[i])) {
            const sa = arr.splice(i, 1);
            return sa[0];
        }
    }
    return;
}

export function removeAllFromArray<T>(arr: T[], cmpFn: (el: T) => boolean): void {
    if (!arr) {
        return;
    }
    const inds: number[] = [];
    let i = arr.length;
    while (i--) {
        if (cmpFn(arr[i])) {
            inds.push(i);
        }
    }
    inds.forEach(ind => arr.splice(ind, 1));
    return;
}

export function getArrayOfNumbers(num: number, start = 0): number[] {
    return Array(num).fill(0).map((_x, i) => i + start);
}
