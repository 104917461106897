import { BaseModel, RecursivePartial } from '@models/base';
import { BaseState } from './base-state';

export class BaseItemsState<T extends BaseModel> extends BaseState {

    items: { [id: string]: T } = {};
    modified: { [id: string]: number } = {};
    lu: number = 0;                             // last update time Date.now()

    constructor(json?: RecursivePartial<BaseItemsState<T>>) {
        super(json);
        this.parseBaseItemsState(json || {});
    }

    parse(json: RecursivePartial<BaseItemsState<T>>): BaseItemsState<T> {
        super.parse(json);
        this.parseBaseItemsState(json);
        return this;
    }

    private parseBaseItemsState(json: RecursivePartial<BaseItemsState<T>>): void {
        BaseItemsState.assignFields<BaseItemsState<T>>(this, json, ['modified', 'lu']);
    }

}
