import { DateRanges } from "@models/shared";
import {
    startOfToday, startOfTomorrow, startOfYesterday, startOfWeek,
    endOfToday, endOfTomorrow, endOfYesterday, endOfWeek, startOfMonth, endOfMonth,
} from "date-fns";

export function getTzShiftString(shift?: number): string {
    if (!shift) {
        shift = 0;
    }
    shift += 0;
    const m = Math.abs(shift) % 60;
    const h = Math.trunc(Math.abs(shift) / 60).toString();
    return 'UTC' + (shift < 0 ? '-' : '+') + h + ':' + ('0' + m).slice(-2);
}

export function getYMD(dt: Date): string {
    let res = '---';
    try {
        res = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    }
    catch(e) {}
    return res;
}

export function getDateRanges(): DateRanges {
    const dt = new Date();
    return {
        yesterday: {
            start: startOfYesterday(),
            end: endOfYesterday(),
        },
        today: {
            start: startOfToday(),
            end: endOfToday(),
        },
        tomorrow: {
            start: startOfTomorrow(),
            end: endOfTomorrow(),
        },
        week: {
            start: startOfWeek(dt, { weekStartsOn: 1 }),
            end: endOfWeek(dt, { weekStartsOn: 1 }),
        },
        month: {
            start: startOfMonth(dt),
            end: endOfMonth(dt),
        },
    };
}
