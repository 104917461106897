import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { NzSelectModule } from 'ng-zorro-antd/select';

import { TagDrawerComponent } from '@shared/_drawers/tag';
import { TagModule } from '@shared/_views/tag';

import { BaseControlComponent } from '@base/base-control.component';
import { SM, VzTag, sortStringsBy } from '@models';
import { InputBoolean } from '@models/utils/coercions';
import { StoreService } from '@services';

@Component({
    selector: 'vz-tags-select',
    template: `
        <nz-select style="width: 100%"
            [formControl]="control"
            [nzCustomTemplate]="tplSelectTag"
            [nzDropdownRender]="allowNew ? tplSelectTagPanel : closeTpl"
            nzMode="multiple"
            [nzMaxMultipleCount]="10"
            [nzPlaceHolder]="placeholder"
            [nzBorderless]="borderless"
            [(nzOpen)]="ddOpen"
            (nzOpenChange)="onOpenChange.emit($event)"
            nzShowSearch
            nzAllowClear>
            @for (item of allTags; track item.id) {
                <nz-option
                    nzCustomContent
                    [nzLabel]="(item.parentId && tagsMap[item.parentId] ? tagsMap[item.parentId]._label + '\n' : '') + (item._label)"
                    [nzValue]="item.id"
                    [nzDisabled]="disabledTagGroups[item.parentId || item.id!] && !selectedTags[item.id!]">
                    <vz-tag [tagId]="item.id" [style.opacity]="disabledTagGroups[item.parentId || item.id!] && !selectedTags[item.id!] ? .3 : undefined" />
                </nz-option>
            }
        </nz-select>
        <ng-template #tplSelectTag let-item>
            <vz-tag [tagId]="item.nzValue" class="mr025 ml-05"></vz-tag>
        </ng-template>
        <ng-template #tplSelectTagPanel>
            <div class="layout end-justified bc mh025" style="border-top: 1px solid">
                <a class="pv025 ph1" (click)="showTagDrawer = true">+ Создать новый тег</a>
                <a class="p025" (click)="ddOpen = false">закрыть</a>
            </div>
        </ng-template>
        <ng-template #closeTpl>
            <div class="layout end-justified bc mh025" style="border-top: 1px solid">
                <a class="p025" (click)="ddOpen = false">закрыть</a>
            </div>
        </ng-template>
        @if (showTagDrawer) {
            <vz-tag-drawer [projectId]="projectId" (closePanel)="showTagDrawer = false; addTag($event)" />
        }
    `,
    styles: [':host { display: flex; flex: 1; flex-basis: 0px; }'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TagsSelectComponent
        }
    ],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule,
        NzSelectModule,
        TagModule, TagDrawerComponent
    ],
})
export class TagsSelectComponent extends BaseControlComponent implements OnChanges {
    @Input() placeholder: string = 'Выберите теги';
    @Input() projectId?: string;
    @Input() @InputBoolean() borderless?: boolean | string = false;
    @Input() @InputBoolean() allowNew?: boolean | string = false;
    @Output() onOpenChange: EventEmitter<boolean> = new EventEmitter();

    tagsMap: SM<VzTag> = {};
    allTags: VzTag[] = [];
    disabledTagGroups: SM<boolean> = {};
    selectedTags: SM<boolean> = {};
    showTagDrawer: boolean = false;
    ddOpen: boolean = false;

    constructor(protected _store: StoreService) {
        super(_store);
        this._S.tags = this._store.state('tags').subscribe(st => this.filterTags(st.items));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectId) {
            this.filterTags(this._store.getState('tags').items);
        }
    }

    vzOnActiveOrgChange(): void {
        this.filterTags(this._store.getState('tags').items);
    }

    filterTags(tm: SM<VzTag>): void {
        this.tagsMap = tm;
        const f = (tag: VzTag) => tag && tag.orgId == this.activeOrgId && (!this.projectId || tag.projectId == this.projectId || !tag.projectId);
        const tags = Object.values(this.tagsMap).filter(f);
        tags.forEach(tag => {
            if (tag.parentId) {
                tag._path = this.tagsMap[tag.parentId]?.text + '/' + tag.text;
            }
            else {
                tag._path = tag.text;
            }
        });
        this.allTags = tags.sort(sortStringsBy('_path'));
        if (this.control.value) {
            setTimeout(() => this.control.setValue(this.control.value.map((id: string) => this.tagsMap[id]).filter(f).map((tag: VzTag) => tag.id!)));
        }
        this.updateTags();
    }

    updateTags(): void {
        this.disabledTagGroups = {};
        this.selectedTags = {};
        if (this.control.value) {
            for (const id of this.control.value) {
                const tag = this.tagsMap[id];
                if (tag) {
                    this.selectedTags[tag.id!] = true;
                    const gr = this.tagsMap[tag.parentId || tag.id!];
                    if (gr?.uniqueGroup) {
                        this.disabledTagGroups[gr.id!] = true;
                    }
                }
            }
        }
    }

    addTag(tag?: VzTag): void {
        if (tag && tag.orgId == this.activeOrgId && (!this.projectId || tag.projectId == this.projectId || !tag.projectId)) {
            this.sortTags([...this.control.value.map((id: string) => this.tagsMap[id]), tag]);
        }
    }

    sortTags(tags: VzTag[]): void {
        this.control.setValue(tags.sort(sortStringsBy('_path')).map(tag => tag.id!));
    }

}
