import { BaseModel, RecursivePartial } from '@models/base';

export class SiteConfigChat extends BaseModel {
    welcomeMessage?: string;                        // Пожалуйста ожидайте, наши консультанты сейчас дерутся за право ответить вам.
    finishedMessage?: string;                       // Сеанс завершен.

    constructor(json?: RecursivePartial<SiteConfigChat>) {
        super(json);
        this.parseSiteConfigAuth(json || {});
    }

    parse(json: RecursivePartial<SiteConfigChat>): SiteConfigChat {
        super.parse(json);
        this.parseSiteConfigAuth(json);
        return this;
    }

    private parseSiteConfigAuth(json: RecursivePartial<SiteConfigChat>): void {
        SiteConfigChat.assignFields<SiteConfigChat>(this, json, [
            'welcomeMessage',
            'finishedMessage',
        ]);
    }
}
