import { OLD_BaseSystemModel, RecursivePartial } from '@models/base';

export enum ContactType {
    Email         = 1,
    Phone         = 2,
    Messenger     = 3,
}

export const ContactTypeName = {
    [ContactType.Email]: 'Электронная почта',
    [ContactType.Phone]: 'Телефон',
    [ContactType.Messenger]: 'Мессенджер',
};

export enum ContactSubType {
    EmailWork        = 101,
    EmailHome        = 102,
    PhoneWork        = 201,
    PhoneHome        = 202,
    PhoneMobile      = 203,
    MessengerSkype   = 301,
    MessengerTg      = 302,
    MessengerViber   = 303,
    MessengerWA      = 304,
}

export const ContactSubTypeName = {
    [ContactSubType.EmailHome]: 'Домашняя',
    [ContactSubType.EmailWork]: 'Рабочая',
    [ContactSubType.PhoneHome]: 'Домашний',
    [ContactSubType.PhoneWork]: 'Рабочий',
    [ContactSubType.PhoneMobile]: 'Мобильный',
    [ContactSubType.MessengerSkype]: 'Skype',
    [ContactSubType.MessengerTg]: 'Telegram',
    [ContactSubType.MessengerViber]: 'Viber',
    [ContactSubType.MessengerWA]: 'WhatsApp',
};

export const CONTACT_TYPE_LIST = [
    ContactType.Email,
    ContactType.Phone,
    ContactType.Messenger,
];

export const CONTACT_SUBTYPE_MAP = {
    [ContactType.Email]: [
        ContactSubType.EmailHome,
        ContactSubType.EmailWork,
    ],
    [ContactType.Phone]: [
        ContactSubType.PhoneHome,
        ContactSubType.PhoneWork,
        ContactSubType.PhoneMobile,
    ],
    [ContactType.Messenger]: [
        ContactSubType.MessengerSkype,
        ContactSubType.MessengerTg,
        ContactSubType.MessengerViber,
        ContactSubType.MessengerWA,
    ],
};


export interface IContactTpl {
    value: string;
    personId?: string;
    type: ContactType;
    subType: ContactSubType;
}

export class Contact extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.Contact;

    value!: string;
    type!: ContactType;
    subType!: ContactSubType;
    connected?: boolean;

    constructor(json?: RecursivePartial<Contact>) {
        super(json);
        this.parseContact(json || {});
    }

    parse(json: RecursivePartial<Contact>): Contact {
        super.parse(json);
        this.parseContact(json);
        return this;
    }

    private parseContact(json: RecursivePartial<Contact>): void {
        Contact.assignFields<Contact>(this, json, ['id', 'value', 'type', 'subType', 'connected']);
    }
}
