import { BaseSystemModel, Guid, SM, RecursivePartial } from '@models/base';
import { ChatStatus } from './chat-status';
import { ChatCall } from './chat-call';
import { ChatMessageThread } from './chat-message-thread';
import { ChatMessage } from './chat-message';

export enum ChatType {
    Public  = 1,
    Private = 2,
    Direct  = 3,
    Task    = 4,
    Webinar = 5,
}

export interface IChatTpl {
    title?: string;
    type: ChatType;
    description?: string;
    orgId: string;
    projectId?: string;
    memberIds?: string[];
    threadsDepthAllowed?: number;
    maxGuestsCount?: number;
}

export class Chat extends BaseSystemModel {
    static Status = ChatStatus;
    title: string = '';
    type?: ChatType;
    description?: string;
    orgId?: Guid;
    projectId?: Guid;
    taskId?: Guid;
    status: ChatStatus = 0;
    pluginId?: Guid;
    ownerId!: Guid;
    hasUnreadThreads?: boolean;
    muted?: boolean;
    changeAfter?: boolean;
    changeTime: number = 0;
    isDefault?: boolean;
    finished?: Date;
    call?: ChatCall;
    thread!: ChatMessageThread;
    unreadThreads?: ChatMessage[];
    threadsDepthAllowed: number = 0;
    maxGuestsCount?: number;
    publicInvitationId?: Guid;
    videoId?: Guid;

    _membersMap: SM<boolean | undefined> = {};
    _activeMembers: string[] = [];

    constructor(json?: RecursivePartial<Chat>) {
        super(json);
        this.parseChat(json || {});
    }

    parse(json: RecursivePartial<Chat>): Chat {
        super.parse(json);
        this.parseChat(json);
        return this;
    }

    private parseChat(json: RecursivePartial<Chat>): void {
        Chat.assignFields<Chat>(this, json, [
            'title',
            'type',
            'projectId',
            'taskId',
            'description',
            'orgId',
            'status',
            'pluginId',
            'ownerId',
            'hasUnreadThreads',
            'threadsDepthAllowed',
            'muted',
            'changeAfter',
            'changeTime',
            'isDefault',
            'maxGuestsCount',
            'publicInvitationId',
            'videoId'
        ]);
        Chat.assignDates<Chat>(this, json, ['finished']);
        Chat.assignClassFields<Chat>(this, json, { call: ChatCall, thread: ChatMessageThread });
        Chat.assignClassArrays<Chat>(this, json, { unreadThreads: ChatMessage });
        if (!this.thread) {
            this.thread = new ChatMessageThread({
                commentators: (json as any).members,
                lastMsgDt: (json as any).lastMsgDt
            });
        }
        this.updateMembersMap();
        if (this.call) {
            this.call.chatId = this.id;
            this.call.pluginId = this.pluginId;
        }
    }

    updateMembersMap(): void {
        this._membersMap = {};
        this._activeMembers = [];
        for (const c of this.thread.commentators) {
            this._membersMap[c.id!] = c.active;
            if (c.active) {
                this._activeMembers.push(c.id!);
            }
        }
    }

}
