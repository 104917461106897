import { ChangeDetectionStrategy, Component, Input, NgModule, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputBoolean } from '@models/utils/coercions';
import { StoreService } from '@services';
import { IdActive } from '@models';

@Component({
    selector: 'vz-names-list, [vz-names-list]',
    template: `{{ list }}`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NamesListComponent implements OnChanges {
    @Input() ids?: string[] | IdActive[];
    @Input() @InputBoolean() excludeSelf: boolean | string = true;
    @Input() onlySelfName?: string;

    list: string = '';

    constructor(private _store: StoreService) { }

    ngOnChanges(): void {
        const user = this._store.getState('user').user;
        if(user && this.ids && this.ids.length == 1 && (this.ids[0] == user.id || (this.ids[0] as IdActive)?.id == user.id)) {
            this.list = this.onlySelfName || user.name;
        }
        else if (user && this.ids?.length) {
            const um = this._store.getState('persons').items;
            this.list = this.ids?.map(cmId => typeof cmId == 'string' ? cmId : cmId.id!).filter(id => id != user.id || !this.excludeSelf).map(id => um[id].name || '#' + id).join(', ');
        }
        else {
            this.list = '';
        }
    }

}

@NgModule({
    imports: [CommonModule],
    declarations: [NamesListComponent],
    exports: [NamesListComponent]
})
export class NamesListModule { }
