import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionTagRemove extends BaseTaskAction {
    type: TaskActionType.TagRemove = TaskActionType.TagRemove;
    tagIds: string[] = [];

    constructor(json?: RecursivePartial<TaskActionTagRemove>) {
        super(json);
        this.parseTaskActionFile(json || {});
    }

    parse(json: RecursivePartial<TaskActionTagRemove>): TaskActionTagRemove {
        super.parse(json);
        this.parseTaskActionFile(json);
        return this;
    }

    private parseTaskActionFile(json: RecursivePartial<TaskActionTagRemove>): void {
        TaskActionTagRemove.assignFields<TaskActionTagRemove>(this, json, ['tagIds']);
    }
}
