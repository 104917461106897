import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, NgModule, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

import { NzNotificationDataOptions, NzNotificationService } from 'ng-zorro-antd/notification';

import { ErrorPanelModule } from './error-panel';
import { HttpApiError } from '@models/api/http-api-error';

@Component({
    selector: 'vz-error-message',
    template: `
        <ng-template let-errors="data">
            <ng-template ngFor [ngForOf]="errors" let-err let-l="last">
                <vz-error-panel [error]="err" [border]="false" style="z-index: 99999"></vz-error-panel>
                <span *ngIf="!l" class="layout bc-ll mb025" style="border-bottom: 1px solid; min-height: 8px"></span>
            </ng-template>
        </ng-template>
    `
})
export class ErrorMessageComponent implements OnInit, AfterViewInit {

    @ViewChild(TemplateRef, { static: false }) template?: TemplateRef<{}>;
    @Input() error!: HttpApiError | HttpApiError[];
    @Input() options: NzNotificationDataOptions = {};
    @Output() dismiss: EventEmitter<boolean> = new EventEmitter();

    constructor(private _notification: NzNotificationService) { }

    ngOnInit(): void {
        console.warn('ErrorMessageComponent', 'ngOnInit, error:', this.error);
        if (this.error && !Array.isArray(this.error)) {
            this.error = [this.error];
        }
    }

    ngAfterViewInit(): void {
        let type = 'nt-error';
        const e = this.error && Array.isArray(this.error) ? this.error[0] : this.error;
        if (e && e.status == 403 || e.status == 404 || e.status == 409) {
            type = 'nt-info';
        }
        this.options.nzData = this.error;
        this.options.nzClass = type;
        this.options.nzStyle = { 'z-index': '9999' };
        const ref = this._notification.template(this.template!, this.options);
        ref.onClose.subscribe(res => this.dismiss.emit(res));
    }

}

@NgModule({
    imports: [
        CommonModule, ErrorPanelModule
    ],
    declarations: [ErrorMessageComponent],
    exports: [ErrorMessageComponent, ErrorPanelModule]
})
export class ErrorMessageModule { }
