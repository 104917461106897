import { BaseModel, RecursivePartial } from '@models/base';

export class SiteConfigFormButton extends BaseModel {
    show?: boolean;
    title?: string;

    constructor(json?: RecursivePartial<SiteConfigFormButton>) {
        super(json);
        this.parseSiteConfigFormButton(json || {});
    }

    parse(json: RecursivePartial<SiteConfigFormButton>): SiteConfigFormButton {
        super.parse(json);
        this.parseSiteConfigFormButton(json);
        return this;
    }

    private parseSiteConfigFormButton(json: RecursivePartial<SiteConfigFormButton>): void {
        SiteConfigFormButton.assignFields<SiteConfigFormButton>(this, json, ['show', 'title']);
    }
}
