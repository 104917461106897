import { BaseSystemModel, RecursivePartial } from '@models/base';

export class VideoServer extends BaseSystemModel {
    host?: string;
    externalUrl?: string;
    instance?: string;
    numRooms: number = 0;
    maxUsers: number = 0;
    activeUsers: number = 0;
    reservedUsers: number = 0;
    lastPoll?: Date;
    isActive?: boolean;

    constructor(json?: RecursivePartial<VideoServer>) {
        super(json);
        this.parseVideoServer(json || {});
    }

    parse(json: RecursivePartial<VideoServer>): VideoServer {
        super.parse(json);
        this.parseVideoServer(json);
        return this;
    }

    private parseVideoServer(json: RecursivePartial<VideoServer>): void {
        VideoServer.assignFields(this, json, [
            'host',
            'externalUrl',
            'instance',
            'numRooms',
            'maxUsers',
            'activeUsers',
            'reservedUsers',
            'isActive',
        ]);
        VideoServer.assignDates(this, json, ['lastPoll']);
    }

}
