import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { DmTableGrouppedRows, DmTableModule } from '@dimanoid/ngx-dm-table';

import { LoadingModule } from '@shared/_utils/loading.module';
import { ErrorMessageModule } from '@shared/_utils/error-message.module';
import { AvatarModule } from '@shared/_views/avatar';
import { CtrlSearchModule } from '@shared/_controls/ctrl-search';

import { DmTableController } from '@dimanoid/ngx-dm-table';

import { BaseComponent } from '@base/base.component';
import { Group, Guid, Org, Person, PersonFilter, SM, Tag, combineArrays, multiSortFn, sortStringsBy } from '@models';
import { InputBoolean } from '@models/utils/coercions';
import { StoreService } from '@services/store.service';
import { FilterController } from '@shared/_controls/filter-form';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

export type PersonOrGroup = Person | Group;

@Component({
    templateUrl: './persons-select.component.html',
    styleUrls: ['./persons-select.component.less'],
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        NzButtonModule, NzCheckboxModule,
        DmTableModule,
        LoadingModule, ErrorMessageModule, AvatarModule, CtrlSearchModule
    ]
})
@Tag('PersonsSelectComponent')
export class PersonsSelectComponent extends BaseComponent implements OnInit {

    @Input() persons?: Person[];
    @Input() pSelected: Guid[] = [];
    @Input() groups?: Group[];
    @Input() gSelected: Guid[] = [];
    @Input() @InputBoolean() single?: boolean | string = false;
    @Input() @InputBoolean() returnObject?: boolean | string = false;
    @Input() @InputBoolean() selfFirst?: boolean | string = true;
    @Input() disabledIds?: SM<boolean> = {}
    @Input() selected?: Guid;
    @Input() okText: string = 'Применить';

    filterPerson = new PersonFilter({ getOrgs: () => this.orgs, getActiveOrgId: () => this.activeOrgId, hideClients: true, defaultFilter: '' });
    filterController = new FilterController(this.filterPerson, { route: this._route, router: this._router, qsKey: 'psfilter' });
    orgs: SM<Org> = {};

    controller: DmTableController<PersonOrGroup, string> = new DmTableController((p: PersonOrGroup) => p.id!);
    disableRow = (row: any) => !!this.disabledIds?.[row.id];

    constructor(
        protected _store: StoreService,
        public drawerRef: NzDrawerRef<string[], { ps: string[], gs: string[] } | PersonOrGroup | string | undefined>,
        private _router: Router,
        private _route: ActivatedRoute,
    ) {
        super(_store);
        this.__initPrefs({
            groupped: 'grouppedUsers',
        });
        this.orgs = this._store.getState('orgs').items;
        this.controller.sortFn = items => items.sort(multiSortFn<PersonOrGroup>([
            (a, b) => a.id == this.userId ? -1 : (b.id == this.userId ? 1 : 0),
            sortStringsBy('name')
        ]));
        this.controller.filterFn = (item, filter) => this.filterPerson.match(item as any, this.filterPerson.parse(filter));
    }

    ngOnInit(): void {
        this.updateItems();
        this.controller.setSelected(combineArrays(this.pSelected, this.gSelected)!, true);
    }

    close(selected?: any[]): void {
        if (selected) {
            const ps: string[] = [];
            const gs: string[] = [];
            for (const id of selected) {
                const item = this.controller.getItem(id);
                if (item instanceof Person) {
                    ps.push(id);
                }
                else if (item instanceof Group) {
                    gs.push(id);
                }
            }
            this.drawerRef.close({ ps, gs });
        }
        else {
            this.drawerRef.close();
        }
    }

    updateItems(): void {
        if (this.pref.groupped) {
            const items: DmTableGrouppedRows<PersonOrGroup>[] = [];
            if (this.groups?.length) {
                items.push({
                    rows: this.groups,
                    data: { title: 'Группы' },
                    collapsible: true,
                    collapsed: true
                });
            }
            if (this.persons?.length) {
                const gm = this._store.getState('groups').items;
                const gpm: SM<Person[]> = {};
                this.persons.forEach(p => {
                    p._orgMap[this.activeOrgId!]?.groupIds.forEach(gid => {
                        if (gpm[gid]) {
                            gpm[gid].push(p);
                        }
                        else {
                            gpm[gid] = [p];
                        }
                    });
                });
                Object.keys(gpm).map(id => gm[id]).forEach(g => {
                    items.push({
                        rows: gpm[g.id!],
                        data: { group: g },
                        collapsible: true,
                        collapsed: true
                    });
                });
            }
            this._L('updateItems', items);
            this.controller.groupped.next(this.pref.groupped);
            this.controller.setItems(items);
        }
        else {
            const items: PersonOrGroup[] = [];
            if (this.groups || this.persons) {
                if (this.groups) {
                    items.push(...this.groups);
                }
                if (this.persons) {
                    items.push(...this.persons);
                }
            }
            this._L('updateItems', items);
            this.controller.groupped.next(this.pref.groupped);
            this.controller.setItems(items);
        }
    }

    toggleGroupped(): void {
        this.pref.groupped = !this.pref.groupped;
        this.updateItems();
    }

    toggle(id?: string): void {
        this._L('toggle', id, this.disabledIds?.[id!]);
        if (id && this.disabledIds && !this.disabledIds[id]) {
            this.controller.toggleSelected(id);
        }
    }

}
