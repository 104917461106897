import { BaseModel, RecursivePartial } from '@models/base';

export class TimeWindow extends BaseModel {
    since?: number;
    till?: number;

    constructor(json?: RecursivePartial<TimeWindow>) {
        super(json);
        this.parseTimeWindow(json || {});
    }

    parse(json: RecursivePartial<TimeWindow>): TimeWindow {
        super.parse(json);
        this.parseTimeWindow(json);
        return this;
    }

    private parseTimeWindow(json: RecursivePartial<TimeWindow>): void {
        TimeWindow.assignFields<TimeWindow>(this, json, ['since', 'till']);
    }
}
