import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionSubtaskAdd extends BaseTaskAction {
    type: TaskActionType.SubtaskAdd = TaskActionType.SubtaskAdd;
    taskId!: string;
    serial!: number;
    subj?: string;

    constructor(json?: RecursivePartial<TaskActionSubtaskAdd>) {
        super(json);
        this.parseTaskActionSubtaskAdd(json || {});
    }

    parse(json: RecursivePartial<TaskActionSubtaskAdd>): TaskActionSubtaskAdd {
        super.parse(json);
        this.parseTaskActionSubtaskAdd(json);
        return this;
    }

    private parseTaskActionSubtaskAdd(json: RecursivePartial<TaskActionSubtaskAdd>): void {
        TaskActionSubtaskAdd.assignFields<TaskActionSubtaskAdd>(this, json, ['taskId', 'serial', 'subj']);
    }
}
