import { Component } from "@angular/core";
import { ControlValueAccessor, FormControl } from "@angular/forms";

import { BaseComponent } from '@base/base.component';
import { StoreService } from "@services/store.service";

@Component({ template: '' })
export class BaseControlComponent extends BaseComponent implements ControlValueAccessor {
    control = new FormControl();
    onChange: any = () => {};
    onTouched: any = () => {};

    constructor(protected _store?: StoreService) {
        super(_store);
        this.control.valueChanges.subscribe(v => {
            this.onChange(v);
        });
    }

    writeValue(obj: any): void {
        this.control.setValue(obj);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        }
        else {
            this.control.enable();
        }
    }

}