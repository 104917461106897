import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { formatISO, isValid } from 'date-fns';

import {
    BaseFilter, FILTER_DATA_KEY, FilterFieldType, SM,
    Tag, getChangedFilterFields, initFilterValues, parseFilterText
} from '@models';

export type FilterControllerOptions = {
    text?: string,
    projectId?: string,
    qsKey?: string,
    router?: Router,
    route?: ActivatedRoute
};

@Tag('FilterController')
export class FilterController {

    readonly values: BehaviorSubject<SM<any> | undefined> = new BehaviorSubject<SM<any> | undefined>(undefined);
    readonly text: BehaviorSubject<string> = new BehaviorSubject('');
    readonly defText: BehaviorSubject<string> = new BehaviorSubject('');
    readonly projectId: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

    private filter?: BaseFilter<any>;
    private qsKey?: string;
    private _router?: Router;
    private _route?: ActivatedRoute;

    constructor(filter?: BaseFilter<any>, opts?: FilterControllerOptions) {
        if (filter) {
            this.init(filter, opts);
        }
    }

    init(filter: BaseFilter<any>, opts?: FilterControllerOptions): void {
        // console.warn('[FilterController] init', 'filter:', filter, 'opts:', opts);
        this.filter = filter;
        this.projectId.next(opts?.projectId);
        this.qsKey = opts?.qsKey;
        this._route = opts?.route;
        this._router = opts?.router;
        let text = opts?.text || '';
        if (this.qsKey && this._route && this._router) {
            if (text) {
                this._router.navigate([], {
                    relativeTo: this._route,
                    replaceUrl: true,
                    queryParams: { [this.qsKey]: text },
                    queryParamsHandling: 'merge'
                });
            }
            else {
                text = this._route.snapshot.queryParamMap.get(this.qsKey) || this.filter.DEFAULT_FILTER || '';
            }
        }
        this.parseText(text);
    }

    setText(text: string): void {
        // console.warn('[FilterController] setText', 'text:', text);
        this.parseText(text);
    }

    setDefText(def: string): void {
        // console.warn('[FilterController] setDefText', 'def:', def);
        let values = this.values.getValue();
        if (!values) {
            values = {};
        }
        values.default = def;
        this.setValues(values);
    }

    private parseText(text: string): void {
        // this._L('parseText', text);
        const r = parseFilterText(text, this.filter);
        if (r) {
            this.updateState(r.values, r.text, r.def);
        }
    }

    setValues(newValues: SM<any>): void {
        // console.warn('[FilterController] setValues', newValues, this.filter);
        const values = Object.assign(initFilterValues(this.filter), newValues);
        let fields: string[] = [];
        let def = '';
        for (const id of Object.keys(values)) {
            const v = values[id];
            const f = this.filter?.fieldsMap[id];
            // console.log('[FilterController] setValues, f:', f?.id, f?.type, 'v:', v);
            if (id == FILTER_DATA_KEY) {
                if (values[FILTER_DATA_KEY] != null && values[FILTER_DATA_KEY] != '') {
                    fields.push(FILTER_DATA_KEY + values[FILTER_DATA_KEY]);
                }
            }
            else if (f) {
                if (f.id == 'default') {
                    def = v || '';
                }
                else if (f.type == FilterFieldType.Date || f.type == FilterFieldType.DateOrUnset) {
                    // console.log('[FilterController] setValues, f:', f?.id, f?.type, 'v:', v);
                    // console.log('[FilterController] setValues <Date|DateOrUnset>, v:', v);
                    if (v?.p == '?') {
                        fields.push(f.id + '?');
                    }
                    else if (v?.tpl) {
                        fields.push(f.id + v.p + v.tpl);
                    }
                    else if (v?.dt && isValid(v.dt)) {
                        fields.push(f.id + v.p + formatISO(v.dt, { representation: 'date' }));
                    }
                }
                else if (
                    f.type == FilterFieldType.Users || f.type == FilterFieldType.Groups || f.type == FilterFieldType.UsersOrGroups
                    || f.type == FilterFieldType.Projects || f.type == FilterFieldType.Tags
                ) {
                    // console.log('[FilterController] setValues <Users|Groups|UsersOrGroups|Projects|Tags>, v:', v);
                    if (Array.isArray(v) && v?.length) {
                        fields.push(f.id + (v as string[]).map(id => '#' + id).join(','));
                    }
                    else if (v?.length) {
                        console.warn('[FilterController] setValues <Users|Groups|UsersOrGroups|Projects|Tags>, v:', v);
                    }
                }
                else if (f.type == FilterFieldType.Enum && v) {
                    const set = Object.keys(v).filter(vv => !!v[vv]);
                    // console.log('[FilterController] setValues <Enum>, v:', v, 'set:', set);
                    if (set.length) {
                        fields.push(f.id + set.join(','));
                    }
                }
                else if (f.type == FilterFieldType.Flag) {
                    // console.log('[FilterController] setValues <Flag>, v:', v);
                    if (v) {
                        fields.push(f.id);
                    }
                }
                else if (v != null) {
                    // console.log('[FilterController] setValues <???>, v:', v);
                    fields.push(f.id + v);
                }
            }
        }
        // console.log('[FilterController] setValues', 'fields:', fields, `def: "${def}"`);
        const text = fields.join(' ');
        this.updateState(values, text + (text != '' && text != null && def != null && def != '' ? ' ' + def : def || ''), def);
    }

    patchValues(changedValues: SM<any>): void {
        const values = { ...this.values.getValue(), ...changedValues };
        this.setValues(values);
    }

    private updateState(values: SM<any>, fText: string, def: string): void {
        // console.warn('[FilterController] updateState',
        //     `\n\t\tdef: "${def}"`,
        //     `\n\t\ttext: "${fText}"`,
        //     '\n\t\tvalues:', values,
        // );
        this.values.next(values);
        this.defText.next(def);
        this.text.next(fText);
        // console.log('[FilterController] updateState qsKey:', this.qsKey, 'routers:', !!(this._route && this._router));
        if (this.qsKey && this._route && this._router) {
            this._router.navigate([], {
                relativeTo: this._route,
                replaceUrl: true,
                queryParams: { [this.qsKey]: fText },
                queryParamsHandling: 'merge'
            });
        }
    }

    getFilter(): BaseFilter<any> | undefined {
        return this.filter;
    }

    getChangedFields(): { id: string, name: string, value: any }[] {
        return getChangedFilterFields(this.values.getValue(), this.filter);
    }

}
