import { BaseModel } from './base-model';
import { Guid, RecursivePartial } from './helper-types';
import { ObjectType } from './object-type';

export class BaseSystemModel extends BaseModel {
    static Type = ObjectType;
    _type?: ObjectType;

    id?: Guid;
    created?: Date;
    modified?: Date;
    createdBy?: Guid;
    modifiedBy?: Guid;

    constructor(json?: RecursivePartial<BaseSystemModel>) {
        super(json);
        this.parseBaseSystemModel(json || {});
    }

    parse(json: RecursivePartial<BaseSystemModel>): BaseSystemModel {
        super.parse(json);
        this.parseBaseSystemModel(json);
        return this;
    }

    private parseBaseSystemModel(json: RecursivePartial<BaseSystemModel>): void {
        BaseSystemModel.assignFields<BaseSystemModel>(this, json, ['id', 'createdBy', 'modifiedBy']);
        BaseSystemModel.assignDates<BaseSystemModel>(this, json, ['created', 'modified']);
    }
}
