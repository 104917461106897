import { RecursivePartial } from '@models/base';
import { BaseTaskAction, TaskActionType } from './_base-task-action';

export class TaskActionCreateChat extends BaseTaskAction {
    type: TaskActionType.CreateChat = TaskActionType.CreateChat;
    chatId?: string;

    constructor(json?: RecursivePartial<TaskActionCreateChat>) {
        super(json);
        this.parseTaskActionCreateChat(json || {});
    }

    parse(json: RecursivePartial<TaskActionCreateChat>): TaskActionCreateChat {
        super.parse(json);
        this.parseTaskActionCreateChat(json);
        return this;
    }

    private parseTaskActionCreateChat(json: RecursivePartial<TaskActionCreateChat>): void {
        TaskActionCreateChat.assignFields<TaskActionCreateChat>(this, json, ['chatId']);
    }
}
