import { BaseSystemModel, RecursivePartial } from '@models/base';
import { TaskStatus } from './task';

export class TaskStats extends BaseSystemModel {
    shortId!: string;
    orgId!: string;
    subj!: string;
    status!: TaskStatus;
    assignee?: string;
    assigneeGroup?: string;
    projectId?: string;
    dueDate?: Date;
    priority: number = 0;
    pluginId?: string;
    type: number = 0;
    filesCount: number = 0;
    filesSize: number = 0;

    _percent: number = 0;
    _percentReal: number = 0;

    constructor(json?: RecursivePartial<TaskStats>) {
        super(json);
        this.parseTaskStats(json || {});
    }

    parse(json: RecursivePartial<TaskStats>): TaskStats {
        super.parse(json);
        this.parseTaskStats(json);
        return this;
    }

    private parseTaskStats(json: RecursivePartial<TaskStats>): void {
        TaskStats.assignFields(this, json, [
            'shortId',
            'orgId',
            'status',
            'subj',
            'assignee',
            'assigneeGroup',
            'projectId',
            'priority',
            'pluginId',
            'type',
            'filesCount',
            'filesSize',
        ]);
        TaskStats.assignDates(this, json, ['dueDate']);
        if (this.priority > 3) {
            this.priority = 3;
        }
    }

}
