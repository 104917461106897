import { BaseSystemModel, RecursivePartial } from '@models/base';

export enum OrgPaidCallType {
    Invalid = 0,
    Meeting = 1,
    Webinar = 2,
    Demo = 3,
}

export class OrgPaidCall extends BaseSystemModel {
    duration: number = 0;
    cost: number = 0;
    roomType: OrgPaidCallType = OrgPaidCallType.Invalid;

    constructor(json?: RecursivePartial<OrgPaidCall>) {
        super(json);
        this.parseOrgPaidCall(json || {});
    }

    parse(json: RecursivePartial<OrgPaidCall>): OrgPaidCall {
        super.parse(json);
        this.parseOrgPaidCall(json);
        return this;
    }

    private parseOrgPaidCall(json: RecursivePartial<OrgPaidCall>): void {
        OrgPaidCall.assignFields<OrgPaidCall>(this, json, [
            'duration',
            'cost',
            'roomType',
        ]);
    }
}
