import { OLD_BaseSystemModel, RecursivePartial } from '@models/base';

export interface IRoleTpl {
    name: string;
    orgId: string;
    projectId?: string;
    privileges: string[];
}

export class Role extends OLD_BaseSystemModel {
    _type = OLD_BaseSystemModel.Type.Role;

    name!: string;
    orgId!: string;
    projectId?: string;
    privileges: string[] = [];

    constructor(json?: RecursivePartial<Role>) {
        super(json);
        this.parseRole(json || {});
    }

    parse(json: RecursivePartial<Role>): Role {
        super.parse(json);
        this.parseRole(json);
        return this;
    }

    private parseRole(json: RecursivePartial<Role>): void {
        Role.assignFields<Role>(this, json, ['name', 'orgId', 'projectId', 'privileges']);
    }
}
