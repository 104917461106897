export const ICONS = [
    'vzi-stack',
    'vzi-kanban3',
    'vzi-kanban2',
    'vzi-kanban',
    'vzi-share-screen',
    'vzi-team',
    'vzi-org',
    'vzi-idea',
    'vzi-crown',
    'vzi-movie',
    'vzi-pause_circle_outline',
    'vzi-play_circle_outline',
    'vzi-repeat',
    'vzi-video',
    'vzi-video_off',
    'vzi-volume_off',
    'vzi-volume_up',
    'vzi-web',
    'vzi-list-check',
    'vzi-featured_video',
    'vzi-call',
    'vzi-call_end',
    'vzi-call_split',
    'vzi-live_help',
    'vzi-location_off',
    'vzi-location_on',
    'vzi-contact_phone',
    'vzi-contact_mail',
    'vzi-mail',
    'vzi-email',
    'vzi-block',
    'vzi-cross',
    'vzi-copy',
    'vzi-cut',
    'vzi-paste',
    'vzi-reply',
    'vzi-inbox',
    'vzi-attach',
    'vzi-edit1',
    'vzi-cast',
    'vzi-cast_connected',
    'vzi-desktop_windows',
    'vzi-ch-down',
    'vzi-ch-left',
    'vzi-ch-right',
    'vzi-ch-up',
    'vzi-return',
    'vzi-tablet_android',
    'vzi-tv',
    'vzi-device_hub',
    'vzi-camera_alt',
    'vzi-camera_front',
    'vzi-camera_rear',
    'vzi-palette',
    'vzi-flip',
    'vzi-rotate-left',
    'vzi-rotate-right',
    'vzi-switch_video',
    'vzi-timer',
    'vzi-tune',
    'vzi-arrow-left',
    'vzi-arrow-right',
    'vzi-check',
    'vzi-arrow-up',
    'vzi-arrow-down',
    'vzi-phone_in_talk',
    'vzi-phone_missed',
    'vzi-ondemand_video',
    'vzi-phone_callback',
    'vzi-person',
    'vzi-person-o',
    'vzi-done',
    'vzi-history',
    'vzi-home',
    'vzi-input',
    'vzi-launch',
    'vzi-search',
    'vzi-settings',
    'vzi-settings_ethernet',
    'vzi-tab',
    'vzi-layout',
    'vzi-update',
    'vzi-tree',
    'vzi-desktop_access_disabled',
    'vzi-imagesearch_roller',
    'vzi-logout',
    'vzi-speed',
    'vzi-tag',
    'vzi-group',
    'vzi-emoji_events',
    'vzi-camera_flip',
    'vzi-cancel-send',
    'vzi-house',
    'vzi-agriculture',
    'vzi-alt_route',
    'vzi-anchor1',
    'vzi-campaign',
    'vzi-construction',
    'vzi-worker',
    'vzi-send-mail',
    'vzi-all-tasks',
    'vzi-history_toggle_off',
    'vzi-local_police',
    'vzi-multiple_stop',
    'vzi-outbox',
    'vzi-working',
    'vzi-qr_code',
    'vzi-qr_code_scanner',
    'vzi-rule',
    'vzi-star-o',
    'vzi-star',
    'vzi-support_agent',
    'vzi-tty',
    'vzi-video_settings',
    'vzi-team2',
    'vzi-menu',
    'vzi-chat1',
    'vzi-chat-1',
    'vzi-chat-2',
    'vzi-note',
    'vzi-comment',
    'vzi-send',
    'vzi-mobile',
    'vzi-lock',
    'vzi-fullscreen-exit',
    'vzi-fullscreen',
    'vzi-list1',
    'vzi-list-nested',
    'vzi-out',
    'vzi-in',
    'vzi-pacman',
    'vzi-spades',
    'vzi-clubs',
    'vzi-diamonds',
    'vzi-barcode',
    'vzi-qrcode',
    'vzi-undo',
    'vzi-redo',
    'vzi-stats-dots',
    'vzi-stats-bars',
    'vzi-trophy',
    'vzi-gift',
    'vzi-glass',
    'vzi-spoon-knife',
    'vzi-hammer2',
    'vzi-fire',
    'vzi-lab',
    'vzi-airplane',
    'vzi-truck',
    'vzi-switch',
    'vzi-list-numbered',
    'vzi-list2',
    'vzi-menu1',
    'vzi-cloud-download',
    'vzi-cloud-upload',
    'vzi-link',
    'vzi-heart',
    'vzi-happy',
    'vzi-happy2',
    'vzi-smile',
    'vzi-smile2',
    'vzi-tongue',
    'vzi-tongue2',
    'vzi-sad',
    'vzi-sad2',
    'vzi-wink',
    'vzi-wink2',
    'vzi-grin',
    'vzi-grin2',
    'vzi-cool',
    'vzi-cool2',
    'vzi-angry',
    'vzi-angry2',
    'vzi-evil',
    'vzi-evil2',
    'vzi-shocked',
    'vzi-shocked2',
    'vzi-baffled',
    'vzi-baffled2',
    'vzi-confused',
    'vzi-confused2',
    'vzi-neutral',
    'vzi-neutral2',
    'vzi-hipster',
    'vzi-hipster2',
    'vzi-wondering',
    'vzi-wondering2',
    'vzi-sleepy',
    'vzi-sleepy2',
    'vzi-frustrated',
    'vzi-frustrated2',
    'vzi-crying',
    'vzi-crying2',
    'vzi-warning',
    'vzi-notification',
    'vzi-question',
    'vzi-info',
    'vzi-cancel-circle',
    'vzi-blocked',
    'vzi-close',
    'vzi-enter',
    'vzi-exit',
    'vzi-infinite',
    'vzi-radio-checked2',
    'vzi-filter',
    'vzi-bold',
    'vzi-underline',
    'vzi-italic',
    'vzi-strikethrough',
    'vzi-omega',
    'vzi-sigma',
    'vzi-superscript',
    'vzi-subscript',
    'vzi-table2',
    'vzi-indent-increase',
    'vzi-indent-decrease',
    'vzi-embed2',
    'vzi-google',
    'vzi-facebook',
    'vzi-instagram',
    'vzi-whatsapp',
    'vzi-telegram',
    'vzi-twitter',
    'vzi-dropbox',
    'vzi-github',
    'vzi-windows',
    'vzi-skype',
    'vzi-linkedin',
    'vzi-pinterest',
    'vzi-tag-lines',
    'vzi-cabinet',
    'vzi-calendar1',
    'vzi-calendar2',
    'vzi-file1',
    'vzi-file2',
    'vzi-window',
    'vzi-mug',
    'vzi-project',
    'vzi-medal',
    'vzi-key',
    'vzi-layout2',
    'vzi-layout3',
    'vzi-layout1',
    'vzi-layout4',
    'vzi-layout5',
    'vzi-layout6',
    'vzi-layout7',
    'vzi-layout8',
    'vzi-resize',
    'vzi-resize1',
    'vzi-attachment',
    'vzi-bookmark2',
    'vzi-addressbook',
    'vzi-rain',
    'vzi-snow',
    'vzi-lightning',
    'vzi-sun',
    'vzi-moon',
    'vzi-cloudy',
    'vzi-rocket',
    'vzi-expand',
    'vzi-collapse',
    'vzi-popout',
    'vzi-popin',
    'vzi-checked',
    'vzi-error',
    'vzi-add',
    'vzi-minus',
    'vzi-shipping',
    'vzi-yingyang',
    'vzi-anchor',
    'vzi-magnet',
    'vzi-tags',
    'vzi-completed',
    'vzi-switch1',
    'vzi-plus',
    'vzi-remove',
    'vzi-trash-o',
    'vzi-file',
    'vzi-image',
    'vzi-photo',
    'vzi-picture-o',
    'vzi-edit',
    'vzi-forward',
    'vzi-eye',
    'vzi-eye-slash',
    'vzi-calendar',
    'vzi-thumbs-o-up',
    'vzi-thumbs-o-down',
    'vzi-bell-o',
    'vzi-floppy',
    'vzi-comment-o',
    'vzi-chat',
    'vzi-bell',
    'vzi-file-text',
    'vzi-code-fork',
    'vzi-mic',
    'vzi-mic-off',
    'vzi-3dots',
    'vzi-level-up',
    'vzi-level-down',
    'vzi-sort-asc',
    'vzi-sort-desc',
    'vzi-youtube',
    'vzi-bug',
    'vzi-vk',
    'vzi-paw',
    'vzi-tree1',
    'vzi-file-pdf',
    'vzi-file-word',
    'vzi-file-excel',
    'vzi-file-pp',
    'vzi-file-image',
    'vzi-file-archive',
    'vzi-file-audio',
    'vzi-file-video',
    'vzi-file-code',
    'vzi-share-alt',
    'vzi-birthday-cake',
    'vzi-user-add',
    'vzi-user-del',
    'vzi-balance-scale',
    'vzi-hand-peace-o',
    'vzi-site',
    'vzi-calendar-plus-o',
    'vzi-calendar-minus-o',
    'vzi-calendar-times-o',
    'vzi-calendar-check-o',
    'vzi-controller-fast-forward',
    'vzi-controller-jump-to-start',
    'vzi-controller-next',
    'vzi-controller-paus',
    'vzi-controller-play',
    'vzi-record',
    'vzi-controller-stop',
    'vzi-flow-cascade',
    'vzi-bookmark',
    'vzi-headphones',
    'vzi-zoom-in',
    'vzi-zoom-out',
];
