import { Component, Input } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { BaseComponent } from '@base/base.component';
import { SM, SortOption, Tag, isArraysDiff, removeFromArray } from '@models';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
    selector: 'sort-form, [sort-form]',
    templateUrl: './sort-form.component.html',
    styleUrls: ['./sort-form.component.less']
})
@Tag('SortFormComponent')
export class SortFormComponent extends BaseComponent {
    currentSort: { id: string, asc: boolean }[] = [];
    private _sortOrig: string[] = [];
    @Input()
    set sort(v: string[]) {
        this._sortOrig = v;
        this.updateSortFields(v);
    }
    get sort(): string[] {
        return this._sortOrig;
    }
    private _sortOptions: SortOption[] = [];
    @Input()
    set sortOptions(v: SortOption[]) {
        this._sortOptions = v;
        this.sortOptionsMap = {};
        if (v) {
            for (const so of v) {
                this.sortOptionsMap[so.id] = so;
            }
        }
    }
    get sortOptions(): SortOption[] {
        return this._sortOptions;
    }

    private _sortDefault?: string[];
    @Input()
    set sortDefault(v: string[] | undefined) {
        this._sortDefault = v;
        this.updateIsDefault();
    }
    get sortDefault(): string[] | undefined {
        return this._sortDefault;
    }

    usedFields: SM<boolean> = {};
    sortOptionsMap: SM<SortOption> = {};

    isDefault: boolean = false;

    constructor(public drawerRef: NzDrawerRef<SortFormComponent, string[] | undefined>) {
        super();
    }

    updateSortFields(v: string[]): void {
        this.usedFields = {};
        this.currentSort = [];
        if (v) {
            for (let s of v) {
                let asc = true;
                if (s[0] == '-') {
                    asc = false;
                    s = s.substr(1);
                }
                this.currentSort.push({ id: s, asc });
                this.usedFields[s] = true;
            }
        }
        else {
            this.currentSort = [];
        }
        this.updateIsDefault();
    }

    clearCurrentSort() {
        this.usedFields = {};
        this.currentSort = [];
        this.updateIsDefault();
    }

    removeSortField(i: number) {
        const sf = this.currentSort.splice(i, 1);
        this.usedFields[sf[0].id] = false;
        this.updateIsDefault();
    }

    addSortField(i: number, asc: boolean) {
        const so = this.sortOptions[i];
        this.usedFields[so.id] = true;
        this.currentSort.push({ id: so.id, asc });
        this.updateIsDefault();
    }

    addSortFieldDrop(e: CdkDragDrop<number>) {
        if (!e || !e.container || !e.container.data || !e.item || !e.item.data) {
            return;
        }
        this.addSortField(e.item.data, true);
    }

    removeSortFieldDrop(e: CdkDragDrop<number>) {
        if (!e || !e.container || !e.container.data || !e.item || !e.item.data) {
            return;
        }
        this.removeSortField(e.item.data);
    }

    dropped(e: CdkDragDrop<string>) {
        if (e.container.data == 'c' && e.previousContainer.data == 'c') {
            moveItemInArray(this.currentSort, e.previousIndex, e.currentIndex);
        }
        else if (e.container.data == 'c' && e.previousContainer.data == 'a') {
            this.usedFields[e.item.data] = true;
            this.currentSort.push({ id: e.item.data, asc: true });
            moveItemInArray(this.currentSort, this.currentSort.length - 1, e.currentIndex);
        }
        else if (e.container.data == 'a' && e.previousContainer.data == 'c') {
            this.usedFields[e.item.data] = false;
            removeFromArray(this.currentSort, el => el.id == e.item.data);
        }
        this.updateIsDefault();
    }

    applySort(): void {
        const sort: string[] = [];
        for (const sf of this.currentSort) {
            sort.push((sf.asc ? '' : '-') + sf.id);
        }
        this.drawerRef.close(sort);
    }

    resetSortOrder(): void {
        if (this.sortDefault) {
            this.updateSortFields(this.sortDefault);
        }
    }

    updateIsDefault(): void {
        this.isDefault = !isArraysDiff(this.currentSort.map(k => (k.asc ? '' : '-') + k.id), this.sortDefault);
    }

}
