import { BaseFilterField, FilterFieldType } from '@models/filters/base-filter-field';
import { Person } from '@models/users';
import { matchFilter } from '@models/utils/strings';

export class PersonFilterFieldDefault extends BaseFilterField<Person, string, true> {
    id: string = 'default';
    name: string = 'Имя';
    desc: string = 'Фильтрует по имени';
    type: FilterFieldType.String = FilterFieldType.String;
    single = true as const;

    value(o: Person): string {
        return o.name;
    }

    match(o: Person, v: string): boolean {
        if (!v || matchFilter(o.firstName, v) || matchFilter(o.lastName, v)) {
            return true;
        }
        return false;
    }

}
