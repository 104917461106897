import { BaseModel, RecursivePartial } from '@models/base';

export enum VzFileContentType {
    Audio = 'audio',
    Video = 'video',
    Image = 'image',
    Gif   = 'gif',
    Pdf   = 'pdf'
}

export class VzFileMetadata extends BaseModel {
    static Type = VzFileContentType;
    seconds: number = 0;
    pages: number = 0;
    width: number = 0;
    height: number = 0;
    type?: VzFileContentType;
    playable?: boolean;

    constructor(json?: RecursivePartial<VzFileMetadata>) {
        super(json);
        this.parseVzFileMetadata(json || {});
    }

    parse(json: RecursivePartial<VzFileMetadata>): VzFileMetadata {
        super.parse(json);
        this.parseVzFileMetadata(json);
        return this;
    }

    private parseVzFileMetadata(json: RecursivePartial<VzFileMetadata>): void {
        VzFileMetadata.assignFields<VzFileMetadata>(this, json, [
            'seconds',
            'pages',
            'width',
            'height',
            'type',
            'playable',
        ]);
    }
}
